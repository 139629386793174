import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { UserPermissionData } from 'types/User/UserPermissionData';

type Params = PaginatedQueryParams & {
  users: string[];
};

export const useGetUserOperationsQuery = ({ ...params }: Params) => {
  return usePaginatedQuery<UserPermissionData>({
    queryKey: ['register/operation', params.users, params.current, params.pageSize, params.sort, params.search],
    url: `register/operation`,
    staleTime: 1000,
    params: {
      ...params,
      params: {
        users: params.users,
      },
    },
  });
};
