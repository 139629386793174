import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import PermissionGroupFormData from 'types/PermissionGroup/PermissionGroupFormData';

export const useUpdatePermissionGroupMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (data: PermissionGroupFormData) => {
      if (!data?.id) return alert({ title: t('record-not-found'), type: 'error' });
      const response = await api.put(`admin/group/${data.id}`, data);
      return response.data;
    },
    mutationKey: ['updatePermissionGroup'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
