import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Empty, Form, Input, Spin } from "antd";
import { FormItem } from "components";
import { DraftButton } from "pages/Contracts/components/DraftButton";

import { useContract } from "../../../../../../hooks/fetch/useContract";
import { Tab } from "../../../../components/Tab";
import { useContractContext } from "../../../../context/ContractContext";
import { CommentList } from "./CommentList";
import { Container, InputGroup, Title, Wrapper } from "./styles";
import ApiSelect from "components/ApiSelect";
import { SelectOptionType } from "components/Select/types";

export const Comments = () => {
  const {
    updateContract,
    contractId,
    contract,
    isFetching: isFetchingContract,
    isSelectedDocumentMain,
  } = useContractContext();
  const {
    sendComment,
    isSendingComment,
    getJustificationsOptions,
    isFetchingJustifications,
  } = useContract();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [comment, setComment] = useState("");
  const [shouldEnableSubmit, setShouldEnableSubmit] = useState(false);

  const handleChangeComment = (evt: ChangeEvent<HTMLTextAreaElement>) =>
    setComment(evt.target.value);

  const handleSendCommentClick = async () => {
    form.validateFields().then(async (values) => {
      const response = await sendComment(
        contractId,
        values.comment,
        values.type_id,
      );

      if (response) {
        setComment("");
        setShouldEnableSubmit(false);
        form.resetFields();
        updateContract(response, isSelectedDocumentMain);
      }
    });
  };

  const enableSubmit = (value: string | SelectOptionType[]) => {
    if (value) setShouldEnableSubmit(true);
  };

  return (
    <Tab>
      <Wrapper>
        <Spin spinning={isFetchingContract}>
          <Title>{t("pages.edocuments.emission.comments.title")}</Title>
          <Container>
            {contract?.comments?.length ? (
              <CommentList comments={contract?.comments ?? []} />
            ) : (
              <Empty />
            )}
            <InputGroup>
              <Form form={form}>
                <FormItem
                  name="type_id"
                  label={t(
                    "pages.edocuments.emission.comments.form.justification",
                  )}
                  rules={[{ required: true }]}
                >
                  <ApiSelect
                    showSearch
                    fetchOptions={(value, page) => {
                      return getJustificationsOptions({
                        search: value,
                        page,
                        per_page: 10,
                      });
                    }}
                    loading={isFetchingJustifications}
                    onChange={enableSubmit}
                    breakOptions
                    size="large"
                    listHeight={201}
                    dropdownMatchSelectWidth={false}
                  />
                </FormItem>
                <FormItem name="comment">
                  <Input.TextArea
                    placeholder={t(
                      "pages.edocuments.emission.comments.form.comments",
                    )}
                    value={comment}
                    maxLength={300}
                    onChange={handleChangeComment}
                    size="large"
                  />
                </FormItem>
                <DraftButton
                  isLoading={isSendingComment}
                  onClick={handleSendCommentClick}
                  disabled={!shouldEnableSubmit}
                  permissions="contract.comment.store"
                >
                  {t("pages.edocuments.emission.comments.sendComment")}
                </DraftButton>
              </Form>
            </InputGroup>
          </Container>
        </Spin>
      </Wrapper>
    </Tab>
  );
};
