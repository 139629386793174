import { useState } from "react";

import { DTOErrorResponse } from "types/DTOReports";
import { useAbility } from "hooks/ability";
import api, { handleRequest } from "services/api/api";
import { downloadHandler } from "services/download";

import type { AmortizationData } from "../../@types/data/AmortizationData";
import creditApi from "../../services/api/credit";
import { ItemsType, usePage } from "../page";

export const useCreditRequest = () => {
  const { alertStatus } = usePage();
  const [isFetching, setIsFetching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUpdatingAmortization, setIsUpdatingAmortization] = useState(false);
  const [isFetchingIntegrations, setIsFetchingIntegrations] = useState(false);
  const [isUpdatingSapStatus, setIsUpdatingSapStatus] = useState(false);
  const [isFetchingSubsidiarySapCode, setIsFetchingSubsidiarySapCode] =
    useState(false);
  const ability = useAbility();

  const get = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await creditApi.requests.get(params);
      return response.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getOptions = async (params = {}) => {
    try {
      const response = await creditApi.requests.get(params);

      return {
        data: response.data.data.map(
          (credit: {
            id: any;
            number: any;
            requester_person: { name: string; company_name: string };
          }) => ({
            key: credit.id,
            value: credit.id,
            label: `${credit.number} - ${
              credit.requester_person.name
                ? credit.requester_person.name
                : credit.requester_person.company_name
            }`,
            ...credit,
          }),
        ),
        lastPage: response.data.last_page,
      };
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return { data: [] as ItemsType[], lastPage: 1 };
    } finally {
      setIsFetching(false);
    }
  };

  const show = async (id: string) =>
    handleRequest(() => creditApi.requests.getById(id));

  const downloadNotDownloadedFiles = async (id: string) => {
    setIsDownloading(true);
    const response = await api.get(
      `credit-request/${id}/attachment/download-not-downloaded-files`,
      {
        responseType: "blob",
      },
    );
    setIsDownloading(false);
    downloadHandler(response);
  };

  const removeCommentsFormalizationGuarantees = async (id: string) => {
    const response = await handleRequest(
      () => creditApi.envelope.destroycomment(id),
      setIsFetching,
    );
    return response;
  };

  const updateAmortization = (
    creditRequestId: string,
    amortizationData: AmortizationData,
  ) => {
    const response = handleRequest(
      () => creditApi.amortization.update(creditRequestId, amortizationData),
      setIsUpdatingAmortization,
    );
    return response;
  };

  const getIntegrationsByWorkerId = async (workerId: string, params = {}) => {
    const response = handleRequest(
      () =>
        ability.can("worker.errors.index", "")
          ? creditApi.integrations.getByWorkerId(workerId, params)
          : null,
      setIsFetchingIntegrations,
    );
    return response;
  };

  const updateSapStatus = async (creditRequestId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.integrations.updateSapStatus(creditRequestId, params),
      setIsUpdatingSapStatus,
    );
    return response;
  };

  const forceSapIntegration = async (creditRequestId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.integrations.forceSapIntegration(creditRequestId, params),
      setIsUpdatingSapStatus,
    );
    return response;
  };

  const forceIntegration = async (route: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.integrations.forceIntegration(route, params),
      setIsUpdatingSapStatus,
    );
    return response;
  };

  const getSubsidiarySapCode = async (providerSapCode: string) => {
    const response = handleRequest(
      () => creditApi.integrations.getSubsidiarySapCode(providerSapCode),
      setIsFetchingSubsidiarySapCode,
    );
    return response;
  };

  return {
    get,
    getOptions,
    isFetching,
    isDownloading,
    downloadNotDownloadedFiles,
    show,
    removeCommentsFormalizationGuarantees,
    updateAmortization,
    isUpdatingAmortization,
    getIntegrationsByWorkerId,
    isFetchingIntegrations,
    updateSapStatus,
    isUpdatingSapStatus,
    forceSapIntegration,
    forceIntegration,
    getSubsidiarySapCode,
    isFetchingSubsidiarySapCode,
  };
};
