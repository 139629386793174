import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { CURRENCY_BRL_ID, CURRENCY_EUR_ID, WEIGHT_UNIT_KG_ID, WEIGHT_UNIT_TON_ID } from 'configs/constants';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { AdvanceValueInput } from '../../atoms/AdvanceValueInput';
import { BasePortSelect } from '../../atoms/BasePortSelect';
import { DailyPriceValueInput } from '../../atoms/DailyPriceValueInput';
import { GrossValueInput } from '../../atoms/GrossValueInput';
import { JsonDataCurrencyTypeIdSelect } from '../../atoms/JsonDataCurrencyTypeIdSelect';
import { JsonDataExpactedPaymentAtInput } from '../../atoms/JsonDataExpectedPaymentAtInput';
import { JsonDataInterestRateInput } from '../../atoms/JsonDataInterestRateInput';
import { JsonDataWeightTypeIdSelect } from '../../atoms/JsonDataWeightTypeIdSelect';
import { NegotiatedFixationDateInput } from '../../atoms/NegotiatedFixationDateInput';

export const AdvanceDataAdvanceProductDelivered = () => {
  const { t } = useTranslation();

  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const isJsonDataCurrencyTypeIdSelectDisabled = useMemo(() => {
    return pageStatus.viewing;
  }, [pageStatus, contractFieldsValue]);

  const handleChangeJsonDataCurrencyTypeId = (value: any, option: any) => {
    if (value) setIsDolarSelected(option.label);
  };

  return (
    <div className="group-form">
      <h2>{t('pages.credit-request.form.titles.down-payment-data')}</h2>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <AdvanceValueInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataInterestRateInput isRequired={false} />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataCurrencyTypeIdSelect
            onChange={handleChangeJsonDataCurrencyTypeId}
            blacklist={[CURRENCY_EUR_ID, CURRENCY_BRL_ID]}
            disabled={isJsonDataCurrencyTypeIdSelectDisabled}
          />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <BasePortSelect />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <DailyPriceValueInput />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWeightTypeIdSelect blacklist={[WEIGHT_UNIT_KG_ID, WEIGHT_UNIT_TON_ID]} />
        </Col>

        {/* Monsanto/Bayer */}
        {/* {selectedSubDivision === 'soybean' && (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataAzParticipantSelect />
          </Col>
        )} */}

        {/* FETHAB */}
        <>
          {/* <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{
              display: isFethabEnableAdvancementForm(creditRequestFormState.selectedStateId) ? 'block' : 'none',
            }}
          >
            <JsonDataFethabSelect isVisible={isFethabEnableAdvancementForm(creditRequestFormState.selectedStateId)} />
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{
              display:
                creditRequestFormState.isDolarSelected &&
                isFethabEnableAdvancementForm(creditRequestFormState.selectedStateId)
                  ? 'block'
                  : 'none',
            }}
          >
            <FethabQuotationInput
              label={t('pages.credit-request.form.ptax')}
              tooltip={t('pages.credit-request.form.get_ptax')}
              isVisible={
                creditRequestFormState.isDolarSelected &&
                isFethabEnableAdvancementForm(creditRequestFormState.selectedStateId)
              }
            />
          </Col> */}
        </>
        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <TodayPtaxInput />
        </Col> */}
        {/* <Col
          xs={24}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          xxl={6}
          style={{ display: !isFundeinfraSelectVisible ? 'none' : '' }}
        >
          <FundeinfraSelect isVisible={isFundeinfraSelectVisible} />
        </Col> */}

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <GrossValueInput />
        </Col>
        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataTaxesInput />
        </Col> */}
        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataRoyaltiesMonsantoInput />
        </Col> */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataExpactedPaymentAtInput
            disabledDate={date => {
              const currentDate = moment();
              const sunday = 0;
              const saturday = 6;
              let daysRemaining = 5;

              const newDate = currentDate.clone();

              while (daysRemaining > 0) {
                newDate.add(1, 'days');
                if (newDate.day() !== sunday && newDate.day() !== saturday) {
                  daysRemaining -= 1;
                }
              }

              if (date.isBefore(newDate, 'date')) return true;
              return false;
            }}
          />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <NegotiatedFixationDateInput />
        </Col>

        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataNetAmountInput />
        </Col> */}
        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <TodayPtaxInput />
        </Col> */}
      </Row>
    </div>
  );
};
