import { AttachmentInputTranslation } from "./types";

export const defaultTranslation = {
  onUploadSuccess: "Success",
  onUploadError: "Error",
  dragAndDropUploadText: "Upload",
  existingAttachmentsText: "Existing Attachments",
  downloadIconText: "Download",
  previewIconText: "Preview",
  destroyIconText: "Destroy",
  alerts: {
    destroy: {
      success: "File deleted",
    },
  },
  downloadAllButton: "Download All",
  downloadingAllMessage: "Downloading all files",
} as AttachmentInputTranslation;
