import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiRefresh } from "react-icons/bi";
import { Link } from "react-router-dom";

import { Spin } from "antd";
import { FormItem } from "components";
import appConfigs from "configs/app";
import { useCreditRequestContext } from "context/CreditRequestContext";
import { useCache } from "hooks/cache";
import { useBasePort } from "hooks/fetch/useBasePort";
import { usePriceDay } from "hooks/fetch/usePriceDay";
import moment from "moment";
import { useCreditRequestStore } from "pages/CreditRequest/store/use-credit-request-store";

import { Button } from "./styles";
import ApiSelect from "components/ApiSelect";

export const BasePortSelect = () => {
  const { t } = useTranslation();
  const { getBasePortsOptions } = useBasePort();
  const { index: getPriceDay, isFetching } = usePriceDay();
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();

  const selectedSubDivisionId = useCreditRequestStore(
    (state) => state.selectedSubDivisionId,
  );
  const setIsBlocking = useCreditRequestStore((state) => state.setIsBlocking);
  const selectedContractNumber = useCreditRequestStore(
    (state) => state.selectedContractNumber,
  );
  const basePort = useCreditRequestStore((state) => state.basePort);
  const pageStatus = useCreditRequestStore((state) => state.pageStatus);

  const [noPriceDayFound, setNoPriceDayFound] = useState(false);
  const [portId, setPortId] = useState("");

  const handleGetBasePorts = async (value: string, page: number) => {
    return getBasePortsOptions({ params: { search: value, page } });
  };

  const getCurrentPriceDay = async (value?: string) => {
    const response = await getPriceDay({
      params: {
        base_port: value || portId,
        sub_division: cache
          .getCreditOptions("sub_division")
          .find((subdivision) => subdivision.value === selectedSubDivisionId)
          ?.key,
        subsidiary: creditForm.getFieldValue("subsidiary_id"),
        date: moment().format(appConfigs.formatApiDate),
        currency_type_id: creditForm.getFieldValue(
          "json_data.currency_type_id",
        ),
        incoterm_id: cache
          .getCreditOptions("incoterm")
          .find(
            (incoterm) =>
              incoterm.label === creditForm.getFieldValue("json_data.freight"),
          )?.key,
      },
    });
    if (response.data.length) {
      creditForm.setFieldsValue({ daily_price_value: response.data[0].value });
      setNoPriceDayFound(false);
    } else {
      creditForm.setFieldsValue({ daily_price_value: undefined });
      setNoPriceDayFound(true);
    }
  };

  return (
    <Spin spinning={isFetching}>
      <FormItem
        label={
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <span>{t("pages.credit-request.form.base_port_id")}</span>
            {portId ? (
              <Button onClick={() => portId && getCurrentPriceDay()}>
                <BiRefresh />
              </Button>
            ) : null}
          </div>
        }
        name="base_port_id"
        rules={[{ required: true }]}
        extra={
          noPriceDayFound ? (
            <Link
              style={{
                color: "red",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              to="/register/price"
              target="_blank"
            >
              {t("pages.credit-request.form.no_price_day_found")}
            </Link>
          ) : null
        }
      >
        <ApiSelect
          showSearch
          initialOptions={
            basePort
              ? [
                  {
                    key: basePort.id,
                    label: basePort.name,
                    value: basePort.id,
                  },
                ]
              : []
          }
          listHeight={300}
          disabled={!pageStatus.new || !selectedContractNumber}
          fetchOptions={handleGetBasePorts}
          onChange={async (value: any) => {
            if (value) {
              setPortId(value);
              getCurrentPriceDay(value);
              setIsBlocking(true);
            } else {
              setPortId("");
              creditForm.setFieldsValue({ daily_price_value: undefined });
            }
          }}
        />
      </FormItem>
    </Spin>
  );
};
