import { useMemo } from 'react';

import {
  DOCUMENT_TYPE_INSS,
  DOCUMENT_TYPE_SINTEGRA,
  DOCUMENT_TYPE_CONTRACT,
  DOCUMENT_DEPOSITARY,
  DOCUMENT_STATEMENT_OR_INJUNCTION,
  DOCUMENT_TYPE_CREDIT_ASSIGNMENT,
  DOCUMENT_MONSANTO,
  DOCUMENT_TYPE_TAKEUP,
  DOCUMENT_TYPE_CONTRACT_ADVANCE_ADDITIVE,
  DOCUMENT_TYPE_DEPOSIT_CONTRACT,
} from 'configs/constants';
import { useCache } from 'hooks/cache';

export const useGetFileTypesFromCache = () => {
  const cache = useCache();

  const fileTypeInss = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_INSS);
  }, [cache]);

  const fileTypeFethab = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_SINTEGRA);
  }, [cache]);

  const fileTypeContract = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_CONTRACT);
  }, [cache]);

  const fileTypeDepositary = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_DEPOSITARY);
  }, [cache]);

  const fileTypeStatementOrInjunction = useMemo(() => {
    return cache
      .getCreditOptions('document_type_credit')
      .find((x: any) => x.value === DOCUMENT_STATEMENT_OR_INJUNCTION);
  }, [cache]);

  const fileTypeMonsantoReceipt = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_MONSANTO);
  }, [cache]);

  const fileTypeAssignee = useMemo(() => {
    return cache
      .getCreditOptions('document_type_credit')
      .find((x: any) => x.slug === 'credit_assignment' || x.id === DOCUMENT_TYPE_CREDIT_ASSIGNMENT);
  }, [cache]);

  const fileTypeTakeup = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_TAKEUP);
  }, [cache]);

  const fileTypeContractAdvanceAdditive = useMemo(() => {
    return cache
      .getCreditOptions('document_type_credit')
      .find((x: any) => x.value === DOCUMENT_TYPE_CONTRACT_ADVANCE_ADDITIVE);
  }, [cache]);

  const fileTypeDepositContract = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_DEPOSIT_CONTRACT);
  }, [cache]);

  return {
    fileTypeInss,
    fileTypeFethab,
    fileTypeContract,
    fileTypeDepositary,
    fileTypeStatementOrInjunction,
    fileTypeMonsantoReceipt,
    fileTypeAssignee,
    fileTypeTakeup,
    fileTypeContractAdvanceAdditive,
    fileTypeDepositContract,
  };
};
