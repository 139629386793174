import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/button';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputNumber } from 'components/ui/input';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

const initialAssignee = {
  cod_gatec: '',
  name: '',
  value: 0,
  id: '',
};

export const AssigneesForm = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const requestedValue = creditForm.getFieldValue('requested_amount');
  const assigneeToEdit = useCreditRequestStore(state => state.assigneeToEdit);
  const setAssigneeToEdit = useCreditRequestStore(state => state.setAssigneeToEdit);
  const setAssigneeList = useCreditRequestStore(state => state.setAssigneeList);
  const assigneeList = useCreditRequestStore(state => state.assigneeList);
  const setIsAssigneeModalOpen = useCreditRequestStore(state => state.setAssigneeModalOpen);

  const form = useForm({
    defaultValues: assigneeToEdit ?? initialAssignee,
  });

  const totalAssigneesValue = assigneeList.reduce((acc, assignee) => acc + assignee.value, 0);

  const handleSubmit = () => {
    form.handleSubmit(values => {
      if (assigneeToEdit)
        setAssigneeList(assigneeList.map(assignee => (assignee.id === assigneeToEdit.id ? values : assignee)));
      else
        setAssigneeList([
          ...assigneeList,
          {
            ...values,
            id: `new-${Math.random().toString(36).substring(7)}`,
          },
        ]);
      setIsAssigneeModalOpen(false);
    })();
  };

  useEffect(() => {
    return () => {
      setAssigneeToEdit(null);
      form.reset(initialAssignee);
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="overflow-hidden flex flex-col" form={form}>
      <div className="flex flex-col gap-2 overflow-y-auto h-full p-4">
        <FormGrid>
          <FormItem name="cod_gatec" label={t('assignee-gatec-code')}>
            <Input autoFocus maxLength={255} />
          </FormItem>
          <FormItem name="name" label={t('assignee-name')}>
            <Input maxLength={255} />
          </FormItem>
          <FormItem
            name="value"
            label={t('assignment-value')}
            rules={{
              required: true,
              validate: {
                moreThanRequested: value =>
                  value + totalAssigneesValue <= requestedValue || t('assignee-value-exceeds-requested-value'),
              },
            }}
          >
            <InputNumber />
          </FormItem>
        </FormGrid>
      </div>

      <div className="flex w-full justify-between items-center p-4 border-t border-gray-200 shadow-md">
        <Button type="button" variant="secondary" onClick={() => setAssigneeToEdit(null)}>
          {t('cancel')}
        </Button>
        <Button type="button" onClick={handleSubmit}>
          {t('save')}
        </Button>
      </div>
    </Form>
  );
};
