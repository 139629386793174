import { ReportModalTranslation } from "components/ReportModal/types";
import { TFunction } from "i18next";

export const getReportModalTranslation = (t: TFunction) =>
  ({
    status: {
      processed: "Processado",
      processing: "Processando",
      failed: "Falhado",
    },
    columns: {
      fileName: t("pages.credit-request.modal-reports.table.headers.name"),
      userName: t("pages.credit-request.modal-reports.table.headers.user"),
      date: t("pages.credit-request.modal-reports.table.headers.created_at"),
      status: t("pages.credit-request.modal-reports.table.headers.status"),
      options: t("pages.credit-request.modal-reports.table.headers.actions"),
    },
    buttons: {
      download: t("pages.credit-request.modal-reports.buttons.download"),
    },
    closeModal: t("pages.credit-request.modal-reports.buttons.close"),
    filters: {
      date: t("pages.credit-request.modal-reports.filters.date"),
    },
    headers: {
      code: t("pages.credit-request.modal-reports.table.headers.code"),
      user: t("pages.credit-request.modal-reports.table.headers.user"),
    },
    newReport: t("pages.credit-request.modal-reports.buttons.new"),
    send: t("pages.credit-request.modal-reports.buttons.send"),
    table: {
      pagination: {
        totalOfRegisters: t(
          "pages.credit-request.modal-reports.table.pagination.totalOfRegisters",
        ),
        page: t("pages.credit-request.modal-reports.table.pagination.page"),
      },
    },
  }) as ReportModalTranslation;
