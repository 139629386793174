import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const JsonDataAzParticipantSelect = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const setIsAzParticipant = useCreditRequestStore(state => state.setIsAzParticipant);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_az_participant')}
      name="json_data.az_participant"
      rules={[{ required: true }]}
    >
      <Select
        options={[
          {
            key: 'az_participant',
            value: 'az_participant',
            label: t('pages.credit-request.form.requester_participant'),
          },
          {
            key: 'have_credit',
            value: 'have_credit',
            label: t('pages.credit-request.form.have_credito'),
          },
          {
            key: 'not_az_participant',
            value: 'not_az_participant',
            label: t('pages.credit-request.form.requester_az_participant_no'),
          },
          {
            key: 'conventional_soy',
            value: 'conventional_soy',
            label: t('pages.credit-request.form.conventional_soy'),
          },
        ]}
        disabled={pageStatus.viewing}
        onChange={async (value: any) => {
          if (value) {
            setIsBlocking(true);

            setIsAzParticipant(value);
          }
        }}
      />
    </FormItem>
  );
};
