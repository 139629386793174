import React from "react";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaQuestionCircle,
  FaRegEdit,
  FaRegTrashAlt,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";

import { Modal } from "antd";

import { Icons, ModalStatusProps } from "./types";

const icons: Icons = {
  confirm: <FaQuestionCircle />,
  success: <FaCheckCircle />,
  error: <FaTimesCircle />,
  warning: <FaExclamationCircle />,
  info: <FaInfoCircle />,
  edit: <FaRegEdit />,
  delete: <FaRegTrashAlt />,
};

export function ModalStatus({
  type,
  overwride,
  iconName,
  title,
  subTitle,
  description,
  okText,
  onOk,
  cancelText,
  onCancel,
  afterClose,
  width,
}: ModalStatusProps) {
  /** Define the icon to use in modal */
  const icon = iconName || overwride || type;
  let useType;
  let useIcon;
  let useStyle;

  switch (icon) {
    case "confirm":
      useType = "confirm";
      useIcon = icons.confirm;
      useStyle = "confirm";
      break;
    case "success":
      useType = "success";
      useIcon = icons.success;
      useStyle = "success";
      break;
    case "info":
      useType = "info";
      useIcon = icons.info;
      useStyle = "info";
      break;
    case "error":
      useType = "error";
      useIcon = icons.error;
      useStyle = "error";
      break;
    case "errorLogin":
      useType = "error";
      useIcon = icons.error;
      useStyle = "error";
      break;
    case "warning":
      useType = "confirm";
      useIcon = icons.warning;
      useStyle = "warning";
      break;
    case "edit":
      useType = "confirm";
      useIcon = icons.edit;
      useStyle = "confirm";
      break;
    case "delete":
      useType = "confirm";
      useIcon = icons.delete;
      useStyle = "error";
      break;
    default:
      useType = "confirm";
      useIcon = icons.confirm;
      useStyle = overwride || type;
  }

  /** Define the props with default values to use in modal */
  const config = {
    title,
    className: `modal-status modal-status-${useStyle}`,
    icon: <div className="modal-status-icon">{useIcon}</div>,
    content: (
      <>
        <span className="modal-status-subtitle">
          {typeof subTitle === "object"
            ? Object.values(subTitle).map((item) => {
                return <div key={item}>{item}</div>;
              })
            : subTitle}
        </span>
        <div className="modal-status-description">{description}</div>
      </>
    ),
    closable: false,
    closeIcon: <FaTimes />,
    maskClosable: false,
    maskStyle: {
      backgroundColor: "rgba(39, 48, 74, 0.5)",
    },
    okText,
    cancelText,
    onOk,
    onCancel,
    afterClose,
    width,
  };

  /** Define the type modal to create */
  switch (useType) {
    case "confirm":
      return Modal.confirm(config);
    case "success":
      return Modal.success(config);
    case "info":
      return Modal.info(config);
    case "error":
      return Modal.error(config);
    case "warning":
      return Modal.warning(config);
    default:
      return Modal.confirm(config);
  }
}

export default ModalStatus;
