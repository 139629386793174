import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from 'components/ui/dialog';
import { PageTitle } from 'components/ui/page-title';
import { useAbility } from 'hooks/ability';
import TeamStatusData from 'types/CreditRequest/DTOTeamStatus';

import { useTeamStatusStore } from '../stores/useTeamStatusStore';
import { SlaForm } from './sla.form';
import { SlaTable } from './sla.table';

export type SlaModalProps = DialogProps & { children?: React.ReactNode; teamStatus: TeamStatusData };

export const SlaModal = ({ children, teamStatus, ...props }: SlaModalProps) => {
  const ability = useAbility();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const isSlaModalOpen = useTeamStatusStore(state => state.isSlaModalOpen);
  const setIsSlaModalOpen = useTeamStatusStore(state => state.setSlaModalOpen);

  const handleSlaFormSuccess = () => {
    queryClient.refetchQueries({ queryKey: ['credit-request/team-status-sla'], type: 'active' });
  };

  return (
    <Dialog {...props} open={isSlaModalOpen} onOpenChange={setIsSlaModalOpen}>
      <DialogTrigger onClick={e => e.stopPropagation()}>{children}</DialogTrigger>
      <DialogContent
        bodyClassName="overflow-hidden flex flex-col p-0"
        className="w-[90%] md:w-1/2"
        onClick={e => e.stopPropagation()}
      >
        <DialogHeader>SLAs - {teamStatus?.name}</DialogHeader>
        <div className="mb-4 h-fit min-h-min overflow-hidden border-b border-gray-200">
          {ability.can('credit.request.teamstatussla.store', '') && <SlaForm onSuccess={handleSlaFormSuccess} />}
        </div>
        <PageTitle className="text-md mb-2 px-4">{t('registered-slas')}</PageTitle>
        <SlaTable teamStatusId={teamStatus?.id} />
      </DialogContent>
    </Dialog>
  );
};
