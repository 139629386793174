import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Form, FormInstance, TablePaginationConfig } from 'antd';
import { SelectOptionType } from 'components/Select/types';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_CUSTODY,
  DIVISION_SOFT_ID,
  SUBDIVISION_COFFEE_ID,
  SUBDIVISION_COTTON_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_ID,
} from 'configs/constants';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
import { usePtax } from 'hooks/fetch/usePtax';
import { PaginationAction, usePagination } from 'hooks/usePagination';
import { useGetCurrentTeamStatus } from 'pages/CreditRequest/hooks/useGetCurrentTeamStatus';
import { useLoadCreditRequestData } from 'pages/CreditRequest/hooks/useLoadCreditRequestData';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

type FormError = {
  message: string;
  [key: string]: string | number;
};

export interface CreditRequestContextProps {
  isFetchingPtax: boolean;
  loadSubsidiaries: (regionId: string) => Promise<void>;
  setIsLoadingSubsidiaries: (isLoadingSubsidiaries: boolean) => void;
  isLoadingSubsidiaries: boolean;
  teamStatusCurrent: SelectOptionType;
  paginationConfig: TablePaginationConfig;
  dispatchPagination: React.Dispatch<PaginationAction>;
  handlePreviewFile: (doc: any) => void;
  fileToPreview: any;
  setFileToPreview: (fileToPreview: any) => void;
  formErrors: FormError[];
  setFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  setFormStatus: React.Dispatch<React.SetStateAction<'danger' | 'warning' | null>>;
  addFormErrors: (error: string | FormError) => void;
  formStatus: 'danger' | 'warning' | null;
  canEditCustodyFields: boolean;
  creditForm: FormInstance;
  amortizationForm: FormInstance;
  initialProviderOption?: SelectOptionType[];
  changeSapStatusManually: (integrationId: string) => Promise<unknown>;
  forceSapStatusIntegration: (creditRequestId: string) => Promise<unknown>;
  forceIntegrationStatus: (route: string) => Promise<unknown>;
  isUpdatingSapStatus: boolean;
  contractId: string;
}

export const CreditRequestContext = createContext<CreditRequestContextProps>({} as CreditRequestContextProps);

export const useCreditRequestContext = () => useContext(CreditRequestContext);

export const CreditRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { subsidiaries } = useAuth();
  const { updateSapStatus, isUpdatingSapStatus, forceSapIntegration, forceIntegration } = useCreditRequest();
  const { loadCreditRequestData } = useLoadCreditRequestData();

  const [creditForm] = Form.useForm();
  const [amortizationForm] = Form.useForm();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const setThirdProviders = useCreditRequestStore(state => state.setThirdProviders);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);
  const isCottonPrepay = useCreditRequestStore(state => state.isCottonPrepay);

  const creditRequestStore = useCreditRequestStore();

  const { isFetching: isFetchingPtax } = usePtax();

  const { paginationConfig, dispatchPagination } = usePagination({
    initialPagination: { current: 1, total: 1, pageSize: 10 },
  });

  const cache = useCache();

  const [isLoadingSubsidiaries, setIsLoadingSubsidiaries] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [formStatus, setFormStatus] = useState<'danger' | 'warning' | null>(null);

  const addFormErrors = (error: string | FormError) => {
    setFormErrors(old => [
      ...old,
      typeof error === 'string'
        ? {
            message: error,
          }
        : error,
    ]);
  };

  const handlePreviewFile = (doc: any) => setFileToPreview(doc);

  const teamStatusCurrent = useGetCurrentTeamStatus();

  const loadSubsidiaries = async (regionId: string) => {
    creditRequestStore.setSubsidiaries(cache.getCreditOptions('subsidiary').filter(s => s.region_id === regionId));
  };

  const initialProviderOption = useMemo(
    () =>
      creditRequestStore.selectedProviderOption
        ? [creditRequestStore.selectedProviderOption, ...creditRequestStore.people]
        : creditRequestStore.people,
    [creditRequestStore.selectedProviderOption, creditRequestStore.people],
  );

  const canEditCustodyFields = useMemo(
    () =>
      pageStatus.new
        ? true
        : teamStatusCurrent?.key
        ? isCottonPrepay
          ? TEAM_STATUS_COMMERCIAL_PENDING_ID === teamStatusCurrent?.key
          : CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_CUSTODY.includes(teamStatusCurrent?.key)
        : false,
    [teamStatusCurrent?.key, pageStatus],
  );

  const changeSapStatusManually = async (integrationId: string) => {
    const response = await updateSapStatus(integrationId, {
      description: 'MANUAL',
      status: 'processed',
    });
    if (response) loadCreditRequestData();
    return response;
  };

  const forceSapStatusIntegration = async (creditRequestId: string) => {
    const response = await forceSapIntegration(creditRequestId);
    if (response) loadCreditRequestData();
    return response;
  };

  const forceIntegrationStatus = async (route: string) => {
    const response = await forceIntegration(route + creditRequestId);
    if (response) loadCreditRequestData();
    return response;
  };

  useEffect(() => {
    if (pageStatus.new) {
      if (
        (selectedSubDivisionId === SUBDIVISION_COFFEE_ID || selectedSubDivisionId === SUBDIVISION_COTTON_ID) &&
        selectedDivisionId === DIVISION_SOFT_ID
      )
        setThirdProviders(['datasul']);
      else setThirdProviders(['sap']);
    }
  }, [selectedOperationId, selectedSubDivisionId, selectedDivisionId]);

  useEffect(() => {
    creditRequestStore.setSubsidiaries(
      subsidiaries.map(subsidiary => ({
        ...subsidiary,
        value: subsidiary.id,
        key: subsidiary.id,
        label: subsidiary.name,
      })),
    );
  }, [subsidiaries]);

  return (
    <CreditRequestContext.Provider
      value={{
        contractId: creditRequestStore.selectedContractId,
        isFetchingPtax,
        loadSubsidiaries,
        setIsLoadingSubsidiaries,
        isLoadingSubsidiaries,
        forceIntegrationStatus,
        teamStatusCurrent,
        paginationConfig,
        dispatchPagination,
        handlePreviewFile,
        setFileToPreview,
        fileToPreview,
        setFormErrors,
        formErrors,
        addFormErrors,
        formStatus,
        setFormStatus,
        canEditCustodyFields,
        initialProviderOption,
        changeSapStatusManually,
        isUpdatingSapStatus,
        forceSapStatusIntegration,
        creditForm,
        amortizationForm,
      }}
    >
      {children}
    </CreditRequestContext.Provider>
  );
};
