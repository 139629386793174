import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { DataTableAsync } from 'components/ui/data-table';
import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { Input } from 'components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { usePagination } from 'hooks/usePagination';
import PermissionGroupData from 'types/PermissionGroup/PermissionGroupData';

import { SFilterContainer } from '../User/styles';
import { useDeletePermissionGroupMutation } from './api/use-delete-permission-group.mutation';
import { useGetPermissionGroupsQuery } from './api/use-get-permission-groups.query';

export const PermissionGroupsTable = () => {
  const { dispatchPagination, paginationConfig } = usePagination();
  const { t } = useTranslation();
  const filterForm = useForm({
    defaultValues: {
      search: '',
    },
  });
  const { alert } = useAlertDialog();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({});

  const getPermissionGroupsQuery = useGetPermissionGroupsQuery({
    ...paginationConfig,
    ...filters,
    dispatchPagination,
  });
  const deletePermissionGroupMutation = useDeletePermissionGroupMutation();

  const columns = useMemo(
    (): ColumnDef<PermissionGroupData>[] => [
      {
        header: t('name'),
        accessorKey: 'name',
        cell: ({ row }) => row.original.name,
      },
      {
        header: t('phases'),
        accessorKey: 'phases',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredPhases = row.original.phases.filter(phase =>
            phase.name.toLowerCase().includes(search.toLowerCase()),
          );

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                    {row.original.phases.map(phase => phase.name).join(', ')}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 text-primary font-bold uppercase">{t('phases')}</div>
                  <div className="pb-2">
                    <Input
                      ref={searchRef}
                      maxLength={255}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={t('search')}
                    />
                  </div>
                  <div className="max-h-[300px] overflow-y-auto flex flex-col gap-2">
                    {filteredPhases.map(phase => (
                      <div key={phase.id}>{phase.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('status'),
        accessorKey: 'status',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredStatus = row.original.status.filter(status =>
            status.name.toLowerCase().includes(search.toLowerCase()),
          );

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                    {row.original.status.map(status => status.name).join(', ')}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 text-primary font-bold uppercase">{t('status')}</div>
                  <div className="pb-2">
                    <Input
                      ref={searchRef}
                      maxLength={255}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={t('search')}
                    />
                  </div>
                  <div className="max-h-[300px] overflow-y-auto flex flex-col gap-2">
                    {filteredStatus.map(status => (
                      <div key={status.id}>{status.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('operations'),
        accessorKey: 'operations',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredOperations = row.original.operations.filter(operation =>
            operation.name.toLowerCase().includes(search.toLowerCase()),
          );

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                    {row.original.operations.map(operation => operation.name).join(', ')}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 text-primary font-bold uppercase">{t('operations')}</div>
                  <Input
                    ref={searchRef}
                    maxLength={255}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('search')}
                  />
                  <div className="max-h-[300px] overflow-y-auto flex flex-col gap-2">
                    {filteredOperations.map(operation => (
                      <div key={operation.id}>{operation.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('sub-divisions'),
        accessorKey: 'sub_divisions',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredSubDivisions = row.original.sub_divisions.filter(subDivision =>
            subDivision.name.toLowerCase().includes(search.toLowerCase()),
          );

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                    {row.original.sub_divisions.map(subDivision => subDivision.name).join(', ')}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 text-primary font-bold uppercase">{t('sub-divisions')}</div>
                  <Input
                    ref={searchRef}
                    maxLength={255}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('search')}
                  />
                  <div className="max-h-[300px] overflow-y-auto flex flex-col gap-2">
                    {filteredSubDivisions.map(subDivision => (
                      <div key={subDivision.id}>{subDivision.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        id: 'actions',
        enablePinning: true,
        accessorKey: 'action',
        header: t('actions'),
        enableSorting: false,
        cell: ({ row }) => {
          const record = row.original;
          return (
            <div>
              <IconWithTooltip
                aria-label={t('edit')}
                onClick={() => {
                  navigate(`${record.id}`);
                }}
                action="edit"
                title={t('edit')}
                permission="admin.group.update"
              />

              <IconWithTooltip
                aria-label={t('remove')}
                action="destroy"
                title={t('remove')}
                permission="admin.group.destroy"
                onClick={() => {
                  alert({
                    type: 'confirm',
                    title: t('pages.admin.permission.delete.title'),
                    subTitle: t('pages.admin.permission.delete.subtitle'),
                    cancelText: t('pages.admin.permission.delete.cancel'),
                    okText: t('pages.admin.permission.delete.confirm'),
                    onOk: async () => {
                      await deletePermissionGroupMutation.mutateAsync(record);
                      getPermissionGroupsQuery.refetch();
                    },
                  });
                }}
              />
            </div>
          );
        },
      },
    ],
    [getPermissionGroupsQuery.data],
  );

  return (
    <div className="flex flex-col gap-2 h-full overflow-y-hidden">
      <SFilterContainer>
        <Form
          form={filterForm}
          name="filter-user"
          className="form-secondary form-filters grid-filters gap-2"
          onSubmit={values => setFilters(values)}
        >
          <div className="filter-search">
            <FormItem name="search" label={t('pages.admin.user.search')}>
              <Input />
            </FormItem>
          </div>
          <div className="filter-button">
            <FormItem label="" name="search" className="form-item-without-label">
              <Button variant="default" className="uppercase" type="submit">
                <FaSearch /> {t('form.actions.search')}
              </Button>
            </FormItem>
          </div>
          <div className="filter-clear">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setFilters({});
                filterForm.reset();
              }}
            >
              {t('pages.admin.user.buttonClearFilter')}
            </Button>
          </div>
        </Form>
      </SFilterContainer>
      <DataTableAsync
        dispatchPagination={dispatchPagination}
        paginationConfig={paginationConfig}
        columns={columns}
        fixedHeader
        data={getPermissionGroupsQuery?.data?.data ?? []}
        withPagination
        onSortingChange={newSort => {
          if (typeof newSort === 'function') {
            const newSorting = newSort(
              paginationConfig.sort.map(sort => ({ id: sort.field, desc: sort.order === 'desc' })),
            );
            dispatchPagination({
              payload: newSorting.map(sort => ({
                field: sort.id,
                order: sort.desc ? 'desc' : 'asc',
              })),
              type: 'SET_SORT',
            });
          }
        }}
        isLoading={getPermissionGroupsQuery?.isLoading}
        isFetching={getPermissionGroupsQuery?.isFetching}
        pageCount={getPermissionGroupsQuery?.data?.last_page ?? 0}
        defaultColumn={{
          enableSorting: true,
        }}
      />
    </div>
  );
};
