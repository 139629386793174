import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { usePrefinModalityRules } from '../prefin/usePrefinModalityRules';

export const useSapRules = () => {
  const { shouldShowLimitBarterInputs } = usePrefinModalityRules();

  const shouldShowSap = useCreditRequestStore(state => state.shouldShowSap);
  const isCoffee = useCreditRequestStore(state => state.isCoffee);
  const isCotton = useCreditRequestStore(state => state.isCotton);
  const isGEO = useCreditRequestStore(state => state.isGEO);
  const isSugarMills = useCreditRequestStore(state => state.isSugarMills);
  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const isDeliveredProductAdvance = useCreditRequestStore(state => state.isDeliveredProductAdvance);

  const isCoffeOrCotton = isCoffee || isCotton;

  const shouldShowRequesterPersonSapCode =
    isGEO ||
    isSugarMills ||
    (isCoffeOrCotton && shouldShowLimitBarterInputs) ||
    shouldShowLimitBarterInputs ||
    shouldShowSap;

  const shouldRequireRequesterPersonSapCode =
    (isPrepay && shouldShowSap) ||
    (!isPrepay &&
      !isDeliveredProductAdvance &&
      (isGEO || isSugarMills || (isCoffeOrCotton && shouldShowLimitBarterInputs)));

  return {
    shouldShowRequesterPersonSapCode,
    shouldRequireRequesterPersonSapCode,
  };
};
