import React from 'react';
import { useTranslation } from 'react-i18next';

import { Radio } from 'antd';
import { FormItem } from 'components';
import { Switch } from 'components/ui/switch';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

type HasAssigneesInputProps = {
  useSwitch?: boolean;
  required?: boolean;
};

export const HasAssignesInput = ({ useSwitch, required = true }: HasAssigneesInputProps) => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const setHasAssignees = useCreditRequestStore(state => state.setHasAssignees);
  const setAssigneeList = useCreditRequestStore(state => state.setAssigneeList);

  const toggleAssignees = (value: boolean) => {
    if (value) return setHasAssignees(true);
    setHasAssignees(false);
    setAssigneeList([]);
  };

  return (
    <FormItem
      label={t('pages.admin.team-credit-status.form.rules.target.has_assignees')}
      name="has_assignees"
      initialValue={undefined}
      rules={[{ required }]}
    >
      {useSwitch ? (
        <Switch disabled={pageStatus.viewing} onValueChange={toggleAssignees} />
      ) : (
        <Radio.Group buttonStyle="solid" disabled={pageStatus.viewing} onChange={e => toggleAssignees(e.target.value)}>
          <Radio.Button value={1}>
            {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_yes')}
          </Radio.Button>
          <Radio.Button value={0}>
            {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_not')}
          </Radio.Button>
        </Radio.Group>
      )}
    </FormItem>
  );
};
