import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import datasulApi from 'services/api/datasul';
import sapApi from 'services/api/sap';

export const useGetProviderRequest = () => {
  const isPrefin = useCreditRequestStore(state => state.isPrefin);
  const isPrefinSugarMills = useCreditRequestStore(state => state.isPrefinSugarMills);
  const thirdProviders = useCreditRequestStore(state => state.thirdProviders);
  const isSugarTrading = useCreditRequestStore(state => state.isSugarTrading);

  const getProviderRequest = (document: string) => {
    return isPrefin || isPrefinSugarMills || isSugarTrading
      ? datasulApi.provider.search({ params: { search: document } }, true)
      : thirdProviders.includes('datasul')
      ? datasulApi.provider.search({ params: { search: document } })
      : sapApi.provider.search({ params: { docNumber: document, startDate: '?', endDate: '?' } });
  };

  return { getProviderRequest };
};
