import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useTable } from "compositions/TableDataAsync/hooks/useTable";

import { Breadcrumb } from "../../../components";
import { SContainer, SHeader, STitlePage } from "../../../styles";
import { FormEdit } from "./FormEdit";
import { useSerasaParameterization } from "./hooks/useSerasaParameterization";
import { SGlobalArea } from "./styles";
import { Table } from "./Table";

export const SerasaParameterization: React.FC = () => {
  const { t } = useTranslation();
  const tableController = useTable();

  const [visibleForm, setVisibleForm] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<object>({});

  const { handleTableChange, handleEdit, handleSave, isFetching } =
    useSerasaParameterization({
      refresh: tableController.refresh,
    });

  return (
    <SGlobalArea>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.serasa") },
          { title: t("breadcrumb.serasa_parameterization") },
        ]}
      />
      <SContainer>
        <SHeader>
          <STitlePage>{t("pages.serasa.parameterization.title")}</STitlePage>
        </SHeader>
        {visibleForm && (
          <FormEdit
            isFetching={isFetching}
            dataForm={dataForm}
            setVisibleForm={setVisibleForm}
            setDataForm={setDataForm}
            handleSave={handleSave}
          />
        )}
        <Table
          controller={tableController}
          isFetching={isFetching}
          handleChange={handleTableChange}
          handleEdit={handleEdit}
          setVisibleForm={setVisibleForm}
          setDataForm={setDataForm}
        />
      </SContainer>
    </SGlobalArea>
  );
};
