import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { ReportModal } from 'components/ReportModal';
import { SapStatusEnum } from 'components/SapStatus/types';
import { Can } from 'hooks/ability';
import { SlaTimer } from 'pages/CreditRequest/components/molecules/sla-timer';

import DTOCreditOperation from '../../../../../../@types/dtos/credit-request/DTOCreditOperation';
import { SapStatus } from '../../../../../../components/SapStatus';
import { getReportModalTranslation } from '../../../../../../componentsTranslation/reportModal';
import {
  OPERATION_PREPAY_ID,
  ImportGroupEnum,
  SAP_OPERATIONS,
  SapStatusAdvanceProgEnum,
  TEAM_STATUS_ADVANCE_PROGRAMMING_EXECUTED_COMERCIAL_SOLICITATION,
} from '../../../../../../configs/constants';
import { useAuth } from '../../../../../../hooks/auth';
import { useCreditStatus } from '../../../../../../hooks/creditStatus';
import api from '../../../../../../services/api/credit';
import documentApi from '../../../../../../services/api/document';
import { CardHeader } from './CardHeader';
import { CardHiddenFooter } from './CardHiddenFooter';
import { CardHistoryData } from './CardHistoryData';
import { CardOperationDetails } from './CardOperationDetails';
import { SerasaRestriction } from './SerasaRestriction';
import { CardBody, Footer } from './styles';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({ data, removeFromColumn }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { getOperation, teamStatusHasChanged, setTeamStatusHasChanged } = useCreditStatus();

  const [exporting, setExporting] = useState(false);
  const [isFooterVisible, setFooterIsVisible] = useState(false);
  const [changingRequestId, setChangingRequestId] = useState('');
  const [creditReportId, setCreditReportId] = useState('');
  const [colorCard, setColorCard] = useState('');
  const [loadingCard, setLoadingCard] = useState(false);

  useEffect(() => {
    if (teamStatusHasChanged) {
      removeFromColumn(changingRequestId);
      setTeamStatusHasChanged(false);
    }
  }, [teamStatusHasChanged]);

  useEffect(() => {
    if (data) {
      const operation = getOperation(data.operation_id) as DTOCreditOperation;
      setColorCard(operation.color);
    }
    // eslint-disable-next-line
  }, []); // Sem dependências para chamar apenas uma vez no carregamento do componente

  return (
    <>
      {data && (
        <Spin spinning={loadingCard}>
          <div className="relative m-0 mb-[10px] flex flex-col rounded-[4px] border-0 bg-white shadow-md">
            <CardBody status={data.is_status_priority ? 'danger' : 'default'} color={colorCard}>
              <div className="card-content-container">
                <SlaTimer missingHours={data.hours_sla} metaHours={data.sla} isKanban />

                <CardHeader data={data} />

                <CardOperationDetails data={data} />

                <Footer status="default">
                  <div className="footer-visible-always">
                    <CardHistoryData
                      data={data}
                      setIsFooterVisible={setFooterIsVisible}
                      isFooterVisible={isFooterVisible}
                    />
                  </div>
                  {SAP_OPERATIONS.includes(data.operation_id) && data.last_import_sap ? (
                    <div
                      style={{
                        width: '100%',
                        padding: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <SapStatus
                        sapStatusUpdatedAt={
                          data?.last_import_prepay_advance
                            ? data.last_import_prepay_advance.created_at
                            : data?.last_import_sap.created_at
                        }
                        sapStatus={
                          data.team_status_id === TEAM_STATUS_ADVANCE_PROGRAMMING_EXECUTED_COMERCIAL_SOLICITATION &&
                          data.status_sap
                            ? ((data?.status_sap ?? '')?.toUpperCase() as SapStatusAdvanceProgEnum)
                            : ((data?.last_import_prepay_advance?.description ||
                                data?.last_import_sap.description) as SapStatusEnum)
                        }
                        workerGroupId={
                          (data?.last_import_prepay_advance?.worker_group_id ||
                            data?.last_import_sap.worker_group_id) as ImportGroupEnum
                        }
                        workerId={data?.last_import_sap.worker_id}
                      />
                    </div>
                  ) : null}

                  {isFooterVisible && (
                    <div className="footer-hide-data">
                      <CardHiddenFooter
                        data={data}
                        setChangingRequestId={setChangingRequestId}
                        setCreditReportId={setCreditReportId}
                        setExporting={setExporting}
                        setLoadingCard={setLoadingCard}
                        userPositionId={user?.position_id}
                      />
                    </div>
                  )}
                </Footer>
              </div>
            </CardBody>
            {data.requester_person?.serasa_counterparty?.serasa_counterparty_status_id &&
              data.operation_id === OPERATION_PREPAY_ID && (
                <SerasaRestriction
                  serasaStatus={data.requester_person?.serasa_counterparty?.serasa_counterparty_status_id}
                  document={data.requester_person?.serasa_counterparty?.doc_number}
                />
              )}
          </div>
        </Spin>
      )}
      <Can I="credit.request.report.index">
        <Can I="credit.request.report.store">
          <ReportModal
            translation={getReportModalTranslation(t)}
            withDateFilter={false}
            width="50%"
            visibility={exporting}
            afterClose={() => setExporting(false)}
            listReportApi={api.requests.listReport}
            generateReportApi={api.requests.generateReport}
            downloadReportApi={documentApi.download}
            reportApiId={creditReportId}
            extraInputs
          />
        </Can>
      </Can>
    </>
  );
};

export default memo(Card);
