import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import UserData from 'types/User/UserData';
import { UserFormData } from 'types/User/UserFormData';

export const useUpdateUserMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (data: UserFormData): Promise<UserData | null> => {
      if (!data?.id) {
        alert({ title: t('record-not-found'), type: 'error' });
        return null;
      }
      const response = await api.put(`admin/user/${data.id}`, data);
      return response.data;
    },
    mutationKey: ['updateUser'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
