import React from "react";

import { cn } from "lib/utils";

type Props = {
  children: React.ReactNode;
  maxCols?: number;
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FormGrid = ({ children, maxCols = 4, className }: Props) => {
  return (
    <div
      className={cn(
        `grid w-full grid-cols-[repeat(auto-fit,_minmax(250px,1fr))] gap-4`,
        className,
      )}
    >
      {children}
    </div>
  );
};
