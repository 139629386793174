import React from "react";
import { useTranslation } from "react-i18next";

import { MoneyInput } from "@agrodatabr/masked-inputs";
import { FormItem, ModalStatus } from "components";
import ApiSelect from "components/ApiSelect";
import {
  WEIGHT_UNIT_KG_ID,
  WEIGHT_UNIT_SACK_ID,
  WEIGHT_UNIT_TON_ID,
} from "configs/constants";
import { useCreditRequestContext } from "context/CreditRequestContext";
import { useCache } from "hooks/cache";
import { useContract } from "hooks/fetch/useContract";
import moment from "moment";
import { useCreditRequestStore } from "pages/CreditRequest/store/use-credit-request-store";
import { ContractFixationOptionData } from "types/Contract/ContractFixationOptionData";
import { Formatter } from "utils/Formatter";

import { ContractFixationData } from "../../../../../types/CreditRequest/ContractFixationData";

const convertFixationToSelectOption = (
  fixation: ContractFixationData,
  weightId: string,
) => {
  return {
    key: fixation.id,
    value: fixation.id,
    label: `${fixation.tradeslip} | ${fixation.currency === "R$" ? "BRL" : fixation.currency} ${calcFixationPrice(
      fixation,
      weightId,
    )} | ${Formatter.integer(Number(fixation.quantity))} `,
    quantity: Number(fixation.quantity),
    price: fixation.price,
    tradeslip: fixation.tradeslip,
    currency: fixation.currency === "R$" ? "BRL" : fixation.currency,
    id: fixation.id,
    payment_date_tradeslip: fixation.payment_date_tradeslip,
  } as ContractFixationOptionData;
};

const calcFixationPrice = (
  fixation: ContractFixationData,
  weightId: string,
) => {
  let value = 0;
  if (weightId === WEIGHT_UNIT_SACK_ID) {
    value = Number(fixation.price);
  }
  if (weightId === WEIGHT_UNIT_TON_ID) {
    value = Number(fixation.price) / 60 / 1000;
  }
  if (weightId === WEIGHT_UNIT_KG_ID) {
    value = Number(fixation.price) / 60;
  }
  const formatter = Formatter.money(value, 2, "", true);
  return formatter;
};

export const JsonDataPriceSelect = () => {
  const { t } = useTranslation();
  const { getContractFixations } = useContract();
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();

  const selectedContractId = useCreditRequestStore(
    (state) => state.selectedContractId,
  );
  const setIsDolarSelected = useCreditRequestStore(
    (state) => state.setIsDolarSelected,
  );
  const setIsFixationQuantityInsufficient = useCreditRequestStore(
    (state) => state.setIsFixationQuantityInsufficient,
  );
  const pageStatus = useCreditRequestStore((state) => state.pageStatus);
  const creditRequestData = useCreditRequestStore(
    (state) => state.creditRequestData,
  );

  const weightId = creditForm.getFieldValue("json_data.weight_type_id");

  const handleGetFixations = async (value: string, page: number) => {
    const response = await getContractFixations(
      selectedContractId,
      { page, search: value },
      true,
    );
    if (response) {
      const options = response.data.map((fixation: ContractFixationData) => {
        return convertFixationToSelectOption(fixation, weightId);
      });
      return { data: options, lastPage: response.last_page };
    }

    return { data: [], lastPage: 1 };
  };

  if (!creditRequestData?.contract_fixation_id && !pageStatus.new) {
    return (
      <FormItem
        label={t("pages.credit-request.form.prepayment_json_data_price")}
        name="json_data.price"
      >
        <MoneyInput disabled />
      </FormItem>
    );
  }

  return (
    <FormItem
      label={t("pages.credit-request.form.prepayment_json_data_price")}
      name="contract_fixation_id"
      rules={[{ required: true }]}
      permission={[
        "contract.dependency.fixations",
        "contract.dependency.dbfixations",
      ]}
      needAllPermissions
    >
      <ApiSelect
        fetchOptions={handleGetFixations}
        initialOptions={
          creditRequestData?.contract_fixation
            ? [
                convertFixationToSelectOption(
                  creditRequestData?.contract_fixation,
                  weightId,
                ),
              ]
            : []
        }
        showSearch
        disabled={pageStatus.viewing || !selectedContractId}
        onChange={(_, option: any) => {
          const formatDate = () => {
            const date = option?.payment_date_tradeslip;
            const verifyDate = date?.includes("-");
            if (!verifyDate) {
              const date = option?.payment_date_tradeslip;
              const year = date?.substring(0, 4);
              const month = date?.substring(4, 6);
              const day = date?.substring(6, 8);
              const dateFormatted =
                year && month && day ? `${year}-${month}-${day}` : undefined;
              return dateFormatted;
            }
            return date;
          };
          if (!option) return;
          creditForm.setFieldsValue({
            contract_fixation_id: option.id,
            "json_data.price_value": option.price,
            "json_data.expected_payment_at": moment(formatDate()),
            "json_data.currency_type_id": cache
              .getCreditOptions("currency_type")
              .find(
                (currency) =>
                  currency.label?.toLowerCase() ===
                    option.currency?.toLowerCase() ||
                  currency.slug?.toLowerCase() ===
                    option.currency?.toLowerCase(),
              )?.key,
          });

          setIsDolarSelected(
            !!cache
              .getCreditOptions("currency_type")
              .find(
                (currency) =>
                  currency.label?.toLowerCase() ===
                    option.currency?.toLowerCase() ||
                  currency.slug?.toLowerCase() ===
                    option.currency?.toLowerCase(),
              )?.label,
          );

          const isFixationQuantityInsufficient =
            Number(creditForm.getFieldValue("json_data.quantity_kg")) >
            option.quantity;

          setIsFixationQuantityInsufficient(isFixationQuantityInsufficient);

          if (isFixationQuantityInsufficient) {
            ModalStatus({
              type: "error",
              title: t("modal.error"),
              subTitle: t(
                "pages.credit-request.form.prepayment_json_data_price_lesser_quantity",
              ),
            });
          }
        }}
        onClear={() => {
          creditForm.setFieldsValue({
            contract_fixation_id: "",
            "json_data.price_value": "",
            "json_data.currency_type_id": "",
          });
        }}
      />
    </FormItem>
  );
};
