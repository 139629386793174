import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { Input } from 'components/ui/input';
import Loading from 'components/ui/loading';
import { PageTitle } from 'components/ui/page-title';
import { Switch } from 'components/ui/switch';
import Tree from 'components/ui/tree';
import { useAuth } from 'hooks/auth';

import { Button, ModalStatus } from '../../../components';
import { useGetNotificationConfigurationTreeQuery } from './api/use-get-notification-configuration-tree.query';
import { useGetNotificationConfigurationQuery } from './api/use-get-notification-configuration.query';
import { useUpdateNotificationConfig } from './api/use-update-notification-config.mutation';
import { SFormButtons } from './styles';

interface Props {
  userId: string;
}

interface SimpleNode {
  id: string;
  label: string;
  readable: string;
  children?: SimpleNode[];
}

interface Tree {
  id: string;
  title: string;
  key: string;
  key2: string;
  children: Children[];
}

interface Children {
  id: string;
  title: string;
  key: string;
  key2: string;
  children?: Children[];
}

export const NotificationConfigTab = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [search, setSearch] = useState('');

  const updateNotificationConfigurationMutation = useUpdateNotificationConfig();
  const getNotificationConfigurationQuery = useGetNotificationConfigurationQuery({ userId });
  const getNotificationConfigurationTree = useGetNotificationConfigurationTreeQuery();

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const getTranslatedTitle = (title: string) => t(`notification.types.${title.replaceAll('.', '@')}`);

  const handleEdit = async () => {
    const { tree, sendEmail, sendTeamsNotification } = form.getValues();

    const response = await updateNotificationConfigurationMutation.mutateAsync({
      userId,
      notificationsIds: tree,
      shouldSendEmail: sendEmail,
      shouldSendTeamsNotification: sendTeamsNotification,
    });

    if (response) {
      handleSuccess(t('user.notification.success'));
      setSearch('');
    }
  };

  const form = useForm({
    defaultValues: {
      tree: [],
      sendEmail: false,
      sendTeamsNotification: false,
    },
  });

  function convertToSimpleFormat(root: any[]): SimpleNode[] {
    function mapTreeToSimpleNode(tree: Tree | Children): SimpleNode {
      const simpleNode: SimpleNode = {
        id: tree.title,
        label: getTranslatedTitle(tree.title),
        readable: getTranslatedTitle(tree.title),
      };

      if (tree.children && tree.children.length > 0) {
        simpleNode.children = tree.children.map(mapTreeToSimpleNode);
      }

      return simpleNode;
    }
    return root.map(mapTreeToSimpleNode);
  }

  useEffect(() => {
    if (getNotificationConfigurationQuery.data)
      form.setValue('tree', getNotificationConfigurationQuery.data?.map((item: any) => item.name) ?? []);
  }, [getNotificationConfigurationQuery.data]);

  useEffect(() => {
    if (user) {
      form.reset({
        tree: getNotificationConfigurationQuery.data?.map((item: any) => item.name) ?? [],
        sendEmail: user.send_email,
        sendTeamsNotification: user.send_teams_notification,
      });
    }
  }, [user]);

  return (
    <Form form={form} onSubmit={console.log} className="flex h-full flex-col bg-white p-4">
      <PageTitle className="mb-4">Configurar notificaçoes</PageTitle>
      <Loading
        isLoading={getNotificationConfigurationQuery.isLoading || updateNotificationConfigurationMutation.isPending}
      >
        <Input
          onChange={e => setSearch(e.target.value)}
          className="max-w-[400px]"
          placeholder={t('search-notification')}
        />
        <FormItem name="tree" className="overflow-auto">
          <Tree data={convertToSimpleFormat(getNotificationConfigurationTree?.data?.tree ?? [])} search={search} />
        </FormItem>

        <div className="mt-6 flex items-center gap-2 font-bold">
          <span>{`${t('send-email')} ?`}</span>
          <FormItem name="sendEmail" hideLabel>
            <Switch />
          </FormItem>
        </div>

        <div className="mt-6 flex items-center gap-2 font-bold">
          <span>{`${t('send-to-teams')} ?`}</span>
          <FormItem name="sendTeamsNotification" hideLabel>
            <Switch />
          </FormItem>
        </div>

        <SFormButtons>
          <Button status="secondary" htmlType="reset" onClick={() => console.log()} disabled={false}>
            {t('pages.admin.user.buttonCancel')}
          </Button>
          <Button
            status="primary"
            htmlType="submit"
            onClick={handleEdit}
            disabled={false}
            loading={updateNotificationConfigurationMutation.isPending}
          >
            {t('pages.admin.user.buttonEdit')}
          </Button>
        </SFormButtons>
      </Loading>
    </Form>
  );
};
