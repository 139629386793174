import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const JsonDataIncotermIdSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_incoterm_id')}
      name="json_data.incoterm_id"
      rules={[{ required: true }]}
    >
      <Select
        options={cache.getCreditOptions('incoterm')}
        disabled={pageStatus.viewing}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
