import React from "react";

import { useBasePort } from "hooks/fetch/useBasePort";
import ApiSelect, { ApiSelectProps } from "components/ApiSelect";

type Props = Omit<ApiSelectProps, "fetchOptions" | "showSearch">;

export const BasePortSelect = (props: Props) => {
  const { getBasePorts } = useBasePort();

  const handleGetBasePorts = async (value: string, page: number) => {
    const response = await getBasePorts({
      params: { search: value, page, page_size: 10 },
    });
    return {
      data: response.data.map((port: any) => ({
        ...port,
        key: port.id,
        value: port.id,
        label: port.name,
      })),
      lastPage: response.last_page,
    };
  };

  return <ApiSelect showSearch fetchOptions={handleGetBasePorts} {...props} />;
};
