import creditApi from 'services/api/credit';

import { useCreditRequestStore } from '../store/use-credit-request-store';

export const useLoadCreditRequestDocuments = () => {
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);
  const setDocumentList = useCreditRequestStore(state => state.setDocumentList);
  const setDocumentTotal = useCreditRequestStore(state => state.setDocumentTotal);

  const loadDocuments = async (docParams = {}, id?: string) => {
    const response = await creditApi.requests.getDocuments(id || creditRequestId, docParams);
    setDocumentList(response.data.data ?? []);
    setDocumentTotal(response.data.total);
    return response.data.data;
  };

  return {
    loadDocuments,
  };
};
