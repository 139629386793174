import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-x: hidden;

  height: 100%;
  max-width: 100%;
  padding-bottom: 16px;
`;

export const HeaderActions = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  flex-flow: wrap;
  justify-content: space-between;

  .buttons-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  .custom-radio-group + .custom-radio-group,
  .custom-radio-group + .buttons-actions,
  button + button {
    margin-left: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  height: 70vh;
  overflow-x: auto;
`;

export const Spinner = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  min-height: 300px;
  width: 100%;
`;

export const KanbanInfo = styled.div`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.text};

  > h2 {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 18px;
    margin-bottom: 16px;
  }

  > p {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

export const KanbanRestriction = styled.table`
  border-left: 1px solid ${({ theme: { colors } }) => colors.text};
  border-right: 1px solid ${({ theme: { colors } }) => colors.text};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.text};
  width: 100%;

  thead th {
    background-color: ${({ theme: { colors } }) => colors.greys.grey04};
  }

  tbody td,
  thead th {
    border-top: 1px solid ${({ theme: { colors } }) => colors.text};
    color: ${({ theme: { colors } }) => colors.text};
    padding: 4px;

    & + td,
    & + th {
      border-left: 1px solid ${({ theme: { colors } }) => colors.text};
    }
  }

  & table {
    border-top: 1px solid ${({ theme: { colors } }) => colors.text};
    width: 100%;

    thead th:first-of-type {
      width: 40%;
    }
  }

  .row-title td {
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.text};
    border-top: 1px solid ${({ theme: { colors } }) => colors.text};
    border-left: 1px solid ${({ theme: { colors } }) => colors.text};
    border-right: 1px solid ${({ theme: { colors } }) => colors.text};
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
  }
`;

export const KanbanConfigList = styled.ul`
  margin: 0;
  list-style: none;
  color: ${({ theme: { colors } }) => colors.text};

  > h2 {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 18px;
    margin-bottom: 24px;
  }

  > li {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 8px;

    display: flex;
    align-items: center;

    > button {
      margin-right: 8px;
    }
  }
`;
