import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type TimeInStatusLabelProps = {
  data: {
    dif_year?: number;
    dif_month?: number;
    dif_day?: number;
    dif_hour?: number;
  };
  className?: string;
};

export const TimeInStatusLabel = ({ data, className }: TimeInStatusLabelProps) => {
  const { t } = useTranslation();
  const { dif_year, dif_month, dif_day, dif_hour } = data;

  const label = useMemo(() => {
    if (dif_year)
      return `${dif_year > 1 ? t('n-years-ago', { years: dif_year }) : t('n-year-ago', { years: dif_year })}`;
    if (dif_month)
      return `${dif_month > 1 ? t('n-months-ago', { months: dif_month }) : t('n-month-ago', { months: dif_month })}`;
    if (dif_day) return `${dif_day > 1 ? t('n-days-ago', { days: dif_day }) : t('n-day-ago', { days: dif_day })}`;
    if (dif_hour)
      return `${dif_hour > 1 ? t('n-hours-ago', { hours: dif_hour }) : t('n-hour-ago', { hours: dif_hour })}`;
    if (dif_hour === 0) return t(`newly-request-created`);
    return null;
  }, [dif_year, dif_month, dif_day, dif_hour]);

  return <span className={className}>{label}</span>;
};
