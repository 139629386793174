import React from 'react';

import { cn } from 'lib/utils';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const PageTitle = ({ children, className }: Props) => {
  return <div className={cn('text-xl font-bold text-primary', className)}>{children}</div>;
};
