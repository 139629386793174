import { useState } from "react";

import { DTOErrorResponse } from "types/DTOReports";
import { useAbility } from "hooks/ability";
import { handleRequest } from "services/api/api";

import documentApi from "../../services/api/document";
import { usePage } from "../page";

export const useDocuments = () => {
  const { alertStatus } = usePage();
  const ability = useAbility();

  const [isFetching, setIsFetching] = useState(false);
  const [isDestroying, setIsDestroying] = useState(false);

  const getTypes = async (params: object) => {
    if (!ability.can("document.type.index", "")) return [];
    try {
      setIsFetching(true);
      const response = await documentApi.documentType(params);
      return response.data.data.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getTypesOptions = async (params = {}) => {
    const types = await getTypes(params);
    return types.map((document: { id: any; name: any }) => ({
      key: document.id,
      value: document.id,
      label: document.name,
      saved: true,
    }));
  };

  const destroyFile = async (id: string) =>
    handleRequest(() => documentApi.delete(id), setIsDestroying);

  return { getTypes, isFetching, getTypesOptions, destroyFile, isDestroying };
};

// {
// 	params: {
// 		tag: 'monitoring',
// 	},
// }
