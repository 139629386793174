import { useCallback } from 'react';

import { PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useContract } from 'hooks/fetch/useContract';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import { PersonType } from 'pages/Sustainability/types';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';

import { useCreditRequestStore } from '../store/use-credit-request-store';
import { useGetContractVolume } from './useGetContractVolume';
import { useLoadSubsidiaries } from './useLoadSubsidiaries';

export const useFindContract = () => {
  const { getContractVolume } = useGetContractVolume();
  const { getContractByNumber } = useContract();
  const cache = useCache();
  const { ADVANCE_PRODUCT_DELIVERED_RULES } = useCreditRequestRules();
  const { loadSubsidiaries } = useLoadSubsidiaries();
  const { creditForm } = useCreditRequestContext();

  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const setContractTotalVolume = useCreditRequestStore(state => state.setContractTotalVolume);
  const setSelectedContractNumber = useCreditRequestStore(state => state.setSelectedContractNumber);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const isDeliveredProductAdvance = useCreditRequestStore(state => state.isDeliveredProductAdvance);
  const setSelectedContractId = useCreditRequestStore(state => state.setSelectedContractId);
  const setSelectedStateId = useCreditRequestStore(state => state.setSelectedStateId);
  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const setContractFieldsValue = useCreditRequestStore(state => state.setContractFieldsValue);
  const setSelectedPersonType = useCreditRequestStore(state => state.setSelectedPersonType);
  const setThirdProviders = useCreditRequestStore(state => state.setThirdProviders);
  const isPrepay = useCreditRequestStore(state => state.isPrepay);

  const resetFields = () => {
    const contractNumber = creditForm.getFieldValue('json_data.contract_number');
    const divisionId = creditForm.getFieldValue('division_id');
    const subdivisionId = creditForm.getFieldValue('sub_division_id');
    const operationId = creditForm.getFieldValue('operation_id');
    creditForm.resetFields();
    creditForm.setFieldsValue({
      'json_data.contract_number': contractNumber,
      division_id: divisionId,
      sub_division_id: subdivisionId,
      operation_id: operationId,
    });
    setSelectedContractId('');
    setIsDolarSelected(false);
    setSelectedStateId('');
    setContractFieldsValue(null);
  };

  const findContract = useCallback(async () => {
    setIsLoading(true);

    const formValues = creditForm.getFieldsValue();
    if (!formValues['json_data.contract_number']) return;
    const contract_number = formValues['json_data.contract_number'];

    const response = await getContractByNumber(contract_number, isDeliveredProductAdvance);
    setIsLoading(false);
    resetFields();
    if (!response) return;

    const volume = await getContractVolume(contract_number);

    const findCurrencyType = cache.currency_type?.find(
      currencyItem =>
        currencyItem.id.toLowerCase() === response.ext_fixed_price_currency.toLowerCase() ||
        currencyItem.slug.toLowerCase() === response.ext_fixed_price_currency.toLowerCase(),
    );

    const isImportationEnd = response.ext_export_purpose === '1' ? 1 : 0;
    let optingInss;
    if (response.provider_type === PHYSICAL_PERSON_TYPE) {
      optingInss = isImportationEnd === 1 ? 0 : 1;
    } else {
      optingInss = undefined;
    }

    const toFixValidation = ADVANCE_PRODUCT_DELIVERED_RULES.toFixEqualsContractQuantityValidate(
      selectedOperationId,
      Number(response.volAFixar),
    );

    const receivedQuantityValidation = ADVANCE_PRODUCT_DELIVERED_RULES.receivedQuantityEqualsContractQuantityValidate(
      selectedOperationId,
      Number(response.ext_quantity_received),
      response.ext_quantity,
    );

    if (response.region_id) loadSubsidiaries(response.region_id);

    creditForm.setFields([
      {
        name: 'balance_to_be_fixed',
        errors: toFixValidation ? [toFixValidation] : [],
        value: response.volAFixar,
      },
    ]);

    creditForm.setFields([
      {
        name: 'json_data.quantity_received',
        errors: receivedQuantityValidation ? [receivedQuantityValidation] : [],
        value: response.ext_quantity_received,
      },
    ]);

    const contractFields = {
      requester_person_type: response.provider_type,
      balance_to_be_fixed: response.volAFixar,
      contract_id: response.id,
      requester_person_id: response.provider?.id ?? undefined,
      'requester_person.name': response.provider_name,
      'json_data.contract.requester_person_cpf': response.provider?.cpf,
      'json_data.contract.requester_person_document': response.provider_doc?.replaceAll(/\D/g, ''),
      'requester_person.document': response.provider_doc?.replaceAll(/\D/g, ''),
      'json_data.contract.requester_person_cnpj': response.provider?.cnpj,
      'json_data.contract.requester_person_code_erp': '',
      region_id: response.region_id ?? undefined,
      subsidiary_id: response.subsidiary_id ?? undefined,
      state_id: response.state_id ?? undefined,
      harvest_id: response.harvest_id ?? undefined, // alterar
      // business_unit_id: response. ?? undefined, // alterar
      sap_bp_headoffice_subsidiary_code:
        isPrepay || isDeliveredProductAdvance ? response.provider?.sap_bp_subsidiary_code ?? undefined : undefined,
      'json_data.is_exportation': isImportationEnd,
      'json_data.opting_inss': optingInss,
      'json_data.freight': response.ext_delivery ?? undefined,
      'json_data.contract_number': contract_number,
      'json_data.quantity_contract': response.ext_quantity,
      'json_data.quantity_received': response.ext_quantity_received,
      'json_data.quantity_delivery': response.ext_quantity_receive,
      'requester_person.json_data.code_erp':
        isPrepay || isDeliveredProductAdvance ? response.provider?.cod_datasul ?? undefined : undefined,
      'json_data.weight_type_id': isPrepay ? response.weight_type_id : undefined,
    } as any;

    creditForm.setFieldsValue(contractFields);

    setContractFieldsValue(contractFields);
    setIsDolarSelected(!!findCurrencyType?.name);
    setSelectedStateId(response.state_id);
    setSelectedPersonType(response.provider_type as PersonType);
    setThirdProviders([response.datasource?.toLowerCase() as ThirdProviderEnum]);
    setContractTotalVolume(volume);
    setSelectedContractNumber(contract_number);
    setSelectedContractId(response?.id);

    setIsLoading(false);
  }, [selectedOperationId]);

  return {
    findContract,
  };
};
