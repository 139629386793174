import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import appConfigs from 'configs/app';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const StartWithdrawalDeadlineInput = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_initial_withdrawal_period')}
      name="start_withdrawal_deadline"
      // validateStatus={withdrawalPeriodStatus || ''}
      // extra={withdrawalPeriodVisible ? withdrawalPeriodExtra : ''}
      rules={[{ required: true }]}
    >
      <DatePicker
        locale={ptBR}
        mode="date"
        format={appConfigs.formatDate}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
        disabledDate={current => {
          if (selectedOperationId === OPERATION_PREPAY_ID && moment(current) < moment().subtract('d', 1)) return true;

          const endWithdrawalPeriod = creditForm.getFieldValue('json_data.withdrawal_period');
          if (!endWithdrawalPeriod) return false;
          // return current.isBefore(endWithdrawalPeriod.subtract(30, 'day'));

          return (
            moment(endWithdrawalPeriod).subtract(-1, 'day') <= current ||
            moment(endWithdrawalPeriod).subtract(30, 'day') >= current
          );
        }}
      />
    </FormItem>
  );
};
