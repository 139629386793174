import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { InputNumber } from 'components/ui/input';
import { useCache } from 'hooks/cache';
import { useStoreTeamStatusSlaMutation } from 'pages/Admin/api/use-store-team-status-sla.mutation';
import { useUpdateTeamStatusSlaMutation } from 'pages/Admin/api/use-update-team-status-sla.mutation';
import { Converter } from 'utils/Converter';

import { useTeamStatusStore } from '../stores/useTeamStatusStore';

const initialSla = {
  operation_id: '',
  division_id: '',
  sla: 0,
};

type SlaFormProps = {
  onSuccess?: () => void;
};

export const SlaForm = ({ onSuccess }: SlaFormProps) => {
  const { t } = useTranslation();
  const { operation, division } = useCache();
  const { alert } = useAlertDialog();

  const slaToEdit = useTeamStatusStore(state => state.slaToEdit);
  const setSlaToEdit = useTeamStatusStore(state => state.setSlaToEdit);
  const teamStatusIdSla = useTeamStatusStore(state => state.teamStatusSla);

  const updateTeamStatusSlaMutation = useUpdateTeamStatusSlaMutation();
  const storeTeamStatusSlaMutation = useStoreTeamStatusSlaMutation();

  const form = useForm({
    defaultValues: slaToEdit ?? { ...initialSla, team_credit_status_id: teamStatusIdSla.id },
  });

  const handleSubmit = async () => {
    form.handleSubmit(async values => {
      if (slaToEdit)
        await updateTeamStatusSlaMutation.mutate(values, {
          onSuccess: () => {
            form.reset(initialSla);
            alert({ title: t('success'), type: 'success', description: t('sla-updated-with-success') });
            onSuccess?.();
          },
          onError: () => {
            alert({ title: t('error'), type: 'error', description: t('error-while-updating-sla') });
          },
        });
      else
        await storeTeamStatusSlaMutation.mutate(values, {
          onSuccess: () => {
            setSlaToEdit(null);
            form.reset(initialSla);
            alert({ title: t('success'), type: 'success', description: t('sla-created-with-success') });
            onSuccess?.();
          },
          onError: () => {
            alert({ title: t('error'), type: 'error', description: t('error-while-creating-sla') });
          },
        });
    })();
  };

  useEffect(() => {
    return () => {
      setSlaToEdit(null);
      form.reset(initialSla);
    };
  }, []);

  useEffect(() => {
    if (slaToEdit) form.reset(slaToEdit);
  }, [slaToEdit]);

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col overflow-hidden" form={form}>
      <div className="flex h-full flex-col gap-2 overflow-y-auto p-4">
        <FormGrid>
          <FormItem name="division_id" label={t('division')} className="w-full">
            <ComboBox options={Converter.selectOptionArray(division, 'id', 'id', 'name') as any} className="w-full" />
          </FormItem>
          <FormItem name="operation_id" label={t('operation')} className="w-full">
            <ComboBox options={Converter.selectOptionArray(operation, 'id', 'id', 'name') as any} className="w-full" />
          </FormItem>
          <FormItem name="sla" label="SLA">
            <InputNumber decimals={0} />
          </FormItem>
        </FormGrid>
      </div>

      <div className="flex w-full items-center justify-between p-4 pt-0 shadow-md">
        <Button
          type="button"
          variant="secondary"
          isLoading={updateTeamStatusSlaMutation.isPending || storeTeamStatusSlaMutation.isPending}
          onClick={() => {
            setSlaToEdit(null);
            form.reset(initialSla);
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          type="button"
          onClick={handleSubmit}
          isLoading={updateTeamStatusSlaMutation.isPending || storeTeamStatusSlaMutation.isPending}
        >
          {slaToEdit ? t('edit') : t('add')}
        </Button>
      </div>
    </Form>
  );
};
