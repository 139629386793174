import { Table } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ width: string }>`
  .ant-table-wrapper {
    width: ${({ width }) => width};
  }
`;

export const STable: any = styled(Table)`
  .ant-table {
    overflow: auto;
  }

  .ant-table-thead > tr > th {
    background: #ededed;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    & .ant-table-filter-trigger-container {
      background: #cfcecf;
    }

    background: #cfcecf;
  }

  /** Sorted column color */
  .ant-table-thead th.ant-table-column-sort {
    background: ${({ theme: { colors } }) => colors.lightBlue};
  }

  /** Sort inactive button */
  .ant-table-column-sorter {
    color: ${({ theme: { colors } }) => colors.lightOrange};
  }

  /** Sort active button */
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${({ theme: { colors } }) => colors.secondary};
  }

  .ant-table-pagination.ant-pagination {
    display: flex;
    gap: 10px;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0 !important;
  }

  .ant-pagination-prev {
    margin: 0 !important;
  }
`;

export const SwitchWrapper = styled.span<{
  colors: { active?: string; inactive?: string };
}>`
  .ant-switch-checked {
    background: ${({ colors }) => colors.active ?? "green"} !important;
  }
`;
