import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import CreditRequestData from 'types/CreditRequest/CreditRequestData';

type Params = PaginatedQueryParams;

export const useGetCreditRequestsQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<CreditRequestData>({
    queryKey: [
      'credit-request',
      JSON.stringify(params.filter),
      params.current,
      params.pageSize,
      params.search,
      JSON.stringify(params.sort),
    ],
    url: `credit-request`,
    permissions: ['credit.request.index'],
    staleTime: 1000,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};
