import React, { useEffect } from "react";

import { InboxOutlined } from "@ant-design/icons";
import { Col, Pagination, Row, Upload, Form } from "antd";
import { Button, IconWithTooltip } from "components";
import { ThemeProvider } from "styled-components";

import theme from "../../styles/theme";
import { useAttachment } from "./hooks";
import {
  CustomCol,
  SListAttachmentsList,
  SListAttachmentsListItem,
} from "./styles";
import { defaultTranslation } from "./translations";
import { AttachmentInputProps } from "./types";

export const AttachmentInput = ({
  translation = defaultTranslation,
  hasPermissionToUpload = true,
  handleDownload,
  handleDestroy,
  controller,
  getFiles,
  hidePagination = true,
  disabled = false,
  onChange,
  value = [],
  handlePreview,
  downloadMultiple,
  dependencies,
  ...rest
}: AttachmentInputProps) => {
  const { Dragger } = Upload;

  const {
    fileList,
    fileUploadList,
    handleBeforeUpdate,
    handleRemoveUpdate,
    loadFiles,
    downloadFile,
    removeFile,
    pagination,
    previewFile,
    handleDownloadAllFiles,
  } =
    controller ?? useAttachment(value, onChange, translation, downloadMultiple);

  const handleChangePage = (page: number, pageSize?: number) => {
    if (getFiles) loadFiles(getFiles, { params: { page, per_page: pageSize } });
  };

  useEffect(() => {
    if (getFiles) loadFiles(getFiles, { params: { page: 1, per_page: 10 } });
  }, [dependencies]);

  return (
    <ThemeProvider theme={theme}>
      <Row gutter={[26, 26]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <CustomCol>
            <Dragger
              disabled={!hasPermissionToUpload || disabled}
              beforeUpload={handleBeforeUpdate}
              onRemove={handleRemoveUpdate}
              multiple
              listType="text"
              fileList={fileUploadList}
              {...rest}
              onChange={(info) => {
                if (onChange) {
                  onChange(
                    info.fileList.map((file) => {
                      if (file.originFileObj) return file.originFileObj;
                      return file;
                    }) as any,
                  );
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {translation.dragAndDropUploadText}
              </p>
            </Dragger>
          </CustomCol>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <span>{translation.existingAttachmentsText}</span>
          <Form.Item>
            <SListAttachmentsList
              dataSource={fileList}
              renderItem={(file: any) => (
                <>
                  <SListAttachmentsListItem key={file.id + file.name}>
                    <div className="file-title">
                      {file.name} {file.id ? "" : "*"}
                    </div>
                    <div className="file-actions">
                      <IconWithTooltip
                        action="download"
                        title={translation.downloadIconText ?? ""}
                        onClick={() => downloadFile(handleDownload, file)}
                      />
                      {handlePreview && (
                        <IconWithTooltip
                          action="detail"
                          title={translation.previewIconText ?? ""}
                          onClick={() => previewFile(file, handlePreview)}
                        />
                      )}
                      {!disabled && (
                        <IconWithTooltip
                          action="destroy"
                          title={translation.destroyIconText ?? ""}
                          onClick={() => removeFile(handleDestroy, file)}
                        />
                      )}
                    </div>
                  </SListAttachmentsListItem>
                </>
              )}
            />
          </Form.Item>
          {!hidePagination && (
            <Pagination
              defaultCurrent={1}
              onChange={handleChangePage}
              total={pagination.total}
            />
          )}
        </Col>
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            gap: "10px",
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          {downloadMultiple && (
            <Button
              type="primary"
              disabled={!fileList.filter((file: any) => !!file.id).length}
              onClick={handleDownloadAllFiles}
            >
              {translation.downloadAllButton}
            </Button>
          )}
        </span>
      </Row>
    </ThemeProvider>
  );
};
