import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const CustodiaStorageCategoryInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.custodia_storage_category')}
      name="custodia_storage_category"
      rules={[{ required: true }]}
    >
      <Input
        disabled
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
