import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { JUSTIFICATION_OPTIONS } from 'configs/constants';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const JustificationSelect = () => {
  const { t } = useTranslation();

  const setJustificationOther = useCreditRequestStore(state => state.setJustificationOther);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem name="justify_id" label={t('justification')} rules={[{ required: true }]}>
      <Select
        onChange={value => {
          setJustificationOther(value === JUSTIFICATION_OPTIONS.OTHER);
        }}
        disabled={pageStatus.viewing}
        options={[
          {
            value: JUSTIFICATION_OPTIONS.APPROVED,
            key: JUSTIFICATION_OPTIONS.APPROVED,
            label: t('cotton-approved-takeup'),
          },
          {
            value: JUSTIFICATION_OPTIONS.OTHER,
            key: JUSTIFICATION_OPTIONS.OTHER,
            label: t('others'),
          },
        ]}
      />
    </FormItem>
  );
};
