import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import { ModalStatus } from "components";
import { useIntegrations } from "pages/Serasa/Integrations/hooks/useIntegrations";
import type { ContractTemplateData } from "types/Contract/ContractTemplateData";
import { DTOErrorResponse } from "types/DTOReports";

import DTOErrorReponse from "../../../../@types/dtos/DTOErrorReponse";
import { useContract } from "../../../../hooks/fetch/useContract";
import { usePage } from "../../../../hooks/page";
import { formatValuesToFormData } from "../../../../services/form";
import { FormControllerProps } from "./types";

export const useFormController = ({
  closeForm,
  refresh,
  form,
  templateId,
}: FormControllerProps) => {
  const { t } = useTranslation();
  // const { getFieldsFromDraftModel } = useDraft();
  const { showTemplate } = useContract();
  const { uploadExposureFile, isUploading } = useIntegrations();
  const { alertStatus } = usePage();

  const [editedTemplate, setEditedTemplate] = useState<
    ContractTemplateData | undefined
  >();
  const [selectedFileTypeRoute, setSelectedFileTypeRoute] =
    useState<string>("");
  const [selectedFileTypeExtension, setSelectedFileTypeExtension] =
    useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasTemplateFileChanged, setHasTemplateFileChanged] = useState(false);
  // const [fileProcessedData, setFileProcessedData] = useState<any>();
  const [fields, setFields] = useState<any>({});
  const [uploadedFile, setUploadedFile] = useState<RcFile>();

  const storeOrUpdate = async (values: any, action: string, id?: string) => {
    let formData = new FormData();

    // if (!fileProcessedData) {
    //   ModalStatus({
    //     type: 'error',
    //     title: t('modal.error'),
    //     subTitle: t('pages.edocuments.templates.form.attachmentRequired'),
    //   });
    //   return;
    // }

    formData = formatValuesToFormData(
      { ...values },
      formData,
      [],
      ["document"],
    );

    if (!id || hasTemplateFileChanged) {
      formData.append("document", uploadedFile);
    }

    // eslint-disable-next-line consistent-return
    return uploadExposureFile(selectedFileTypeRoute, formData);
  };

  const handleSend = async (values: any, action: string, id?: string) => {
    form.validateFields().then(async () => {
      const response = await storeOrUpdate(values, action, id);
      if (response) {
        refresh();
        ModalStatus({
          onOk: () => {
            closeForm();
            setUploadedFile(undefined);
            form.resetFields();
          },
          type: "success",
          description: !id
            ? t("pages.integrations.expositionfileupload.form.store.success")
            : t("pages.integrations.expositionfileupload.form.edit.success"),
        });
        closeForm();
      }
    });
  };

  const handleRegister = (values: any) => handleSend(values, "register");

  const processFile = async (_: RcFile) => {
    const hideProcessingMessage = message.loading(
      t("pages.edocuments.contracttypes.processingfile"),
      0,
    );
    // const processedData = await getFieldsFromDraftModel(file);
    hideProcessingMessage();

    // setFileProcessedData({
    //   ...processedData,
    // });
  };

  const handleFileUpload = (file: RcFile) => {
    if (!uploadedFile) {
      const extension = selectedFileTypeExtension.split(",").filter((ext) => {
        return file.name.includes(ext.trim());
      });
      if (!extension.length) {
        alertStatus(
          {
            message: `${file.name} ${t(
              "pages.integrations.fileupload.invalidFileType",
              {
                ext: selectedFileTypeExtension,
              },
            )}`,
          } as DTOErrorReponse,
          "error",
        );
        return false;
      }
      setHasTemplateFileChanged(true);
      processFile(file);
      setUploadedFile(file);
    } else {
      alertStatus(
        {
          message: t("pages.edocuments.contracttypes.uploadOneFile"),
        } as DTOErrorResponse,
        "error",
      );
    }

    return true;
  };

  const handleFileRemove = () => {
    setUploadedFile(undefined);
    // setFileProcessedData((oldFileProcessedData: any) => ({
    //   url: '',
    // }));
    setEditedTemplate({
      ...editedTemplate,
      url: "",
      fields: editedTemplate.fields.map((field: any) => ({
        ...field,
        value: "",
      })),
      template: undefined,
    });
    editedTemplate.fields.map((field: any) =>
      form.setFieldsValue({ [field.key]: "" }),
    );
  };

  const getEditedTemplateData = async (id: string) => {
    setIsLoading(true);
    const templateData = await showTemplate(id);
    if (templateData) {
      setEditedTemplate(templateData);
      setUploadedFile(templateData.template);
      // setFileProcessedData({ url: templateData.url});
      form.setFieldsValue({ ...templateData });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (templateId) getEditedTemplateData(templateId);
  }, [templateId]);

  return {
    isLoading,
    setIsLoading,
    handleRegister,
    handleFileUpload,
    handleFileRemove,
    setUploadedFile,
    // fileProcessedData,
    fields,
    setFields,
    // setFileProcessedData,
    editedTemplate,
    uploadedFile,
    isUploading,
    setSelectedFileTypeRoute,
    setSelectedFileTypeExtension,
    selectedFileTypeExtension,
  };
};
