import React from 'react';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const IntervenerNameInput = () => {
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem label="Nome do interveniente" name="intervener_name" rules={[{ required: true }]}>
      <Input disabled={pageStatus.viewing} />
    </FormItem>
  );
};
