import React from 'react';
import { useTranslation } from 'react-i18next';

import { UseQueryResult } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableAsync } from 'components/ui/data-table';
import { PaginatedQueryParams } from 'hooks/usePaginatedQuery';
import { usePagination } from 'hooks/usePagination';
import PaginationData from 'types/Api/PaginationData';
import UserData from 'types/User/UserData';
import { UserPermissionData } from 'types/User/UserPermissionData';

type UserPermissionsTableProps = {
  query: ({
    ...params
  }: PaginatedQueryParams & {
    users: string[];
  }) => UseQueryResult<PaginationData<any>, Error>;
  user: UserData;
};

export const UserPermissionsTable = ({ query, user }: UserPermissionsTableProps) => {
  const { t } = useTranslation();
  const { dispatchPagination, paginationConfig } = usePagination();

  const getQuery = query({
    ...paginationConfig,
    users: [user.id],
    dispatchPagination,
  });

  const columns: ColumnDef<UserPermissionData>[] = [
    {
      accessorKey: 'name',
      header: t('pages.registered-customers.form.pj.partner.name'),
    },
    {
      accessorKey: 'groups',
      header: t('groups'),
      cell: ({ row }) => {
        const { groups } = row.original;
        return <span>{groups?.map(group => group.name).join(', ')}</span>;
      },
    },
  ];

  return (
    <DataTableAsync
      dispatchPagination={dispatchPagination}
      paginationConfig={paginationConfig}
      isLoading={getQuery.isLoading}
      isFetching={getQuery.isFetching}
      pageCount={paginationConfig.lastPage ?? 1}
      data={getQuery?.data?.data ?? []}
      columns={columns}
      withPagination
    />
  );
};
