import { useState } from "react";

import { DTOErrorResponse } from "types/DTOReports";

import monitoringApi from "../../services/api/monitoring";
import { ItemsType, usePage } from "../page";

export const useOperator = () => {
  const { alertStatus } = usePage();

  const [isFetching, setIsFetching] = useState(false);

  const getAll = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await monitoringApi.operator.get(params);
      return response.data.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getOptions = async (params = {}) => {
    const response = await getAll(params);

    if (!response)
      return {
        data: [] as ItemsType[],
        lastPage: 1,
      };

    return {
      data: response.data.map((operator) => ({
        key: operator.id,
        value: operator.id,
        label: operator.name,
      })),
      lastPage: response.last_page,
    };
  };

  return { getAll, getOptions, isFetching };
};
