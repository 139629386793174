import { useMemo } from 'react';

import { useCache } from 'hooks/cache';

import { useCreditRequestStore } from '../store/use-credit-request-store';

export const useGetWarrantyPledgedAreaOptions = () => {
  const cache = useCache();

  const isPhysicalPerson = useCreditRequestStore(state => state.isPhysicalPerson);

  const warrantyPledgedAreaOptions = useMemo(() => {
    if (isPhysicalPerson === true) {
      return cache.getCreditOptions('warranty_pledge_area').filter(w => w.tag === 'physical');
    }
    return cache.getCreditOptions('warranty_pledge_area');
    // eslint-disable-next-line
  }, [isPhysicalPerson, cache.getCreditOptions]);

  return {
    warrantyPledgedAreaOptions,
  };
};
