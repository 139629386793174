import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Col, Row, Table } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';
import { TableData } from 'compositions';
import { PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useCache } from 'hooks/cache';
import { useContractCreditRequestsTableController } from 'pages/CreditRequest/hooks/useGetCreditRequestsWithContract';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { useTheme } from 'styled-components';
import { Formatter } from 'utils/Formatter';

import CreditRequestData from '../../../../../types/CreditRequest/CreditRequestData';
import { columns } from './columns';

export const ContractCreditRequestsTable = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id: idParam } = useParams<{ id: string }>();
  const cache = useCache();
  const { volumePaginationConfig, getCreditRequestsWithContract } = useContractCreditRequestsTableController();

  const creditRequestOfContract = useCreditRequestStore(state => state.creditRequestOfContract);
  const selectedContractNumber = useCreditRequestStore(state => state.selectedContractNumber);
  const contractTotalVolume = useCreditRequestStore(state => state.contractTotalVolume);

  return (
    creditRequestOfContract.length > 0 && (
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.credit_request_of_contract')}</h2>

        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <PermissionedComponent permission="credit.request.contract.volume">
              <TableData
                rowKey="id"
                columns={columns.map(col => {
                  switch (col.key) {
                    case 'number':
                      return {
                        ...col,
                        render: (value: any, record: CreditRequestData) => {
                          return (
                            <>
                              {idParam === record.id ? (
                                `${value} (solicitação atual)`
                              ) : (
                                <Link to={`/credit-request/view/${record.id}`} target="_blank">
                                  {value}
                                </Link>
                              )}
                            </>
                          );
                        },
                      };
                    case 'name':
                      return {
                        ...col,
                        render: (_: any, record: CreditRequestData) => {
                          const personTypeRowData = record.requester_person_type === PHYSICAL_PERSON_TYPE;
                          const provider = personTypeRowData
                            ? record.requester_person?.name
                            : record.requester_person?.company_name;

                          let name = provider || '-';
                          if (record.operation.slug === 'prepayment') {
                            name = record.json_data ? record.json_data?.contract?.requester_person_name : '-';
                          }
                          return name;
                        },
                      };
                    case 'doc_number':
                      return {
                        ...col,
                        render: (_: any, record: CreditRequestData) => {
                          const personTypeRowData = record.requester_person_type === PHYSICAL_PERSON_TYPE;
                          const requesterDocNumber = personTypeRowData
                            ? record.requester_person?.cpf
                            : record.requester_person?.cnpj;

                          let docNumber = requesterDocNumber || '-';
                          if (record.operation.slug === 'prepayment' && record.json_data) {
                            docNumber = personTypeRowData
                              ? record.json_data?.contract?.requester_person_cpf
                              : record.json_data?.contract?.requester_person_cnpj;
                          }
                          return docNumber;
                        },
                      };
                    case 'quantity':
                      return {
                        ...col,
                        render: (_: any, record: CreditRequestData) => {
                          if (record.json_data && record.json_data?.quantity_kg) {
                            return Formatter.integer(record.json_data?.quantity_kg, 3);
                          }
                          return '0';
                        },
                      };
                    case 'requested_amount':
                      return {
                        ...col,
                        render: (_: any, record: CreditRequestData) => {
                          return Formatter.money(
                            record.requested_amount,
                            2,
                            cache.currency_type.find(c => c.id === record?.json_data?.currency_type_id)?.name,
                            true,
                          );
                        },
                      };
                    default:
                      return {
                        ...col,
                        render: (_: any, record: CreditRequestData) => {
                          const value: any = record;
                          return value[col.key];
                        },
                      };
                  }
                })}
                dataSource={creditRequestOfContract ?? []}
                summary={() => {
                  return (
                    <Table.Summary.Row style={{ background: theme.colors.greys.grey01 }}>
                      <Table.Summary.Cell index={0}>
                        <strong>{t('pages.credit-request.form.titles.credit_request_of_contract_total')}</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={2} />
                      <Table.Summary.Cell index={3}>
                        <strong>{Formatter.integer(contractTotalVolume, 3, null)}</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} />
                    </Table.Summary.Row>
                  );
                }}
                tableConfig={{ nopagination: false }}
                pagination={{ ...volumePaginationConfig, showSizeChanger: false }}
                onChange={pagination => getCreditRequestsWithContract(selectedContractNumber, pagination)}
              />
            </PermissionedComponent>
          </Col>
        </Row>
      </div>
    )
  );
};
