import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useGetCreditRequestByIdQuery } from 'pages/CreditRequest/api/use-get-credit-request-by-id.query';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/use-formalization-guarantees-store';

import { Drafts } from './components/draft/drafts';
import { Packages } from './components/package/packages';
import { Container } from './styles';
import CommentsProps from './types';

const FormalizationGuarantees: React.FC<CommentsProps> = ({ creditRequestId }) => {
  const queryClient = useQueryClient();

  const setPhase = useFormalizationGuaranteesStore(state => state.setPhase);
  const setCreditRequestId = useFormalizationGuaranteesStore(state => state.setCreditRequestId);
  const setRequesterPersonId = useFormalizationGuaranteesStore(state => state.setRequesterPersonId);

  const creditRequestQuery = useGetCreditRequestByIdQuery(creditRequestId);

  useEffect(() => {
    if (creditRequestQuery.data?.requester_person) setRequesterPersonId(creditRequestQuery.data?.requester_person.id);
    if (creditRequestQuery.data?.phase) setPhase(creditRequestQuery.data?.phase);
  }, [creditRequestQuery.data?.id]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['credit-request/package'],
    });
    creditRequestQuery.refetch();
    setCreditRequestId(creditRequestId);
  }, []);

  return (
    <Container className="h-full overflow-y-auto flex flex-col gap-2">
      <Packages />
      <Drafts />
    </Container>
  );
};

export default FormalizationGuarantees;
