import React from 'react';

import { RadioGroupProps } from '@radix-ui/react-radio-group';
import { Label } from 'components/ui/label';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { randomUUID } from 'lib/utils';

type Props = {
  options: {
    value: string;
    label: string | number | React.ReactNode;
  }[];
} & RadioGroupProps;

export const KanbanRadioFilter = ({ options, ...props }: Props) => {
  const [id] = React.useState(randomUUID());

  return (
    <RadioGroup
      {...props}
      className="border-1 flex h-max w-fit gap-0 overflow-hidden rounded-md border border-gray-400"
    >
      {options?.map(option => (
        <div
          className="relative flex w-full max-w-[300px] flex-col items-center justify-center border-l-[1px] border-gray-400 first-of-type:border-l-0"
          key={id + option.value}
        >
          <RadioGroupItem value={option.value.toString()} id={id + option.value} className="peer sr-only" />
          <Label
            htmlFor={id + option.value}
            className="relative flex h-full w-full cursor-pointer items-center justify-center bg-gray-200 px-2 py-1 text-center text-xs font-bold uppercase hover:bg-primary-100 peer-data-[state=checked]:bg-primary peer-data-[state=checked]:text-white"
          >
            <span className="whitespace-nowrap">{option.label}</span>
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};
