import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form as AntForm, Radio, Row, Col, Spin } from "antd";
import ApiSelect from "components/ApiSelect";

import { FormItem, Input, Button } from "../../../../components";
import { SFormContainer, SFormButtons } from "../../../../styles";
import { FormProps } from "./types";

export const Form = ({
  isOpen,
  setIsFormOpen,
  form,
  handleSave,
  handleStatus,
  handlePositions,
  isFetchingForm,
  dataForm,
  setDataForm,
}: FormProps) => {
  const { t } = useTranslation();

  const [optionsSelect, setOptionsSelect] = useState({
    status_origin: [],
    status_next: [],
    position: [],
  });

  useEffect(() => {
    if (Object.keys(dataForm).length) {
      const newData = {
        id: dataForm.id,
        is_attachment_required: dataForm.is_attachment_required,
        is_comments_required: dataForm.is_comments_required,
        status_origin: dataForm.sustainability_report_status_id,
        status_next: dataForm.sustainability_report_status_next_id,
        position: dataForm.position_id,
      };
      setOptionsSelect({
        status_origin: [
          {
            value: dataForm.sustainability_report_status_id,
            key: dataForm.sustainability_report_status_id,
            label: dataForm.status_origin.name,
          },
        ],
        status_next: [
          {
            value: dataForm.sustainability_report_status_next_id,
            key: dataForm.sustainability_report_status_next_id,
            label: dataForm.status_next.name,
          },
        ],
        position:
          dataForm.position_id && dataForm.position
            ? [
                {
                  value: dataForm.position_id,
                  key: dataForm.position_id,
                  label: dataForm.position.name,
                },
              ]
            : [],
      });
      form.setFieldsValue({ ...newData });
    }
  }, [dataForm]);

  const handleSubmit = () => {
    const data = form.getFieldsValue();
    handleSave(data);
  };

  const changeOptions = (value: any, page: number, type: any, handle: any) => {
    setOptionsSelect((prev) => ({ ...prev, [type]: [] }));
    return handle(value, page);
  };

  return (
    <SFormContainer visible={isOpen}>
      <Spin spinning={isFetchingForm}>
        <AntForm form={form} onFinish={handleSubmit}>
          <Row gutter={[8, 24]}>
            <FormItem name="id" hidden>
              <Input />
            </FormItem>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                label={t(
                  "pages.sustainability.team-status.table.status_origin",
                )}
                name="status_origin"
                key="status_origin"
                rules={[{ required: true }]}
              >
                <ApiSelect
                  initialOptions={optionsSelect.status_origin}
                  fetchOptions={handleStatus}
                  listHeight={221}
                  showSearch
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                label={t("pages.sustainability.team-status.table.status_next")}
                name="status_next"
                key="status_next"
                rules={[{ required: true }]}
              >
                <ApiSelect
                  initialOptions={optionsSelect.status_next}
                  fetchOptions={async (value, page) =>
                    changeOptions(value, page, "status_next", handleStatus)
                  }
                  listHeight={221}
                  showSearch
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                label={t("pages.sustainability.team-status.table.position")}
                name="position"
                key="position"
                rules={[{ required: true }]}
              >
                <ApiSelect
                  initialOptions={optionsSelect.position}
                  fetchOptions={async (value, page) =>
                    changeOptions(value, page, "position", handlePositions)
                  }
                  listHeight={221}
                  showSearch
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={4}>
              <FormItem
                label={t(
                  "pages.sustainability.team-status.table.is_attachment_required",
                )}
                name="is_attachment_required"
                key="is_attachment_required"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  style={{ marginTop: "14px" }}
                  className="custom-radio-group small-radio-group"
                  buttonStyle="solid"
                >
                  <Radio.Button key="is_attachment_required_yes" value>
                    {t("pages.sustainability.team-status.table.required.true")}
                  </Radio.Button>
                  <Radio.Button key="is_attachment_required_not" value={false}>
                    {t("pages.sustainability.team-status.table.required.false")}
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={4}>
              <FormItem
                label={t(
                  "pages.sustainability.team-status.table.is_comments_required",
                )}
                name="is_comments_required"
                key="is_comments_required"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  style={{ marginTop: "14px" }}
                  className="custom-radio-group small-radio-group"
                  buttonStyle="solid"
                >
                  <Radio.Button key="is_attachment_required_yes" value>
                    {t("pages.sustainability.team-status.table.required.true")}
                  </Radio.Button>
                  <Radio.Button key="is_attachment_required_not" value={false}>
                    {t("pages.sustainability.team-status.table.required.false")}
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>
          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={() => {
                setIsFormOpen(false);
                setDataForm({});
              }}
            >
              {t("pages.sustainability.team-status.buttons.cancel")}
            </Button>

            <Button status="primary" htmlType="submit">
              {dataForm.id
                ? t("pages.sustainability.team-status.buttons.edit")
                : t("pages.sustainability.team-status.buttons.new")}
            </Button>
          </SFormButtons>
        </AntForm>
      </Spin>
    </SFormContainer>
  );
};
