import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { useAbility } from 'hooks/ability';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDeleteCreditRequestAssigneeMutation = () => {
  const { alertStatus } = usePage();
  const { alert } = useAlertDialog();
  const ability = useAbility();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['deleteCreditRequestAssignee'],
    mutationFn: async (assigneeId: string) => {
      if (ability.can('credit.request.registered_assignees.destroy', ''))
        return api.delete(`credit-request/registered-assignees/${assigneeId}`);

      return alert({
        title: t('no-permission'),
        description: t('you-dont-have-permission-to-do-that'),
        type: 'error',
      });
    },
    onError: err => {
      alertStatus(err.message, 'error');
    },
  });
};
