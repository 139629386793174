import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaMinus, FaSearch } from "react-icons/fa";

import { Form as AntForm, DatePicker, Spin } from "antd";
import ptBR from "antd/es/date-picker/locale/pt_BR";
import { TablePaginationConfig } from "antd/lib/table";
import { BasePortSelect } from "components/BasePortSelect";
import { SubdivisionSelect } from "components/SubdivisionSelect";
import { SubsidiarySelect } from "components/SubsidiarySelect";
import { TableData } from "compositions/TableDataAsync";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import appConfigs from "configs/app";
import {
  CURRENCY_BRL_ID,
  CURRENCY_USD_ID,
  SUBDIVISION_SOYBEAN_ID,
} from "configs/constants";
import { useCache } from "hooks/cache";
import { usePriceDay } from "hooks/fetch/usePriceDay";
import moment from "moment";
import { OptionsType } from "rc-select/lib/interface";
import { getCurrentHourInTimezone } from "utils/DateFunctions";
import { Formatter } from "utils/Formatter";

import type { PriceDayData } from "../../../@types/data/PriceDayData";
import {
  Breadcrumb,
  Button,
  FormItem,
  IconWithTooltip,
  Select,
} from "../../../components";
import { Can } from "../../../hooks/ability";
import { CacheOptions } from "../../../types/Hooks/cache";
import columns from "./columns";
import { Form } from "./Form";
import { SContainer, SFilterContainer, SHeader, STitlePage } from "./styles";

const PriceDay: React.FC = () => {
  const [form] = AntForm.useForm();
  const [searchForm] = AntForm.useForm();

  const { t } = useTranslation();

  // const { alertStatus } = usePage();
  const controller = useTable();
  const { refresh } = controller;
  const { index, isFetching, isDestroying } = usePriceDay();
  const cache = useCache();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibilityForm, setVisibilityForm] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({
    subdivision_id: SUBDIVISION_SOYBEAN_ID,
  });
  const [basePortOptions, setBasePortOptions] = useState<OptionsType>([]);

  const canCreate = getCurrentHourInTimezone("America/Sao_Paulo") < 18;
  const canEdit = (date: string) =>
    getCurrentHourInTimezone("America/Sao_Paulo") < 18 &&
    moment(date).isSame(moment(), "day");

  // const handleSuccess = (messageSuccess: string) =>
  //   ModalStatus({
  //     type: 'success',
  //     title: t('modal.success'),
  //     subTitle: messageSuccess,
  //   });

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleFastSearch = () => {
    const values = searchForm.getFieldsValue();
    setFilters({ ...values });
  };

  const tableDataClearAllFilters = () => {
    setFilters({});
    searchForm.resetFields();
  };

  // const handleRemoveChange = async (record: any) => {
  //   ModalStatus({
  //     type: 'delete',
  //     title: t('pages.register.harvest.delete.title'),
  //     subTitle: t('pages.register.harvest.delete.subtitle'),
  //     cancelText: t('pages.register.harvest.delete.cancel'),
  //     okText: t('pages.register.harvest.delete.confirm'),
  //     onOk: () => {
  //       destroy(record.id)
  //         .then(response => {
  //           handleSuccess(response.data.message);
  //         })
  //         .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
  //     },
  //   });
  // };

  const getActions = () => ({
    render: (active: number, record: PriceDayData) => (
      <>
        {/* <Can I="" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.register.harvest.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can> */}
        <Can I="register.dailyprice.update" a="">
          <IconWithTooltip
            action="edit"
            title={t("pages.register.harvest.table.icons.edit")}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                date: moment(record.date),
              });

              if (record.base_port)
                setBasePortOptions([
                  {
                    key: record.base_port_id,
                    label: record.base_port.name,
                    value: record.base_port_id,
                  },
                ]);

              form.scrollToField("name", {
                scrollMode: "always",
                block: "start",
                behavior: (actions) =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfig = {
    getActions,
  };

  const searchRequest = async (
    pagination: TablePaginationConfig,
    filter: object,
  ) => {
    const response = await index({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        ...filter,
      },
    });
    return {
      data: response.data,
      total: response.total,
    };
  };

  useEffect(() => {
    searchForm.setFieldsValue({
      subdivision_id: SUBDIVISION_SOYBEAN_ID,
    });
  }, []);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.register") },
          { title: t("breadcrumb.priceday"), to: "/register/harvest" },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t("pages.register.priceday.title_page")}</STitlePage>

          <Can I="register.dailyprice.store" a="">
            <Button
              status="primary"
              icon={visibilityForm ? <FaMinus /> : <FaPlus />}
              onClick={handleDisplayForm}
              disabled={!canCreate}
            >
              {t("pages.register.priceday.buttonAdd")}
            </Button>
          </Can>
        </SHeader>

        <Form
          form={form}
          handleHideForm={handleHideForm}
          isVisible={visibilityForm}
          isEditing={editing}
          refetchTable={refresh}
          canCreate={canCreate}
          canEdit={canEdit}
          basePortOptions={basePortOptions}
        />

        <SFilterContainer>
          <AntForm
            form={searchForm}
            name="filter-harvest"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div style={{ display: "flex", overflowX: "auto" }}>
              {/* <FormItem name="search" label={t('pages.register.harvest.search')}>
                <Input />
              </FormItem> */}
              <FormItem
                name="date"
                label={t("pages.register.harvest.date")}
                style={{ minWidth: "150px" }}
              >
                <DatePicker
                  locale={ptBR}
                  mode="date"
                  format={appConfigs.formatDate}
                />
              </FormItem>
              <FormItem
                name="base_port_id"
                label={t("pages.register.harvest.base_port_id")}
                style={{ minWidth: "300px" }}
              >
                <BasePortSelect breakOptions listHeight={300} />
              </FormItem>
              <FormItem
                name="currency_type_id"
                label={t("pages.register.harvest.currency_type_id")}
                style={{ minWidth: "300px" }}
              >
                <Select
                  options={cache
                    .getCreditOptions("currency_type")
                    .filter(
                      (currency) =>
                        currency.key === CURRENCY_BRL_ID ||
                        currency.key === CURRENCY_USD_ID,
                    )}
                />
              </FormItem>
              <FormItem
                name="subsidiary_id"
                label={t("pages.register.harvest.subsidiary_id")}
                style={{ minWidth: "300px" }}
              >
                <SubsidiarySelect showSearch />
              </FormItem>
              <FormItem
                name="subdivision_id"
                label={t("pages.register.harvest.subdivision_id")}
                style={{ minWidth: "300px" }}
              >
                <SubdivisionSelect whitelist={[SUBDIVISION_SOYBEAN_ID]} />
              </FormItem>
            </div>
            <div className="filter-button">
              <Button status="primary" text="uppercase" htmlType="submit">
                <FaSearch /> {t("form.actions.search")}
              </Button>
              <Button
                status="secondary"
                size="middle"
                onClick={tableDataClearAllFilters}
              >
                {t("pages.register.harvest.buttonClearFilter")}
              </Button>
            </div>
          </AntForm>
        </SFilterContainer>

        <Spin spinning={isFetching || isDestroying}>
          <TableData
            searchFilters={filters}
            searchRequest={searchRequest}
            rowKey="id"
            width="100%"
            controller={controller}
            columns={columns.map((column: any) => {
              let columnRender: any = {
                ...column,
                title: t(column.title),
              };

              if (column.dataIndex === "date") {
                columnRender = {
                  ...columnRender,
                  render: (value: string) =>
                    moment(value).format(appConfigs.formatDate),
                };
              }

              if (column.dataIndex === "value") {
                columnRender = {
                  ...columnRender,
                  render: (value: number, obj: any) =>
                    Formatter.money(value, 2, obj.currency_type.slug),
                };
              }

              if (column.dataIndex === "currency_type") {
                columnRender = {
                  ...columnRender,
                  render: (value: { name: string }) => value.name,
                };
              }

              if (column.dataIndex === "sub_division_id") {
                columnRender = {
                  ...columnRender,
                  render: (value: string) =>
                    cache
                      .getCreditOptions("sub_division")
                      .find(
                        (subdivision: CacheOptions) =>
                          subdivision.key === value,
                      ).label,
                };
              }

              if (column.dataIndex === "subsidiary_id") {
                columnRender = {
                  ...columnRender,
                  render: (value: string) =>
                    cache
                      .getCreditOptions("subsidiary")
                      .find(
                        (subsidiary: CacheOptions) => subsidiary.key === value,
                      ).label,
                };
              }

              if (column.dataIndex === "base_port") {
                columnRender = {
                  ...columnRender,
                  render: (value: { name: string }) => value?.name,
                };
              }

              if (column.dataIndex === "incoterm_id") {
                columnRender = {
                  ...columnRender,
                  render: (value: string) =>
                    cache
                      .getCreditOptions("incoterm")
                      .find((modality) => modality.key === value)?.label || "-",
                };
              }

              return columnRender;
            })}
            loading={loading}
            pagination={{ position: ["bottomCenter"] }}
            tableConfig={tableConfig}
          />
        </Spin>
      </SContainer>
    </>
  );
};

export default PriceDay;
