import React from "react";

import { ContractTable } from "../contract.table";
import { Filter } from "./Filter";

type TableProps = {
  parentId?: string;
};

export const Table = ({ parentId }: TableProps) => {
  return (
    <div className="flex h-full flex-col overflow-hidden">
      {!parentId ? <Filter /> : null}

      <div className="mb-2 flex h-full overflow-hidden">
        <ContractTable />
      </div>
    </div>
  );
};
