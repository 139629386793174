import TeamStatusData from 'types/CreditRequest/DTOTeamStatus';
import { create } from 'zustand';

interface TemStatusState {
  slaToEdit: any;
  slaToRemove: any;
  isSlaModalOpen: boolean;
  teamStatusSla: TeamStatusData;
  setSlaModalOpen: (isOpen: boolean) => void;
  setSlaToEdit: (sla: any) => void;
  setSlaToRemove: (sla: any) => void;
  setTeamStatusSla: (teamStatus: TeamStatusData) => void;
}

export const useTeamStatusStore = create<TemStatusState>()(set => ({
  slaToEdit: null,
  slaToRemove: null,
  isSlaModalOpen: false,
  teamStatusSla: null,
  setSlaModalOpen: isOpen => set({ isSlaModalOpen: isOpen }),
  setSlaToEdit: sla => set({ slaToEdit: sla }),
  setSlaToRemove: sla => set({ slaToRemove: sla }),
  setTeamStatusSla: teamStatus => set({ teamStatusSla: teamStatus }),
}));
