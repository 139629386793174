import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { useAbility } from 'hooks/ability';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import { TeamStatusSlaFormData } from 'types/Admin/TeamStatus/TeamStatusSlaFormData';

export const useStoreTeamStatusSlaMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();
  const ability = useAbility();

  return useMutation({
    mutationFn: async (data: TeamStatusSlaFormData) => {
      if (!ability.can('credit.request.teamstatussla.store', ''))
        return alert({ title: t('no-permission'), type: 'error' });

      const response = await api.post('credit-request/team-status-sla', data);
      return response.data.data;
    },
    mutationKey: ['storeTeamStatusSla'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
