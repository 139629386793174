import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import DTODepositary from '../../../../../@types/dtos/credit-request/DTODepositary';
import { Button } from '../../../../../components';
import { ModalAdd } from './modalAdd';
import { SDepositary } from './styles';
import { TableDepositary } from './table';

export const Depositary = () => {
  const { t } = useTranslation();
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [valuesEditDepositary, setValuesEditDepositary] = useState<DTODepositary>();

  const setDepositaryList = useCreditRequestStore(state => state.setDepositaryList);
  const depositaryList = useCreditRequestStore(state => state.depositaryList);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  return (
    <SDepositary className="group-form">
      <h2>{t('pages.credit-request.form.titles.depositary')}</h2>
      {!pageStatus.viewing && (
        <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>
          <Button status="primary-outline" onClick={() => setModalAddVisible(true)}>
            {t('pages.credit-request.depositary.buttons.new-depositary')}
          </Button>
        </div>
      )}
      <TableDepositary
        depositaryList={depositaryList}
        pageStatus={pageStatus}
        setValuesEditDepositary={setValuesEditDepositary}
        setModalAddVisible={setModalAddVisible}
        setDepositaryList={value => setDepositaryList(value)}
        setLoading={setIsLoading}
        creditRequestId={creditRequestId}
      />

      <ModalAdd
        setDepositaryList={value => setDepositaryList(value)}
        depositaryList={depositaryList}
        setModalAddVisible={setModalAddVisible}
        modalAddVisible={modalAddVisible}
        valuesEditDepositary={valuesEditDepositary}
        setValuesEditDepositary={setValuesEditDepositary}
      />
    </SDepositary>
  );
};
