import { Button } from "antd";
import styled from "styled-components";

import { ButtonCustomProps } from "./types";

export const SBtn: any = styled(Button)<ButtonCustomProps>`
  border-radius: 5px;
  font-size: 14px !important;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-weight: 600;
  min-width: 188px;
  text-align: center;
  transition: background 0.2s;
  transition: font-size 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  ${({ size }) => {
    if (size === "large") {
      return `padding: 6px 17px;
      min-width: 188px;`;
    }
    return `min-width: 131px;`;
  }};
  ${({
    theme: {
      colors: { buttons },
      fonts,
    },
    status,
  }) => {
    switch (status) {
      case "primary":
        return `color: ${buttons.primary.label};
      background: ${buttons.primary.background};
      border-color: ${buttons.primary.border};`;
      case "secondary":
        return `color: ${buttons.secondary.label};
      background: ${buttons.secondary.background};
      border-color: ${buttons.secondary.border};`;
      case "outline":
        return `color: ${buttons.primaryOutline.label};
      background: ${buttons.primaryOutline.background};
      border-color: ${buttons.primaryOutline.border};`;
      case "outline-white":
        return `color: #ffffff;
      background: transparent;
      border-color: #ffffff;
      border-width: 2px;
      border-radius: 5px;
      font-size: 18px !important;
      font-family: ${fonts.primary};
      font-weight: 500;`;
      default:
        return `color: inherit;
          background: inherit;
          border-color: inherit;
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
    }
  }}

  & > svg {
    margin-right: 5px;
  }

  :hover,
  :visited {
    ${({
      theme: {
        colors: { buttons },
        colors,
      },
      status,
    }) => {
      switch (status) {
        case "primary":
          return `color: ${colors.white};
          background: ${colors.primary};
          border-color: ${colors.primary};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case "secondary":
          return `color: ${colors.secondary};
          background: ${colors.white};
          border-color: ${colors.secondary};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case "outline":
          return `color: ${colors.primary};
          background: ${colors.white};
          border-color: ${colors.primary};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case "outline-white":
          return `color: ${colors.text};
          background: #ffffff;
          border-color: #ffffff;
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case "ligth-danger":
          return `color: ${colors.white};
          background: ${colors.danger};
          border-color: ${buttons.lightDanger};`;
        case "ligth-green":
          return `color: ${colors.white};
          background: ${colors.success};
          border-color: ${buttons.lightGreen};`;
        default:
          return `color: inherit;
          background: inherit;
          border-color: inherit;
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
      }
    }}
  }
`;

export default SBtn;
