import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { message } from "antd";
import { SelectOptionType } from "components/Select/types";
import { useAbility } from "hooks/ability";
import { useContractType } from "hooks/fetch/useContractType";
import { useDocuments } from "hooks/fetch/useDocuments";
import { useModalMessage } from "hooks/helpers/useModalMessage";
import datasulApi from "services/api/datasul";
import { ContractAttachmentData } from "types/Contract/ContractAttachmentData";
import type { ContractFixationOptionData } from "types/Contract/ContractFixationOptionData";
import { Formatter } from "utils/Formatter";

import {
  CONTRACT_STATUS_FULLY_SIGNED,
  CONTRACT_STATUS_PARTIALLY_SIGNED,
  CONTRACT_STATUS_REISSUE_DRAFT,
  CONTRACT_STATUS_APPROVED_EMISSION,
  CONTRACT_STATUS_APPROVED_SIGNATURE,
  CONTRACT_STATUS_FINISHED,
  CONTRACT_STATUS_STORED,
  CONTRACT_STATUS_VALIDATE_SIGNATURE,
  CONTRACT_STATUS_COMPLETED_DRAFT,
  CONTRACT_STATUS_SENT_SIGNATURE,
  CONTRACT_STATUS_CREATED,
  CONTRACT_STATUS_VALIDATE_EMISSION,
  CONTRACT_STATUS_REQUEST_NEW_SIGNATURES,
  CONTRACT_STATUS_COMPLETED_DRAFT_ORDER,
  CONTRACT_STATUS_SENT_SIGNATURE_ORDER,
  CONTRACT_STATUS_STORED_ORDER,
  CONTRACT_STATUS_FULLY_SIGNED_ORDER,
  CONTRACT_STATUS_PARTIALLY_SIGNED_ORDER,
  CONTRACT_STATUS_APPROVED_SIGNATURE_ORDER,
  CONTRACT_STATUS_CREATED_ORDER,
  CONTRACT_STATUS_APPROVED_EMISSION_ORDER,
  CONTRACT_STATUS_VALIDATE_EMISSION_ORDER,
  CONTRACT_STATUS_VALIDATE_SIGNATURE_ORDER,
  CONTRACT_STATUS_REISSUE_DRAFT_ORDER,
  CONTRACT_STATUS_REQUEST_NEW_SIGNATURES_ORDER,
  CONTRACT_STATUS_WAITING_ORDER,
  CONTRACT_STATUS_WAITING,
  ContractOperationType,
} from "../../../configs/constants";
import { useContract } from "../../../hooks/fetch/useContract";
import { useContractRelatedDocument } from "../../../hooks/fetch/useContractRelatedDocument";
import { ContractData } from "../../../types/Contract/ContractData";
import { ContractFixationData } from "../../../types/CreditRequest/ContractFixationData";
import { useStages } from "../Emission/controllers/useStagesController";
import { ContractsContextProps, ContractStatuses } from "./types";

export const ContractContext = createContext<ContractsContextProps>(undefined);

export const useContractContext = () => useContext(ContractContext);

export const ContractProvider: React.FC = ({ children }) => {
  const {
    get,
    isFetching,
    toggleActive,
    preview,
    isFetchingPreview,
    addAttachments,
    isAddingAttachments,
    getRelatedDocumentsAvaliableTypesForContractOptions,
    updateProperties,
    isUpdating,
    getContractFixations,
    isFetchingFixations,
    getPurchaseTypes,
    requestReissueDraft,
  } = useContract();
  const redirectToTab = new URLSearchParams(window.location.search).get(
    "stage",
  );
  const relatedDocument = new URLSearchParams(window.location.search).get(
    "related",
  );
  const { isStageSelected, setSelectedStage, selectedStage } =
    useStages(redirectToTab);
  const { destroyFile } = useDocuments();
  const { id } = useParams<{ id: string }>();
  const {
    addNewRelatedDocument: storeRelatedDocument,
    isStoring: isStoringNewRelatedDocument,
    destroy: destroyRelatedDocument,
    isDestroying: isDeletingRelatedDocument,
  } = useContractRelatedDocument();
  const {
    getTypesOptions: getContractTypesOptions,
    isFetching: isFetchingContractTypes,
  } = useContractType();
  const { handleSuccess } = useModalMessage();
  const { t } = useTranslation();
  const ability = useAbility();

  const [contracts, setContracts] = useState<ContractData[]>([]);
  const [contract, setContract] = useState<ContractData | undefined>();
  const [mainContract, setMainContract] = useState<ContractData>();
  const [selectedContract, setSelectedContract] = useState<ContractData>();
  const [relatedDocuments, setRelatedDocuments] = useState<any>([]);
  const [contractId, setContractId] = useState("");
  const [fileToPreview, setFileToPreview] = useState<{
    type: string;
    url: string;
  }>({ type: undefined, url: "" });
  const [contractAttachments, setContractAttachments] = useState<
    ContractAttachmentData[]
  >([]);
  const [isShowingAttachmentCompare, setIsShowingAttachmentCompare] =
    useState(false);
  const [needEmissionValidation, setNeedEmissionValidation] = useState(false);
  const [needSignatureValidation, setNeedSignatureValidation] = useState(false);
  const [hasUploadedSignedContract, setHasUploadedSignedContract] =
    useState(false);
  const [isValidatedOnEmission, setIsValidatedOnEmission] = useState(false);
  const [isValidatedOnSignature, setIsValidatedOnSignature] = useState(false);
  const [isValidatingSignatures, setIsValidatingSignatures] = useState(false);
  const [isChangingContractType, setIsChangingContractType] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [alreadyHasDraft, setAlreadyHasDraft] = useState(false);
  const [childrenTypes, setChildrenTypes] = useState([]);
  const [selectedRelatedDocument, setSelectedRelatedDocument] = useState<any>();

  const lastSelectedContractIdRef = useRef("");

  const isMainContractCancelled =
    CONTRACT_STATUS_STORED_ORDER === contract?.status?.order;
  const isSelectedContractCancelled =
    CONTRACT_STATUS_STORED_ORDER === selectedContract?.status?.order ||
    isMainContractCancelled;

  const isSelectedContractInStatus = (status: ContractStatuses) => {
    if (!selectedContract) return false;

    switch (status) {
      case "completedDraft":
        return selectedContract.status.id === CONTRACT_STATUS_COMPLETED_DRAFT;
      case "waiting":
        return selectedContract.status.id === CONTRACT_STATUS_WAITING;
      case "inSignature":
        return selectedContract.status.id === CONTRACT_STATUS_SENT_SIGNATURE;
      case "stored":
        return selectedContract.status.id === CONTRACT_STATUS_STORED;
      case "fullySigned":
        return selectedContract.status.id === CONTRACT_STATUS_FULLY_SIGNED;
      case "partiallySigned":
        return selectedContract.status.id === CONTRACT_STATUS_PARTIALLY_SIGNED;
      case "approvedSignature":
        return (
          selectedContract.status.id === CONTRACT_STATUS_APPROVED_SIGNATURE
        );
      case "created":
        return selectedContract.status.id === CONTRACT_STATUS_CREATED;
      case "approvedEmission":
        return selectedContract.status.id === CONTRACT_STATUS_APPROVED_EMISSION;
      case "validateEmission":
        return selectedContract.status.id === CONTRACT_STATUS_VALIDATE_EMISSION;
      case "validateSignature":
        return (
          selectedContract.status.id === CONTRACT_STATUS_VALIDATE_SIGNATURE
        );
      case "sentSignature":
        return selectedContract.status.id === CONTRACT_STATUS_SENT_SIGNATURE;
      case "reissueDraft":
        return selectedContract.status.id === CONTRACT_STATUS_REISSUE_DRAFT;
      case "requestNewSignatures":
        return (
          selectedContract.status.id === CONTRACT_STATUS_REQUEST_NEW_SIGNATURES
        );

      default:
        return false;
    }
  };

  const getMainContract = () => contracts.find((c) => !c.parent_id);

  const getRelatedDocuments = () => contract?.dependencies ?? [];

  const isDocumentSelected = (documentId: string) =>
    selectedContract?.id === documentId;

  const isSelectedDocumentMain = selectedContract?.id === id;

  const isDocumentCancelled =
    !isSelectedDocumentMain &&
    selectedContract?.status?.id === CONTRACT_STATUS_STORED;

  // const isMainContractCancelledDatasul = mainContract?.external_status === 'Cancelado';

  const isStored = selectedContract?.status?.id === CONTRACT_STATUS_STORED;

  const hasContractType = !!mainContract?.contract_type_id;

  const isBuyContract = ContractOperationType.BUY === mainContract?.group?.id;

  const isLegalProvider = isBuyContract
    ? mainContract?.ext_seller_cnpj.length >= 14
    : mainContract?.ext_buyer_cnpj?.length >= 14;

  const providerDocument = isBuyContract
    ? isLegalProvider
      ? mainContract?.ext_seller_cnpj
      : mainContract?.ext_seller_cpf
    : isLegalProvider
      ? mainContract?.ext_buyer_cnpj
      : mainContract?.ext_buyer_cpf;

  const selectedContractHasFixation = !!selectedContract?.selected_fixation;

  const shouldShowSyncSubscribersButton =
    selectedContract?.status?.order === CONTRACT_STATUS_WAITING_ORDER &&
    selectedContract?.is_digital &&
    mainContract.subscribers.filter((subscriber) => !subscriber.sync).length !==
      0;

  const shouldShowResendSubscribersButton =
    (isSelectedContractInStatus("partiallySigned") ||
      isSelectedContractInStatus("sentSignature")) &&
    selectedContract?.is_digital;

  const isIssued =
    mainContract?.status &&
    mainContract?.status.order > 1 &&
    mainContract?.status.id !== CONTRACT_STATUS_REISSUE_DRAFT &&
    mainContract?.status.id !== CONTRACT_STATUS_STORED;

  const isFullySigned =
    mainContract?.status?.id === CONTRACT_STATUS_FULLY_SIGNED;

  const hasSelectedContractPastStatus = (status: ContractStatuses) => {
    if (!selectedContract?.status) return false;
    switch (status) {
      case "completedDraft":
        return (
          selectedContract.status.order > CONTRACT_STATUS_COMPLETED_DRAFT_ORDER
        );
      case "inSignature":
        return (
          selectedContract.status.order > CONTRACT_STATUS_SENT_SIGNATURE_ORDER
        );
      case "stored":
        return selectedContract.status.order > CONTRACT_STATUS_STORED_ORDER;
      case "fullySigned":
        return (
          selectedContract.status.order > CONTRACT_STATUS_FULLY_SIGNED_ORDER
        );
      case "partiallySigned":
        return (
          selectedContract.status.order > CONTRACT_STATUS_PARTIALLY_SIGNED_ORDER
        );
      case "approvedSignature":
        return (
          selectedContract.status.order >
          CONTRACT_STATUS_APPROVED_SIGNATURE_ORDER
        );
      case "waiting":
        return selectedContract.status.order > CONTRACT_STATUS_WAITING_ORDER;
      case "created":
        return selectedContract.status.order > CONTRACT_STATUS_CREATED_ORDER;
      case "approvedEmission":
        return (
          selectedContract.status.order >
          CONTRACT_STATUS_APPROVED_EMISSION_ORDER
        );
      case "validateEmission":
        return (
          selectedContract.status.order >
          CONTRACT_STATUS_VALIDATE_EMISSION_ORDER
        );
      case "validateSignature":
        return (
          selectedContract.status.order >
          CONTRACT_STATUS_VALIDATE_SIGNATURE_ORDER
        );
      case "sentSignature":
        return (
          selectedContract.status.order > CONTRACT_STATUS_SENT_SIGNATURE_ORDER
        );
      case "reissueDraft":
        return (
          selectedContract.status.order > CONTRACT_STATUS_REISSUE_DRAFT_ORDER
        );
      case "requestNewSignatures":
        return (
          selectedContract.status.order >
          CONTRACT_STATUS_REQUEST_NEW_SIGNATURES_ORDER
        );

      default:
        return false;
    }
  };

  const canAddRelatedDocuments =
    (childrenTypes.length > 0 &&
      ((isIssued && mainContract.type.allow_not_signed_dependency) ||
        mainContract.type.allow_not_issued_dependency)) ||
    hasSelectedContractPastStatus("partiallySigned");

  const updateContractOnState = (updatedContract: ContractData) => {
    setContracts((oldContracts) =>
      oldContracts.map((c) =>
        c.number === updatedContract.number ? updatedContract : c,
      ),
    );
    if (!updatedContract.parent_id) setMainContract(updatedContract);
  };

  const showAttachmentCompare = () => setIsShowingAttachmentCompare(true);

  const hideAttachmentCompare = () => setIsShowingAttachmentCompare(false);

  const canUploadAttachmentsToSignature = () =>
    !selectedContract?.timeline?.find(
      (status) => status.uuid === CONTRACT_STATUS_PARTIALLY_SIGNED,
    )?.date;

  const changeSelectedRelatedDocument = async () => {
    setSelectedRelatedDocument(document);
  };

  const changeSelectedDocument = async (documentId: string) => {
    const document = await get(documentId);
    setSelectedContract(document);
    setContractId(document.id);
  };

  const updateRelatedDocument = (changedRelatedDocument: ContractData) => {
    const oldRelatedDocuments = [...relatedDocuments];
    const changedRelatedDocumentIndex = oldRelatedDocuments.findIndex(
      (doc) => doc.id === changedRelatedDocument.id,
    );
    oldRelatedDocuments[changedRelatedDocumentIndex] = changedRelatedDocument;
    setRelatedDocuments(oldRelatedDocuments);
  };

  const updateContractAttachments = async (
    files: any[],
    sendToSignature: boolean,
  ) => {
    const formData = new FormData();

    formData.append("attachments[0][document]", files[0].originFileObj);
    formData.append("attachments[0][send]", sendToSignature ? "1" : "0");

    const response = await addAttachments(contractId, formData);
    if (response) {
      updateContract(response, isSelectedDocumentMain);
      return true;
    }

    return false;
  };

  const destroyAttachment = async (attachmentId: string) => {
    const response = await destroyFile(attachmentId);
    if (response)
      updateContract(
        undefined,
        isSelectedDocumentMain,
        !isSelectedDocumentMain ? selectedContract.id : undefined,
      );
  };

  const changeSelectedContractStatus = (status: boolean) => {
    const updatedContract = { ...selectedContract, active: status };
    toggleActive(selectedContract.id);
    updateContractOnState(updatedContract);
    setSelectedContract(updatedContract);
  };

  const addNewRelatedDocument = async (values: any) => {
    const response = await storeRelatedDocument(id, values);
    if (response) {
      updateContract();
      return true;
    }
    return false;
  };

  const deleteRelatedDocument = async (relatedDocumentData: {
    id: string;
    reason: string;
  }) => {
    const response = await destroyRelatedDocument(relatedDocumentData);
    return response;
  };

  const getIsValidatedOnEmission = (contractData: ContractData) =>
    !!contractData?.timeline?.filter(
      (timelinePoint) =>
        timelinePoint.uuid === CONTRACT_STATUS_APPROVED_EMISSION &&
        !!timelinePoint.date,
    ).length;

  const getIsValidatedOnSignature = (contractData: ContractData) =>
    !!contractData?.timeline?.filter(
      (timelinePoint) =>
        timelinePoint.uuid === CONTRACT_STATUS_APPROVED_SIGNATURE &&
        !!timelinePoint.date,
    ).length;

  const getIsUploadedSignedContract = (contractData: ContractData) =>
    !!contractData?.signed_draft;

  const getIsValidatingSignatures = (contractData: ContractData) =>
    !!contractData?.timeline?.filter(
      (timelinePoint) =>
        timelinePoint.uuid === CONTRACT_STATUS_VALIDATE_SIGNATURE &&
        !!timelinePoint.date,
    ).length;

  const getIsFinished = (contractData: ContractData) =>
    !!contractData?.timeline?.filter(
      (timelinePoint) =>
        timelinePoint.uuid === CONTRACT_STATUS_FINISHED && !!timelinePoint.date,
    ).length;

  const getShouldValidateOnEmission = (contractData: ContractData) => {
    if (contractData.type) return !!contractData.type.verify_before_sign;
    return false;
  };

  const getShouldValidateOnSignature = (contractData: ContractData) => {
    if (contractData.type) return !!contractData.type.verify_after_sign;
    return false;
  };

  // TODO: verifica se o usuário logado tem permissão para validar o contrato
  const canCurrentUserValidateOnEmission = selectedContract?.canVerifyBefore;

  // TODO: verifica se o usuário logado tem permissão para validar o contrato
  const canCurrentUserValidateOnSignature = selectedContract?.canVerifyAfter;

  const updateContract = async (
    newContractData?: ContractData,
    isMainContract = true,
    relatedId?: string,
  ) => {
    const contractData: ContractData = newContractData ?? (await get(id));
    if (contractData) {
      if (isMainContract) {
        setContract(contractData);
        setMainContract(contractData);
      }
      setHasUploadedSignedContract(getIsUploadedSignedContract(contractData));
      setSelectedContract(contractData);
      setNeedEmissionValidation(getShouldValidateOnEmission(contractData));
      setNeedSignatureValidation(getShouldValidateOnSignature(contractData));
      setIsValidatingSignatures(getIsValidatingSignatures(contractData));
      setIsValidatedOnEmission(getIsValidatedOnEmission(contractData));
      setIsValidatedOnSignature(getIsValidatedOnSignature(contractData));
      setIsFinished(getIsFinished(contractData));
      setAlreadyHasDraft(!!contractData.draft);

      if (relatedId) {
        const related = contractData?.dependencies?.find(
          (d) => d.id === relatedId,
        );
        if (related) changeSelectedDocument(related.id);
      }
    }
  };

  const getPreviewUrlFromContract = async (
    previewContractId: string,
    payload?: any,
  ) => preview(previewContractId, payload);

  const updateFileToPreview = async (
    previewContractId: string,
    payload?: any,
  ) => {
    const response = await getPreviewUrlFromContract(
      previewContractId,
      payload,
    );
    if (!response) {
      setFileToPreview({ url: "", type: "" });
      return false;
    }
    setFileToPreview({
      type: response.extension,
      url: response.url,
    });
    return true;
  };

  const findChildrenTypes = async () => {
    if (contract?.contract_type_id) {
      const response =
        await getRelatedDocumentsAvaliableTypesForContractOptions(
          contract.contract_type_id,
        );
      if (response) setChildrenTypes(response);
    }
  };

  const updateDocumentProperties = async (values: {
    [name: string]: unknown;
  }) => updateProperties(selectedContract.id, values);

  const handleGetContractTypes = async (
    value: string,
    page: number,
    perPage: number,
  ) => {
    return getContractTypesOptions(
      {
        value,
        page,
        per_page: perPage || 10,
        product_id: mainContract.product.id,
        doc_type: isLegalProvider ? "CNPJ" : "CPF",
        purchase_type: mainContract?.purchase_type?.id,
        is_export: mainContract?.ext_export_purpose,
        group_type_id: mainContract?.group?.id,
      },
      "parent",
    );
  };

  const handleChangeContractType = async (
    value: SelectOptionType[] | string,
  ) => {
    setIsChangingContractType(true);
    const response = await updateDocumentProperties({
      contract_type_id: value,
    });
    if (response) {
      updateContract(response);
    }
    setIsChangingContractType(false);
  };

  const forceContractUpdate = async () => {
    const hide = message.loading(t("updating"));
    const response = await datasulApi.contractV2.forceContractById(
      mainContract.id,
    );
    await updateContract();
    if (response.data) handleSuccess(t("pages.edocuments.refreshSuccess"));
    setTimeout(hide, 0);
  };

  const updateMainContractFixations = async (search = "", page: number) => {
    if (
      !contract?.id ||
      !ability.can("contract.dependency.fixations", "") ||
      !ability.can("contract.dependency.dbfixations", "")
    )
      return;
    const response = await getContractFixations(
      contract.id,
      { page, search },
      true,
    );
    if (response) {
      const options = response.data.map((fixation: ContractFixationData) => {
        return {
          key: fixation.id,
          value: fixation.id,
          label: `${fixation.tradeslip} | ${fixation.currency === "R$" ? "BRL" : fixation.currency} ${
            fixation.price
          } | ${Formatter.integer(fixation.quantity, 3)}`,
          quantity: Number(fixation.quantity),
          price: fixation.price,
          tradeslip: fixation.tradeslip,
          currency: fixation.currency === "R$" ? "BRL" : fixation.currency,
          id: fixation.id,
        } as ContractFixationOptionData;
      });
      return { data: options, lastPage: response.last_page };
    }

    return { data: [], lastPage: 1 };
  };

  const updatePurchaseTypes = async (page = 1, value = "") => {
    const response = await getPurchaseTypes({ page, search: value });
    return response;
  };

  const reissueDraft = async (justification = "") => {
    const hide = message.loading(
      t("pages.edocuments.emission.draftEmission.reissuingDraft"),
      0,
    );
    const response = await requestReissueDraft(
      selectedContract.id,
      justification,
    );
    setTimeout(hide, 2500);
    updateContract(response, isSelectedDocumentMain);
    setSelectedStage(1);
  };

  useEffect(() => {
    if (id) {
      setContractId(id);
      updatePurchaseTypes();
      const mainContractResponse = getMainContract();
      setMainContract(mainContractResponse);
      setSelectedContract(mainContractResponse);
    }
  }, []);

  useEffect(() => {
    setRelatedDocuments(getRelatedDocuments());
    updateMainContractFixations("", 1);
  }, [contract]);

  useEffect(() => {
    if (id) updateContract(null, true, relatedDocument);
  }, [id]);

  useEffect(() => {
    if (contract?.contract_type_id) findChildrenTypes();
  }, [contract?.contract_type_id]);

  useEffect(() => {
    if (selectedContract) {
      updateFileToPreview(selectedContract.id);
      setContractAttachments(selectedContract?.attachments ?? []);
      const isMain = selectedContract.id === id;
      updateContract(
        selectedContract,
        isMain,
        isMain ? undefined : selectedContract.id,
      );
      if (lastSelectedContractIdRef.current !== selectedContract.id) {
        setSelectedStage(1);
        lastSelectedContractIdRef.current = selectedContract.id;
      }
    }
  }, [selectedContract]);

  const value = {
    contracts,
    setContracts,
    getMainContract,
    getRelatedDocuments,
    mainContract,
    selectedContract,
    setSelectedContract,
    relatedDocuments,
    changeSelectedContractStatus,
    setContractId,
    contractId,
    contract: selectedContract,
    isFetching,
    setFileToPreview,
    fileToPreview,
    setSelectedStage,
    isStageSelected,
    id,
    selectedStage,
    addNewRelatedDocument,
    updateContract,
    contractAttachments,
    updateContractAttachments,
    isFetchingPreview,
    isShowingAttachmentCompare,
    hideAttachmentCompare,
    showAttachmentCompare,
    needEmissionValidation,
    hasUploadedSignedContract,
    isValidatedOnEmission,
    isValidatedOnSignature,
    isValidatingSignatures,
    needSignatureValidation,
    isFinished,
    isAddingAttachments,
    destroyAttachment,
    alreadyHasDraft,
    childrenTypes,
    providerDocument,
    findChildrenTypes,
    changeSelectedRelatedDocument,
    selectedRelatedDocument,
    isDocumentSelected,
    canCurrentUserValidateOnEmission,
    canCurrentUserValidateOnSignature,
    isStored,
    changeSelectedDocument,
    isSelectedDocumentMain,
    updateFileToPreview,
    canUploadAttachmentsToSignature,
    isDeletingRelatedDocument,
    deleteRelatedDocument,
    isDocumentCancelled,
    updateDocumentProperties,
    isUpdatingDocument: isUpdating,
    isFetchingContractTypes,
    handleGetContractTypes,
    hasContractType,
    handleChangeContractType,
    isIssued,
    isChangingContractType,
    canAddRelatedDocuments,
    forceContractUpdate,
    isFetchingFixations,
    isLegalProvider,
    selectedContractHasFixation,
    updateRelatedDocument,
    reissueDraft,
    isFullySigned,
    isSelectedContractInStatus,
    updateMainContractFixations,
    shouldShowSyncSubscribersButton,
    hasSelectedContractPastStatus,
    isStoringNewRelatedDocument,
    shouldShowResendSubscribersButton,
    isMainContractCancelled,
    isSelectedContractCancelled,
  };

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};
