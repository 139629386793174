import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillWarning } from "react-icons/ai";

import { Form, Modal, Input as AntInput, Alert, message } from "antd";
import { Button, FormItem, Input, ModalStatus, Select } from "components";
import { AttachmentInput } from "compositions/AttachmentInput";
import { SUSTAINABILITY_REGISTRATION_IN_ANALYSIS } from "configs/constants";
import { useAbility } from "hooks/ability";
import { useCache } from "hooks/cache";
import { useSustainabilityRegistrationStatus } from "hooks/fetch/useSustainbilityRegistrationStatus";
import { useDownload } from "hooks/helpers/useDownload";
import { OptionData } from "rc-select/lib/interface";
import exceptionHandler from "services/exceptions";
import { useTheme } from "styled-components";
import { AttachmentInputTranslation } from "types/DTOReports";

import documentApi from "../../../services/api/document";
import { CacheOptions } from "../../../types/Hooks/cache";
import { SustainabilityRegistrationData } from "../../../types/Sustainability/SustainabilityRegistrationData";
import { ButtonsWrapper } from "./styles";

interface Props {
  isOpen: boolean;
  close: () => void;
  addRegistration: (registration: SustainabilityRegistrationData) => void;
  editRegistration: (registration: SustainabilityRegistrationData) => void;
  registrationToEdit?: SustainabilityRegistrationData;
  handlePreviewFile: (file: any) => void;
  isReadOnly: boolean;
  isEditing?: boolean;
  registrations?: string[];
  canShowRegistrationWarning?: boolean;
  isClone?: boolean;
}

export const NewRegistrationModal = ({
  isOpen,
  close,
  addRegistration,
  registrationToEdit,
  editRegistration,
  handlePreviewFile,
  isReadOnly,
  isEditing = false,
  registrations = [],
  canShowRegistrationWarning: isBlock = false,
  isClone = false,
}: Props) => {
  const { t } = useTranslation();
  const cache = useCache();
  const [form] = Form.useForm();
  const { getOptions, changeStatus } = useSustainabilityRegistrationStatus();
  const registrationNumberRef = useRef<any>();
  const { downloadMultiple } = useDownload();
  const theme = useTheme();
  const ability = useAbility();

  const [cities, setCities] = useState<CacheOptions[]>([]);
  const [states, setStates] = useState<CacheOptions[]>([]);
  const [statuses, setStatuses] = useState([]);
  const [disabledCities, setDisabledCities] = useState(true);
  const [
    shouldShowRegistrationNumberNotFoundWarning,
    setShouldShowRegistrationNumberNotFoundWarning,
  ] = useState(false);
  const [selectedState, setSelectedState] = useState<
    { id: string; name: string } | undefined
  >();
  const [selectedCity, setSelectedCity] = useState<
    { id: string; name: string } | undefined
  >();

  const attachmentInputTranslation = {
    destroyIconText: t(
      "pages.monitoring.surveyrequest.form.attachments.destroy.icon",
    ),
    downloadIconText: t(
      "pages.monitoring.surveyrequest.form.attachments.download.icon",
    ),
    dragAndDropUploadText: t(
      "pages.monitoring.surveyrequest.form.attachments.drag",
    ),
    existingAttachmentsText: t(
      "pages.monitoring.surveyrequest.form.attachments.existing",
    ),
    onUploadError: t(
      "pages.monitoring.surveyrequest.form.attachments.upload.error",
    ),
    onUploadSuccess: t(
      "pages.monitoring.surveyrequest.form.attachments.upload.success",
    ),
    downloadAllButton: t("downloadAll"),
    downloadingAllMessage: t("messages.downloadingAllFiles"),
    alerts: {
      destroy: {
        success: t(
          "pages.monitoring.surveyrequest.form.attachments.destroy.success",
        ),
      },
    },
  } as AttachmentInputTranslation;

  const closeModal = () => {
    form.resetFields();
    setShouldShowRegistrationNumberNotFoundWarning(false);
    close();
  };

  const loadCityOptions = useCallback(
    async (stateId: string) => {
      setCities(cache.getCityByStateOptions(stateId));
      setDisabledCities(false);
    },
    [cache],
  );

  const handleSubmit = () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      const currentStatus = statuses.find(
        (status: any) =>
          status.key === values.sustainability_registration_status_id,
      ) as any;
      const registration = {
        ...values,
        state: selectedState,
        city: {
          ...selectedCity,
          state: selectedState,
          state_id: selectedState?.id,
        },
        sustainability_registration_status: {
          id: currentStatus?.key,
          name: currentStatus?.label,
        },
      };
      if (registrationToEdit) {
        editRegistration(registration);
        form.resetFields();
        setShouldShowRegistrationNumberNotFoundWarning(false);
        close();
      } else {
        addRegistration(registration);
        form.setFieldsValue({ registration_number: "", documents: [] });
        ModalStatus({
          type: "success",
          subTitle: t("pages.sustainability.success"),
          onOk: () => {
            if (registrationNumberRef?.current)
              registrationNumberRef?.current?.focus();
          },
        });
      }
    });
  };

  const handleChangeStatus = (value: any) => {
    ModalStatus({
      type: "confirm",
      title: t("pages.sustainability.actions.titleChangeStatus"),
      subTitle: t("pages.sustainability.actions.subtitleChangeStatus"),
      cancelText: t("pages.admin.phases.table.actions.cancel-delete"),
      okText: t("pages.admin.phases.table.actions.confirm-delete"),
      onOk: async () => {
        try {
          if (registrationToEdit?.id)
            await changeStatus(registrationToEdit?.id, value);
        } catch (err) {
          exceptionHandler(err);
        }
      },
    });
  };

  const handleRemoveAttachment = (file: any) => {
    if (!registrationToEdit) return;
    const newDocuments = registrationToEdit.documents.filter(
      (document) => document.uid !== file.uid && !document.id,
    );
    registrationToEdit.documents = newDocuments;
    form.setFieldsValue({ documents: newDocuments });
  };

  const validateRegistration = (evt: any) => {
    const { value } = evt.target;
    if (isBlock)
      setShouldShowRegistrationNumberNotFoundWarning(
        value ? !registrations.includes(value) : false,
      );
  };

  useEffect(() => {
    (async () => {
      const response = await getOptions({ per_page: 20, page: 1 });
      if (response) setStatuses(response.data);
    })();
  }, []);

  useEffect(() => {
    setCities(cache.getCreditOptions("city"));
    setStates(cache.getCreditOptions("state"));
  }, [cache]);

  useEffect(() => {
    if (registrationToEdit) {
      form.setFieldsValue({
        ...registrationToEdit,
        documents: registrationToEdit.documents.filter(
          (document) => !document.id || isClone,
        ),
        state_id: registrationToEdit.city?.state_id,
      });
      setSelectedCity(registrationToEdit.city);
      setSelectedState(registrationToEdit.state);
    }
  }, [registrationToEdit]);

  return (
    <Modal
      visible={isOpen}
      width="80%"
      footer={null}
      onCancel={closeModal}
      title={t("pages.sustainability.form.registrationModal.title")}
      destroyOnClose
    >
      {shouldShowRegistrationNumberNotFoundWarning && (
        <>
          <Alert
            message={
              <span style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <AiFillWarning color={theme.colors.danger} size={20} />
                {t(
                  "pages.sustainability.form.registrationModal.registrationNotFound",
                )}
              </span>
            }
            type="error"
          />
          <br />
        </>
      )}
      <Form form={form}>
        <FormItem hidden name="id">
          <Input />
        </FormItem>
        <FormItem
          name="registration_number"
          rules={[{ required: true }]}
          label={t(
            "pages.sustainability.form.registrationModal.registrationNumber",
          )}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <Input
            maxLength={255}
            ref={registrationNumberRef}
            disabled={isReadOnly}
            onBlur={validateRegistration}
          />
        </FormItem>

        <FormItem
          name="farm"
          rules={[{ required: true }]}
          label={t("pages.sustainability.form.registrationModal.farmName")}
        >
          <Input maxLength={255} disabled={isReadOnly} />
        </FormItem>

        <FormItem
          name="owner"
          rules={[{ required: true }]}
          label={t("pages.sustainability.form.registrationModal.owners")}
        >
          <Input maxLength={255} disabled={isReadOnly} />
        </FormItem>

        <FormItem
          name="state_id"
          rules={[{ required: true }]}
          label={t("pages.sustainability.form.registrationModal.uf")}
        >
          <Select
            allowClear
            options={states}
            onChange={(value, option) => {
              if (value) loadCityOptions(value.toString());
              const { key, label } = option as OptionData;
              setSelectedState({
                id: (key ?? "")?.toString(),
                name: (label ?? "")?.toString(),
              });
            }}
            onClear={() => {
              setDisabledCities(true);
              form.setFieldsValue({ county: "" });
            }}
            disabled={isReadOnly}
          />
        </FormItem>

        <FormItem
          name="city_id"
          rules={[{ required: true }]}
          label={t("pages.sustainability.form.registrationModal.counties")}
        >
          <Select
            allowClear
            options={cities}
            disabled={disabledCities || isReadOnly}
            onChange={(value, option) => {
              const { key, label } = option as OptionData;
              setSelectedCity({
                id: (key ?? "").toString(),
                name: (label ?? "").toString(),
              });
            }}
          />
        </FormItem>

        <div style={{ marginBottom: "20px" }}>
          <FormItem
            label={t("pages.sustainability.form.registrationModal.attachments")}
            name="documents"
          >
            <AttachmentInput
              multiple
              translation={attachmentInputTranslation}
              onRemove={handleRemoveAttachment}
              getFiles={async () => ({
                data: {
                  data: isClone
                    ? []
                    : (registrationToEdit?.documents?.filter(
                        (document) => !!document.id,
                      ) ?? []),
                  total: isClone
                    ? 0
                    : (registrationToEdit?.documents?.filter(
                        (document) => !!document.id,
                      ).length ?? 0),
                  page: 1,
                  per_page: 10,
                },
              })}
              handleDestroy={(() => {
                if (ability.can("document.destroy", ""))
                  return documentApi.delete;
                return (_: string, _2: any) =>
                  new Promise((resolve) =>
                    resolve(message.error(t("noPermission"))),
                  );
              })()}
              disabled={isReadOnly}
              downloadMultiple={downloadMultiple}
              handleDownload={documentApi.download}
              handlePreview={async (file) => {
                handlePreviewFile(file);
                return true;
              }}
            />
          </FormItem>
        </div>

        <FormItem
          name="sustainability_registration_status_id"
          rules={[{ required: true }]}
          label={t("pages.sustainability.form.registrationModal.status")}
          initialValue={SUSTAINABILITY_REGISTRATION_IN_ANALYSIS}
        >
          <Select
            options={statuses}
            disabled={!registrationToEdit || isReadOnly || !isEditing}
            onChange={handleChangeStatus}
          />
        </FormItem>

        <FormItem
          name="comments"
          label={t("pages.sustainability.form.registrationModal.observations")}
        >
          <AntInput.TextArea maxLength={255} rows={4} disabled={isReadOnly} />
        </FormItem>
      </Form>

      <ButtonsWrapper>
        <Button status="secondary" onClick={close}>
          {t("cancel")}
        </Button>
        <Button status="primary" onClick={handleSubmit} disabled={isReadOnly}>
          {registrationToEdit ? t("edit") : t("register")}
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};
