import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import moment from 'moment';

import DTOAuth from '../@types/dtos/DTOAuth';
import DTOErrorReponse from '../@types/dtos/DTOErrorReponse';
import DTOAuthUser from '../@types/dtos/user/DTOAuthUser';
import AuthContextData, { AuthState } from '../@types/hooks/auth';
import { ModalStatus } from '../components';
import {
  AUTH_2FA_ENABLED,
  AUTH_PASSWORD_EXPIRES_ENABLED,
  EXPIRED_PAGE_URL,
  HOME_PAGE_URL,
  LOGIN_PAGE_URL,
  TFA_PAGE_URL,
  Teams,
} from '../configs/constants';
import {
  SESSION_USER_PERMISSIONS,
  SESSION_USER_REGIONS,
  SESSION_USER_ROLES,
  SESSION_USER_SUBSIDIARIES,
  SESSION_USER_TOKEN,
  SESSION_USER_TOKEN_EXPIRATION,
  SESSION_USER,
  SESSION_USER_TEAMS,
  SYSTEM_VERSION,
  SESSION_USER_TOKEN_TYPE,
  SESSION_USER_LAST_PASS_CHANGE,
  SESSION_USER_IS_EXTERNAL,
} from '../configs/storage';
import adminApi from '../services/api/admin';
import authApi from '../services/api/auth';
import { UserConfig, userConfigDefault } from '../types/User/UserData';
import { useCache } from './cache';
import { useUserConfig } from './fetch/useUserConfig';
import { usePage } from './page';
import useCurrentLocation from './useCurrentLocation';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { i18n, t } = useTranslation();
  const { update: updateConfig } = useUserConfig();
  const { instance } = useMsal();

  const [, clearPath] = useCurrentLocation();

  const { updateCache } = useCache();
  const { alertStatus } = usePage();
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [changelogVersion, setChangelogVersion] = useState<string>(() => {
    const version = localStorage.getItem(SYSTEM_VERSION);
    if (version) {
      return version;
    }
    return '';
  });

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(SESSION_USER_TOKEN);
    const permissions = JSON.parse(localStorage.getItem(SESSION_USER_PERMISSIONS));
    const regions = JSON.parse(localStorage.getItem(SESSION_USER_REGIONS));
    const roles = JSON.parse(localStorage.getItem(SESSION_USER_ROLES));
    const subsidiaries = JSON.parse(localStorage.getItem(SESSION_USER_SUBSIDIARIES));
    const teams = JSON.parse(localStorage.getItem(SESSION_USER_TEAMS));
    const user = JSON.parse(localStorage.getItem(SESSION_USER));

    if (token && user && permissions && regions && roles && subsidiaries && teams) {
      return { token, user, permissions, regions, roles, subsidiaries, teams };
    }

    return {} as AuthState;
  });

  const userIsLinked: boolean = useMemo(() => {
    if (data && data.regions && data.subsidiaries) {
      return data.regions.length > 0 && data.subsidiaries.length > 0;
    }

    return false;
  }, [data]);

  const clearData = useCallback(async () => {
    clearPath();
    localStorage.clear();

    setData({} as AuthState);
  }, []);

  const microsoftLogout = async () => {
    await instance.logoutPopup();
  };

  const getToken = useCallback(() => localStorage.getItem(SESSION_USER_TOKEN), []);
  const getTokenExpiration = useCallback(() => Number(localStorage.getItem(SESSION_USER_TOKEN_EXPIRATION)), []);
  const getTokenType = useCallback(() => localStorage.getItem(SESSION_USER_TOKEN_TYPE), []);
  const isExpiredToken = useCallback(() => getTokenExpiration() > 0 && getTokenExpiration() < moment().unix(), []);
  const isExpiredPassword = () => {
    const passLastChange = localStorage.getItem(SESSION_USER_LAST_PASS_CHANGE);
    return (
      typeof localStorage[SESSION_USER_LAST_PASS_CHANGE] !== 'undefined' &&
      ([null, 'null', ''].includes(passLastChange) || moment(passLastChange).add(30, 'days').isSameOrBefore(moment()))
    );
  };
  const isExternalUser = () => {
    return (
      localStorage.getItem(SESSION_USER_IS_EXTERNAL) === 'true' &&
      localStorage.getItem(SESSION_USER_IS_EXTERNAL) !== null
    );
  };

  const refreshPasswordLastChanged = useCallback(async (timestamp = null) => {
    localStorage.setItem(
      SESSION_USER_LAST_PASS_CHANGE,
      timestamp === null ? (await authApi.passwordLastChange()).data : timestamp,
    );
  }, []);

  const updateUserConfig = useCallback(
    async (configuration: UserConfig, onLogin?: boolean) => {
      if (onLogin) {
        setLoadingData(true);
      }
      const updatedConfig = await updateConfig(data.user.id, configuration);
      if (updatedConfig) {
        localStorage.setItem(SESSION_USER, JSON.stringify({ ...data.user, config: updatedConfig }));
        setData((dataState: any) => ({ ...dataState, user: { ...data.user, config: updatedConfig } }));
      }

      if (onLogin) {
        setLoadingData(false);
      }
    },
    [data.user],
  );

  const isUserOnTeam = useCallback(
    (teamId: Teams) => {
      const userTeams = data.teams;
      if (userTeams) {
        return userTeams.some(team => team.id === teamId);
      }
      return false;
    },
    [data.teams],
  );

  const updateUserData = async (onLogin?: boolean, cacheOption?: string | null) => {
    if (onLogin) {
      setLoadingData(true);
    }

    await authApi
      .getUserData()
      .then(async (response: { data: DTOAuthUser }) => {
        const { user, permissions, regions, roles, subsidiaries, teams } = response.data;

        if (!user.config) {
          user.config = userConfigDefault;
        }
        i18n.changeLanguage(user.locale);

        const notNullRegions = regions?.filter(x => x !== null);
        const notNullSubsidiaries = subsidiaries?.filter(x => x !== null);

        localStorage.setItem(
          SESSION_USER,
          JSON.stringify({ ...user, config: { ...user.config, app: { ...user.config.app, locale: user.locale } } }),
        );
        localStorage.setItem(SESSION_USER_PERMISSIONS, JSON.stringify(permissions));
        localStorage.setItem(SESSION_USER_REGIONS, JSON.stringify(notNullRegions));
        localStorage.setItem(SESSION_USER_ROLES, JSON.stringify(roles));
        localStorage.setItem(SESSION_USER_TEAMS, JSON.stringify(teams));
        localStorage.setItem(SESSION_USER_SUBSIDIARIES, JSON.stringify(notNullSubsidiaries));
        localStorage.setItem(SESSION_USER_LAST_PASS_CHANGE, user.password_changed_at);

        setData((dataState: any) => ({
          ...dataState,
          permissions,
          regions: notNullRegions,
          roles,
          subsidiaries: notNullSubsidiaries,
          teams,
          user,
        }));

        if (!roles.length) {
          const userName = user.name;
          const message = (
            <div>
              <p>
                {t('pages.admin.user.notRoles.p1', { userName })} <strong>{t('pages.admin.user.notRoles.p11')}</strong>{' '}
                {t('pages.admin.user.notRoles.p12')} <strong>{t('pages.admin.user.notRoles.p13')}</strong>{' '}
                {t('pages.admin.user.notRoles.p14')}
              </p>
              <p>
                {t('pages.admin.user.notRoles.p2')}{' '}
                <a href="https://servicos.cofcointernational.com" target="_blank" rel="noreferrer">
                  {t('pages.admin.user.notRoles.link')}
                </a>{' '}
                {t('pages.admin.user.notRoles.p3')}
              </p>
            </div>
          );

          ModalStatus({
            type: 'info',
            title: t('pages.admin.user.notRoles.title'),
            subTitle: {
              message,
            },
          });
        }
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        clearData();
        navigate('/login');
      });

    await updateCache(cacheOption).finally(() => {
      if (onLogin) {
        setLoadingData(false);
      }
    });
  };

  const storeToken = useCallback(async (accessToken: string, expiresIn: number, type = 'bearer') => {
    localStorage.setItem(SESSION_USER_TOKEN_EXPIRATION, expiresIn.toString());
    localStorage.setItem(SESSION_USER_TOKEN_TYPE, type);
    localStorage.setItem(SESSION_USER_TOKEN, accessToken);
    setData(dataState => ({ ...dataState, accessToken }));
  }, []);

  const tokenResponse = useCallback(
    async (response: { data: DTOAuth }, redirectTo = HOME_PAGE_URL, isExternal = false) => {
      storeToken(response.data.token, response.data.expires_in, response.data.token_type);
      localStorage.setItem(SESSION_USER_IS_EXTERNAL, isExternal);

      adminApi.changelog.latest().then(changelogResponse => {
        setChangelogVersion(changelogResponse.data.data?.version);
        localStorage.setItem(SYSTEM_VERSION, changelogResponse.data.data?.version);
      });

      /**
       * se for acesso externo (ex.: acesso via microsoft/AD) não verifica expiração de senha, pois,
       * não é responsabilidade do agrodata
       */
      if (!isExternal && AUTH_PASSWORD_EXPIRES_ENABLED) {
        await refreshPasswordLastChanged();
        if (isExpiredPassword()) {
          navigate(EXPIRED_PAGE_URL);
          return;
        }
      }

      await updateUserData(true).finally(() => {
        navigate(redirectTo);
      });
    },
    // eslint-disable-next-line
    [history, updateUserData],
  );

  const logIn = useCallback(
    async ({ email, password, setLoading }) => {
      setLoading(true);

      await authApi
        .login({
          email,
          password,
        })
        .then(async response => {
          if (AUTH_2FA_ENABLED && response.data.token_type === 'authorization') {
            await storeToken(response.data.token, response.data.expires_in, response.data.token_type);
            navigate(TFA_PAGE_URL);
          } else {
            tokenResponse(response);
          }
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'errorLogin');
          clearData();
        })
        .finally(() => setLoading(false));
    },
    [i18n.language, clearData],
  );

  const microsoftLogIn = useCallback(
    async ({ accessToken, setLoading }) => {
      setLoading(true);

      await authApi
        .microsoftLogin({
          accessToken,
        })
        .then(response => tokenResponse(response, HOME_PAGE_URL, true))
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          clearData();
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language, clearData, tokenResponse],
  );

  const logOut = useCallback(async () => {
    const isExternal = isExternalUser();

    await authApi
      .logout({
        params: {},
      })
      .catch((err: DTOErrorReponse) => {
        if (err && err.response) {
          const {
            response: { status },
          } = err;

          if (status !== 401) {
            alertStatus(err, 'error', 'warning', () => navigate(LOGIN_PAGE_URL));
          }
        }
      })
      .finally(async () => {
        if (isExternal) await microsoftLogout();

        clearData();
        if (!isExternal) navigate(LOGIN_PAGE_URL);
      });
    // eslint-disable-next-line
  }, [history, clearData]);

  const TwoFaQrCode = useCallback(async () => {
    return authApi.twoFactorAuthentication
      .getQrCode()
      .then(response => {
        return { svg: `data:image/svg+xml;utf8,${encodeURIComponent(response.data.svg)}`, code: response.data.code };
      })
      .catch((err: any) => {
        alertStatus(err, 'error');
      });
  }, []);

  const TwoFaChallengeCode = useCallback(
    async (numbers: string, setLoading) => {
      setLoading(true);
      authApi.twoFactorAuthentication
        .sendChallengeCode({
          code: numbers,
        })
        .then(tokenResponse)
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          if (err.response?.status === 403) {
            navigate('login');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [tokenResponse],
  );

  /**
   * verifica se o 2fa está ativado para o usuário
   */
  const TwoFactorIsEnabled = useCallback(async () => {
    return authApi.twoFactorAuthentication.checkEnabled();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loadingData,
        changelogVersion,
        user: data.user,
        userIsLinked,
        permissions: data.permissions,
        roles: data.roles,
        regions: data.regions,
        subsidiaries: data.subsidiaries,
        teams: data.teams,
        clearData,
        logIn,
        logOut,
        microsoftLogIn,
        updateUserConfig,
        updateUserData,
        getToken,
        getTokenExpiration,
        isExpiredToken,
        getTokenType,
        tokenResponse,
        storeToken,
        isExpiredPassword,
        isExternalUser,
        refreshPasswordLastChanged,
        TwoFaChallengeCode,
        TwoFaQrCode,
        TwoFactorIsEnabled,
        isUserOnTeam,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
