import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import appConfigs from 'configs/app';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const PaymentDateInput = ({ required = true }: { required?: boolean }) => {
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const { t } = useTranslation();

  return (
    <FormItem label={t('payment-date')} name="payment_date" rules={[{ required }]}>
      <DatePicker
        locale={ptBR}
        mode="date"
        disabledDate={date => {
          const currentDate = moment().set('hour', 0).set('minute', 0).set('second', 0);
          const sunday = 0;
          const saturday = 6;
          let daysRemaining = 4;

          const newDate = currentDate.clone();

          while (daysRemaining > 0) {
            newDate.add(1, 'days');
            if (newDate.day() !== sunday && newDate.day() !== saturday) {
              daysRemaining -= 1;
            }
          }

          if (date.isBefore(newDate, 'date')) return true;
          return false;
        }}
        format={appConfigs.formatDate}
        disabled={pageStatus.viewing}
      />
    </FormItem>
  );
};
