import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const HarvestIdSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();
  const { current_harvest } = useCache();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const isCottonPrepay = useCreditRequestStore(state => state.isCottonPrepay);

  useEffect(() => {
    if (isCottonPrepay) creditForm.setFieldsValue({ harvest_id: current_harvest });
    else creditForm.setFieldsValue({ harvest_id: undefined });
  }, [isCottonPrepay]);

  return (
    <FormItem label={t('pages.credit-request.form.harvest_id')} name="harvest_id" rules={[{ required: true }]}>
      <Select
        options={cache.getCreditOptions('harvest')}
        disabled={pageStatus.viewing || (contractFieldsValue && contractFieldsValue.harvest_id !== undefined)}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
