import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID } from 'configs/constants';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

export const JsonDataNetAmountInput = () => {
  const { t } = useTranslation();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);

  const label =
    selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
      ? t('pages.credit-request.form.json_data_net_amount_advance')
      : t('pages.credit-request.form.json_data_net_amount');

  return (
    <FormItem
      label={label}
      name="json_data.net_amount"
      rules={[
        {
          required: true,
        },
        {
          validator(_, value) {
            if (!value || value <= 0) {
              return Promise.reject(t('pages.credit-request.form.json_data_net_amount_empty'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value, 2)}
        disabled
      />
    </FormItem>
  );
};
