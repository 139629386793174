import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearchDollar } from 'react-icons/fa';

import { Button, InputNumber, Tooltip, message } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { usePtax } from 'hooks/fetch/usePtax';
import { useCalculateFields } from 'pages/CreditRequest/hooks/useCalculateFields';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

interface Props {
  isVisible: boolean;
  label?: string;
  tooltip?: React.ReactNode;
}

export const FethabQuotationInput = ({ isVisible, label, tooltip }: Props) => {
  const { t } = useTranslation();
  const { isFetchingPtax, creditForm } = useCreditRequestContext();
  const { getLastPtax } = usePtax();
  const { onChangeFieldsRecalculate } = useCalculateFields();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  async function handleGetPtax() {
    message.loading({ content: t('pages.credit-request.form.getting_fethab_quotation'), key: 'ptax' });
    const lastPtax = await getLastPtax();
    creditForm.setFieldsValue({ 'json_data.fethab_quotation': lastPtax });
    onChangeFieldsRecalculate();
  }

  return (
    <FormItem
      label={label ?? t('pages.credit-request.form.json_data_fethab_quotation')}
      name="json_data.fethab_quotation"
      hidden={!isVisible}
    >
      <InputNumber<string>
        min="0.00001"
        maxLength={8}
        formatter={(value: string | number) => Formatter.money(value, 5, null)}
        parser={(value: string) => Formatter.moneyParser(value, 5)}
        disabled={pageStatus.viewing || isFetchingPtax}
        style={{ width: '100%' }}
        addonAfter={
          <Tooltip title={tooltip ?? <span>{t('pages.credit-request.form.get_fethab_quotation')}</span>}>
            <Button type="default" size="small" onClick={handleGetPtax} disabled={isFetchingPtax}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FaSearchDollar style={{ margin: 'none' }} />
              </span>
            </Button>
          </Tooltip>
        }
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
