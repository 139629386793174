import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DTOErrorResponse } from "types/DTOReports";
import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import { RcFile } from "antd/lib/upload";
import { Button, FormItem, Input } from "components";
import { FilePreviewer } from "components/FilePreviewer";
import { useContract } from "hooks/fetch/useContract";
import { usePage } from "hooks/page";
import { useContractContext } from "pages/Contracts/context/ContractContext";

import DTOErrorReponse from "../../../../../../../@types/dtos/DTOErrorReponse";
import { SDragger } from "components/UploadAttachment/styles";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const ALLOWED_FILE_TYPES = [".pdf", ".doc", ".docx"];

export const ForceManualEmissionModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { alertStatus } = usePage();

  const { selectedContract, updateContract, isSelectedDocumentMain } =
    useContractContext();
  const { isSendingManualDraftEmissionSigned, forceManualDraftEmissionSigned } =
    useContract();
  const [uploadedFile, setUploadedFile] = useState<RcFile>();

  const [fileToPreview, setFileToPreview] = useState<any>(null);

  const closeModal = () => {
    form.resetFields();
    close();
  };

  const handleFileUpload = (file: RcFile) => {
    if (!uploadedFile) {
      const extension = ALLOWED_FILE_TYPES.filter((ext) => {
        return file.name.includes(ext.trim());
      });
      if (!extension.length) {
        alertStatus(
          {
            message: `${file.name} ${t(
              "pages.integrations.fileupload.invalidFileType",
              {
                ext: ALLOWED_FILE_TYPES.join(", "),
              },
            )}`,
          } as DTOErrorReponse,
          "error",
        );
        return false;
      }
      setUploadedFile(file);
    } else {
      alertStatus(
        {
          message: t("pages.edocuments.contracttypes.uploadOneFile"),
        } as DTOErrorResponse,
        "error",
      );
    }

    return true;
  };

  const handleSubmit = async () => {
    form.validateFields().then(async () => {
      const values = form.getFieldsValue();
      if (values.documents?.file?.originFileObj) {
        const formData = new FormData();
        formData.append("minuta", values.documents.file.originFileObj);
        formData.append("type", `signed`);
        const response = await forceManualDraftEmissionSigned(
          selectedContract.id,
          formData,
        );
        if (response) {
          updateContract(
            undefined,
            isSelectedDocumentMain,
            !isSelectedDocumentMain ? selectedContract.id : undefined,
          );
          closeModal();
        }
      }
    });
  };

  const handleRemoveAttachment = () => {
    form.setFieldsValue({ documents: [] });
    setUploadedFile(undefined);
  };

  return (
    <Modal
      visible={isOpen}
      width="80%"
      footer={null}
      onCancel={() => {
        if (!isSendingManualDraftEmissionSigned) closeModal();
      }}
      title={t("pages.edocuments.form.signedDraft.title")}
      destroyOnClose
    >
      <Form form={form}>
        <FormItem hidden name="id">
          <Input />
        </FormItem>

        <div style={{ marginBottom: "20px" }}>
          <FormItem
            label={t("pages.sustainability.form.registrationModal.attachments")}
            name="documents"
            rules={[{ required: true }]}
          >
            <SDragger
              accept={ALLOWED_FILE_TYPES.join(",")}
              beforeUpload={handleFileUpload}
              onRemove={handleRemoveAttachment}
              maxCount={1}
              fileList={uploadedFile ? [uploadedFile] : []}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t(
                  "pages.integrations.exposurefileupload.form.fields.uploadText",
                  {
                    extensions: ALLOWED_FILE_TYPES.join(", "),
                  },
                )}
              </p>
              <p className="ant-upload-hint">
                {t("pages.edocuments.templates.form.uploadHint")}
              </p>
            </SDragger>
          </FormItem>
        </div>
      </Form>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          status="secondary"
          onClick={close}
          loading={isSendingManualDraftEmissionSigned}
        >
          {t("cancel")}
        </Button>
        <Button
          status="primary"
          onClick={handleSubmit}
          loading={isSendingManualDraftEmissionSigned}
          permission="contract.uploadminuta"
        >
          {t("send")}
        </Button>
      </div>

      {fileToPreview && (
        <FilePreviewer
          document={fileToPreview}
          onClose={() => setFileToPreview(null)}
        />
      )}
    </Modal>
  );
};
