import React from 'react';

interface TriStateCheckboxProps {
  checked: boolean;
  indeterminate: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const MultiCheckbox = React.forwardRef<HTMLInputElement, TriStateCheckboxProps>(
  ({ checked, indeterminate, onChange, className }, ref) => {
    const internalRef = React.useRef<HTMLInputElement | null>(null);

    React.useEffect(() => {
      if (internalRef.current) {
        internalRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    return (
      <input
        type="checkbox"
        checked={checked}
        ref={(el) => {
          internalRef.current = el;
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }
        }}
        onChange={onChange}
        className={className}
      />
    );
  }
);

MultiCheckbox.displayName = 'MultiCheckbox';
export default MultiCheckbox;
