import React from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { Can } from 'hooks/ability';
import { ContractCreditRequestsTable } from 'pages/CreditRequest/components/molecules/ContractCreditRequestsTable';
import { DocumentsList } from 'pages/CreditRequest/components/molecules/DocumentsList';
import { AdvanceData } from 'pages/CreditRequest/components/organisms/AdvanceData';
import { AdvancePayment } from 'pages/CreditRequest/components/organisms/AdvancePayment';
import { DepositAgreement } from 'pages/CreditRequest/components/organisms/DepositAgreement';
import { StatusHistory } from 'pages/CreditRequest/components/organisms/StatusHistory';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { Amortization } from '../organisms/Amortization';
import { Depositary } from '../organisms/Depositary';
import Justification from '../organisms/Justification';

export const PrepaymentForm = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const justificationVisible = useCreditRequestStore(state => state.justificationVisible);

  return (
    <>
      <AdvancePayment />

      <ContractCreditRequestsTable />

      <AdvanceData />

      <DepositAgreement />
      {justificationVisible && <Justification />}

      <Depositary />

      <Amortization />

      <Can I="credit.request.attachment.index">
        <DocumentsList />
      </Can>

      {!pageStatus.new ? (
        <PermissionedComponent
          permission="credit.request.attachment.index"
          description={t('permission.message.credit.request.history.index')}
        >
          <StatusHistory />
        </PermissionedComponent>
      ) : null}
    </>
  );
};
