import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlatButton } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { Plus } from 'lucide-react';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { AssigneesModal } from './assignees.modal';
import { AssigneesTable } from './assignees.table';

export const Assignees = () => {
  const { t } = useTranslation();
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <GroupForm
      title={
        <div className="w-full relative flex items-center justify-end pr-10">
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
            {t('registered-assignees')}
          </span>
          <AssigneesModal>
            {!pageStatus.viewing ? (
              <FlatButton roundness="rounded" size="icon">
                <Plus />
              </FlatButton>
            ) : null}
          </AssigneesModal>
        </div>
      }
    >
      <AssigneesTable />
    </GroupForm>
  );
};
