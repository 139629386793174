import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';

import { useGetUserQuery } from './api/use-get-user.query';
import { AuthTab } from './user-auth.form';
import { NotificationConfigTab } from './user-notification-config.form';
import { UserPermissionsTab } from './user-permissions/user-permissions';
import { UserDetailsTab } from './user.form';

export type PageParams = {
  id: string;
};

export const User = () => {
  const { t } = useTranslation();

  const params = useParams<PageParams>();
  const { id: idParam } = params;

  const getUserQuery = useGetUserQuery(idParam !== 'new' ? idParam : '');

  return (
    <Tabs defaultValue="details" className="bg-white h-full overflow-hidden  flex flex-col">
      <TabsList className="w-full pt-2">
        <TabsTrigger value="details" title={t('pages.credit-request.tabs.general-data')}>
          {t('pages.credit-request.tabs.general-data')}
        </TabsTrigger>
        {idParam !== 'new' && (
          <>
            <TabsTrigger value="auth" title={t('auth')}>
              {t('auth')}
            </TabsTrigger>
            <TabsTrigger value="permissions" title={t('permission-groups')}>
              {t('permission-groups')}
            </TabsTrigger>
            <TabsTrigger value="notification" title={t('notifications')}>
              {t('notifications')}
            </TabsTrigger>
          </>
        )}
      </TabsList>
      <TabsContent value="details" className="w-full max-h-full overflow-y-hidden flex flex-col">
        <UserDetailsTab />
      </TabsContent>
      <TabsContent value="auth" className="w-full max-h-full overflow-y-hidden flex flex-col">
        <AuthTab handleHideForm={() => console.log('')} user={getUserQuery.data ?? null} />
      </TabsContent>
      <TabsContent value="permissions" className="w-full max-h-full overflow-y-hidden flex flex-col">
        <UserPermissionsTab user={getUserQuery.data ?? null} />
      </TabsContent>
      <TabsContent value="notification" className="w-full max-h-full overflow-y-hidden flex flex-col">
        <NotificationConfigTab userId={idParam} />
      </TabsContent>
    </Tabs>
  );
};
