import React from "react";
import { useTranslation } from "react-i18next";

import { useTable } from "compositions/TableDataAsync/hooks/useTable";

import { Breadcrumb } from "../../../components";
// import { Can } from '../../../hooks/ability';
import { useFilesController } from "./controllers/useFilesController";
import { Form } from "./Form";
import { SContainer, SHeader, STitlePage } from "./styles";

export const FileUpload = () => {
  const { t } = useTranslation();
  const tableController = useTable();

  const { closeForm, templateBeingEditedId } = useFilesController();

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.integration") },
          { title: t("breadcrumb.exposurefileupload") },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>
            {t("pages.edocuments.exposurefileupload.title")}
          </STitlePage>

          {/* <Can I="contract.template.store" a="">
            <Button status="primary" icon={isFormOpen ? <FaMinus /> : <FaPlus />} onClick={toggleForm}>
              {t('pages.edocuments.exposurefileupload.buttonAdd')}
            </Button>
          </Can> */}
        </SHeader>

        <div>
          {/* {isFormOpen && ( */}
          <Form
            closeForm={closeForm}
            templateId={templateBeingEditedId}
            refresh={tableController.refresh}
          />
          {/* )} */}

          {/* <Table
            handleChange={handleTableChange}
            isLoading={isFetchingFileTypes}
            controller={tableController}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
          /> */}
        </div>
      </SContainer>
    </>
  );
};

export default FileUpload;
