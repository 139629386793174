import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { ModalStatus } from "components";
import ApiSelect from "components/ApiSelect";
import { useContract } from "hooks/fetch/useContract";
import { useContractContext } from "pages/Contracts/context/ContractContext";
import { useTheme } from "styled-components";
import type { ContractSubscriberData } from "types/Contract/ContractSubscriberData";

import { SignaturesList } from "./SignaturesList";
import { ContractSelect } from "./styles";

export interface Props {
  isOpen: boolean;
  close: () => void;
  onOk: (selectedSignatures: ContractSubscriberData[]) => void;
  isFromAnotherContract: boolean;
}

export const CloneSignaturesModal = ({
  isOpen,
  close,
  onOk,
  isFromAnotherContract = false,
}: Props) => {
  const { t } = useTranslation();
  const { getAllOptions: getContracts } = useContract();
  const { mainContract } = useContractContext();
  const theme = useTheme();

  const [signatures, setSignatures] = React.useState<ContractSubscriberData[]>(
    [],
  );
  const [selectedSignaturesIds, setSelectedSignaturesIds] = React.useState<
    string[]
  >([]);

  const handleClose = () => {
    setSelectedSignaturesIds([]);
    setSignatures([]);
    close();
  };

  const handleGetContracts = async (search: string, page: number) => {
    const { data, last_page } = await getContracts({
      page,
      perPage: 10,
      search,
    });
    return { data, lastPage: last_page };
  };

  const handleSubmit = () => {
    if (isFromAnotherContract && !selectedSignaturesIds.length) {
      return ModalStatus({
        type: "error",
        title: t("modal.error"),
        subTitle: t(
          "pages.edocuments.emission.signatures.cloneSignatures.noContractSelected",
        ),
      });
    }
    onOk(
      (isFromAnotherContract ? signatures : mainContract.subscribers).filter(
        (signature) => selectedSignaturesIds.includes(signature.id),
      ),
    );
    handleClose();
  };

  useEffect(() => {
    if (!isFromAnotherContract)
      setSelectedSignaturesIds(
        (mainContract?.subscribers || [])?.map((signature) => signature.id),
      );
  }, [isFromAnotherContract, signatures, mainContract.subscribers]);

  return (
    <Modal
      visible={isOpen}
      destroyOnClose
      onCancel={handleClose}
      onOk={handleSubmit}
      title={
        isFromAnotherContract
          ? t("pages.edocuments.emission.signatures.cloneSignatures.title")
          : t("pages.edocuments.emission.signatures.cloneSignatures.fromParent")
      }
      width="80%"
    >
      {isFromAnotherContract ? (
        <ContractSelect style={{ width: "100%" }}>
          <p color={theme.colors.primary}>
            {t(
              "pages.edocuments.emission.signatures.cloneSignatures.chooseContract",
            )}
          </p>
          <ApiSelect
            showSearch
            style={{ width: "50%" }}
            fetchOptions={handleGetContracts}
            onChange={(_, option) => {
              setSignatures((option as any).subscribers);
              setSelectedSignaturesIds(
                (option as any).subscribers.map(
                  (signature: ContractSubscriberData) => signature.id,
                ),
              );
            }}
          />
        </ContractSelect>
      ) : null}

      <SignaturesList
        signatures={(isFromAnotherContract
          ? signatures
          : mainContract?.subscribers || []
        ).sort((a, b) => a.routing_order - b.routing_order)}
        setSelectedSignaturesIds={setSelectedSignaturesIds}
        selectedSignaturesIds={selectedSignaturesIds}
      />
    </Modal>
  );
};
