import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

interface Props {
  rows?: number;
}

export const CommentsInput = ({ rows = 2 }: Props) => {
  const { t } = useTranslation();

  const formIsBlocked = useCreditRequestStore(state => state.formIsBlocked);
  const setFormIsBlocked = useCreditRequestStore(state => state.setFormIsBlocked);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem label={t('pages.credit-request.form.prepayment_comments')} name="comments" rules={[{ required: true }]}>
      <Input.TextArea
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setFormIsBlocked(!formIsBlocked);
          }
        }}
        rows={rows}
      />
    </FormItem>
  );
};
