import { useState } from "react";

import { DTOErrorResponse } from "types/DTOReports";
import { useAbility } from "hooks/ability";

import monitoringApi from "../../services/api/monitoring";
import { usePage } from "../page";

export const useRegistration = () => {
  const { alertStatus } = usePage();
  const ability = useAbility();

  const [isFetching, setIsFetching] = useState(false);

  const get = async (id: string, params = {}) => {
    try {
      setIsFetching(true);
      const response = ability.can("monitoring.registration.show", "")
        ? await monitoringApi.registration.getById(id, params)
        : null;
      return response?.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getAll = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = ability.can("monitoring.registration.all", "")
        ? await monitoringApi.registration.all(params)
        : null;
      return response?.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getOptions = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await monitoringApi.registration.all(params);
      return {
        data: response.data.map((registration) => ({
          key: registration.id,
          value: registration.id,
          label: registration.number,
          ...registration,
        })),
        lastPage: 1,
      };
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  return { isFetching, getAll, getOptions, get };
};
