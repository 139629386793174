import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from 'components/ui/page-title';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import UserData from 'types/User/UserData';

import { UserPermissionsDivisionsTable } from './user-permissions-table/user-permissions-divisions.table';
import { UserPermissionsOperationsTable } from './user-permissions-table/user-permissions-operations.table';
import { UserPermissionsPhasesTable } from './user-permissions-table/user-permissions-phases.table';
import { UserPermissionsStatusTable } from './user-permissions-table/user-permissions-status.table';
import { UserPermissionsSubdivisionsTable } from './user-permissions-table/user-permissions-subdivisions.table';
import { UserPermissionsTeamsTable } from './user-permissions-table/user-permissions-teams.table';
import { UserPermissionsForm } from './user-permissions.form';

type Props = {
  user: UserData;
};

export const UserPermissionsTab = ({ user }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      <UserPermissionsForm user={user} />

      <div className="px-4 h-full w-full flex flex-col overflow-hidden pb-10">
        <PageTitle>{t('groups-permissions')}</PageTitle>
        <Tabs defaultValue="phases" className="bg-white h-full w-full flex flex-col overflow-hidden">
          <TabsList className="w-full pt-2 bg-transparent">
            <TabsTrigger value="phases" title={t('phases')}>
              {t('phases')}
            </TabsTrigger>
            <TabsTrigger value="teams" title={t('teams')}>
              {t('teams')}
            </TabsTrigger>
            <TabsTrigger value="status" title={t('status')}>
              {t('status')}
            </TabsTrigger>
            <TabsTrigger value="divisions" title={t('divisions')}>
              {t('divisions')}
            </TabsTrigger>
            <TabsTrigger value="sub-divisions" title={t('sub-divisions')}>
              {t('sub-divisions')}
            </TabsTrigger>
            <TabsTrigger value="operations" title={t('operations')}>
              {t('operations')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="phases" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsPhasesTable user={user} />
          </TabsContent>
          <TabsContent value="teams" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsTeamsTable user={user} />
          </TabsContent>
          <TabsContent value="status" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsStatusTable user={user} />
          </TabsContent>
          <TabsContent value="divisions" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsDivisionsTable user={user} />
          </TabsContent>
          <TabsContent value="sub-divisions" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsSubdivisionsTable user={user} />
          </TabsContent>
          <TabsContent value="operations" className="w-full max-h-full overflow-y-hidden flex flex-col">
            <UserPermissionsOperationsTable user={user} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
