import React, { useState } from "react";

import { Modal, Spin } from "antd";
import { ModalStatus } from "components";

import { UploadAttachment } from "../../../../components/UploadAttachment";
import { Container } from "./styles";

interface Props {
  close: () => void;
  isOpen: boolean;
  isUploading: boolean;
  onOk: (contract: any) => void;
}

export const UploadSignedContractModal = ({
  close,
  isOpen,
  onOk,
  isUploading,
}: Props) => {
  const [file, setFile] = useState();

  const handleOk = () => {
    if (file) onOk(file);
    else
      ModalStatus({
        description: "Ao menos um arquivo deve ser selecionado",
        type: "error",
        title: "Erro",
      });
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={close}
      onOk={handleOk}
      title="Upload Contrato Assinado"
      destroyOnClose
    >
      <Spin spinning={isUploading}>
        <Container>
          <UploadAttachment maxFiles={1} onChange={setFile} />
        </Container>
      </Spin>
    </Modal>
  );
};
