import { useState } from "react";

import { DTOErrorResponse } from "types/DTOReports";
import { handleRequest } from "services/api/api";

import documentApi from "../../services/api/document";
import { downloadHandler } from "../../services/download";
import { usePage } from "../page";

export const useDownload = () => {
  const { alertStatus } = usePage();
  const [isDownloading, setIsDownloading] = useState(false);

  const download = async (id: string) => {
    try {
      setIsDownloading(true);
      return documentApi.download(id);
    } catch (err) {
      alertStatus(err as DTOErrorResponse, "error");
      return null;
    } finally {
      setIsDownloading(false);
    }
  };

  const downloadMultiple = async (ids: string[]) => {
    const response = await handleRequest(
      () =>
        documentApi.downloadmultiplefiles({ params: { attachment_id: ids } }),
      setIsDownloading,
    );
    downloadHandler(response);
    return response;
  };

  const downloadAll = async (attachments: any) => {
    const response = await handleRequest(
      () =>
        documentApi.downloadmultiplefiles({
          params: attachments,
        }),
      setIsDownloading,
    );
    downloadHandler(response);
  };

  return { downloadAll, isDownloading, download, downloadMultiple };
};
