import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Form } from 'components/ui/form';
import { CONTRACT_SIGNATURE_DSELETRONIC, FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN } from 'configs/constants';
import { usePrepareDraftMutation } from 'pages/CreditRequest/api/use-prepare-draft.mutation';
import { useStoreDraftMutation } from 'pages/CreditRequest/api/use-store-draft.mutation';
import { useUpdateDraftMutation } from 'pages/CreditRequest/api/use-update-draft.mutation';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/use-formalization-guarantees-store';
import { DocumentData } from 'types/Api/DocumentData';
import DraftData from 'types/CreditRequest/DraftData';
import { CommentFormData } from 'types/CreditRequest/forms/CommentFormData';
import { DraftFormData } from 'types/CreditRequest/forms/DraftFormData';
import { FormRef } from 'types/FormRef';

import { DraftCommentSection } from './draft-comment.section';
import { DraftEmailDataSection } from './draft-email-data.section';
import { DraftGeneralDataSection } from './draft-general-data.section';
import { DraftSubscribersSection } from './draft-subscribers.section';
import { DraftFormConverter } from './draft.form.converter';

export const EMPTY_SUBSCRIBER = {
  id: '',
  value: '',
  name: '',
  email: '',
  recipient_type_id: FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN,
  dt_sign: '',
  signature_type_id: '',
  is_resend: false,
  sms: '',
  routing_order: 0,
};

export const draftFormInitialValues = {
  id: '',
  is_digital: '',
  order: 0,
  status_id: '',
  external_envelope_id: '',
  people: '',
  subscribers: [] as any[],
  comments: [] as CommentFormData[],
  files_to_sign: [] as File[],
  files_to_sign_documents: [] as DocumentData[],
  files_signed: [] as File[],
  files_signed_documents: [] as DocumentData[],
  email_subject: '',
  email_message: '',
};

export type DraftFormProps = {
  onSuccess?: () => void;
  isReadonly?: boolean;
};

export type DraftFormRef = {
  submit: (isPrepare?: boolean) => void;
  isDigital: boolean;
  externalEnvelopeId?: string;
} & FormRef;

export const DraftForm = forwardRef(({ onSuccess, isReadonly }: DraftFormProps, ref) => {
  const form = useForm({
    defaultValues: draftFormInitialValues,
  });
  const { t } = useTranslation();
  const { alert } = useAlertDialog();
  const queryClient = useQueryClient();

  const isDigital = Boolean(Number(form.watch('is_digital')));
  const externalEnvelopeId = form.watch('external_envelope_id');

  const creditRequestId = useFormalizationGuaranteesStore(state => state.creditRequestId);
  const selectedDraft = useFormalizationGuaranteesStore(state => state.selectedDraft);
  const setSelectedDraft = useFormalizationGuaranteesStore(state => state.setSelectedDraft);

  const storeDraftMutation = useStoreDraftMutation();
  const updateDraftMutation = useUpdateDraftMutation();
  const prepareDraftMutation = usePrepareDraftMutation();

  const initializeForm = (draftData: DraftData) => {
    form.setValue('id', draftData.id);
    form.setValue('status_id', draftData.status?.id);
    form.setValue('is_digital', draftData.is_digital ? '1' : '0');
    form.setValue('order', draftData?.status?.order || 0);
    form.setValue(
      'comments',
      draftData.comments.map(comment => ({
        ...comment,
        dt_comment: comment.created_at,
        registered_by: comment.user?.name,
      })),
    );
    form.setValue(
      'subscribers',
      draftData.recipients.map(subscriber => ({
        ...EMPTY_SUBSCRIBER,
        ...subscriber,
      })),
    );
    form.setValue('files_signed_documents', draftData?.documents_certified || []);
    form.setValue('files_to_sign_documents', draftData?.documents || []);
    form.setValue('external_envelope_id', draftData.external_envelope_id);
    form.setValue('email_message', draftData.email_message);
    form.setValue('email_subject', draftData.email_subject);
  };

  const checkIsFormValid = (values: DraftFormData) => {
    if (values.files_to_sign.length + values.files_to_sign_documents.length > 0) {
      if (values.subscribers.length > 0) {
        const hasDuplicates = values.subscribers.some(subscriber => {
          return (
            subscriber.signature_type_id === CONTRACT_SIGNATURE_DSELETRONIC &&
            values.subscribers.filter(s => s.email === subscriber.email || s.sms === subscriber.sms).length - 1 > 0
          );
        });

        if (!hasDuplicates) return true;

        alert({
          title: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.sameEmailSms'),
          type: 'error',
        });
      } else {
        alert({
          type: 'error',
          title: t('modal.error'),
          subTitle: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.error_addressee'),
        });
      }
    } else {
      alert({
        type: 'error',
        title: t('modal.error'),
        subTitle: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.error_minuta'),
      });
    }
    return false;
  };

  const submit = (isPrepare?: boolean) => {
    form.handleSubmit(async values => {
      if (!checkIsFormValid(values)) return;
      const formData = await DraftFormConverter.toApiFormData(values, creditRequestId, isPrepare);
      if (selectedDraft && isPrepare)
        await prepareDraftMutation.mutateAsync(formData, {
          onSuccess: response => {
            if (response.data[1].is_digital) window.open(response.data[0].envelope_url, '_blank');
            alert({ title: response.message, type: 'success', onOk: () => onSuccess?.() });
          },
        });
      else if (selectedDraft)
        await updateDraftMutation.mutateAsync(formData, {
          onSuccess: response => {
            alert({ title: response.data.message, type: 'success', onOk: () => onSuccess?.() });
          },
        });
      else
        await storeDraftMutation.mutateAsync(formData, {
          onSuccess: response => {
            alert({ title: response.data.message, type: 'success', onOk: () => onSuccess?.() });
          },
        });
      await queryClient.invalidateQueries({
        queryKey: ['envelope'],
      });
    })();
  };

  useImperativeHandle(ref, () => ({
    form,
    submit,
    isDigital,
    externalEnvelopeId,
  }));

  useEffect(() => {
    if (!selectedDraft) return form.reset(draftFormInitialValues);
    initializeForm(selectedDraft);

    return () => {
      form.reset(draftFormInitialValues);
      setSelectedDraft(null);
    };
  }, [selectedDraft]);

  return (
    <Form onSubmit={submit} form={form} disabled={isReadonly}>
      <DraftGeneralDataSection />

      <DraftSubscribersSection />

      <DraftEmailDataSection />
      <DraftCommentSection />
    </Form>
  );
});
DraftForm.displayName = 'DraftForm';
