import React from "react";
import { useTranslation } from "react-i18next";

import { InboxOutlined } from "@ant-design/icons";
import { Row, Spin, Form as AntForm, Col } from "antd";
import { useForm } from "antd/lib/form/Form";
import ApiSelect from "components/ApiSelect";
import { CustomFormContainer } from "components/CustomFormContainer";
import { useIntegrations } from "pages/Serasa/Integrations/hooks/useIntegrations";
import { v4 as uuid } from "uuid";

import { ExpositionFileTypeData } from "../../../../@types/data/ExpositionFileTypeData";
import { Button, FormItem } from "../../../../components";
import { useFormController } from "../controllers/useFormController";
import { SFormButtons } from "./styles";
import { FormProps } from "./types";
import { SDragger } from "components/UploadAttachment/styles";

export const Form = ({ closeForm, templateId, refresh }: FormProps) => {
  const { t } = useTranslation();

  const [form] = useForm();
  const { getFileTypes } = useIntegrations();
  const {
    isLoading,
    handleRegister,
    handleFileUpload,
    handleFileRemove,
    editedTemplate,
    uploadedFile,
    isUploading,
    setSelectedFileTypeRoute,
    setSelectedFileTypeExtension,
    selectedFileTypeExtension,
  } = useFormController({
    closeForm,
    refresh,
    form,
    templateId,
  });

  const handleGetFileTypes = async (value: string, page: number) => {
    const response = await getFileTypes({
      params: { search: value, page, page_size: 10 },
    });
    return {
      data: response.data.map((fileType: ExpositionFileTypeData) => ({
        ...fileType,
        key: fileType.id,
        value: fileType.id,
        label: fileType.name,
      })),
      lastPage: response.last_page,
    };
  };

  return (
    <CustomFormContainer>
      <Spin spinning={isLoading}>
        <h2>{t("pages.integrations.exposurefileupload.form.title")}</h2>

        <AntForm form={form}>
          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="type_id"
                label={t(
                  "pages.integrations.exposurefileupload.form.fields.type_id",
                )}
                rules={[{ required: true }]}
              >
                <ApiSelect
                  listHeight={221}
                  showSearch
                  fetchOptions={handleGetFileTypes}
                  allowClear={false}
                  onSelect={(_, option) => {
                    setSelectedFileTypeRoute(option.route);
                    setSelectedFileTypeExtension(option.extension_file);
                  }}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={26}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <FormItem
                name="document"
                label={t(
                  "pages.integrations.exposurefileupload.form.fields.file",
                )}
              >
                <SDragger
                  accept={`.${selectedFileTypeExtension}`}
                  beforeUpload={handleFileUpload}
                  onRemove={handleFileRemove}
                  maxCount={1}
                  fileList={
                    // eslint-disable-next-line no-nested-ternary
                    editedTemplate?.template
                      ? [{ ...(editedTemplate?.template as any), uid: uuid() }]
                      : uploadedFile
                        ? [uploadedFile]
                        : []
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t(
                      "pages.integrations.exposurefileupload.form.fields.uploadText",
                      {
                        extensions:
                          selectedFileTypeExtension ??
                          ".txt, .csv, .xls, .xlsx, .xlsm",
                      },
                    )}
                  </p>
                  <p className="ant-upload-hint">
                    {t("pages.edocuments.templates.form.uploadHint")}
                  </p>
                </SDragger>
                <FormItem />
              </FormItem>
            </Col>
          </Row>

          {/* {(fileProcessedData || editedTemplate) && (
            <>
              {(fileProcessedData?.url || editedTemplate?.url) && (
                <Row gutter={26} style={{ marginTop: '40px' }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div style={{ height: '500px' }}>
                      <DocxViewer
                        useExternalRenderApi
                        url={fileProcessedData?.url ?? editedTemplate?.url}
                        height="100%"
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )} */}

          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={closeForm}
              disabled={isLoading}
            >
              {t("pages.edocuments.contracttypes.buttonCancel")}
            </Button>
            <Button
              status="primary"
              htmlType="submit"
              onClick={() => handleRegister(form.getFieldsValue())}
              loading={isLoading || isUploading}
            >
              {t("pages.edocuments.contracttypes.buttonRegister")}
            </Button>
          </SFormButtons>
        </AntForm>
      </Spin>
    </CustomFormContainer>
  );
};
