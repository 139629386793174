import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { UserPermissionData } from 'types/User/UserPermissionData';

type Params = PaginatedQueryParams & {
  users: string[];
};

export const useGetUserPhasesQuery = ({ ...params }: Params) => {
  return usePaginatedQuery<UserPermissionData>({
    queryKey: ['credit-request/phase', params.users, params.current, params.pageSize, params.sort, params.search],
    url: `credit-request/phase`,
    params: {
      ...params,
      params: {
        users: params.users,
      },
    },
  });
};
