import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { IconType } from "react-icons/lib";

import { Select as AntSelect, Divider } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select";
import { OptionData } from "rc-select/lib/interface";

import { Latinise } from "../../utils/Latinise";
import Button from "../Button";

declare type RawValue = string | number;

export interface SelectOptionType {
  disabled?: boolean;
  selected?: boolean;
  key?: string;
  value: RawValue;
  label: React.ReactNode;
}

export interface SelectTranslation {
  all: string;
}

export interface SelectProps
  extends AntSelectProps<SelectOptionType[] | string> {
  translation: SelectTranslation;
  options?: OptionData[];
  getOptions?: () => Promise<SelectOptionType[]>;
  autocomplete?: boolean;
  autocompleteEmptyText?: string;
  allOption?: boolean;
  afterOnChange?: (value: any, option: any) => any;
  dropdownOptions?: {
    buttonText?: string;
    buttonIcon?: IconType;
    buttonOnClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  };
}

export type Ref = HTMLElement;

export const AllSelect = React.forwardRef<any, SelectProps>(
  (
    {
      options,
      dropdownOptions,
      loading,
      allOption,
      value: oldValue,
      afterOnChange = () => false,
      translation = { all: "All" },
      ...rest
    },
    ref,
  ) => {
    const { Option } = AntSelect;

    const [selectedValues, setSelectedValues] = useState<
      string | SelectOptionType[]
    >();

    let dropdownRender = (menu: React.ReactElement) => menu;
    if (dropdownOptions) {
      const {
        buttonText,
        buttonIcon,
        buttonOnClick = () => null,
      } = dropdownOptions;
      const CustomIcon = buttonIcon;
      dropdownRender = (menu: React.ReactElement) => (
        <>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <Button
            status="primary-outline"
            onClick={(e) => buttonOnClick(e)}
            block
          >
            {CustomIcon ? <CustomIcon /> : <FiPlus />}
            {buttonText}
          </Button>
        </>
      );
    }

    const handleChange = (value: any, oldOption: any) => {
      if (value[value.length - 1] === "all") {
        value.splice(-1);
        const newValues = [
          ...(options?.map((option: OptionData) => option?.key ?? "") ?? []),
        ] as never[];
        setSelectedValues(newValues);
        afterOnChange(
          options.map((option) => option.key),
          oldOption,
        );
      } else {
        setSelectedValues(value);
        afterOnChange(
          options
            .filter((option) => [...value].includes(option.value))
            .map((option) => option.key),
          oldOption,
        );
      }
    };

    useEffect(() => {
      if (oldValue?.length) setSelectedValues(oldValue);
    }, [oldValue]);

    return (
      <AntSelect
        allowClear
        optionFilterProp="label"
        optionLabelProp="label"
        dropdownRender={dropdownRender}
        loading={loading}
        value={selectedValues}
        showSearch
        onChange={handleChange}
        filterOption={(input: any, option: any) =>
          Latinise.normalize(option?.children).indexOf(
            Latinise.normalize(input),
          ) >= 0
        }
        ref={ref}
        {...rest}
      >
        {allOption && (
          <AntSelect.Option
            value="all"
            disabled={(selectedValues?.length ?? 0) === (options?.length ?? 0)}
            key="all"
          >
            {translation.all}
          </AntSelect.Option>
        )}
        {options &&
          options
            .filter(
              (itemValue, index, self) => self.indexOf(itemValue) === index,
            )
            .map((option: OptionData) => (
              <Option
                key={option.key}
                value={option.key}
                selected={option?.selected}
                disabled={option?.disabled}
                label={option.label}
              >
                {option.label}
              </Option>
            ))}
      </AntSelect>
    );
  },
);
