import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMutating } from '@tanstack/react-query';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooterForm, DialogHeader } from 'components/ui/dialog';
import { useAbility } from 'hooks/ability';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/use-formalization-guarantees-store';

import { DraftForm, draftFormInitialValues, DraftFormRef } from './draft.form';

export const DraftModal = () => {
  const { t } = useTranslation();
  const formRef = useRef<DraftFormRef>(null);
  const ability = useAbility();

  const isDraftModalOpen = useFormalizationGuaranteesStore(state => state.isDraftModalOpen);
  const setIsDraftModalOpen = useFormalizationGuaranteesStore(state => state.setIsDraftModalOpen);
  const setSelectedDraft = useFormalizationGuaranteesStore(state => state.setSelectedDraft);
  const selectedDraft = useFormalizationGuaranteesStore(state => state.selectedDraft);

  const isStoringDraft = useIsMutating({
    mutationKey: ['storeDraft'],
  });
  const isUpdatingDraft = useIsMutating({
    mutationKey: ['updateDraft'],
  });
  const isPreparingDraft = useIsMutating({
    mutationKey: ['prepareDraft'],
  });

  const isDigital = selectedDraft?.is_digital;

  const isReadOnly = isDigital && !ability.can('credit.request.envelope.digital_update', '') && !!selectedDraft;

  return (
    <Dialog open={isDraftModalOpen} onOpenChange={setIsDraftModalOpen}>
      <DialogContent className="w-[100%] h-[100%] max-h-[100vh]">
        <DialogHeader>
          {selectedDraft
            ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.titleEdit')
            : t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.title')}
        </DialogHeader>
        <DraftForm
          isReadonly={isReadOnly}
          ref={formRef}
          onSuccess={() => {
            setIsDraftModalOpen(false);
          }}
        />
        <DialogFooterForm
          isReadOnly={isReadOnly}
          isLoading={!!isStoringDraft || !!isUpdatingDraft || !!isPreparingDraft}
          onConfirm={() => {
            formRef.current?.submit();
          }}
          onCancel={() => {
            setIsDraftModalOpen(false);
            setSelectedDraft(null);
            formRef.current?.form.reset(draftFormInitialValues);
          }}
        >
          <ShouldRender
            condition={
              !!Number(selectedDraft?.is_digital) &&
              selectedDraft?.status?.order <= 1 &&
              !!selectedDraft.recipients.length
            }
          >
            <Button
              permissions={['credit.request.envelope.send']}
              type="button"
              onClick={() => {
                formRef.current?.submit(true);
              }}
              isLoading={!!isStoringDraft || !!isUpdatingDraft || !!isPreparingDraft}
            >
              {t('prepare')}
            </Button>
          </ShouldRender>
        </DialogFooterForm>
      </DialogContent>
    </Dialog>
  );
};
