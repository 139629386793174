import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import {
  CURRENCY_USD_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_MTM_ID,
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

interface Props {
  onChange?: (value: any, option: any) => void;
  disabled?: boolean;
  blacklist?: string[];
}

export const JsonDataCurrencyTypeIdSelect = ({ onChange, disabled, blacklist = [] }: Props) => {
  const { t } = useTranslation();
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_currency_type_id')}
      name="json_data.currency_type_id"
      rules={[{ required: true }]}
    >
      <Select
        options={cache
          .getCreditOptions('currency_type')
          .filter(currency => !blacklist.includes(currency.key))
          .filter(
            currency =>
              (selectedOperationId !== OPERATION_MTM_ID && selectedOperationId !== OPERATION_FERTILIZER_SUPPLIERS_ID) ||
              currency.value === CURRENCY_USD_ID,
          )}
        disabled={disabled}
        onChange={(value: any, option: any) => {
          if (value) {
            setIsBlocking(true);
          }

          if (selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
            creditForm.setFieldsValue({
              daily_price_value: null,
              base_port_id: null,
            });
          }

          if (onChange) onChange(value, option);
        }}
      />
    </FormItem>
  );
};
