import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';

import {
  advanceProductDeliveredCalculation,
  prepaymentCalculation,
} from '../components/templates/DetailView/prepayment';
import { useCreditRequestStore } from '../store/use-credit-request-store';

export const useCalculateFields = () => {
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();

  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const isDeliveredProductAdvance = useCreditRequestStore(state => state.isDeliveredProductAdvance);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);

  function onChangeFieldsRecalculate() {
    if (isPrepay)
      prepaymentCalculation(creditForm, cache, selectedDivisionId, selectedSubDivisionId, selectedOperationId);

    if (isDeliveredProductAdvance)
      // advanceProductDeliveredCalculation(creditForm, cache, selectedSubDivision);
      advanceProductDeliveredCalculation(creditForm, cache);

    // if (assigneeList.length > 0) {
    //   const sumOfRequestedValues = assigneeList.reduce((acc, assignee) => {
    //     if (assignee.id !== 'bp_user') {
    //       return acc + assignee.requested_amount || 0;
    //     }
    //     return acc;
    //   }, 0);
    //   const bpUser = assigneeList.find(assignee => assignee.id === 'bp_user');
    //   if (bpUser) {
    //     setAssigneeList(
    //       assigneeList.map(assignee => {
    //         if (assignee.id === 'bp_user') {
    //           return {
    //             ...assignee,
    //             requested_amount: parseFloat(creditForm.getFieldValue('json_data.net_amount')) - sumOfRequestedValues,
    //           };
    //         }
    //         return assignee;
    //       }),
    //     );
    //   }
    // }
  }

  return {
    onChangeFieldsRecalculate,
  };
};
