import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Space, Badge } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableData } from "compositions/TableDataAsync";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import { TableTranslation } from "compositions/TableDataAsync/types";
import moment from "moment";

import { Button, IconWithTooltip } from "../../../../components";
import { Formatter } from "../../../../utils/Formatter";
import { Report } from "../../components";
import { TableSearch } from "../../components/Table/TableSearch";
import { Title } from "../../styles";
// eslint-disable-next-line import-helpers/order-imports
import { useMonitoringPrefin } from "../hooks/useMonitoringPrefin";
import { columns } from "./columns";
import { PropsResume } from "./types";

export const TableResume = ({
  getReport,
  generateExport,
  searchFilters,
  setSearchFilters,
  searchForm,
  listReportPermissions = [],
  generateReportPermissions = [],
}: PropsResume) => {
  const tableController = useTable();
  const { handleChangeTableResume, isFetchingResume } = useMonitoringPrefin();
  const { t } = useTranslation();

  const [exportingGeneral, setExportingGeneral] = useState(false);

  function handleStatus(deadline: string, row: any, survey: string) {
    const value = row.survey.find(
      (element: any) => element.survey_stage === survey,
    );
    const date =
      value !== undefined ? value.date_survey : moment().format("YYYY-MM-DD");

    if (date < deadline) {
      return "light-green";
    }
    if (date === deadline) {
      return "light-yellow";
    }
    return "light-danger";
  }

  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Link
          to={`/monitoring/cpr/${record.cpr_id}/registration/${record.registration_id}/survey`}
          target="_blank"
        >
          <Badge
            count={record.survey.length ? record.survey.length : 0}
            style={{ backgroundColor: "#1c5a8d" }}
          >
            <IconWithTooltip
              action="registrationArea"
              title={t("pages.monitoring.registration.form.iconSurvey")}
            />
          </Badge>
        </Link>
      </>
    ),
  });

  const handleCondition = (survey: any) => {
    if (survey.length) {
      const surveySort = survey.sort((a: any, b: any) => {
        const value1 = new Date(b.date_survey);
        const value2 = new Date(a.date_survey);
        return value1.getTime() - value2.getTime();
      });
      return surveySort[0].color_condition;
    }

    return "";
  };

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };
    switch (column.key) {
      case "planting":
        column = {
          ...column,
          render: (record: any, data: any) => {
            return (
              <Space>
                <Button
                  key={record}
                  status={
                    data.cpr.planting_deadline
                      ? handleStatus(data.cpr.planting_deadline, data, "1")
                      : "grey"
                  }
                >
                  {data.cpr.planting_deadline
                    ? Formatter.date(data.cpr.planting_deadline)
                    : t("pages.monitoring.cpr.table.notNecessary")}
                </Button>
              </Space>
            );
          },
        };
        break;
      case "development":
        column = {
          ...column,
          render: (record: any, data: any) => {
            return (
              <Space>
                <Button
                  key={record}
                  status={
                    data.cpr.development_deadline
                      ? handleStatus(data.cpr.development_deadline, data, "2")
                      : "grey"
                  }
                >
                  {data.cpr.development_deadline
                    ? Formatter.date(data.cpr.development_deadline)
                    : t("pages.monitoring.cpr.table.notNecessary")}
                </Button>
              </Space>
            );
          },
        };
        break;
      case "harvest":
        column = {
          ...column,
          render: (record: any, data: any) => {
            return (
              <Space>
                <Button
                  key={record}
                  status={
                    data.cpr.harvest_deadline
                      ? handleStatus(data.cpr.harvest_deadline, data, "3")
                      : "grey"
                  }
                >
                  {data.cpr.harvest_deadline
                    ? Formatter.date(data.cpr.harvest_deadline)
                    : t("pages.monitoring.cpr.table.notNecessary")}
                </Button>
              </Space>
            );
          },
        };
        break;
      case "condition":
        column = {
          ...column,
          render: (record: any, data: any) => {
            const condition = handleCondition(data.survey);
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    background: condition,
                    borderRadius: "50%",
                  }}
                />
              </div>
            );
          },
        };
        break;
      default:
        break;
    }
    return column;
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>{t("pages.dashboard.resumeVisits.title")}</Title>
        <Button
          status="primary"
          onClick={() => {
            setExportingGeneral(true);
          }}
          permission={[...listReportPermissions, ...generateReportPermissions]}
        >
          {t("pages.sustainability.form.buttonExport")}
        </Button>
      </div>
      <TableSearch
        setSearchFilters={setSearchFilters}
        searchForm={searchForm}
        placeholder={t("pages.dashboard.search.placeholderMonitoring")}
        monitoring
      />
      <TableData
        searchRequest={handleChangeTableResume}
        searchFilters={searchFilters}
        customColumns={customColumns}
        columns={columns}
        width="100%"
        pagination={{ position: ["bottomCenter"] }}
        controller={tableController}
        translation={
          {
            pagination: { totalOfRegisters: t("pagination.totalOfRegisters") },
          } as TableTranslation
        }
        rowKey="id"
        loading={isFetchingResume}
        tableConfig={{
          getActions,
        }}
      />
      <Report
        searchFilters={searchFilters}
        exportingGeneral={exportingGeneral}
        setExportingGeneral={setExportingGeneral}
        getReport={getReport}
        generateExport={generateExport}
        withDateFilter={false}
      />
    </div>
  );
};
