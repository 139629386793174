import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TableData } from "compositions/TableDataAsync";
import { TableTranslation } from "compositions/TableDataAsync/types";
import { Button, Modal } from "antd";
import { ColumnType } from "antd/lib/table";
import {
  SERASA_STATUS_WITHOUT_RESTRICTION,
  SERASA_STATUS_WITH_RESTRICTION,
} from "configs/constants";
import i18n from "services/i18next";

// eslint-disable-next-line import-helpers/order-imports
import theme from "styles/theme";
import SustainabilityData from "../../../../types/Sustainability/SustainabilityData";
import { IconWithTooltip } from "../../../../components";
import { Can } from "../../../../hooks/ability";
import { Formatter } from "../../../../utils/Formatter";
import { LogsTable } from "../Edit/History/LogsTable";
import { Restrictions } from "../Edit/History/Restrictions";
import { useSerasaCounterparties } from "../hooks/useSerasaCounterparties";
import { columns } from "./columns";
import { Filter } from "./Filter";
import { RestrictionsModalTitle } from "./RestrictionsModalTitle";
import { TableProps } from "./types";

export const Table = ({
  handleChange,
  handleEdit,
  isLoading = false,
  controller,
  searchFilters,
  setSearchFilters,
  filterForm,
}: TableProps) => {
  const { t } = useTranslation();
  const { handleSwitchChange } = useSerasaCounterparties({
    refresh: controller.refresh,
  });

  const [previewingCounterparty, setPreviewingCounterparty] = useState<any>();

  const handleFastSearch = (values = {}) => {
    setSearchFilters(values);
  };

  const handlePreview = (counterparty: any) => {
    setPreviewingCounterparty(counterparty);
  };

  const clearPreviewingCounterparty = () => setPreviewingCounterparty(null);

  const getColorFromStatus = (statusId: string) => {
    switch (statusId) {
      case SERASA_STATUS_WITHOUT_RESTRICTION:
        return theme.colors.success;
      case SERASA_STATUS_WITH_RESTRICTION:
        return theme.colors.danger;
      default:
        return theme.colors.warning;
    }
  };

  const getActions = () => ({
    render: (_: unknown, record: SustainabilityData) => (
      <>
        <Can I="serasa.counterparty.restriction.index" a="">
          <IconWithTooltip
            action="edit"
            title={t("pages.edocuments.contracttypes.table.edit")}
            onClick={() => handleEdit(record)}
          />
          <IconWithTooltip
            action="detail"
            title={t("pages.serasacounterparty.table.preview")}
            onClick={() => {
              handlePreview(record);
            }}
          />
        </Can>
      </>
    ),
  });

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };

    switch (column.key) {
      case "status":
        column = {
          ...column,
          render: (status: any) => {
            return (
              <span style={{ color: getColorFromStatus(status.id) }}>
                {status?.translations[i18n.language].title.toUpperCase() ?? "-"}
              </span>
            );
          },
        };
        break;

      case "created_at":
        column = {
          ...column,
          render: (created_at) => {
            return (
              <span>
                {created_at != null ? `${Formatter.date(created_at)}` : ""}
              </span>
            );
          },
        };
        break;

      case "updated_at":
        column = {
          ...column,
          render: (updated_at) => {
            return (
              <span>
                {updated_at != null ? `${Formatter.date(updated_at)}` : ""}
              </span>
            );
          },
        };
        break;

      case "is_parent":
        column = {
          ...column,
          render: (isMain: boolean) => {
            return (
              <strong>
                {isMain
                  ? t("pages.edocuments.contracttypes.table.yes")
                  : t("pages.edocuments.contracttypes.table.no")}
              </strong>
            );
          },
        };
        break;

      default:
        break;
    }

    return column;
  };

  return (
    <div>
      <Filter handleFastSearch={handleFastSearch} form={filterForm} />

      <TableData
        columns={columns}
        customColumns={customColumns}
        searchRequest={handleChange}
        searchFilters={searchFilters}
        width="100%"
        tableConfig={{
          getActions,
          handleSwitchChange,
          searchConfig: {
            initialFilter: {
              active: ["1"],
            },
          },
        }}
        pagination={{ position: ["bottomCenter"] }}
        loading={isLoading}
        controller={controller}
        translation={
          {
            pagination: { totalOfRegisters: "Total de registros" },
            filterDropdown: {
              filterActive: "Ativo",
              filterInactive: "Inativo",
            },
            filterConfirm: "Buscar",
            filterReset: "Limpar",
          } as TableTranslation
        }
      />

      <Modal
        visible={previewingCounterparty}
        onCancel={clearPreviewingCounterparty}
        width="80%"
        destroyOnClose
        footer={
          <Button type="primary" onClick={clearPreviewingCounterparty}>
            Ok
          </Button>
        }
        title={
          <RestrictionsModalTitle
            counterparty={previewingCounterparty}
            statusColor={getColorFromStatus(previewingCounterparty?.id)}
          />
        }
      >
        <Restrictions
          showTotal
          counterparty={previewingCounterparty}
          totalRestrictions={previewingCounterparty?.restrictions_sum_value}
        />
        <h2>{t("pages.serasacounterparty.historic.logs")}</h2>
        <LogsTable counterpartyId={previewingCounterparty?.id} />
      </Modal>
    </div>
  );
};
