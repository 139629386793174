import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItemProps, InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
  disableOnChange?: boolean;
  required?: boolean;
  rules?: FormItemProps['rules'];
}

export const RequestedAmountInput = ({
  disabled = false,
  disableOnChange = false,
  required = false,
  rules = [],
}: Props) => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const isCottonPrepay = useCreditRequestStore(state => state.isCottonPrepay);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_requested_amount')}
      name="requested_amount"
      extra={t('cant-be-grater-than-90-percent-of-takeup')}
      rules={[
        { required },
        {
          validator(_, value) {
            if ((!value || value <= 0) && required)
              return Promise.reject(t('pages.credit-request.form.requested_amount_empty'));

            if (value > creditForm.getFieldValue('json_data.price') && isCottonPrepay)
              return Promise.reject(t('requested-amount-must-be-less-than-contract-value'));

            return Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={disabled}
        onChange={value => {
          if (value && !disableOnChange) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
