import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { TeamStatusSlaData } from 'types/Admin/TeamStatus/TeamStatusSlaData';

type Params = PaginatedQueryParams & { teamStatusId: string };

export const useGetTeamStatusSlasQuery = ({ dispatchPagination, teamStatusId, ...params }: Params) => {
  return usePaginatedQuery<TeamStatusSlaData>({
    queryKey: [
      'credit-request/team-status-sla',
      JSON.stringify(params.filter),
      params.current,
      params.pageSize,
      params.search,
      teamStatusId,
      JSON.stringify(params.sort),
    ],
    url: `credit-request/team-status-sla`,
    enabled: !!teamStatusId,
    permissions: ['credit.request.teamstatussla.index'],
    staleTime: 1000,
    params: {
      ...params,
      params: { team_credit_status: teamStatusId },
      dispatchPagination,
    },
  });
};
