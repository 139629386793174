import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { ContractNumberInput } from '../../atoms/ContractNumberInput';
import { DatasulContractInput } from '../../atoms/DatasulContractInput/Index';
import { ForExportationSelect } from '../../atoms/ForExportationSelect';
import { HasAssignesInput } from '../../atoms/HasAssignesInput';
import { JsonDataFreightInput } from '../../atoms/JsonDataFreightInput';
import { JsonDataRequesterPersonDocumentInput } from '../../atoms/JsonDataRequesterPersonDocumentInput';
import { JsonDataRequesterPersonNameInput } from '../../atoms/JsonDataRequesterPersonNameInput';
import { NumberInput } from '../../atoms/NumberInput';
import { PersonTypeSelectInput } from '../../atoms/PersonTypeSelectInput';
import { QuantityContractInput } from '../../atoms/QuantityContractInput';
import { QuantityDeliveryInput } from '../../atoms/QuantityDeliveryInput';
import { QuantityReceivedInput } from '../../atoms/QuantityReceivedInput';
import { RequesterPersonIdInput } from '../../atoms/RequesterPersonIdInput';
import { RequesterPersonJsonDataCodeErpInput } from '../../atoms/RequesterPersonJsonDataCodeErpInput';
import { RequesterPersonSapCodeInput } from '../../atoms/RequesterPersonSapCodeInput';
import { OperationalFields } from '../OperationalFields';

export const AdvancePayment = () => {
  const { t } = useTranslation();

  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const shouldShowSap = useCreditRequestStore(state => state.shouldShowSap);
  const shouldShowDatasul = useCreditRequestStore(state => state.shouldShowDatasul);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <div className="group-form">
      <div className="bg-gray-100 w-full text-center text-md uppercase p-2 font-bold group-focus:text-primary">
        {t('pages.credit-request.form.titles.payment')}
      </div>

      <DatasulContractInput />

      <Row gutter={[8, 8]}>
        {!pageStatus.new ? (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <NumberInput />
          </Col>
        ) : null}

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <ContractNumberInput />
        </Col>

        <PersonTypeSelectInput />

        <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
          <RequesterPersonIdInput />
          <JsonDataRequesterPersonNameInput />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          {/* <JsonDataRequesterPersonCpfInput />
          <JsonDataRequesterPersonCnpjInput /> */}
          <JsonDataRequesterPersonDocumentInput />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowDatasul ? '' : 'none' }}>
          <RequesterPersonJsonDataCodeErpInput
            isVisible={shouldShowDatasul}
            isRequired={isPrepay && shouldShowDatasul}
          />
        </Col>

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowSap ? '' : 'none' }}>
          <RequesterPersonSapCodeInput isVisible={shouldShowSap} isRequired={shouldShowSap} />
        </Col>

        <OperationalFields />

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <ForExportationSelect />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataFreightInput />
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <QuantityContractInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <QuantityReceivedInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
          <QuantityDeliveryInput
            disabled={
              pageStatus.viewing ||
              (contractFieldsValue && contractFieldsValue['json_data.quantity_delivery'] !== undefined)
            }
          />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
          <HasAssignesInput />
        </Col>
      </Row>
    </div>
  );
};
