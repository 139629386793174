import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { DTOErrorResponse } from "types/DTOReports";
import { Validate } from "utils/Validate";

import { ModalStatus } from "../../../../components";
import { PHYSICAL_PERSON_TYPE } from "../../../../configs/constants";
import { useSerasaCounterparty } from "../../../../hooks/fetch/useSerasaCounterparty";
import { useModalMessage } from "../../../../hooks/helpers/useModalMessage";
import { usePage } from "../../../../hooks/page";
import { ContractTypesProps } from "./types";

export const useSerasaCounterparties = ({ refresh }: ContractTypesProps) => {
  const navigate = useNavigate();
  const {
    getSerasa,
    toggleActive,
    isFetching,
    deleteReport,
    create,
    getStatusOptions,
    update,
  } = useSerasaCounterparty();
  const { handleSuccess } = useModalMessage();
  const { alertStatus } = usePage();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [contractBeingEditedId, setContractBeingEditedId] = useState("");
  const [searchFilters, setSearchFilters] = useState<object>();
  const [isDocSerasa, setIsDocSerasa] = useState(false);
  const [typePerson, setTypePerson] = useState(PHYSICAL_PERSON_TYPE);

  const closeForm = () => {
    setIsFormOpen(false);
    setContractBeingEditedId("");
  };

  const openForm = () => setIsFormOpen(true);

  const handleChangeDocStatus = (value: boolean) => setIsDocSerasa(value);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    form.resetFields();
    setTypePerson(PHYSICAL_PERSON_TYPE);
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        // createOrUpdate(values);

        if (
          !Validate.cpf(values.doc_number) &&
          !Validate.cnpj(values.doc_number)
        ) {
          form.setFields([
            {
              name: "doc_number",
              errors: [t("pages.serasacounterparty.form.docNotValid")],
            },
          ]);
        }

        values.code_datasul = values.code_datasul?.toString() ?? undefined;
        values.cod_sap = values.cod_sap?.toString() ?? undefined;
        if (isDocSerasa) createOrUpdate(values);
        else
          alertStatus(
            {
              message: t("pages.serasacounterparty.form.docNotInSerasa"),
            } as DTOErrorResponse,
            "error",
          );
      })
      .finally();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: object,
    sorter: any,
    generalFilters?: any,
  ) => {
    const search: any = {};
    if (filters) {
      const dataFilter: any = filters;
      Object.keys(dataFilter).forEach((key: string) => {
        if (dataFilter[key]) {
          search[key] = dataFilter[key].toString();
        } else {
          search[key] = "";
        }
      });
    }
    const { data, total } = await getSerasa({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...generalFilters,
        ...search,
      },
    });

    return { data, total };
  };

  const handleEdit = (record: any) => {
    navigate(`/serasa/counterparty/${record.id}`, {
      state: { counterParty: record },
    });
  };

  const createOrUpdate = async (values: any) => {
    setLoading(true);

    const response = values.id
      ? await update(values.id, values)
      : await create(values);
    if (response) {
      handleSuccess("Operação realizada com sucesso");
      refresh();
      closeForm();
    }
    setLoading(false);
  };

  const remove = async (id: string) => {
    const response = await deleteReport(id);
    if (response) {
      refresh();
      ModalStatus({
        type: "success",
        title: t("success"),
        description: "Contraparte removida com sucesso",
      });
    }
  };

  const handleRemove = async (id: string) => {
    ModalStatus({
      type: "confirm",
      title: "Deseja realmente remover esta contraparte?",
      onOk: () => remove(id),
    });
  };

  const handleSwitchChange = (counterparty: any) => {
    ModalStatus({
      type: "confirm",
      subTitle: counterparty.active
        ? "Tem certeza que deseja desativar a contraparte? Assim que você desativá-la, ela não será mais avaliada no SERASA e não poderá mais abrir solicitações de pré-pagamento para ela"
        : "A partir de agora a contraparte será avaliada pelo SERASA e poderá ser aberta solicitações de pré-pagamento",
      onOk: async () => {
        await toggleActive(counterparty.id);
        refresh();
      },
    });
  };

  return {
    closeForm,
    openForm,
    isFormOpen,
    toggleForm,
    isEditing,
    setIsEditing,
    isFetching,
    handleTableChange,
    getStatusOptions,
    handleEdit,
    handleRemove,
    contractBeingEditedId,
    setSearchFilters,
    searchFilters,
    createOrUpdate,
    form,
    handleSave,
    handleSwitchChange,
    handleChangeDocStatus,
    loading,
    setLoading,
    typePerson,
    setTypePerson,
    filterForm,
  };
};
