import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const SubsidiaryIdSelect = () => {
  const { t } = useTranslation();
  const { isLoadingSubsidiaries } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const subsidiaries = useCreditRequestStore(state => state.subsidiaries);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={`${t('pages.credit-request.form.subsidiary_id')} ${
        selectedOperationId === OPERATION_PREPAY_ID ? `/ ${t('center')}` : ''
      }
      `}
      name="subsidiary_id"
      rules={[{ required: true }]}
    >
      <Select
        disabled={
          pageStatus.viewing ||
          isLoadingSubsidiaries ||
          (contractFieldsValue && contractFieldsValue.subsidiary_id !== undefined)
        }
        loading={isLoadingSubsidiaries}
        options={subsidiaries}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
