import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGearFill } from 'react-icons/bs';
import { FaSyncAlt, FaInfoCircle, FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Tabs as ATabs, Modal } from 'antd';

import DTOTeam from '../../../../../@types/dtos/credit-request/DTOTeam';
import { TeamStatusRulesTarget } from '../../../../../@types/hooks/creditStatus';
import KanbanCreditResponse from '../../../../../@types/responseKanban/KanbanCreditResponse';
import { Button, Spin, Switch, Tabs } from '../../../../../components';
import { SelectOptionType } from '../../../../../components/Select/types';
import { useAuth } from '../../../../../hooks/auth';
import { useCreditStatus } from '../../../../../hooks/creditStatus';
import { useKanbanPhases } from '../../../../../hooks/useKanbanPhases';
import api from '../../../../../services/api/credit';
import { getTranslations } from '../../../../../services/translations';
import TeamStatusData from '../../../../../types/CreditRequest/DTOTeamStatus';
import { KanbanModuleConfig, kanbanConfigDefault } from '../../../../../types/User/UserData';
import Column from './Column';
import { teamsCredit } from './kanbanOptions';
import ModalInfoItem from './ModalInfoItem';
import { PhaseFilter } from './phase-filter';
import { Container, HeaderActions, Content, Spinner, KanbanInfo, KanbanConfigList, KanbanRestriction } from './styles';
import { TeamFilter } from './team-filter';
import { KanbanProps } from './types';

const CreditKanban: React.FC<KanbanProps> = ({ filterValues }) => {
  const { t, i18n } = useTranslation();
  const { TabPane } = ATabs;

  const {
    getTeamsByPhaseId,
    getTeam,
    getTeamStatusBySlug,
    getListOfTeamStatusOrdered,
    getListOfTeamStatusOrderedTargetRules,
    teamStatusHasChanged,
    setTeamStatusHasChanged,
  } = useCreditStatus();
  const {
    user: { config: userConfig },
    updateUserConfig,
    updateUserData,
  } = useAuth();
  const { getLastSelectedTeam } = useKanbanPhases();

  const [data, setData] = useState<KanbanCreditResponse>({} as KanbanCreditResponse);

  const [updating, setUpdating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [kanbanInfo, setKanbanInfo] = useState(false);
  const [kanbanConfig, setKanbanConfig] = useState(false);

  const [teamSelected, setTeamSelected] = useState('');
  const [selectedPhaseId, setSelectedPhaseId] = useState('');
  const [expandedAllColumns, setExpandedAllColumns] = useState<boolean>(false);
  const [minimizedAllColumns, setMinimizedAllColumns] = useState<boolean>(false);
  const [, setExpandColumns] = useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [listRulesTargetTeamStatus, setListRulesTargetTeamStatus] = useState<TeamStatusRulesTarget[]>([]);
  const [hasPhaseChanged, setHasPhaseChanged] = useState(false);

  const [userTeamsConfigs, setUserTeamsConfigs] = useState<KanbanModuleConfig>(() => {
    const configs = userConfig.modules.credit.kanban;

    if (!configs) {
      const creditKanbanDefault: KanbanModuleConfig = {} as KanbanModuleConfig;
      Object.keys(teamsCredit).forEach((value, index) => {
        creditKanbanDefault[teamsCredit[index]] = kanbanConfigDefault;
      });
      return creditKanbanDefault as KanbanModuleConfig;
    }

    return configs;
  });

  const selectedTeam: DTOTeam | null = useMemo(() => {
    if (!teamSelected || hasPhaseChanged) {
      if (!selectedPhaseId) return null;
      const teams = getTeamsByPhaseId(selectedPhaseId) as DTOTeam[];
      // Se a fase foi alterada pega a primeira equipe sempre
      const lastSelectedTeam = !hasPhaseChanged ? teams.find(team => team.id === getLastSelectedTeam()) : null;
      const teamObj = lastSelectedTeam ?? teams[0];
      if (!teamObj) return null;
      setListRulesTargetTeamStatus(getListOfTeamStatusOrderedTargetRules(teamObj?.id));
      setHasPhaseChanged(false);
      setTeamSelected(teamObj.id);
      return teamObj || null;
    }
    setListRulesTargetTeamStatus(getListOfTeamStatusOrderedTargetRules(teamSelected));
    return getTeam(teamSelected) as DTOTeam;
    // eslint-disable-next-line
  }, [teamSelected, selectedPhaseId]);

  const listTeamStatusOrdered = useMemo(() => {
    if (!selectedTeam || !selectedPhaseId) return [];
    return getListOfTeamStatusOrdered(false, selectedTeam.id, selectedPhaseId);
    // eslint-disable-next-line
  }, [getListOfTeamStatusOrdered, selectedTeam, selectedPhaseId]);

  useEffect(() => {
    const configs = userConfig.modules.credit.kanban;

    if (selectedTeam)
      if (configs[selectedTeam.slug] && configs[selectedTeam.slug] !== undefined) {
        setExpandedAllColumns(configs[selectedTeam.slug].allColumnsExpanded);
        setMinimizedAllColumns(configs[selectedTeam.slug].allColumnsMinimized);
        setExpandColumns(configs[selectedTeam.slug]?.expandedColumns);
        setHiddenColumns(configs[selectedTeam.slug]?.hiddenColumns);
      }
    // eslint-disable-next-line
  }, [selectedTeam, userConfig.modules.credit.kanban]);

  const minimizeAllColumns = useCallback(() => {
    const updateConfigs: KanbanModuleConfig = {
      ...userTeamsConfigs,
      [selectedTeam.slug]: {
        ...userTeamsConfigs[selectedTeam.slug],
        allColumnsExpanded: false,
        allColumnsMinimized: true,
        expandedColumns: [],
      },
    };
    setExpandedAllColumns(false);
    setMinimizedAllColumns(true);
    setExpandColumns([]);

    setUserTeamsConfigs(updateConfigs);
    userConfig.modules.credit.kanban = updateConfigs;
    updateUserConfig(userConfig);
  }, [userTeamsConfigs, selectedTeam, updateUserConfig, userConfig]);

  const expandAllColumns = useCallback(() => {
    const updateConfigs: KanbanModuleConfig = {
      ...userTeamsConfigs,
      [selectedTeam.slug]: {
        ...userTeamsConfigs[selectedTeam.slug],
        allColumnsExpanded: true,
        allColumnsMinimized: false,
        expandedColumns: [],
      },
    };
    setExpandedAllColumns(true);
    setMinimizedAllColumns(false);
    setExpandColumns([]);

    setUserTeamsConfigs(updateConfigs);
    userConfig.modules.credit.kanban = updateConfigs;
    updateUserConfig(userConfig);
  }, [userTeamsConfigs, selectedTeam, updateUserConfig, userConfig]);

  const expandColumn = useCallback(
    (column: string) => {
      let expand: string[] = [];

      if (userTeamsConfigs[selectedTeam.slug].expandedColumns) {
        const columns = userTeamsConfigs[selectedTeam.slug].expandedColumns;
        expand = [...columns?.filter(eColumn => eColumn !== column)];
      }

      const updateConfigs: KanbanModuleConfig = {
        ...userTeamsConfigs,
        [selectedTeam.slug]: {
          ...userTeamsConfigs[selectedTeam.slug],
          allColumnsExpanded: false,
          allColumnsMinimized: false,
          expandedColumns: [...expand, column],
        },
      };
      setExpandedAllColumns(false);
      setMinimizedAllColumns(false);
      setExpandColumns([...expand, column]);

      setUserTeamsConfigs(updateConfigs);
      userConfig.modules.credit.kanban = updateConfigs;
    },
    [userTeamsConfigs, selectedTeam, updateUserConfig, userConfig],
  );

  const handleToggleVisibilityColumn = useCallback(
    (column: string) => {
      let hideColumns: string[] = userTeamsConfigs[selectedTeam.slug]?.hiddenColumns || [];

      if (hideColumns.includes(column)) {
        hideColumns = [...hideColumns].filter(eColumn => eColumn !== column);
      } else {
        hideColumns = [...hideColumns, column];
      }

      const updateConfigs: KanbanModuleConfig = {
        ...userTeamsConfigs,
        [selectedTeam.slug]: {
          ...userTeamsConfigs[selectedTeam.slug],
          hiddenColumns: hideColumns,
        },
      };
      setHiddenColumns(hideColumns);

      setUserTeamsConfigs(updateConfigs);
      userConfig.modules.credit.kanban = updateConfigs;
      updateUserConfig(userConfig);
    },
    [userTeamsConfigs, selectedTeam, updateUserConfig, userConfig],
  );

  const isMinimized = useCallback(
    (column: string) => {
      if (userTeamsConfigs[selectedTeam.slug] !== undefined) {
        if (userTeamsConfigs[selectedTeam.slug].allColumnsMinimized) {
          return true;
        }

        if (
          !userTeamsConfigs[selectedTeam.slug].allColumnsExpanded &&
          !userTeamsConfigs[selectedTeam.slug].expandedColumns?.includes(column)
        ) {
          return true;
        }
      }

      return false;
    },
    [userTeamsConfigs, selectedTeam],
  );

  const isHidden = useCallback(
    (column: string) => {
      return userTeamsConfigs[selectedTeam.slug]?.hiddenColumns?.includes(column) ?? false;
    },
    [userTeamsConfigs, selectedTeam],
  );

  const handleUpdateKanban = useCallback(
    async (teamId = undefined, phaseId = undefined, refreshUserData = false) => {
      setUpdating(true);

      const filters: any = {};
      const dataFilter: any = filterValues;

      if (dataFilter) {
        Object.keys(dataFilter).forEach((key: string) => {
          filters[key] = dataFilter[key];
        });
      }

      filters.team_id = teamId ?? selectedTeam.id;
      filters.phase = phaseId ?? selectedPhaseId;
      const apiRequest = async () => {
        await api.requests
          .getKanban({ params: { ...filters, per_page: 10 } })
          .then(({ data: responseData }) => {
            setData(responseData);
          })
          .finally(() => {
            setUpdating(false);
          });
      };

      if (refreshUserData) {
        await updateUserData(false, 'person').finally(async () => apiRequest());
      } else {
        apiRequest();
      }
    },
    // eslint-disable-next-line
    [filterValues, selectedTeam, selectedPhaseId],
  );

  useEffect(() => {
    if (selectedTeam && selectedPhaseId) {
      handleUpdateKanban(selectedTeam.id, selectedPhaseId);
    }
  }, [handleUpdateKanban, selectedTeam, selectedPhaseId]);

  useEffect(() => {
    if (teamStatusHasChanged) {
      handleUpdateKanban(selectedTeam.id, selectedPhaseId);
      setTeamStatusHasChanged(false);
    }
  }, [teamStatusHasChanged]);

  const renderColumns = useCallback(() => {
    const columnsRender: any = {};
    listTeamStatusOrdered.forEach(item => {
      columnsRender[item.slug] = data[item.slug];
    });

    return Object.keys(columnsRender).map((key: string) => {
      const teamStatus = getTeamStatusBySlug(key, true) as SelectOptionType;
      const title = teamStatus ? teamStatus.label : '';

      return (
        <Column
          key={`kanban-credit-request-column-${key}`}
          data={columnsRender[key]}
          expandColumn={() => expandColumn(key)}
          minimized={isMinimized(key)}
          hidden={isHidden(key)}
          statusColumn={key}
          title={title.toString()}
          filterValues={filterValues}
        />
      );
    });
    // eslint-disable-next-line
  }, [
    data,
    selectedPhaseId,
    selectedTeam,
    filterValues,
    expandColumn,
    handleUpdateKanban,
    isMinimized,
    isHidden,
    listTeamStatusOrdered,
    getTeamStatusBySlug,
  ]);

  function renderModalInfo() {
    return (
      <>
        <h2>{t('pages.credit-request.kanban.modals.information.title')}</h2>

        {Object.keys(data).map(key => {
          const teamStatus = getTeamStatusBySlug(key) as TeamStatusData;
          return (
            <ModalInfoItem
              key={`modal-info-${key}`}
              title={getTranslations(teamStatus, 'title', i18n.language, 'name')}
              description={getTranslations(teamStatus, 'description', i18n.language)}
            />
          );
        })}
      </>
    );
  }

  function renderModalConfigs() {
    return (
      <>
        <h2>{t('pages.credit-request.kanban.modals.configuration.title')}</h2>

        {Object.keys(data).map(key => {
          const teamStatus = getTeamStatusBySlug(key) as TeamStatusData;

          return (
            <li key={`modal-config-${key}`}>
              <Switch
                onChange={() => handleToggleVisibilityColumn(key)}
                checked={!hiddenColumns?.includes(key)}
                size="small"
              />{' '}
              {getTranslations(teamStatus, 'title', i18n.language, 'name')}
            </li>
          );
        })}
      </>
    );
  }

  function renderButtonsMinimizeAndExpand() {
    if (minimizedAllColumns && !expandedAllColumns) {
      return (
        <Button status="secondary-outline" size="small" onClick={expandAllColumns}>
          <FaPlusCircle />
          {t('pages.credit-request.kanban.buttons.expand-columns')}
        </Button>
      );
    }

    return (
      <Button status="secondary-outline" size="small" onClick={minimizeAllColumns}>
        <FaMinusCircle />
        {t('pages.credit-request.kanban.buttons.minimize-columns')}
      </Button>
    );
  }

  function renderInfoContentModal() {
    return (
      <>
        {kanbanInfo && (
          <Tabs className="kanban-tabs" defaultActiveKey="kanban-tab-information">
            <TabPane tab={t('pages.credit-request.kanban.modals.tabs.information')} key="kanban-tab-information">
              <KanbanInfo>{renderModalInfo()}</KanbanInfo>
            </TabPane>
            <TabPane tab={t('pages.credit-request.kanban.modals.tabs.restriction')} key="kanban-tab-restriction">
              <KanbanRestriction>
                {selectedTeam && (
                  <>
                    <tr className="row-title">
                      <td>{getTranslations(selectedTeam, 'title', i18n.language, 'name')}</td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <thead>
                            <tr>
                              <th>{t('pages.credit-request.kanban.modals.restrictions.colStatus')}</th>
                              <th>{t('pages.credit-request.kanban.modals.restrictions.colPossibilities')}</th>
                              <th>{t('pages.credit-request.kanban.modals.restrictions.colOperation')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listRulesTargetTeamStatus.map(status => {
                              const targetRules = status.target_team_status;
                              const firstRow = (
                                <tr>
                                  <td rowSpan={targetRules.length > 0 ? targetRules.length : 1}>
                                    {getTranslations(status, 'title', i18n.language, 'name')}
                                  </td>
                                  {targetRules.length > 0 && (
                                    <>
                                      <td>{getTranslations(targetRules[0], 'title', i18n.language, 'name')}</td>
                                      <td>{targetRules[0].operation.name}</td>
                                    </>
                                  )}
                                  {targetRules.length === 0 && (
                                    <td colSpan={2}>
                                      {t(
                                        'pages.credit-request.kanban.modals.restrictions.status-doesnot-move-anywhere',
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );

                              const rows = [firstRow];

                              if (targetRules.length > 0) {
                                // eslint-disable-next-line no-plusplus
                                for (let index = 1; index < targetRules.length; index + 1) {
                                  const element = targetRules[index];
                                  rows.push(
                                    <tr>
                                      <td>{getTranslations(element, 'title', i18n.language, 'name')}</td>
                                      <td>{element.operation.name}</td>
                                    </tr>,
                                  );
                                }
                              }

                              return rows.map(row => <>{row}</>);
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                )}
              </KanbanRestriction>
            </TabPane>
          </Tabs>
        )}
      </>
    );
  }

  return (
    <Container>
      {updating && (
        <Spin spinning={updating}>
          <Spinner />
        </Spin>
      )}
      {!updating && data && (
        <>
          {userTeamsConfigs && (
            <HeaderActions>
              <div className="flex flex-col gap-1 px-2 font-bold">
                <div className="flex items-center gap-2">
                  <span className="w-[50px]">{t('phase')}:</span>
                  <PhaseFilter onValueChange={setSelectedPhaseId} />
                </div>
                <div className="flex items-center gap-2">
                  <span className="w-[50px]">{t('team')}:</span>
                  <TeamFilter phaseId={selectedPhaseId} onValueChange={setTeamSelected} />
                </div>
              </div>

              <div className="buttons-actions">
                <Button
                  status="secondary-outline"
                  size="small"
                  onClick={async () => {
                    setUpdating(true);
                    await handleUpdateKanban(selectedTeam.id, selectedPhaseId, true);
                    setUpdating(false);
                  }}
                >
                  <FaSyncAlt />
                  {t('pages.credit-request.kanban.buttons.update')}
                </Button>

                <Button
                  status="secondary-outline"
                  size="small"
                  onClick={() => {
                    setModalVisible(true);
                    setKanbanInfo(true);
                  }}
                >
                  <FaInfoCircle />
                  {t('pages.credit-request.kanban.buttons.information')}
                </Button>

                <Button
                  status="secondary-outline"
                  size="small"
                  onClick={() => {
                    setModalVisible(true);
                    setKanbanConfig(true);
                  }}
                >
                  <BsGearFill />
                  {t('pages.credit-request.kanban.buttons.configurations')}
                </Button>

                {renderButtonsMinimizeAndExpand()}
              </div>
            </HeaderActions>
          )}
          <Content>{renderColumns()}</Content>
        </>
      )}

      <Modal
        visible={modalVisible}
        title={
          kanbanInfo
            ? t('pages.credit-request.kanban.modals.information-title')
            : t('pages.credit-request.kanban.modals.configuration-title')
        }
        className="modal-with-custom-footer"
        onCancel={() => {
          setModalVisible(false);
          setKanbanInfo(false);
          setKanbanConfig(false);
        }}
        closeIcon={
          <MdClose
            onClick={() => {
              setModalVisible(false);
              setKanbanInfo(false);
              setKanbanConfig(false);
            }}
          />
        }
        width="70%"
        footer={
          <>
            <div className="buttons">
              <Button
                status="primary"
                onClick={() => {
                  setModalVisible(false);
                  setKanbanInfo(false);
                  setKanbanConfig(false);
                }}
              >
                {t('pages.credit-request.kanban.modals.buttons.ok')}
              </Button>
            </div>
          </>
        }
      >
        {renderInfoContentModal()}

        {kanbanConfig && <KanbanConfigList>{renderModalConfigs()}</KanbanConfigList>}
      </Modal>
    </Container>
  );
};

export default CreditKanban;
