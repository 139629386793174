import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';
import { Button } from 'components';
import { usePage } from 'hooks/page';
import { useLoadCreditRequestData } from 'pages/CreditRequest/hooks/useLoadCreditRequestData';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import creditApi from 'services/api/credit';

import { ModalAdd } from './modalAdd';
import { ModalView } from './modalView';
import { TableJustification } from './table';
import { DataJustificationViewProps } from './types';

const Justification = () => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { loadCreditRequestData } = useLoadCreditRequestData();

  const listJustification = useCreditRequestStore(state => state.listJustification);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  const [form] = Form.useForm();
  const [modalAddVisible, setModalAddVisible] = useState<boolean>(false);
  const [modalViewVisible, setModalViewVisible] = useState<boolean>(false);
  const [dataJustificationView, setDataJustificationView] = useState<DataJustificationViewProps>();

  const saveJustification = async () => {
    setIsLoading(true);
    setModalAddVisible(false);
    await creditApi.requests
      .justify(creditRequestId, {
        justify: form.getFieldValue('justification'),
      })
      .then(async () => {
        alertStatus(t('pages.credit-request.messages.success'));
        setIsLoading(false);
        form.setFieldsValue({ justification: '' });
        loadCreditRequestData();
      })
      .catch((err: any) => {
        alertStatus(err, 'error');
        console.warn('error-saving', err);
      });
  };

  const openModalView = (id: string) => {
    const filterList = listJustification.filter((item: any) => item.id === id);
    setDataJustificationView(filterList[0]);
    setModalViewVisible(true);
  };

  return (
    <div className="group-form">
      <h2>{t('pages.credit-request.form.titles.justification')}</h2>
      <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>
        <Button status="primary-outline" onClick={() => setModalAddVisible(true)}>
          {t('pages.credit-request.form.buttons.new-justification')}
        </Button>
      </div>
      <TableJustification listJustification={listJustification} openModalView={openModalView} />
      <ModalAdd
        setModalAddVisible={setModalAddVisible}
        form={form}
        modalAddVisible={modalAddVisible}
        saveJustification={saveJustification}
      />
      <ModalView
        setModalViewVisible={setModalViewVisible}
        modalViewVisible={modalViewVisible}
        dataJustificationView={dataJustificationView}
      />
    </div>
  );
};

export default Justification;
