import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import appConfigs from '../../../../../configs/app';

export const NegotiatedFixationDateInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  return (
    <FormItem
      label={t('pages.credit-request.form.negotiated_fixation_date')}
      name="negotiated_fixation_date"
      rules={[{ required: true }]}
    >
      <DatePicker
        locale={ptBR}
        mode="date"
        disabledDate={date => {
          const currentDate = moment();

          if (pageStatus.viewing && creditRequestData.created_at)
            return date.isBefore(creditRequestData.created_at, 'date');

          return date.isBefore(currentDate, 'date');
        }}
        format={appConfigs.formatDate}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
