import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const JsonDataContractTypeIdSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const setContractTypeVisible = useCreditRequestStore(state => state.setContractTypeVisible);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_contract_type_id')}
      name="json_data.contract_type_id"
      rules={[{ required: true }]}
    >
      <Select
        options={cache.getOptions('contract_type')}
        disabled={pageStatus.viewing}
        onChange={(value: any, option: any) => {
          if (value) {
            setIsBlocking(true);

            setContractTypeVisible(option.label && option.label?.toLowerCase() === 'fixo');
          }
        }}
      />
    </FormItem>
  );
};
