import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Spin } from "antd";
import { Button, FormItem, IconWithTooltip } from "components";
import ApiSelect from "components/ApiSelect";
import PermissionedComponent from "components/PermissionedComponent";
import { TableData } from "compositions/TableDataAsync";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import { TableTranslation } from "compositions/TableDataAsync/types";
import { Can } from "hooks/ability";
import { useIntegrationGroups } from "hooks/fetch/useIntegrationGroups";

import { columns } from "./columns";
import { ExpandedRowProps } from "./types";

export const ExpandedRow = ({ groupId }: ExpandedRowProps) => {
  const [form] = Form.useForm();
  const controller = useTable();
  const { t } = useTranslation();

  const {
    updateGroupUsers,
    isUpdatingGroupUsers,
    getUsersNotInGroupById,
    updateGroupIntegrationType,
    isUpdatingGroupIntegrationType,
    getGroupUsersById,
    isFetchingGroupUsers,
    removeUserFromGroup,
    isRemovingUserFromGroup,
  } = useIntegrationGroups();

  const handleUpdateGroup = () => {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue();
      if (formValues.worker_type)
        updateGroupIntegrationType(groupId, formValues.worker_type);

      await updateGroupUsers(groupId, formValues.users);

      controller.refresh();
      form.resetFields();
    });
  };

  const handleGetUsersNotInGroupById = async (value: string, page: number) => {
    const response = await getUsersNotInGroupById(groupId, {
      params: { search: value, page },
    });
    if (response) {
      const options = response.data.map((user: any) => {
        return {
          key: user.id,
          value: user.id,
          label: user.name,
        };
      });

      return { data: options, lastPage: response.last_page };
    }
    return { data: [], lastPage: 0 };
  };

  const handleFetchTableData = async (pagination: any) => {
    const response = await getGroupUsersById(groupId, {
      params: {
        page: pagination?.current ?? 1,
        per_page: pagination?.pageSize ?? 10,
      },
    });
    if (!response) return { data: [], total: 0 };
    return { data: response.data, total: response.total };
  };

  const handleRemoveUserFromGroup = async (userId: string) => {
    await removeUserFromGroup(groupId, [userId]);
    controller.refresh();
  };

  const getActions = () => ({
    render: (_1: number, userData: { name: string; id: string }) => (
      <>
        <Can I="worker.group.users.delete">
          <IconWithTooltip
            action="destroy"
            title={t("pages.admin.changelog.table.icons.remove")}
            onClick={() => handleRemoveUserFromGroup(userData.id)}
          />
        </Can>
      </>
    ),
  });

  return (
    <Spin spinning={isUpdatingGroupIntegrationType || isUpdatingGroupUsers}>
      <div>
        <Form form={form}>
          <FormItem
            name="users"
            label={t("pages.integrations.group.form.users")}
            permission="worker.group.users.index"
          >
            <ApiSelect
              mode="multiple"
              fetchOptions={handleGetUsersNotInGroupById}
              showSearch
              listHeight={221}
            />
          </FormItem>

          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button
              onClick={handleUpdateGroup}
              permission={[
                "worker.group.users.store",
                "worker.importexport.store",
              ]}
            >
              {t("save")}
            </Button>
          </div>
        </Form>
        <PermissionedComponent permission="worker.group.users.index">
          <TableData
            loading={isFetchingGroupUsers || isRemovingUserFromGroup}
            rowKey="id"
            controller={controller}
            searchRequest={handleFetchTableData}
            width="100%"
            columns={columns.map((column: any) => {
              const columnRender: any = {
                ...column,
                title: t(column.title),
              };

              return columnRender;
            })}
            tableConfig={{
              getActions,
            }}
            translation={
              {
                pagination: { totalOfRegisters: t("table.totalOfRegisters") },
                filterDropdown: {
                  filterActive: "Ativo",
                  filterInactive: "Inativo",
                },
                filterConfirm: "Buscar",
                filterReset: "Limpar",
              } as TableTranslation
            }
            pagination={{ position: ["bottomCenter"] }}
          />
        </PermissionedComponent>
      </div>
    </Spin>
  );
};
