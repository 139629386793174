import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Input as AntInput } from 'antd';
import { FormItem, Input, Row } from 'components';
import PermissionedComponent from 'components/PermissionedComponent';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { Can } from 'hooks/ability';
import { NumberInput } from 'pages/CreditRequest/components/atoms/NumberInput';
import { RequestedAmountInput } from 'pages/CreditRequest/components/atoms/RequestedAmountInput';
import { DocumentsList } from 'pages/CreditRequest/components/molecules/DocumentsList';
import { OperationalFields } from 'pages/CreditRequest/components/organisms/OperationalFields';
import { PersonFieldsMtmInternMarket } from 'pages/CreditRequest/components/organisms/PersonFieldsMtmInternMarket';
import { StatusHistory } from 'pages/CreditRequest/components/organisms/StatusHistory';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { ApprovedValueInput } from '../atoms/ApprovedValueInput';
import { CreditLimitDateInput } from '../atoms/CreditLimitDateInput';
import { JsonDataCurrencyTypeIdSelect } from '../atoms/JsonDataCurrencyTypeIdSelect';

export const FertilizingForm = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const formIsBlocked = useCreditRequestStore(state => state.formIsBlocked);
  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const handleChangeJsonDataCurrencyTypeId = (value: any, option: any) => {
    if (value) setIsDolarSelected(!!option.label);
  };

  const isJsonDataCurrencyTypeIdSelectDisabled = useMemo(() => {
    return (
      pageStatus.viewing || (contractFieldsValue && contractFieldsValue['json_data.currency_type_id'] !== undefined)
    );
  }, [pageStatus, contractFieldsValue]);

  return (
    <>
      {/* Dados Gerais */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.general-data')}</h2>

        <Row gutter={[8, 8]}>
          {!pageStatus.new ? (
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <NumberInput />
            </Col>
          ) : null}

          <PersonFieldsMtmInternMarket isProvider />
        </Row>

        <Row gutter={[8, 8]}>
          <OperationalFields />

          {/* Currency Type */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataCurrencyTypeIdSelect
              onChange={handleChangeJsonDataCurrencyTypeId}
              disabled={isJsonDataCurrencyTypeIdSelectDisabled}
            />
          </Col>

          {/* Requested Amount */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <RequestedAmountInput disabled={pageStatus.viewing} required />
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: creditForm.getFieldValue('approved_value') ? '' : 'none' }}
          >
            <ApprovedValueInput disabled />
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: creditForm.getFieldValue('credit_due_date') ? '' : 'none' }}
          >
            <CreditLimitDateInput
              required={!!creditForm.getFieldValue('credit_due_date')}
              disabled={pageStatus.viewing}
            />
          </Col>
          {/* Condition */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <FormItem
              label={t('pages.credit-request.form.json_data_payment_term')}
              name="json_data.payment_term"
              rules={[{ required: true }]}
            >
              <Input
                disabled={pageStatus.viewing}
                onChange={value => {
                  if (value) {
                    setIsBlocking(true);
                  }
                }}
              />
            </FormItem>
          </Col>
          {/* Comments */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <FormItem label={t('pages.credit-request.form.comments')} name="comments" rules={[{ required: true }]}>
              <AntInput.TextArea
                disabled={pageStatus.viewing}
                onChange={value => {
                  if (value) {
                    setIsBlocking(!formIsBlocked);
                  }
                }}
                rows={5}
              />
            </FormItem>
          </Col>
        </Row>
      </div>

      <Can I="credit.request.attachment.index">
        <DocumentsList />
      </Can>

      {!pageStatus.new ? (
        <PermissionedComponent
          permission="credit.request.attachment.index"
          description={t('permission.message.credit.request.history.index')}
        >
          <StatusHistory />
        </PermissionedComponent>
      ) : null}
    </>
  );
};
