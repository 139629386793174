import { useMemo } from 'react';

import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const useOperationHasThirdProviderEnabled = () => {
  const isPrefin = useCreditRequestStore(state => state.isPrefin);
  const isPrefinSugarMills = useCreditRequestStore(state => state.isPrefinSugarMills);
  const isMTM = useCreditRequestStore(state => state.isMTM);
  const isInternMarket = useCreditRequestStore(state => state.isInternMarket);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);

  const operationHasThirdProviderEnabled = useMemo(
    () => isPrefin || isPrefinSugarMills || isMTM || isInternMarket,
    [selectedOperationId],
  );

  return { operationHasThirdProviderEnabled };
};
