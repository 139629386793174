import React, { useEffect, useMemo, useState } from 'react';

import { RadioGroupProps } from '@radix-ui/react-radio-group';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { useCreditStatus } from 'hooks/creditStatus';
import { kanbanConfigDefault, KanbanModuleConfig } from 'types/User/UserData';

import DTOTeam from '../../../../../@types/dtos/credit-request/DTOTeam';
import { KanbanRadioFilter } from './kanban-radio-filter';
import { teamsCredit } from './kanbanOptions';

type Props = RadioGroupProps & {
  phaseId: string;
};

export const TeamFilter = ({ phaseId, ...props }: Props) => {
  const { getCreditStatusOptions } = useCache();
  const { getTeamsByPhaseId } = useCreditStatus();

  const {
    user: { config: userConfig },
    updateUserConfig,
  } = useAuth();

  const listTeams: DTOTeam[] = useMemo(() => {
    return getTeamsByPhaseId(phaseId) as DTOTeam[];
    // eslint-disable-next-line
  }, [getCreditStatusOptions, getTeamsByPhaseId, phaseId]);

  const [value, setValue] = useState('');
  const [userTeamsConfigs, setUserTeamsConfigs] = useState<KanbanModuleConfig | null>(null);

  const handleValueChange = (value: string) => {
    setValue(value);
    props.onValueChange?.(value);

    const updatedConfig = {
      ...userTeamsConfigs,
      lastTeam: value,
    } as any;

    setUserTeamsConfigs(updatedConfig);
    userConfig.modules.credit.kanban = updatedConfig;
    updateUserConfig(userConfig);
  };

  const createNewTeamsConfig = () => {
    const creditKanbanDefault: KanbanModuleConfig = {} as KanbanModuleConfig;
    Object.keys(teamsCredit).forEach((value, index) => {
      creditKanbanDefault[teamsCredit[index]] = kanbanConfigDefault;
    });
    setUserTeamsConfigs(creditKanbanDefault);
  };

  useEffect(() => {
    const configs = userConfig.modules.credit.kanban;
    if (!configs) createNewTeamsConfig();
    setUserTeamsConfigs(configs);
  }, [userConfig, teamsCredit]);

  useEffect(() => {
    const configs = userConfig.modules.credit.kanban;
    setValue(configs.lastTeam as any);
  }, []);

  return (
    <KanbanRadioFilter
      {...props}
      options={listTeams.map(team => ({ value: team.id.toString(), label: team.name }))}
      defaultValue={props.defaultValue || (listTeams.length ? listTeams[0].id.toString() : '')}
      value={value}
      onValueChange={handleValueChange}
    />
  );
};
