import React, { useState, useCallback, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { useDebounce } from "@uidotdev/usehooks";
import { Select as AntdSelect, Divider } from "antd";

import { SelectOptionType, SelectProps } from "./Select/types";

export interface ApiSelectProps
  extends Omit<SelectProps, "options" | "onPopupScroll"> {
  fetchOptions: (
    search: string,
    page: number,
  ) => Promise<{ data: SelectOptionType[]; lastPage: number }>;
  debounceTimeout?: number;
  breakOptions?: boolean;
  initialOptions?: SelectOptionType[];
  separatorText?: string;
  deps?: any[];
}

export const ApiSelect = forwardRef<any, ApiSelectProps>(
  (
    {
      fetchOptions,
      debounceTimeout = 300,
      breakOptions = false,
      initialOptions = [],
      separatorText,
      deps,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<SelectOptionType[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    // Determine if the select should be disabled
    const isDisabled = deps && deps.some((dep) => !dep);

    const loadOptions = useCallback(
      async (value: string, loadMore = false) => {
        if (loading) return;
        setLoading(true);
        try {
          const { data: newOptions, lastPage: newLastPage } =
            await fetchOptions(value, loadMore ? page + 1 : 1);
          if (loadMore) {
            setOptions((prev) => [...prev, ...newOptions]);
            setPage((prev) => prev + 1);
          } else {
            setOptions(newOptions);
            setPage(1);
          }
          setHasMore(page < newLastPage);
        } catch (error) {
          console.error("Error fetching options:", error);
        } finally {
          setLoading(false);
        }
      },
      [fetchOptions, page, loading],
    );

    useEffect(() => {
      if (dropdownOpen && !isDisabled) {
        loadOptions(debouncedSearchTerm);
      }
    }, [dropdownOpen, debouncedSearchTerm]);

    const handlePopupScroll = useCallback(
      (event: React.UIEvent<HTMLDivElement>) => {
        const { target } = event;
        if (target && !loading && hasMore) {
          const { scrollTop, clientHeight, scrollHeight } =
            target as HTMLDivElement;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            loadOptions(debouncedSearchTerm, true);
          }
        }
      },
      [loading, hasMore, loadOptions, debouncedSearchTerm],
    );

    const handleDropdownVisibleChange = (open: boolean) => {
      setDropdownOpen(open);
      if (open && options.length === 0 && !loading) {
        loadOptions(searchTerm);
      }
    };

    const handleSearch = (value: string) => {
      setSearchTerm(value);
      // Clear options and reset pagination when the input changes
      if (value !== debouncedSearchTerm) {
        setOptions([]);
        setPage(1);
        setHasMore(true);
      }
    };

    const renderOption = (option: SelectOptionType) => (
      <AntdSelect.Option
        {...option}
        key={option.value}
        value={option.value}
        label={option.label}
      >
        <span style={{ whiteSpace: breakOptions ? "pre-wrap" : "nowrap" }}>
          {option.label}
        </span>
      </AntdSelect.Option>
    );

    const renderSeparator = () => (
      <AntdSelect.Option
        key="separator"
        value="separator"
        disabled
        className="ant-select-item-option-divider"
      >
        <Divider style={{ margin: "4px 0" }}>
          {separatorText || t("other options")}
        </Divider>
      </AntdSelect.Option>
    );

    return (
      <AntdSelect
        ref={ref}
        loading={loading}
        onSearch={handleSearch} // Keep this to trigger search on input change
        onPopupScroll={handlePopupScroll}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        filterOption={false}
        disabled={isDisabled}
        {...props}
      >
        {initialOptions.map((option, index) => (
          <AntdSelect.Option
            key={`initial-${option.value + index.toString()}`}
            value={option.value}
          >
            {option.label}
          </AntdSelect.Option>
        ))}
        {initialOptions.length > 0 && options.length > 0 && renderSeparator()}
        {options.map(renderOption)}
      </AntdSelect>
    );
  },
);

ApiSelect.displayName = "ApiSelect";

export default ApiSelect;
