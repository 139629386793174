import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem } from 'components';
import ApiSelect from 'components/ApiSelect';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import api from 'services/api/api';
import { Converter } from 'utils/Converter';

export const EntityTypeSelect = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  const handleGetOptions = async (search: string, page: number) => {
    const response = await api.get('credit-request/entity_type', {
      params: {
        search,
        page,
        perPage: 10,
      },
    });

    return {
      data: Converter.selectOptionArray(response.data.data, 'id', 'id', 'name'),
      lastPage: response.data.last_page,
    };
  };

  return (
    // <PermissionedField permission={['credit.request.entity_type.index']}>
    <FormItem label={t('entity-type')} name="entity_type_id" rules={[{ required: true }]}>
      <ApiSelect
        fetchOptions={handleGetOptions}
        initialOptions={
          creditRequestData?.entity_type_id
            ? [Converter.selectOption(creditRequestData.entity_type, 'id', 'id', 'name')]
            : []
        }
        disabled={pageStatus.viewing}
      />
    </FormItem>
    // </PermissionedField>
  );
};
