import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogFooterForm, DialogHeader, DialogTrigger } from 'components/ui/dialog';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/use-package-form-state-store';
import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';
import { FormRef } from 'types/FormRef';

import { DocumentsToCollectForm } from './documents-collect.form';

type Props = {
  children: React.ReactNode;
  className?: string;
  onSuccess?: (data: DocumentToCollectFormData) => void;
  shouldRegister?: boolean;
  onClick?: () => void;
};

export const DocumentsToCollectModal = ({ children, onClick, className, onSuccess, shouldRegister = true }: Props) => {
  const { t } = useTranslation();
  const formRef = React.useRef<FormRef>(null);
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = React.useState(false);

  const selectedDocumentToCollectToEdit = usePackageFormStateStore(state => state.selectedDocumentToCollectToEdit);

  const isUpdatingDocumentToCollect = useIsMutating({
    mutationKey: ['updateDocumentToCollect'],
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className={className} onClick={onClick} tabIndex={-1}>
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {selectedDocumentToCollectToEdit
            ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.titleEdit')
            : t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.title')}
        </DialogHeader>
        <DocumentsToCollectForm
          ref={formRef}
          onSuccess={data => {
            if (onSuccess) onSuccess(data);
            setIsOpen(false);
            if (data.id && !data.id.includes('new-'))
              queryClient.invalidateQueries({
                queryKey: ['credit-request/package'],
              });
          }}
          shouldRegister={shouldRegister}
        />
        <DialogFooterForm
          isLoading={!!isUpdatingDocumentToCollect}
          onConfirm={() => {
            formRef.current?.submit();
          }}
          onCancel={() => {
            setIsOpen(false);
            formRef.current?.form.reset();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
