import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineClipboardList } from 'react-icons/hi';

import { Col, Row } from 'antd';
import { Button } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { CustodiaApprovalStatusInput } from '../../atoms/CustodiaApprovalStatusInput';
import { CustodiaCityIdSelect } from '../../atoms/CustodiaCityIdSelect';
import { CustodiaStateIdSelect } from '../../atoms/CustodiaStateIdSelect';
import { CustodiaStorageCategoryInput } from '../../atoms/CustodiaStorageCategoryInput';
import { CustodiaStorageCommodityDivisionIdSelect } from '../../atoms/CustodiaStorageCommodityDivisionIdSelect';
import { CustodiaStorageUnitIdSelect } from '../../atoms/CustodiaStorageUnitIdSelect';
import { JsonDataPostMaturityValueInput } from '../../atoms/JsonDataPostMaturityValueInput';
import { JsonDataStoreLocationInput } from '../../atoms/JsonDataStoreLocationInput';
import { JsonDataWarehouseCityIdSelect } from '../../atoms/JsonDataWarehouseCityIdSelect';
import { JsonDataWarehouseShippingCapacityInput } from '../../atoms/JsonDataWarehouseShippingCapacityInput';
import { JsonDataWarehouseStateIdSelect } from '../../atoms/JsonDataWarehouseStateIdSelect';
import { JsonDataWarehouseStreetInput } from '../../atoms/JsonDataWarehouseStreetInput';
import { JsonDataWithdrawalPeriodInput } from '../../atoms/JsonDataWithdrawalPeriodInput';
import { StartWithdrawalDeadlineInput } from '../../atoms/StartWithdrawalDeadlineInput';

export const DepositAgreement = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);

  const [commodities, setCommodities] = React.useState<any[]>([]);

  return (
    <div className="group-form">
      <h2>{t('pages.credit-request.form.titles.contract')}</h2>

      <Row gutter={[8, 8]}>
        {/* Warehouse State */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWarehouseStateIdSelect />
          <CustodiaStateIdSelect />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWarehouseCityIdSelect />
          <CustodiaCityIdSelect />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataStoreLocationInput />
          <CustodiaStorageUnitIdSelect setCommodities={setCommodities} />
        </Col>
        {!custodyWarehouseAddressIsLegacy ? (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <CustodiaStorageCommodityDivisionIdSelect commodities={commodities} />
          </Col>
        ) : null}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          {!custodyWarehouseAddressIsLegacy ? <CustodiaApprovalStatusInput /> : null}
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          {!custodyWarehouseAddressIsLegacy ? <CustodiaStorageCategoryInput /> : null}
        </Col>
      </Row>

      <Row gutter={[8, 8]} style={{ display: 'flex' }}>
        <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
          <JsonDataWarehouseStreetInput />
        </Col>
        <Button
          title={t('clipboardCopy')}
          onClick={() => navigator.clipboard.writeText(creditForm.getFieldValue('json_data.warehouse_street'))}
        >
          <span>
            <HiOutlineClipboardList />
          </span>
        </Button>
      </Row>

      <Row gutter={[8, 8]}>
        {/* Withdrawal Period */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <StartWithdrawalDeadlineInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWithdrawalPeriodInput />
        </Col>
        {/* Warehouse Shipping Capacity */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWarehouseShippingCapacityInput />
        </Col>
        {/* Post Maturity Value */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataPostMaturityValueInput />
        </Col>
        {/* Warehouse Neighborhood */}
        {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWarehouseNeighborhoodInput />
        </Col> */}
      </Row>
    </div>
  );
};
