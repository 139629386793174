import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import PermissionGroupData from 'types/PermissionGroup/PermissionGroupData';

type Params = PaginatedQueryParams;

export const useGetPermissionGroupsQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<PermissionGroupData>({
    queryKey: ['admin/group', params],
    url: `admin/group`,
    permissions: 'admin.group.index',
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

export const useGetPermissionGroupsOptionsQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<PermissionGroupData>>(
    'admin/group',
    {
      queryKey: ['admin/group', params],
      staleTime: 1000 * 60 * 5,
      permissions: ['admin.group.index'],
    },
    params,
  );

  return query;
};
