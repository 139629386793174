import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

export const AdvanceValueInput = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const currencySlug =
    cache
      .getCreditOptions('currency_type')
      .find(currency => currency.key === creditForm.getFieldValue('json_data.currency_type_id'))?.label || '';

  return (
    <FormItem
      label={`${t('pages.credit-request.form.json_data_advance_value')}${currencySlug ? ` (${currencySlug})` : ''}`}
      name="json_data.gross_value"
      rules={[
        { required: true },
        selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
          ? {
              validator: async _ => {
                const formValues = creditForm.getFieldsValue();
                const balaceToFix = Number(formValues.balance_to_be_fixed);
                const grossValue = Number(formValues['json_data.gross_value']);
                const priceDay = Number(formValues.daily_price_value);
                const currentPercentage = (grossValue / ((balaceToFix * priceDay) / 60)) * 100;
                if (currentPercentage > 70) {
                  return Promise.reject(
                    new Error(
                      t('pages.credit-request.messages.contract_liquid_value_exceeded') +
                        Formatter.decimal(currentPercentage.toFixed(2), 2, 3),
                    ),
                  );
                }
                return Promise.resolve();
              },
            }
          : undefined,
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={!pageStatus.new}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
