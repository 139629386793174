import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PermissionedField } from 'components/PermissionedField';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { ComboBox, OptionData } from 'components/ui/combo';
import { DatePicker } from 'components/ui/date-picker';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, InputMoney, TextArea } from 'components/ui/input';
import { useCache } from 'hooks/cache';
import moment from 'moment';
import { useUpdateDocumentToCollectMutation } from 'pages/CreditRequest/api/use-update-document-to-collect.mutation';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/use-package-form-state-store';
import { DocumentData } from 'types/Api/DocumentData';
import { DocumentToCollectData } from 'types/CreditRequest/DocumentToCollectData';
import { CommentFormData } from 'types/CreditRequest/forms/CommentFormData';
import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';
import { v4 as uuid } from 'uuid';

import { DocumentStatusSelect } from '../../document-status.select';
import { NotarySelect } from '../../notary.select';
import { DocumentsToCollectAttachments } from './documents-collect-attachments.section';
import { DocumentsToCollectComments } from './documents-collect-comments.section';
import { DocumentsToCollectFormConverter } from './documents-collect.form.converter';

export const initialValues = {
  index: 0,
  id: '',
  name: '',
  collection_cost: 0,
  city_id: '',
  state_id: '',
  notary_id: '',
  documents_files: [] as File[],
  description: '',
  status_id: '',
  comments: [] as CommentFormData[],
  documents: [] as DocumentData[],
  collected: {
    created_at: '',
    name: '',
  },
  requested: {
    created_at: '',
    name: '',
  },
} as DocumentToCollectFormData;

type DocumentsToCollectFormProps = {
  onSuccess?: (data: DocumentToCollectFormData) => void;
  shouldRegister?: boolean;
};

export const DocumentsToCollectForm = forwardRef(
  ({ onSuccess, shouldRegister = true }: DocumentsToCollectFormProps, ref) => {
    const { t } = useTranslation();
    const cache = useCache();
    const { alert } = useAlertDialog();

    const form = useForm({
      defaultValues: { ...initialValues, id: `new-${uuid()}` },
    });

    const selectedStateId = form.watch('state_id');
    const selectedCityId = form.watch('city_id');
    const collectedAt = useWatch({ name: 'collected.created_at', control: form.control });

    const resetDocumentToCollectFormState = usePackageFormStateStore(state => state.resetDocumentToCollectFormState);
    const selectedDocumentToCollectToEdit = usePackageFormStateStore(state => state.selectedDocumentToCollectToEdit);

    const states = Array.from(new Set(cache.getCreditOptions('state')));
    const cities = useMemo(
      () => Array.from(new Set(selectedStateId ? cache.getCityByStateOptions(selectedStateId) : [])),
      [selectedStateId],
    );

    const updateDocumentToCollectMutation = useUpdateDocumentToCollectMutation();

    useImperativeHandle(ref, () => ({
      form,
      submit: () => submitDocumentsToCollectForm(form),
    }));

    const initializeDocumentsToCollectFormData = (
      form: UseFormReturn<DocumentToCollectFormData, any, undefined>,
      data: DocumentToCollectData | DocumentToCollectFormData,
    ) => {
      form.setValue('index', data.index);
      form.setValue('id', data.id);
      form.setValue('name', data.name);
      form.setValue('collection_cost', data.collection_cost);
      form.setValue('city_id', data.city_id);
      form.setValue('notary_id', data.notary_id);
      form.setValue('description', data.description);
      form.setValue('status_id', data.status_id);

      if ('user' in data) {
        form.setValue('state_id', data.city?.state_id ? data.city?.state_id : data?.state_id);
        form.setValue('documents', data.documents);
        form.setValue(
          'comments',
          data.comments.map(comment => ({
            ...comment,
            dt_comment: moment(comment.created_at).format('YYYY-MM-DD'),
            registered_by: comment.user?.name,
          })),
        );
      } else {
        form.setValue('documents_files', data.documents_files);
        form.setValue('state_id', data.state_id);
        form.setValue('comments', data.comments);
      }
    };

    const submitDocumentsToCollectForm = (form: UseFormReturn<DocumentToCollectFormData>) => {
      form.handleSubmit(async data => {
        if ((!shouldRegister || data.id.includes('new-')) && onSuccess) return onSuccess(data);

        const formData = await DocumentsToCollectFormConverter.toApiFormData(data);

        await updateDocumentToCollectMutation.mutateAsync(formData, {
          onSuccess: response => {
            alert({ title: response.message, type: 'success' });
            if (onSuccess) onSuccess(data);
          },
        });
      })();
    };

    useEffect(() => {
      if (!selectedDocumentToCollectToEdit) return;
      initializeDocumentsToCollectFormData(form, selectedDocumentToCollectToEdit);

      return () => {
        form.reset();
        resetDocumentToCollectFormState();
      };
    }, [selectedDocumentToCollectToEdit]);

    return (
      <Form form={form} onSubmit={() => submitDocumentsToCollectForm(form)}>
        <GroupForm
          title={t(
            'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.documents-to-collect.general-data',
          )}
        >
          <FormGrid>
            <FormItem name="index" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <FormItem name="id" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <FormItem name="comments" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <FormItem
              label={t(
                'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.document_identification',
              )}
              name="name"
              rules={{ required: true }}
              className="w-full"
            >
              <Input type="text" />
            </FormItem>
            <FormItem
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.value')}
              name="collection_cost"
              className="w-full"
              rules={{
                valueAsNumber: true,
              }}
            >
              <InputMoney min="0" maxLength={15} />
            </FormItem>
            <FormItem
              name="state_id"
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.state')}
              className="w-full"
            >
              <ComboBox
                options={(states as OptionData[]) || []}
                onValueChange={() => {
                  form.setValue('city_id', '');
                }}
                className="w-full"
              />
            </FormItem>
            <FormItem
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.city')}
              name="city_id"
              className="w-full"
            >
              <ComboBox options={(cities as OptionData[]) || []} disabled={!selectedStateId} className="w-full" />
            </FormItem>
            <FormItem
              name="notary_id"
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.notary')}
            >
              <NotarySelect cityId={selectedCityId} disabled={!selectedCityId} className="w-full" />
            </FormItem>
            <FormItem
              name="status_id"
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.status')}
            >
              <PermissionedField permission="credit.request.documentstatus.all">
                <DocumentStatusSelect className="w-full" initialOptions={[selectedDocumentToCollectToEdit?.status]} />
              </PermissionedField>
            </FormItem>

            <ShouldRender condition={!!collectedAt}>
              <FormItem name="collected.created_at" className="w-full" label={t('collected-at')}>
                <DatePicker className="w-full" disabled />
              </FormItem>
            </ShouldRender>
            <FormItem
              className="col-span-full"
              name="description"
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.note')}
            >
              <TextArea maxLength={5000} />
            </FormItem>
          </FormGrid>
        </GroupForm>

        <DocumentsToCollectAttachments />

        <DocumentsToCollectComments />
      </Form>
    );
  },
);

DocumentsToCollectForm.displayName = 'DocumentsToCollectForm';
