import React from "react";
import { useTranslation } from "react-i18next";

import { Breadcrumb } from "../../../components";
import { ContractListFilterProvider } from "../context/ContractListFilterContext";
import { useContractListStore } from "../store/useContractListStore";
import { Table } from "./Table";

const withContractListFilterProvider =
  (Component: React.FC) => (props: any) => (
    <ContractListFilterProvider>
      <Component {...props} />
    </ContractListFilterProvider>
  );

export const ContractList = withContractListFilterProvider(() => {
  const { t } = useTranslation();
  const total = useContractListStore((state) => state.total);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <Breadcrumb
        items={[
          { title: t("breadcrumb.edocuments") },
          { title: t("breadcrumb.contractlist") },
        ]}
      />
      <div className="h-full overflow-hidden p-[8px_8px_24px]">
        <h2 className="mb-[16px] block text-[16px] font-bold text-primary">
          {t("pages.edocuments.contractlist.title")} ({total})
        </h2>

        <Table />
      </div>
    </div>
  );
});
