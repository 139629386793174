import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  TableOptions,
  getExpandedRowModel,
  Row,
  PaginationState as PaginationStateTable,
  SortingState,
  getGroupedRowModel,
  VisibilityState,
  ExpandedState,
} from '@tanstack/react-table';
import { PaginationAction, PaginationState } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { ChevronDown, ChevronsUpDown, ChevronUp, Loader } from 'lucide-react';

import { DataTablePagination } from './data-table.pagination';
import Loading from './loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table';

export type DataTableProps<TData, TValue = unknown> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  withPagination?: boolean;
  expandedRowContent?: (
    row: Row<TData>,
    getCommonPinningStyles: (column: Column<any>) => React.CSSProperties,
  ) => React.ReactNode;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  fixedHeader?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  onPaginationChange?: (pagination: PaginationStateTable) => void;
} & Partial<Omit<TableOptions<TData>, 'columns' | 'data' | 'onPaginationChange'>>;

export type DataTableAsyncProps<TData, TValue = unknown> = DataTableProps<TData, TValue> & {
  dispatchPagination: (pagination: PaginationAction) => void;
  paginationConfig: PaginationState;
};

export function DataTableAsync<TData, TValue>({
  dispatchPagination,
  paginationConfig,
  ...props
}: DataTableAsyncProps<TData, TValue>) {
  return (
    <DataTable<TData, TValue>
      {...props}
      state={{
        ...props.state,
        pagination: {
          pageIndex: paginationConfig.current - 1,
          pageSize: paginationConfig.pageSize,
        },
      }}
      pageCount={paginationConfig.lastPage}
      withPagination
      onPaginationChange={newPagination => {
        props.onPaginationChange?.(newPagination);
        dispatchPagination({
          payload: newPagination.pageSize,
          type: 'SET_PER_PAGE',
        });
        dispatchPagination({
          payload: newPagination.pageIndex + 1,
          type: 'SET_CURRENT',
        });
      }}
    />
  );
}

export function DataTable<TData, TValue>({
  columns,
  data,
  withPagination,
  expandedRowContent,
  getRowCanExpand,
  fixedHeader,
  onSortingChange,
  isLoading,
  onExpandedChange,
  isFetching,
  ...props
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    ...props,
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onExpandedChange: onExpandedChange ?? setExpanded,
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand,
    onPaginationChange: p => {
      const pagination = props.state?.pagination;
      if (typeof p === 'function') {
        const newP = p(pagination);
        if (newP.pageSize !== pagination.pageSize) {
          if (props.onPaginationChange) props.onPaginationChange({ ...newP, pageIndex: 1 });
          return;
        }
        if (props.onPaginationChange) props.onPaginationChange({ ...newP });
      }
    },
    onSortingChange: sort => {
      if (props.onPaginationChange) props.onPaginationChange({ ...props.state?.pagination, pageIndex: 1 });
      setSorting(sort);
      if (onSortingChange) onSortingChange(sort);
    },
    getGroupedRowModel: getGroupedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    pageCount: props.pageCount,
    state: {
      sorting,
      columnVisibility,
      ...props.state,
      expanded: props?.state?.expanded ?? expanded,
    },
    defaultColumn: {
      enableSorting: false,
      ...props.defaultColumn,
    },
  });

  const getCommonPinningStyles = useMemo(
    () =>
      (column: Column<any>, isHeader?: boolean): React.CSSProperties => {
        const isPinned = column.getIsPinned() || (column.id === 'actions' ? 'right' : '');
        if (!isPinned) return {};
        const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
        const isFirstRightPinnedColumn =
          (isPinned === 'right' && column.getIsFirstColumn('right')) || column.id === 'actions';

        const styles: any = {};

        if (isPinned) styles.background = 'white';

        return {
          ...styles,
          boxShadow: isLastLeftPinnedColumn
            ? '-4px 0 4px -4px gray inset'
            : isFirstRightPinnedColumn
              ? '4px 0 4px -4px gray inset'
              : undefined,
          left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
          right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
          opacity: isPinned ? 0.95 : 1,
          position: isPinned ? 'sticky' : 'relative',
          width: 'auto',
          zIndex: isPinned ? (isHeader ? 20 : 10) : 0,
        };
      },
    [],
  );

  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden rounded-lg border">
      <Table className="h-full w-full overflow-auto">
        <TableHeader className={cn(fixedHeader ? 'margin-0 sticky top-0 z-20 bg-gray-200' : '')}>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead
                    key={header.id}
                    style={{ ...getCommonPinningStyles(header.column, true) }}
                    colSpan={header.colSpan}
                    className={cn(
                      'border-r border-t border-gray-200',
                      header.column.getCanSort() ? 'cursor-pointer' : '',
                    )}
                    onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                  >
                    <div className="flex items-center gap-2 whitespace-nowrap">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            typeof header.column.columnDef.header === `string`
                              ? t(header.column.columnDef.header)
                              : header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() ? (
                        header.column.getNextSortingOrder() === 'asc' ? (
                          <ChevronsUpDown className="h-4 w-4 min-w-4 max-w-4" />
                        ) : header.column.getNextSortingOrder() === 'desc' ? (
                          <ChevronUp className="h-4 w-4 min-w-4 max-w-4" />
                        ) : (
                          <ChevronDown className="h-4 w-4 min-w-4 max-w-4" />
                        )
                      ) : null}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <>
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn(row.getCanExpand() ? 'bg-gray-100/50' : 'white')}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      style={{ ...getCommonPinningStyles(cell.column) }}
                      className={cn('border-r border-gray-200', cell.column.columnDef.meta?.body?.cell?.className)}
                    >
                      {cell.getIsAggregated()
                        ? flexRender(
                            cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                            cell.getContext(),
                          )
                        : flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
                {row.getIsExpanded() && expandedRowContent && expandedRowContent(row, getCommonPinningStyles)}
              </>
            ))
          ) : (
            <TableRow>
              <TableCell className="h-24 w-full p-0 text-center text-gray-300" colSpan={columns.length}>
                <Loading isLoading={isLoading} className="flex h-full w-full items-center justify-center">
                  {t('noResults')}
                </Loading>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isFetching ? (
        <div className="flex w-full items-center gap-2 bg-primary p-2 text-sm text-white">
          <Loader className="h-4 w-4 min-w-6" color="white" />
          <span>{t('loading-new-data')}</span>
        </div>
      ) : null}
      <div className="my-2 w-full">{withPagination ? <DataTablePagination table={table} /> : null}</div>
    </div>
  );
}
