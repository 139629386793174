import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

import { Row, Spin, Form as AntForm, Col, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  CONTRACT_PURCHASE_TYPE_DAP,
  CONTRACT_PURCHASE_TYPE_FIXAR,
  CONTRACT_PURCHASE_TYPE_FIXO,
} from "configs/constants";
import { useCache } from "hooks/cache";
import { useGetContractGroupTypes } from "hooks/fetch/useGetContractGroupTypes";
import { Converter } from "utils/Converter";

import type { DTOContractTypeField } from "../../../../types/Contract/DTOContractTypeField";
import { Input, Button, FormItem, Select } from "../../../../components";
import { CustomFormContainer } from "../../../../components/CustomFormContainer";
import { useContractType } from "../../../../hooks/fetch/useContractType";
import { ContractTypeField } from "../../components/ContractTypeField";
import { useFormController } from "../controllers/useFormController";
import { FieldsDragDropWrapper, SFormButtons } from "./styles";
import { FormProps } from "./types";
import ApiSelect from "components/ApiSelect";

export const Form = ({
  isEditing,
  closeForm,
  contractId,
  refresh,
}: FormProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const cache = useCache();
  const {
    isLoading,
    isMain,
    handleEdit,
    handleRegister,
    getEditedContractData,
    handleChangeIsMainContract,
    searchRequest,
    fields,
    addField,
    handleChangeField,
    handleChangeSelectField,
    removeCustomField,
    addNewFieldToTable,
    removeFieldFromTable,
    isStoring,
    searchRequestValidators,
    handleChangeVerifyBeforeSign,
    isVerifyBeforeSignChecked,
    handleChangeVerifyAfterSign,
    isVerifyAfterSignChecked,
    afterUsersThatAlreadyCanValidate,
    beforeUsersThatAlreadyCanValidate,
    isFixation,
    handleChangeIsFixation,
    updateFields,
    editedContractType,
    setHideAllowNotSigned,
    hideAllowNotSigned,
    contractTypeData,
  } = useFormController({
    closeForm,
    refresh,
    form,
    contractId,
  });
  const { getFieldTypesOptionsNoPagination } = useContractType();
  const { getContractGroupTypes } = useGetContractGroupTypes();

  const [fieldTypes, setFieldTypes] = useState([]);

  const onDragEnd = ({ destination, source }: any) => {
    if (
      !destination ||
      (source.index === destination.index &&
        source.droppableId === destination.droppableId)
    )
      return;

    const field = fields[source.index];
    const newFields = [...fields];
    newFields.splice(source.index, 1);

    const previousFieldRoutingOrder =
      destination.index >= 1 ? newFields[destination.index - 1].order : 1;

    newFields.splice(destination.index, 0, {
      ...field,
      order: previousFieldRoutingOrder,
    });

    updateFields(newFields);
  };

  const handleGetContractGroupTypes = async (search: string, page: number) => {
    const response = await getContractGroupTypes({ params: { page, search } });
    return {
      ...response,
      data:
        response.data?.map((group: any) =>
          Converter.selectOptionArray(group, "id", "id", "name"),
        ) ?? [],
    };
  };

  const updateField = (updatedField: DTOContractTypeField) => {
    const newFields = fields.map((field) => {
      if (field.id === updatedField.id) {
        return updatedField;
      }
      return field;
    });
    updateFields(newFields);
  };

  useEffect(() => {
    if (contractId) getEditedContractData();

    const getFieldTypes = async () => {
      const response = await getFieldTypesOptionsNoPagination();
      setFieldTypes(response);
    };

    getFieldTypes();
  }, [contractId]);

  return (
    <CustomFormContainer>
      <Spin spinning={isLoading}>
        <h2>{t("pages.edocuments.contracttypes.form.title")}</h2>

        <AntForm form={form}>
          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="name"
                label={t("pages.edocuments.contracttypes.form.typeName")}
                rules={[{ required: true }]}
              >
                <Input maxLength={255} />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="is_parent"
                label={t("pages.edocuments.contracttypes.form.isMain")}
                rules={[{ required: true }]}
              >
                <Radio.Group
                  buttonStyle="solid"
                  onChange={handleChangeIsMainContract}
                >
                  <Radio.Button value>
                    {t("pages.edocuments.contracttypes.form.yes")}
                  </Radio.Button>
                  <Radio.Button value={false}>
                    {t("pages.edocuments.contracttypes.form.no")}
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          {isMain && (
            <>
              <Row gutter={26}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    name="allow_not_issued_dependency"
                    label={t(
                      "pages.edocuments.contracttypes.form.allowRelatedDocumentWithoutEmission",
                    )}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(evt) => {
                        setHideAllowNotSigned(evt.target.value);
                        if (evt.target.value)
                          form.setFieldsValue({
                            allow_not_signed_dependency: false,
                          });
                      }}
                    >
                      <Radio.Button value>{t("yes")}</Radio.Button>
                      <Radio.Button value={false}>{t("no")}</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              {!hideAllowNotSigned && (
                <Row gutter={26}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <FormItem
                      name="allow_not_signed_dependency"
                      label={t(
                        "pages.edocuments.contracttypes.form.allowRelatedDocumentWithoutSignature",
                      )}
                      rules={[{ required: true }]}
                    >
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value>{t("yes")}</Radio.Button>
                        <Radio.Button value={false}>{t("no")}</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
              )}
              <Row gutter={26}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    name="doc_type"
                    label={t("pages.edocuments.contracttypes.form.docType")}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="CPF">
                        {t("pages.edocuments.contracttypes.form.pf")}
                      </Radio.Button>
                      <Radio.Button value="CNPJ">
                        {t("pages.edocuments.contracttypes.form.pj")}
                      </Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={26}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    name="purchase_type_id"
                    label={t(
                      "pages.edocuments.contracttypes.form.purchaseType",
                    )}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={CONTRACT_PURCHASE_TYPE_FIXAR}>
                        {t("pages.edocuments.contracttypes.form.toFix")}
                      </Radio.Button>
                      <Radio.Button value={CONTRACT_PURCHASE_TYPE_FIXO}>
                        {t("pages.edocuments.contracttypes.form.fixed")}
                      </Radio.Button>
                      <Radio.Button value={CONTRACT_PURCHASE_TYPE_DAP}>
                        DAP
                      </Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={26}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    name="products"
                    label={t("pages.edocuments.contracttypes.form.product")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      mode="multiple"
                      listHeight={300}
                      options={cache.getCreditOptions("product")}
                      showSearch
                    />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="contract_group_id"
                label={t(
                  "pages.edocuments.contracttypes.form.contract_group_id",
                )}
                rules={[{ required: true }]}
                permission="contract.group.index"
              >
                <ApiSelect
                  listHeight={300}
                  initialOptions={
                    contractTypeData?.group
                      ? [
                          Converter.selectOption(
                            contractTypeData.group,
                            "id",
                            "id",
                            "name",
                          ),
                        ]
                      : []
                  }
                  fetchOptions={handleGetContractGroupTypes}
                  showSearch
                />
              </FormItem>
            </Col>
          </Row>

          {isMain !== undefined && !isMain && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  name="parents"
                  label={t(
                    "pages.edocuments.contracttypes.form.mainContractType",
                  )}
                >
                  <ApiSelect
                    mode="multiple"
                    fetchOptions={searchRequest}
                    listHeight={300}
                    initialOptions={
                      editedContractType
                        ? editedContractType.parents.map((parent) => ({
                            label: parent.parent.name,
                            value: parent.parent.id,
                            key: parent.parent.id,
                          }))
                        : []
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          )}

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="verify_before_sign"
                label={t(
                  "pages.edocuments.contracttypes.form.emissionValidation",
                )}
              >
                <Radio.Group
                  buttonStyle="solid"
                  onChange={handleChangeVerifyBeforeSign}
                  value={isVerifyBeforeSignChecked}
                >
                  <Radio.Button value>
                    {t("pages.edocuments.contracttypes.form.yes")}
                  </Radio.Button>
                  <Radio.Button value={false}>
                    {t("pages.edocuments.contracttypes.form.no")}
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          {isVerifyBeforeSignChecked && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  name="beforeUsers"
                  label={t(
                    "pages.edocuments.contracttypes.form.emissionValidators",
                  )}
                  rules={[{ required: true }]}
                >
                  <ApiSelect
                    mode="multiple"
                    listHeight={300}
                    initialOptions={beforeUsersThatAlreadyCanValidate.map(
                      (user) => ({
                        label: user.name,
                        value: user.id,
                        key: user.id,
                      }),
                    )}
                    fetchOptions={searchRequestValidators}
                  />
                </FormItem>
              </Col>
            </Row>
          )}

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="verify_after_sign"
                label={t(
                  "pages.edocuments.contracttypes.form.signatureValidation",
                )}
              >
                <Radio.Group
                  buttonStyle="solid"
                  value={isVerifyAfterSignChecked}
                  onChange={handleChangeVerifyAfterSign}
                >
                  <Radio.Button value>
                    {t("pages.edocuments.contracttypes.form.yes")}
                  </Radio.Button>
                  <Radio.Button value={false}>
                    {t("pages.edocuments.contracttypes.form.no")}
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          {isVerifyAfterSignChecked && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  name="afterUsers"
                  label={t(
                    "pages.edocuments.contracttypes.form.signatureValidators",
                  )}
                  rules={[{ required: true }]}
                >
                  <ApiSelect
                    mode="multiple"
                    listHeight={300}
                    initialOptions={afterUsersThatAlreadyCanValidate.map(
                      (user) => ({
                        label: user.name,
                        value: user.id,
                        key: user.id,
                      }),
                    )}
                    fetchOptions={searchRequestValidators}
                  />
                </FormItem>
              </Col>
            </Row>
          )}

          {isMain !== undefined && !isMain && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  name="is_fixation"
                  label={t("pages.edocuments.contracttypes.form.isFixation")}
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    value={isFixation}
                    onChange={handleChangeIsFixation}
                  >
                    <Radio.Button value>
                      {t("pages.edocuments.contracttypes.form.yes")}
                    </Radio.Button>
                    <Radio.Button value={false}>
                      {t("pages.edocuments.contracttypes.form.no")}
                    </Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          )}

          {isMain && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  name="is_export"
                  label={t("pages.edocuments.contracttypes.form.endExport")}
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>
                      {t("pages.edocuments.contracttypes.form.yes")}
                    </Radio.Button>
                    <Radio.Button value={false}>
                      {t("pages.edocuments.contracttypes.form.no")}
                    </Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          )}

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="can_upload"
                label={t("pages.edocuments.contracttypes.form.canUpload")}
                rules={[{ required: true }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value>{t("yes")}</Radio.Button>
                  <Radio.Button value={false}>{t("no")}</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="can_template"
                label={t("pages.edocuments.contracttypes.form.canTemplate")}
                rules={[{ required: true }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value>{t("yes")}</Radio.Button>
                  <Radio.Button value={false}>{t("no")}</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          <h2
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Campos
            <Button status="primary" icon={<FaPlus />} onClick={addField}>
              {t("pages.edocuments.templates.form.buttonAddField")}
            </Button>
          </h2>

          <FieldsDragDropWrapper style={{ position: "relative" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="contractTypeFields">
                {(provided) => (
                  <>
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      {fields.map((field, index) => (
                        <ContractTypeField
                          updateField={updateField}
                          addNewFieldToTable={addNewFieldToTable}
                          key={field.id}
                          removeFieldFromTable={removeFieldFromTable}
                          field={field}
                          fieldTypes={fieldTypes}
                          handleChangeField={handleChangeField}
                          handleChangeSelectField={handleChangeSelectField}
                          removeField={removeCustomField}
                          index={index}
                        />
                      ))}
                    </div>
                    {provided.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </FieldsDragDropWrapper>

          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={closeForm}
              disabled={isLoading}
            >
              {t("pages.edocuments.contracttypes.buttonCancel")}
            </Button>
            {isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={() => handleEdit(contractId, form.getFieldsValue())}
                loading={isLoading || isStoring}
              >
                {t("pages.edocuments.contracttypes.buttonEdit")}
              </Button>
            )}
            {!isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={() => handleRegister(form.getFieldsValue())}
                loading={isLoading || isStoring}
              >
                {t("pages.edocuments.contracttypes.buttonRegister")}
              </Button>
            )}
          </SFormButtons>
        </AntForm>
      </Spin>
    </CustomFormContainer>
  );
};
