import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { message, TablePaginationConfig } from "antd";
import { Badge, IconWithTooltip } from "components";
import PermissionedComponent from "components/PermissionedComponent";
import { TableData } from "compositions/TableDataAsync";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import { TableTranslation } from "compositions/TableDataAsync/types";
import { useCreditRequest } from "hooks/fetch/useCreditRequest";
import { useIntegrationGroups } from "hooks/fetch/useIntegrationGroups";
import { useTheme } from "styled-components";
import { Formatter } from "utils/Formatter";

import {
  IntegrationDataProvider,
  IntegrationDataProviderStatus,
} from "../../../../@types/data/IntegrationDataProvider";
import { columns } from "./columns";
import { ExpandedRowProps } from "./types";

export const ExpandedRow = ({
  groupId,
  setDataAttachment,
  setModalFileViewVisible,
  setSourceIntegration,
  setListErrorsIntegration,
  setModalErrorIntegrationVisible,
}: ExpandedRowProps) => {
  const controller = useTable();
  const { t } = useTranslation();
  const theme = useTheme();
  const { getGroupIntegrations, isFetchingGroupIntegrations } =
    useIntegrationGroups();
  const { forceIntegration } = useCreditRequest();

  const [filters, _] = useState({});

  const getActions = () => ({
    render: (_1: number, integrationData: IntegrationDataProvider) => (
      <>
        {integrationData?.attachments ? (
          <IconWithTooltip
            action="detail"
            title={t("pages.integration.dataProviders.table.icons.view")}
            onClick={() => {
              setDataAttachment(integrationData.attachments);
              setModalFileViewVisible(true);
              setSourceIntegration({
                source_name: integrationData.group.name,
                source_type: integrationData.group.origin,
              });
            }}
          />
        ) : (
          <IconWithTooltip action="fake" />
        )}
        {integrationData?.status === IntegrationDataProviderStatus.FAILED ? (
          // <Can I="" a="">
          <IconWithTooltip
            action="refresh"
            title={t("pages.integration.dataProviders.table.icons.refresh")}
            onClick={async () => {
              message.loading(
                t("pages.integrations.messages.forcingIntegration"),
                3,
              );
              const response = await forceIntegration(
                integrationData.group?.route + integrationData.model?.model_id,
              );
              if (response) controller.refresh();
            }}
          />
        ) : (
          <IconWithTooltip action="fake" />
        )}
        {
          integrationData?.errors?.length ? (
            // <Can I="" a="">
            <Badge
              count={integrationData.errors.length}
              size="small"
              offset={[-8, 5]}
            >
              <IconWithTooltip
                action="error"
                title={t("pages.integration.dataProviders.table.icons.errors")}
                onClick={() => {
                  setListErrorsIntegration(integrationData.errors);
                  setModalErrorIntegrationVisible(true);
                }}
              />
            </Badge>
          ) : (
            <IconWithTooltip action="fake" />
          )
          // </Can>
        }
      </>
    ),
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case "processing":
        return theme.colors.warning;
      case "processed":
        return theme.colors.success;
      case "failed":
        return theme.colors.danger;
      default:
        return theme.colors.text;
    }
  };

  const searchRequest = async (
    pagination: TablePaginationConfig,
    requestFilters: any,
    sorter: any,
  ) => {
    const response = await getGroupIntegrations(groupId, {
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...requestFilters,
      },
    });
    if (!response) return { data: [], total: 0 };

    return { data: response.data, total: response.total };
  };

  return (
    <PermissionedComponent permission="worker.importexport.index">
      <TableData
        rowKey="id"
        controller={controller}
        searchRequest={searchRequest}
        searchFilters={filters}
        width="100%"
        columns={columns.map((column: any) => {
          let columnRender: any = {
            ...column,
            title: t(column.title),
          };

          if (column.dataIndex === "created_at") {
            columnRender = {
              ...columnRender,
              render: (date: string) => Formatter.datetime(date),
            };
          }

          if (column.dataIndex === "source_name") {
            columnRender = {
              ...columnRender,
              render: (source: string) => source?.toString().toUpperCase(),
            };
          }

          if (column.dataIndex === "user") {
            columnRender = {
              ...columnRender,
              render: (user: any) => user.name?.toString().toUpperCase(),
            };
          }

          if (column.dataIndex === "status") {
            columnRender = {
              ...columnRender,
              render: (status: string) => (
                <strong
                  style={{
                    color: getStatusColor(status),
                    textTransform: "uppercase",
                  }}
                >
                  {t(`pages.integration.dataProviders.status.${status}`)}
                </strong>
              ),
            };
          }

          return columnRender;
        })}
        loading={isFetchingGroupIntegrations}
        tableConfig={{
          getActions,
        }}
        translation={
          {
            pagination: { totalOfRegisters: t("table.totalOfRegisters") },
            filterDropdown: {
              filterActive: "Ativo",
              filterInactive: "Inativo",
            },
            filterConfirm: "Buscar",
            filterReset: "Limpar",
          } as TableTranslation
        }
        pagination={{ position: ["bottomCenter"] }}
      />
    </PermissionedComponent>
  );
};
