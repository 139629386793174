import { useMemo } from 'react';

import { CreditRequestModalityId, SUBDIVISION_COFFEE_ID, SUBDIVISION_COTTON_ID } from 'configs/constants';
import { SubDivisions } from 'pages/CreditRequest/store/slices/credit-request-slices/division.slice';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const usePrefinModalityRules = () => {
  const modalityId = useCreditRequestStore(state => state.modalityId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);

  const getShouldShowLimitInputs = (modalityId: string, subdivisonId: SubDivisions) =>
    (modalityId === CreditRequestModalityId.CASH_BARTER &&
      subdivisonId !== SUBDIVISION_COFFEE_ID &&
      subdivisonId !== SUBDIVISION_COTTON_ID) ||
    (subdivisonId !== SUBDIVISION_COFFEE_ID && subdivisonId !== SUBDIVISION_COTTON_ID);
  const getShouldShowLimitBarterInputs = (modalityId: string, subdivisonId: SubDivisions) =>
    (modalityId === CreditRequestModalityId.BARTER || modalityId === CreditRequestModalityId.CASH_BARTER) &&
    (subdivisonId === SUBDIVISION_COFFEE_ID || subdivisonId === SUBDIVISION_COTTON_ID);
  const getShouldShowLimitCashInputs = (modalityId: string, subdivisonId: SubDivisions) =>
    (modalityId === CreditRequestModalityId.CASH || modalityId === CreditRequestModalityId.CASH_BARTER) &&
    (subdivisonId === SUBDIVISION_COFFEE_ID || subdivisonId === SUBDIVISION_COTTON_ID);

  const shouldShowLimitInputs = useMemo(
    () => getShouldShowLimitInputs(modalityId ?? '', selectedSubDivisionId),
    [modalityId, selectedSubDivisionId],
  );

  const shouldShowLimitBarterInputs = useMemo(
    () => getShouldShowLimitBarterInputs(modalityId ?? '', selectedSubDivisionId),
    [modalityId, selectedSubDivisionId],
  );

  const shouldShowLimitCashInputs = useMemo(
    () => getShouldShowLimitCashInputs(modalityId ?? '', selectedSubDivisionId),
    [modalityId, selectedSubDivisionId],
  );

  return {
    shouldShowLimitInputs,
    shouldShowLimitBarterInputs,
    shouldShowLimitCashInputs,
    getShouldShowLimitInputs,
    getShouldShowLimitBarterInputs,
    getShouldShowLimitCashInputs,
  };
};
