import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { useAbility } from 'hooks/ability';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import { TeamStatusSlaFormData } from 'types/Admin/TeamStatus/TeamStatusSlaFormData';

export const useUpdateTeamStatusSlaMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();
  const ability = useAbility();

  return useMutation({
    mutationKey: ['updateTeamStatusSla'],
    mutationFn: async (teamStatusSlaData: TeamStatusSlaFormData) => {
      if (!ability.can('credit.request.teamstatussla.update', ''))
        return alert({ title: t('no-permission'), type: 'error' });

      return api.put(`credit-request/team-status-sla/${teamStatusSlaData.id}`, teamStatusSlaData);
    },
    onError: err => {
      exceptionHandler(err);
    },
  });
};
