import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { CreditRequestValidator } from 'utils/CreditRequestValidator';
import { Formatter } from 'utils/Formatter';

export const JsonDataQuantityKgInput = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_quantity_kg')}
      name="json_data.quantity_kg"
      rules={[
        { required: true },
        {
          validator: async (_, value) => {
            const formValues = creditForm.getFieldsValue();

            const quantityContract = Number(formValues['json_data.quantity_contract']);
            const quantityReceived = Number(formValues['json_data.quantity_received']);
            const quantityAvailable = Number(formValues['json_data.quantity_delivery']);

            if (CreditRequestValidator.volume(value, quantityContract, quantityReceived, quantityAvailable)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(t('pages.credit-request.messages.contract_volume_exceeded')));
          },
        },
        {
          validator: async (_, value) => {
            if (value <= 0) {
              return Promise.reject(new Error(t('pages.credit-request.messages.contract_volume_0')));
            }
            return Promise.resolve();
          },
        },
        {
          min: 1,
          type: 'number',
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.integer(value)}
        parser={(value: string) => Formatter.integerParser(value)}
        disabled={pageStatus.viewing}
      />
    </FormItem>
  );
};
