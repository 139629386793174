import { TablePaginationConfig } from "antd/lib/table";

import { TableTranslation } from "./types";

export const defaultTableWidth = "100px";

export const defaultTranslation = {
  filterConfirm: "Confirm",
  filterReset: "Clear",
  emptyText: "Empty",
  filterDropdown: {
    placeholderPrefix: "",
    resetButton: "Clear",
    searchButton: "Search",
    filterActive: "Active",
    filterInactive: "Inactive",
  },
  pagination: {
    totalOfRegisters: "Total of registers",
  },
} as TableTranslation;

export const defaultPagination = {
  responsive: false,
  current: 1,
  total: 1,
  showSizeChanger: true,
  defaultPageSize: 10,
  defaultCurrent: 1,
  pageSizeOptions: ["5", "10", "20", "50", "100"],
  pageSize: 10,
} as TablePaginationConfig;
