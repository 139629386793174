import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Modal, Spin } from 'antd';
import Button from 'components/Button';
import { useTable } from 'compositions/TableDataAsync/hooks/useTable';
import { useAbility } from 'hooks/ability';

import { useReportExport } from './hooks/useReportExport';
import { NewReportModal } from './NewReportModal';
import { ReportTable } from './ReportTable';
import { defaultTranslation } from './translations';
import { ModalProps } from './types';

export const ReportModal = ({
  afterClose,
  visibility,
  withDateFilter = false,
  extraInputs,
  listReportApi,
  generateReportApi,
  generateReportPermission,
  downloadReportApi,
  reportApiId = '',
  width = 800,
  translation: customizedTranslation = defaultTranslation,
  rangeLimit,
  customFields,
  ...modalProps
}: ModalProps & {
  generateReportPermission?: string;
}) => {
  const tableController = useTable();
  const ability = useAbility();

  const {
    isExportModalLoading,
    handleGenerateReport,
    reportsProcessingList,
    handleDownloadReport,
    fetchReportsToExport,
    isFetchingReports,
    reports,
    stopTimeout,
    handleReportsTableChange,
  } = useReportExport({
    listReportApi,
    visibility,
    reportApiId,
    generateReportApi,
    downloadReportApi,
    controller: tableController,
  });

  const [visibleDate, setVisibleDate] = useState(false);

  const translation = { ...defaultTranslation, ...customizedTranslation };

  const canGenerateReport = !generateReportPermission || ability.can(generateReportPermission, '');

  useEffect(() => {
    tableController.setData(reports);
  }, [reports]);

  return (
    visibility && (
      <>
        {/** Modal export credit-requests */}
        <Modal
          {...modalProps}
          destroyOnClose
          visible={visibility}
          className="modal-with-custom-footer"
          style={{ top: 20 }}
          width={width}
          closable={!isExportModalLoading}
          closeIcon={
            <MdClose
              onClick={() => {
                afterClose();
                stopTimeout();
              }}
            />
          }
          footer={
            <>
              <div className="buttons">
                <Button
                  status="secondary"
                  loading={isExportModalLoading}
                  onClick={() => {
                    afterClose();
                    stopTimeout();
                  }}
                >
                  {translation.closeModal}
                </Button>
              </div>
            </>
          }
        >
          <Spin spinning={isExportModalLoading}>
            <Button
              status="primary"
              loading={isFetchingReports}
              disabled={!canGenerateReport}
              onClick={() => {
                if (withDateFilter || extraInputs || customFields) {
                  setVisibleDate(true);
                } else {
                  handleGenerateReport(reportApiId);
                }
              }}
            >
              <FaPlus /> {translation.newReport}
            </Button>
            <ReportTable
              fetchReportsToExport={fetchReportsToExport}
              handleChangePage={handleReportsTableChange}
              translation={translation}
              controller={tableController}
              reportsProcessingList={reportsProcessingList}
              handleDownloadReport={handleDownloadReport}
              extraInputs={extraInputs}
              isFetchingReports={isFetchingReports}
            />
          </Spin>
        </Modal>
        <NewReportModal
          visibleDate={visibleDate}
          translation={translation}
          setVisibleDate={setVisibleDate}
          reportApiId={reportApiId}
          handleGenerateReport={handleGenerateReport}
          withDateFilter={withDateFilter}
          extraInputs={!!extraInputs}
          rangeLimit={rangeLimit}
          customFields={customFields}
        />
      </>
    )
  );
};
