import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID, OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';

import CREDIT_FORM_INITIAL_VALUE from '../components/templates/DetailView/formData';
import { useCreditRequestStore } from '../store/use-credit-request-store';

export const useFormatDataToApi = () => {
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const selectedPersonId = useCreditRequestStore(state => state.selectedPersonId);
  const isPhysicalPerson = useCreditRequestStore(state => state.isPhysicalPerson);

  function formatDataToApi() {
    let dataFormattedToApi: any = CREDIT_FORM_INITIAL_VALUE.credit.data;
    const dataForm: any = creditForm.getFieldsValue();

    Object.keys(dataForm).forEach((field: string) => {
      const splitted = field.split('.');
      if (splitted.length === 1) {
        dataFormattedToApi[field] = dataForm[field];
      }
    });

    dataFormattedToApi.json_data = {
      az_participant: dataForm['json_data.az_participant'],
      contract_number: dataForm['json_data.contract_number'],
      contract_type_id: dataForm['json_data.contract_type_id'],
      currency_type_id: dataForm['json_data.currency_type_id'],
      expected_payment_at: dataForm['json_data.expected_payment_at'],
      fethab: dataForm['json_data.fethab'],
      fethab_quotation: dataForm['json_data.fethab_quotation'],
      freight: dataForm['json_data.freight'],
      guarantor_code_erp: dataForm['json_data.guarantor_code_erp'],
      guarantor_code_sap: dataForm['json_data.guarantor_code_sap'],
      guarantor_doc_number: dataForm['json_data.guarantor_doc_number'],
      guarantor_name: dataForm['json_data.guarantor_name'],
      guarantor_person_type: dataForm['json_data.guarantor_person_type'],
      incoterm_id: dataForm['json_data.incoterm_id'],
      interest_rate: dataForm['json_data.interest_rate'],
      is_exportation: dataForm['json_data.is_exportation'],
      modality_id: dataForm['json_data.modality_id'],
      net_amount: dataForm['json_data.net_amount'],
      gross_value: dataForm['json_data.gross_value'],
      opting_inss: dataForm['json_data.opting_inss'],
      payment_term: dataForm['json_data.payment_term'],
      price: dataForm['json_data.price'],
      quantity: dataForm['json_data.quantity'],
      quantity_kg: dataForm['json_data.quantity_kg'],
      reason_id: dataForm['json_data.reason_id'],
      royalties_monsanto: dataForm['json_data.royalties_monsanto'],
      post_maturity_value: dataForm['json_data.post_maturity_value'],
      step: dataForm['json_data.step'],
      store_location: dataForm['json_data.store_location'],
      taxes: dataForm['json_data.taxes'],
      total_steps: dataForm['json_data.total_steps'],
      warehouse_city_id: dataForm['json_data.warehouse_city_id'],
      warehouse_neighborhood: dataForm['json_data.warehouse_neighborhood'],
      warehouse_shipping_capacity: dataForm['json_data.warehouse_shipping_capacity'],
      warehouse_state_id: dataForm['json_data.warehouse_state_id'],
      warehouse_street: dataForm['json_data.warehouse_street'],
      warranty_register_number: dataForm['json_data.warranty_register_number'],
      weight_type_id: dataForm['json_data.weight_type_id'],
      withdrawal_period: dataForm['json_data.withdrawal_period'],
      warranty_pledge_areas: dataForm['json_data.warranty_pledge_areas'],
      warranty_types: dataForm['json_data.warranty_types'],
      quantity_contract: dataForm['json_data.quantity_contract'],
      quantity_delivery: dataForm['json_data.quantity_delivery'],
      quantity_received: dataForm['json_data.quantity_received'],
      code_erp: dataForm['requester_person.json_data.code_erp'],
      renovation: dataForm['json_data.renovation'],
    };

    if (selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
      dataFormattedToApi.json_data = {
        ...dataFormattedToApi.json_data,
        requested_amount: dataForm['json_data.gross_value'],
        quantity_received: dataForm['json_data.quantity_received'],
        quantity_contract: dataForm['json_data.quantity_contract'],
      };
      dataFormattedToApi = {
        ...dataFormattedToApi,
        requested_amount: dataForm['json_data.gross_value'],
        quantity_received: dataForm['json_data.quantity_received'],
        quantity_contract: dataForm['json_data.quantity_contract'],
      };
    }

    if (selectedOperationId === OPERATION_PREPAY_ID || selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
      const document = dataForm['json_data.contract.requester_person_document'];
      dataFormattedToApi.json_data = {
        ...dataFormattedToApi.json_data,
        contract: {
          requester_person_name: dataForm['requester_person.name'],
          requester_person_cpf: document?.length === 11 ? document : '',
          requester_person_cnpj: document?.length > 11 ? document : '',
          requester_person_code_erp: dataForm['json_data.contract.requester_person_code_erp'],
        },
        datasul_contract: dataForm['json_data.datasul_contract'],
      };

      dataFormattedToApi.requester_person_id = dataForm.requester_person_id;
    } else {
      let requesterPersonJsonData: any = {
        code_erp: dataForm['requester_person.json_data.code_erp'],
      };

      if (isPhysicalPerson === true) {
        requesterPersonJsonData = {
          ...requesterPersonJsonData,
          cpf: dataForm['requester_person.cpf'],
        };
      }

      if (isPhysicalPerson === false) {
        requesterPersonJsonData = {
          ...requesterPersonJsonData,
          cnpj: dataForm['requester_person.cnpj'],
        };
      }
      // eslint-disable-next-line prefer-destructuring
      dataFormattedToApi.requester_person_id = selectedPersonId || dataForm.requester_person_id;
      dataFormattedToApi.requester_person = {
        id: selectedPersonId || dataForm.requester_person_id,
        json_data: requesterPersonJsonData,
      };
    }

    return dataFormattedToApi;
  }

  return {
    formatDataToApi,
  };
};
