import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { Tooltip } from 'antd';
import { ShouldRender } from 'components/ShouldRender';
import appConfigs from 'configs/app';
import {
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_PREPAY_ID,
  TEAM_STATUS_NOT_SYNCED_ID,
  TEAM_STATUS_NOT_SYNCED_SLUG,
} from 'configs/constants';
import moment from 'moment';
import { TimeInStatusLabel } from 'pages/CreditRequest/components/atoms/time-in-status-label';
import { Formatter } from 'utils/Formatter';

import { CardHistoryDataProps } from './types';

export const CardHistoryData = ({ data, setIsFooterVisible, isFooterVisible }: CardHistoryDataProps) => {
  const { t } = useTranslation();

  const {
    team_status,
    last_history_change,
    created_at,
    updated_at,
    operation_id,
    contract_number,
    expected_payment_at,
    dif_day,
    dif_hour,
    dif_year,
    dif_month,
  } = data;

  const isSynced = useMemo(
    () =>
      team_status &&
      team_status.slug &&
      team_status.slug !== TEAM_STATUS_NOT_SYNCED_SLUG &&
      team_status.id !== TEAM_STATUS_NOT_SYNCED_ID,

    [team_status?.slug],
  );

  const { labelDate, formattedDate } = useMemo(
    () => ({
      labelDate: isSynced
        ? t('pages.credit-request.kanban.cards.updated_at')
        : t('pages.credit-request.kanban.cards.created_at'),
      formattedDate: moment(isSynced ? updated_at : created_at).format(appConfigs.formatDate),
    }),
    [created_at, isSynced, dif_day, dif_hour, dif_year, dif_month],
  );

  const { changedByUser, changedByUserTooltip } = useMemo(() => {
    const lastUserName = last_history_change.user?.name;
    return {
      changedByUserTooltip: lastUserName ?? '',
      changedByUser: lastUserName.length > 25 ? `${lastUserName.substr(0, 25)}...` : lastUserName.substr(0, 25),
    };
  }, [last_history_change?.user?.name]);

  const isPrepayOrDeliveredProductAdvance =
    operation_id === OPERATION_PREPAY_ID || operation_id === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID;

  return (
    <>
      <div>
        <ShouldRender condition={isPrepayOrDeliveredProductAdvance}>
          <>
            <div>
              {`${t('pages.credit-request.kanban.cards.contractNumber')}: ${contract_number ?? t('notProvided')}`}
            </div>
            <div>
              {`${t('pages.credit-request.kanban.cards.prevPayment')}: ${
                expected_payment_at ? Formatter.date(expected_payment_at) : t('notProvided')
              }`}
            </div>
            {/* <div>
                {`${t('pages.credit-request.kanban.cards.withdrawalPeriod')}: ${
                  withdrawal_period ? Formatter.date(withdrawal_period) : t('notProvided')
                }`}
              </div> */}
          </>
        </ShouldRender>
        <div>
          {`${labelDate} ${formattedDate} (`}
          <TimeInStatusLabel data={{ dif_year, dif_month, dif_day, dif_hour }} />
          &#41;
        </div>
        <div className="updated-by">
          {`${t('pages.credit-request.kanban.cards.updated_by')} `}
          <Tooltip title={changedByUserTooltip} placement="top" overlayClassName="kanban-card-tooltip">
            <strong className="updated-by-name">{changedByUser}</strong>
          </Tooltip>
        </div>
      </div>
      <button type="button" onClick={() => setIsFooterVisible(!isFooterVisible)}>
        {isFooterVisible ? <FaChevronUp /> : <FaChevronDown />}
      </button>
    </>
  );
};
