import React, { useState } from "react";
import ReactQuill from "react-quill";

import { Col, Form, Modal, Row, Select } from "antd";
import { Can } from "hooks/ability";
import type { ContractFixationOptionData } from "types/Contract/ContractFixationOptionData";
import { Latinise } from "utils/Latinise";

import { FormItem } from "../../../../components";
import { useContractContext } from "../../context/ContractContext";
import { NewRelatedDocumentModalProps } from "./types";
import ApiSelect from "components/ApiSelect";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

export const NewRelatedDocumentModal = ({
  isOpen,
  onClose,
  onOk,
}: NewRelatedDocumentModalProps) => {
  const [form] = Form.useForm();
  const { contract, childrenTypes, updateMainContractFixations } =
    useContractContext();

  const [comment, setComment] = useState("");
  const [shouldShowFixationSelect, setShouldShowFixationSelect] =
    useState(false);
  const [selectedFixation, setSelectedFixation] = useState<
    ContractFixationOptionData | undefined
  >();

  const handleChangeComment = (content: string) => setComment(content);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (!shouldShowFixationSelect) onOk(values);
      else
        onOk({
          ...values,
          quantity: selectedFixation?.quantity,
          price: selectedFixation?.price,
          tradeslip: selectedFixation?.tradeslip,
        });
      handleClose();
    });
  };

  const handleChangeContractType = (value: string, option: any) => {
    setShouldShowFixationSelect(!!option.is_fixation);
    if (!option.is_fixation) {
      form.setFieldsValue({ fixation_index: "" });
    }
  };

  const handleClose = () => {
    form.resetFields();
    setShouldShowFixationSelect(false);
    onClose();
  };

  return (
    isOpen && (
      <Modal
        visible={isOpen}
        destroyOnClose
        onCancel={handleClose}
        title={`Novo Documento Acessório do Contrato ${contract.number}`}
        width="60%"
        onOk={handleSubmit}
      >
        <Form form={form}>
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <FormItem
                label="Tipo do documento acessório"
                name="contract_type_id"
                rules={[{ required: true }]}
              >
                <Select
                  options={childrenTypes}
                  onChange={handleChangeContractType}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    Latinise.normalize(option?.label).indexOf(
                      Latinise.normalize(input),
                    ) >= 0
                  }
                />
              </FormItem>
            </Col>

            {contract.fixations_exists && shouldShowFixationSelect && (
              <Can I="contract.dependency.fixations">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label="Fixação do contrato"
                    name="fixation_index"
                    rules={[
                      {
                        required:
                          shouldShowFixationSelect && contract.fixations_exists,
                      },
                    ]}
                  >
                    <ApiSelect
                      fetchOptions={updateMainContractFixations}
                      showSearch
                      onChange={(_, option) =>
                        setSelectedFixation(
                          option as ContractFixationOptionData,
                        )
                      }
                      onClear={() => {
                        setSelectedFixation(undefined);
                      }}
                    />
                  </FormItem>
                </Col>
              </Can>
            )}

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div style={{ height: "200px" }}>
                <FormItem
                  label="Comentários"
                  name="create_comment"
                  rules={[{ required: false }]}
                  initialValue=""
                >
                  <ReactQuill
                    style={{ height: "100px" }}
                    value={comment ?? ""}
                    onChange={handleChangeComment}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                </FormItem>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  );
};
