import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const WarrantyRegisterNumberInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warranty_register_number')}
      name="json_data.warranty_register_number"
    >
      <Input
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
