import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus } from "react-icons/fa";

import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import { Form as AntForm } from "antd";
import { Breadcrumb, Button } from "components";
import { Can } from "hooks/ability";

import { IntegrationGroupData } from "../../../@types/data/IntegrationGroupData";
import { Form } from "./Form";
import { List } from "./List";
import { SContainer, SHeader, STitlePage } from "styles";

export enum IntegrationGroupSource {
  AGRODATA = "agrodata",
  SAP = "sap",
}

const { useForm } = AntForm;

export const Groups = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [visibilityForm, setVisibilityForm] = useState(false);

  const controller = useTable();

  const [groupToEdit, setGroupToEdit] = useState<IntegrationGroupData | null>(
    null,
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setGroupToEdit(undefined);
  };

  const handleSetGroupToEdit = (group: IntegrationGroupData) => {
    setVisibilityForm(true);
    setGroupToEdit(group);
  };

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.integration") },
          { title: t("breadcrumb.groups"), to: "/integrations/groups" },
        ]}
      />
      <SContainer>
        <SHeader>
          <STitlePage>{t("pages.integration.groups.title_page")}</STitlePage>

          <Can I="admin.integrations.groups.store" a="">
            <Button
              status="primary"
              icon={visibilityForm ? <FaMinus /> : <FaPlus />}
              onClick={handleDisplayForm}
            >
              {t("pages.integrations.group.buttonAdd")}
            </Button>
          </Can>
        </SHeader>

        <Form
          isVisible={visibilityForm}
          handleHideForm={handleHideForm}
          form={form}
          refetchTable={controller.refresh}
          groupToEdit={groupToEdit}
        />

        <List setGroupToEdit={handleSetGroupToEdit} controller={controller} />
      </SContainer>
    </>
  );
};

export default Groups;
