import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaMinus, FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useParams, Link } from "react-router-dom";

import {
  Badge,
  Col,
  Form,
  Spin,
  Radio,
  DatePicker,
  InputNumber,
  Space,
  Modal,
  message,
} from "antd";
import ptBR from "antd/es/date-picker/locale/pt_BR";
import { Store } from "antd/lib/form/interface";
import TextArea from "antd/lib/input/TextArea";
import { TablePaginationConfig, TableProps } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { AttachmentInput } from "compositions/AttachmentInput";
import { useDownload } from "hooks/helpers/useDownload";
import moment from "moment";
import { formatValuesToFormData } from "services/form";
import { AttachmentInputTranslation } from "types/DTOReports";

import PaginationData from "../../../@types/data/PaginationData";
import RegistrationData from "../../../@types/data/RegistrationData";
import RegistrationSituationData from "../../../@types/data/RegistrationSituationData";
import {
  ResponseList,
  ResponseListData,
} from "../../../@types/data/ResponseData";
import DTOErrorReponse from "../../../@types/dtos/DTOErrorReponse";
import DTOMonitoringReports from "../../../@types/dtos/monitoring/DTOMonitoringReports";
import DTORegistrationModel from "../../../@types/dtos/monitoring/DTORegistrationModel";
import DTOSurvey from "../../../@types/dtos/monitoring/DTOSurvey";
import {
  Breadcrumb,
  Button,
  Input,
  ModalStatus,
  FormItem,
  Row,
  Select,
  IconWithTooltip,
  MaskedInput,
} from "../../../components";
import { TableData } from "../../../compositions";
import appConfigs from "../../../configs/app";
import { Can, useAbility } from "../../../hooks/ability";
import { useAuth } from "../../../hooks/auth";
import { ItemsType, usePage } from "../../../hooks/page";
import documentApi from "../../../services/api/document";
import monitoringApi from "../../../services/api/monitoring";
import { downloadHandler } from "../../../services/download";
import { Coordinate } from "../../../utils/Coordinate";
import { Formatter } from "../../../utils/Formatter";
import columns from "./columns";
import {
  SContainer,
  SHeader,
  STitlePage,
  SFilterContainer,
  SFormContainer,
  SFormButtons,
} from "./styles";
import { MonitoringDataTable } from "./types";

export type PageParams = "cpr" | "id";

const Registration: React.FC = () => {
  const { t, i18n } = useTranslation();
  const attachmentInputTranslation = {
    destroyIconText: t(
      "pages.monitoring.surveyrequest.form.attachments.destroy.icon",
    ),
    downloadIconText: t(
      "pages.monitoring.surveyrequest.form.attachments.download.icon",
    ),
    dragAndDropUploadText: t(
      "pages.monitoring.surveyrequest.form.attachments.drag",
    ),
    existingAttachmentsText: t(
      "pages.monitoring.surveyrequest.form.attachments.existing",
    ),
    onUploadError: t(
      "pages.monitoring.surveyrequest.form.attachments.upload.error",
    ),
    onUploadSuccess: t(
      "pages.monitoring.surveyrequest.form.attachments.upload.success",
    ),
    downloadAllButton: t("downloadAll"),
    downloadingAllMessage: t("messages.downloadingAllFiles"),
    alerts: {
      destroy: {
        success: t(
          "pages.monitoring.surveyrequest.form.attachments.destroy.success",
        ),
      },
    },
  } as AttachmentInputTranslation;

  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();
  const { downloadMultiple } = useDownload();
  const ability = useAbility();

  const processingReload = 5 * 1000;
  const [reportsData, setReportsData] = useState<DTOMonitoringReports[]>([]);
  const [modalReportsVisible, setModalReportsVisible] = useState(false);
  const [modalReportsLoading, setModalReportsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState([]);
  const params = useParams<PageParams>();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [registrationId] = useState("");
  const [reportLoading, setReportLoading] = useState(false);
  const [intervalProcess, setIntervalProcess] = useState(false);

  const [degrees, setDegrees] = useState(true);

  const [data, setData] = useState([]);
  const [carDocuments, setCarDocuments] = useState<any[]>([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState<
    TablePaginationConfig & { per_page: number }
  >({
    current: 1,
    total: 1,
    per_page: 10,
  });
  const [reportPaginationConfig, setReportPaginationConfig] =
    useState<TablePaginationConfig>({
      current: 1,
      total: 1,
      pageSize: 10,
    });
  const [visibilityForm, setVisibilityForm] = useState(false);

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.per_page) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  // Registration Situation
  const [selectedSituation, setSelectedSituation] = useState("");
  const [situations, setSituations] = useState<ItemsType[]>([]);

  const [situationArrendada, setSituationArrendada] =
    useState<RegistrationSituationData>({} as RegistrationSituationData);

  const handleRemoveAttachment = (file: any) => {
    const newCars = carDocuments.filter(
      (document) => document.uid !== file.uid && !document.id,
    );
    setCarDocuments(newCars);
    form.setFieldsValue({ documents: newCars });
  };

  useEffect(() => {
    async function getSituation() {
      if (ability.can("monitoring.registration.situation.all", ""))
        monitoringApi.situation
          .all()
          .then((response: { data: { data: RegistrationSituationData[] } }) => {
            const { data: responseData } = response.data;

            const situationOptions: ItemsType[] = responseData.map(
              (situation) => {
                if (situation.name.toLocaleLowerCase() === "arrendada") {
                  setSituationArrendada(situation);
                }

                return {
                  key: situation.id,
                  value: situation.id,
                  label: situation.name,
                };
              },
            );

            setSituations(situationOptions);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, "error"));
    }
    getSituation();
    // eslint-disable-next-line
  }, []);

  // Verify the situation selected id "arrendada" to show especific fields
  const [showFieldsArrendada, setShowFieldsArrendada] = useState(false);
  useEffect(() => {
    if (selectedSituation === situationArrendada.id) {
      setShowFieldsArrendada(true);
    } else {
      setShowFieldsArrendada(false);
    }
  }, [selectedSituation, situationArrendada.id]);

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: "success",
      title: t("modal.success"),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<PaginationData<RegistrationData>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          cpr: params.cpr,

          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      monitoringApi.registration
        .get(dataSend)
        .then((response: ResponseList<RegistrationData>) => {
          const result = response.data;
          setData(result.data);
          setPaginationConfig((paginationConfigState) => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, "error"))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize, params.cpr],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    setCarDocuments([]);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setCarDocuments([]);
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: "delete",
      title: t("pages.monitoring.cpr.delete.title"),
      subTitle: t("pages.monitoring.cpr.delete.subtitle"),
      cancelText: t("pages.monitoring.cpr.delete.cancel"),
      okText: t("pages.monitoring.cpr.delete.confirm"),
      onOk: () => {
        monitoringApi.registration
          .destroy(record.id, {
            params: {},
          })
          .then((response) => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, "error"));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.language = i18n.language;
        let formData = new FormData();
        formData = formatValuesToFormData(
          formValues,
          formData,
          [],
          ["documents"],
        );
        formValues.documents?.forEach((document: File, index: number) => {
          formData.append(`documents[${index}]`, document);
        });
        formData.append("cpr_id", params.cpr);

        monitoringApi.registration
          .store(formData)
          .then((response: ResponseListData<RegistrationData>) => {
            const { data: registration } = response;
            handleSuccess(response.data.message);
            updateData(registration);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, "error");
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  function checkReportStatus(report: DTOMonitoringReports) {
    if (report.status === "processing") {
      setDownloadLoading((value) => [...value, report.id]);
    } else {
      setDownloadLoading((value) => [
        ...value.filter((id: string) => report.id !== id),
      ]);
    }
  }

  const loadReportTableData = useCallback(
    (idRegistration: string, values: MonitoringDataTable = null) => {
      setReportLoading(true);
      monitoringApi.registration
        .listReport(idRegistration, {
          params: {
            ...values,
          },
        })
        .then((response) => {
          const result = response.data.data;
          const filtered = result.data.find(
            (item: DTOMonitoringReports) => item.status === "processing",
          );

          if (typeof filtered === "undefined") {
            setIntervalProcess(false);
          } else if (!intervalProcess) {
            setIntervalProcess(true);
            setTimeout(() => {
              loadReportTableData(registrationId);
            }, processingReload);
          }

          setReportsData(
            result.data.map((item: DTOMonitoringReports) => {
              checkReportStatus(item);
              return item;
            }),
          );
          setReportPaginationConfig((paginationConfigState) => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, "error"))
        .finally(() => setReportLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language, intervalProcess, processingReload],
  );

  async function handleRegistrationReport() {
    setModalReportsLoading(true);
    monitoringApi.registration
      .generateReport(registrationId)
      .then((response: { data: { data: DTOMonitoringReports } }) => {
        checkReportStatus(response.data.data);
        setReportsData((report) => [response.data.data, ...report]);
        setReportPaginationConfig((paginationConfigState) => ({
          ...paginationConfigState,
          total: paginationConfigState.total + 1,
        }));

        /** reload reports while processing */
        if (!intervalProcess) {
          setIntervalProcess(true);
          setTimeout(() => {
            loadReportTableData(registrationId);
          }, processingReload);
        }
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, "error"))
      .finally(() => {
        setModalReportsLoading(false);
      });
  }

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.language = i18n.language;

        const formValueEntries = Object.entries(formValues);

        const findNullables = formValueEntries.map((item: any) => {
          if (item[1] === null) {
            item[1] = undefined;
          }
          return item;
        });

        const newObj = findNullables.reduce((acc, current) => {
          const key = current[0];
          const value = current[1];
          acc[key] = value;

          return acc;
        }, {});

        let formData = new FormData();
        formData = formatValuesToFormData(newObj, formData, [], ["documents"]);
        formValues.documents.forEach((document: any, index: number) => {
          formData.append(`documents[${index}]`, document);
        });

        monitoringApi.registration
          .update(formValues.id, formData, { params: { _method: "PUT" } })
          .then((response: ResponseListData<RegistrationData>) => {
            const { data: registration } = response;
            handleSuccess(response.data.message);
            updateUserData();
            handleHideForm();
            updateData(registration);
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, "error");
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, "error");
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const tableConfigReport = {
    search: "",
    filtered: {},
    sorter: {},
  };
  const [tableDataReportConfig, setTableDataReportConfig] =
    useState(tableConfigReport);

  const handleReport = (id: string) => {
    window.open(
      `${process.env.REACT_APP_REGISTRATION_REPORT}/?id=${id}`,
      "_blank",
    );
  };

  const getActions = () => ({
    render: (active: number, record: RegistrationData) => (
      <>
        <Can I="monitoring.registration.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t("pages.monitoring.registration.table.icons.remove")}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.registration.update" a="">
          <IconWithTooltip
            action="edit"
            title={t("pages.monitoring.registration.table.icons.edit")}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                emission_date: Formatter.date(record.emission_date, true),
                scripture_date: Formatter.date(record.scripture_date, true),
                leased_expiration_date: Formatter.date(
                  record.leased_expiration_date,
                  true,
                ),
                property_assessment_date: Formatter.date(
                  record.property_assessment_date,
                  true,
                ),
                is_dms: record.is_dms ? 1 : 0,
                leased_consent: record.leased_consent ? 1 : 0,
                documents: [],
              });

              setSelectedSituation(record.registration_situation_id);
              setDegrees(record.is_dms);
              setCarDocuments(
                record.kml
                  .map((kml: any) =>
                    kml.documents.map((document: any) => document),
                  )
                  .flat(),
              );

              form.scrollToField("name", {
                scrollMode: "always",
                block: "start",
                behavior: (actions) =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
          <Link
            to={`/monitoring/cpr/${record.cpr_id}/registration/${record.id}/survey`}
          >
            <Badge
              count={record.survey.length ? record.survey.length : 0}
              style={{ backgroundColor: "#1c5a8d" }}
            >
              <IconWithTooltip
                action="registrationArea"
                title={t("pages.monitoring.registration.form.iconSurvey")}
              />
            </Badge>
          </Link>
          <IconWithTooltip
            action="attachment"
            title={t("pages.monitoring.registration.table.icons.reports")}
            onClick={() => {
              if (!record.survey.length)
                ModalStatus({
                  title: t("warning"),
                  description: t(
                    "pages.monitoring.registration.form.noSurveys",
                  ),
                  type: "info",
                });
              else handleReport(record.id);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: "",
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig((tableDataConfigState) => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig((tableDataConfigState) => ({
      ...tableDataConfigState,
      filtered: {},
      search: "",
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>["onChange"] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig((tableDataConfigState) => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = "";
      const search: any = {};
      let sort = "";

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === "ascend") direction = "asc";
        if (order === "descend") direction = "desc";
        sort = field?.toString() ?? "";
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (
              dataFilter[key] !== null &&
              dataFilter[key] !== undefined &&
              dataFilter[key][0]
            ) {
              search[key] = dataFilter[key][0].toString();
            } else if (
              dataFilter[key] !== null &&
              dataFilter[key] !== undefined
            ) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = "";
            }
          });
        }
      }

      setPaginationConfig((paginationConfigState) => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  const handleTableReportChange: TableProps<any>["onChange"] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setReportLoading(true);
      setTableDataReportConfig((tableDataConfigState) => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = "";
      const search: any = {};
      let sort = "";

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === "ascend") direction = "asc";
        if (order === "descend") direction = "desc";
        sort = field?.toString() ?? "";
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (
              dataFilter[key] !== null &&
              dataFilter[key] !== undefined &&
              dataFilter[key][0]
            ) {
              search[key] = dataFilter[key][0].toString();
            } else if (
              dataFilter[key] !== null &&
              dataFilter[key] !== undefined
            ) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = "";
            }
          });
        }
      }

      setReportPaginationConfig((paginationConfigState) => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadReportTableData(registrationId, {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadReportTableData, registrationId],
  );
  // Table DATA Config: END

  function handleStatus(
    deadline: string,
    row: DTORegistrationModel,
    survey: string,
  ) {
    const value = row.survey.find(
      (element: DTOSurvey) => element.survey_stage.order === survey,
    );
    const date =
      value !== undefined ? value.date_survey : moment().format("YYYY-MM-DD");

    if (date < deadline) {
      return "light-green";
    }
    if (date === deadline) {
      return "light-yellow";
    }
    return "light-danger";
  }

  useEffect(() => {
    const filters: any = {};

    handleTableChange(
      { current: 1, pageSize: paginationConfig.pageSize },
      filters,
      {},
      null,
    );
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.monitoring") },
          { title: t("breadcrumb.cpr"), to: "/monitoring/cpr" },
          { title: t("breadcrumb.registrations") },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>
            {t("pages.monitoring.registration.title_page")}
          </STitlePage>

          <Can I="monitoring.registration.store" a="">
            <Button
              status="primary"
              icon={visibilityForm ? <FaMinus /> : <FaPlus />}
              onClick={handleDisplayForm}
            >
              {t("pages.monitoring.registration.buttonAdd")}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t("pages.monitoring.registration.registerTitle")}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: "none" }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.number")}
                    name="number"
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={12} type="text" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.emission_date",
                    )}
                    name="emission_date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.scripture_date",
                    )}
                    name="scripture_date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.property_assessment_date",
                    )}
                    name="property_assessment_date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.incra_code")}
                    name="incra_code"
                  >
                    <MaskedInput
                      type="text"
                      mask="111.111.111.111-1"
                      placeholderChar="_"
                      placeholder="___.___.___.___-_"
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.itr_nirf")}
                    name="itr_nirf"
                  >
                    <Input type="text" maxLength={255} />
                  </FormItem>
                </Col>

                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t(
                      "pages.monitoring.registration.form.estimated_production",
                    )}
                    name="estimated_production"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      formatter={(value: string | number) =>
                        Formatter.decimal(value, 2, 3, null)
                      }
                      parser={(value: string) =>
                        Number(Formatter.decimalParser(value))
                      }
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.total_area")}
                    name="total_area"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      formatter={(value: string | number) =>
                        Formatter.decimal(value, 2, 3, null, "ha")
                      }
                      parser={(value: string) =>
                        Number(Formatter.decimalParser(value))
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.diff_area")}
                    name="diff_area"
                  >
                    <InputNumber
                      min={0}
                      formatter={(value: string | number) =>
                        Formatter.decimal(value, 2, 3, null, "ha")
                      }
                      parser={(value: string) =>
                        Number(Formatter.decimalParser(value))
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.cefir_code")}
                    name="cefir_code"
                  >
                    <MaskedInput
                      type="text"
                      mask="1111.111.111111"
                      placeholderChar="_"
                      placeholder="____.____.______"
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: "10px" }}
                    label={t("pages.monitoring.registration.form.district")}
                    name="district"
                  >
                    <Input type="text" maxLength={255} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.registration_situation",
                    )}
                    name="registration_situation_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={situations}
                      onChange={(value) =>
                        setSelectedSituation(value.toString())
                      }
                    />
                  </FormItem>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ display: !showFieldsArrendada ? "none" : "" }}
                >
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.leasedExpirationDate",
                    )}
                    name="leased_expiration_date"
                    style={{ display: !showFieldsArrendada ? "none" : "" }}
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                    />
                  </FormItem>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ display: !showFieldsArrendada ? "none" : "" }}
                >
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.leasedConsent",
                    )}
                    name="leased_consent"
                    style={{ display: !showFieldsArrendada ? "none" : "" }}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={1}>Sim</Radio.Button>
                      <Radio.Button value={0}>Não</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t(
                      "pages.monitoring.registration.form.coordinateType",
                    )}
                    name="is_dms"
                  >
                    <Select
                      onChange={(value) => setDegrees(Number(value) === 1)}
                      options={[
                        {
                          value: 1,
                          label: t(
                            "pages.monitoring.registration.form.degrees",
                          ),
                        },
                        {
                          value: 0,
                          label: t(
                            "pages.monitoring.registration.form.decimal",
                          ),
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col
                  style={{ display: degrees ? "block" : "none" }}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <FormItem
                    label={t("pages.monitoring.registration.form.degrees")}
                    name="dms"
                    rules={[
                      {
                        pattern: Coordinate.regexDMS,
                        message: t("pages.monitoring.farm.validator.dms"),
                      },
                    ]}
                  >
                    <Input placeholder="__° __′ __″ _, ___° __′ __″ _" />
                  </FormItem>
                </Col>
                <Col
                  style={{ display: !degrees ? "block" : "none" }}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <FormItem
                    label={t("pages.monitoring.registration.form.latitude")}
                    name="latitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLat,
                        message: t(
                          "pages.monitoring.registration.form.validator.lat",
                        ),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
                <Col
                  style={{ display: !degrees ? "block" : "none" }}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <FormItem
                    label={t("pages.monitoring.registration.form.longitude")}
                    name="longitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLon,
                        message: t(
                          "pages.monitoring.registration.form.validator.lon",
                        ),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t("pages.monitoring.registration.form.details")}
                    name="details"
                  >
                    <TextArea
                      maxLength={255}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t("pages.monitoring.registration.form.car")}
                    name="documents"
                  >
                    <AttachmentInput
                      multiple
                      accept=".kml,.kmz"
                      translation={attachmentInputTranslation}
                      onRemove={handleRemoveAttachment}
                      dependencies={[carDocuments]}
                      getFiles={async () => {
                        return {
                          data: {
                            data: carDocuments.filter(
                              (document) => !!document.id,
                            ),
                            total: carDocuments.filter(
                              (document) => !!document.id,
                            ).length,
                            page: 1,
                            per_page: carDocuments.filter(
                              (document) => !!document.id,
                            ).length,
                          },
                        };
                      }}
                      handleDestroy={(() => {
                        if (ability.can("document.destroy", ""))
                          return documentApi.delete;
                        return (_: string, _2: any) =>
                          new Promise((resolve) =>
                            resolve(message.error(t("noPermission"))),
                          );
                      })()}
                      downloadMultiple={downloadMultiple}
                      handleDownload={documentApi.download}
                    />
                  </FormItem>
                </Col>
              </Row>

              <SFormButtons>
                <Button
                  status="secondary"
                  htmlType="reset"
                  onClick={handleHideForm}
                  disabled={disabledButton}
                >
                  {t("pages.monitoring.registration.buttonCancel")}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                    permission="monitoring.registration.update"
                  >
                    {t("pages.monitoring.registration.buttonEdit")}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                    permission="monitoring.registration.store"
                  >
                    {t("pages.monitoring.registration.buttonRegister")}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem
                name="search"
                label={t("pages.monitoring.registration.search")}
              >
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t("form.actions.search")}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button
                status="secondary"
                size="middle"
                onClick={tableDataClearAllFilters}
              >
                {t("pages.register.harvest.buttonClearFilter")}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.registrationColumns.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === "scripture_date") {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) =>
                  `${Formatter.date(row?.scripture_date)}`,
              };
            }

            if (column.key === "emission_date") {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) =>
                  `${Formatter.date(row?.emission_date)}`,
              };
            }
            if (column.key === "kml") {
              columnRender = {
                ...columnRender,
                render: (kmls: any[]) => (kmls?.length ? t("yes") : t("no")),
              };
            }

            if (column.key === "planting") {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={handleStatus(
                          row.cpr?.planting_deadline,
                          row,
                          "1",
                        )}
                      >
                        {Formatter.date(row.cpr?.planting_deadline)}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            if (column.key === "development") {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={handleStatus(
                          row.cpr?.development_deadline,
                          row,
                          "2",
                        )}
                      >
                        {Formatter.date(row.cpr?.development_deadline)}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            if (column.key === "harvest") {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={handleStatus(
                          row.cpr?.harvest_deadline,
                          row,
                          "3",
                        )}
                      >
                        {Formatter.date(row.cpr?.harvest_deadline)}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
        <Modal
          className="modal-with-custom-footer"
          style={{ top: 20 }}
          // title={t('pages.credit-request.modal-reports.title')}
          width={800}
          visible={modalReportsVisible}
          closeIcon={
            <MdClose
              onClick={() => {
                setModalReportsVisible(false);
              }}
            />
          }
          footer={
            <>
              <div className="buttons">
                <Button
                  status="secondary"
                  onClick={() => {
                    setModalReportsVisible(false);
                    setModalReportsLoading(false);
                  }}
                >
                  {t(
                    "pages.monitoring.registration.modal-reports.buttons.close",
                  )}
                </Button>
              </div>
            </>
          }
        >
          <Spin spinning={modalReportsLoading}>
            <Button
              status="primary"
              onClick={() => {
                handleRegistrationReport();
              }}
            >
              <FaPlus />{" "}
              {t("pages.monitoring.registration.modal-reports.buttons.new")}
            </Button>
            <TableData
              rowKey="id"
              columns={columns.reportsColumns.map((col) => {
                let column: any = {
                  ...col,
                };

                switch (column.key) {
                  case "created_at":
                    column = {
                      ...col,
                      render: (_: any, row: DTOMonitoringReports) => {
                        return row.created_at
                          ? Formatter.datetime(row.created_at)
                          : "-";
                      },
                    };
                    break;
                  case "user":
                    column = {
                      ...col,
                      render: (_: any, row: DTOMonitoringReports) => {
                        return row.user ? row.user.name : "-";
                      },
                    };
                    break;
                  case "status":
                    column = {
                      ...col,
                      render: (_: any, row: DTOMonitoringReports) => {
                        return t(
                          `pages.monitoring.registration.modal-reports.status.${row.status}`,
                        );
                      },
                    };
                    break;
                  case "action":
                    column = {
                      ...column,
                      render: (active: number, record: DTOMonitoringReports) =>
                        record.status !== "failed" && (
                          <IconWithTooltip
                            loading={downloadLoading.includes(record.id)}
                            action="download"
                            title={t(
                              "pages.monitoring.registration.modal-reports.buttons.download",
                            )}
                            onClick={async () => {
                              setDownloadLoading((value) => [
                                ...value,
                                record.id,
                              ]);
                              await documentApi
                                .download(record.document?.id)
                                .then((response: any) =>
                                  downloadHandler(response),
                                )
                                .catch((err: DTOErrorReponse) =>
                                  alertStatus(err, "error"),
                                )
                                .finally(() => {
                                  setDownloadLoading(
                                    downloadLoading.filter((item) => {
                                      return item.id !== record.id;
                                    }),
                                  );
                                });
                            }}
                          />
                        ),
                    };
                    break;
                  default:
                    return column;
                }

                return column;
              })}
              dataSource={reportsData}
              loading={reportLoading}
              onChange={handleTableReportChange}
              pagination={reportPaginationConfig}
              tableConfig={tableDataReportConfig}
            />
          </Spin>
        </Modal>
      </SContainer>
    </>
  );
};

export default Registration;
