import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

interface Props {
  isVisible: boolean;
}

export const FundeinfraSelect = ({ isVisible }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const setIsFundeinfra = useCreditRequestStore(state => state.setIsFundeinfra);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.fundeinfra')}
      name="is_fundeinfra"
      style={{ display: !isVisible ? 'none' : '' }}
      rules={[{ required: isVisible }]}
    >
      <Select
        options={[
          {
            key: 'fundeinfra_yes',
            label: t('pages.credit-request.form.fethab_yes'),
            value: 1,
          },
          { key: 'fundeinfra_no', label: t('pages.credit-request.form.fethab_no'), value: 0 },
        ]}
        disabled={pageStatus.viewing}
        onChange={(value: any) => {
          setIsFundeinfra(!!value);

          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
