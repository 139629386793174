import { useEffect, useRef, useState } from "react";

import { TablePaginationConfig } from "antd/lib/table";
import ModalStatus from "components/ModalStatus";
import moment from "moment";
import { downloadHandler } from "services/download";
import exceptionHandler from "services/exceptions";
import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic";
import { DTOErrorResponse, DTOReport } from "types/DTOReports";

import { ReportExportProps } from "./types";

const PROCESSING_TIMER = 4 * 1000;

export const defaultPagination = {
  responsive: false,
  current: 1,
  total: 1,
  showSizeChanger: true,
  defaultPageSize: 10,
  defaultCurrent: 1,
  pageSizeOptions: ["5", "10", "20", "50", "100"],
  pageSize: 10,
} as TablePaginationConfig;

export const useReportExport = ({
  listReportApi,
  generateReportApi,
  downloadReportApi,
  reportApiId,
  controller,
  visibility,
}: ReportExportProps) => {
  const [reports, setReports] = useState<DTOReport[]>([]);
  const [reportsProcessingList, setReportsProcessingList] = useState<string[]>(
    [],
  );

  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const [isChangingPage, setIsChangingPage] = useState(false);
  const [isExportModalLoading, setIsExportModalLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const intervalRef = useRef<any>(null);
  const isLookingForUpdates = useRef<any>(false);

  const addReportToProcessingList = (reportId: string | undefined) => {
    if (reportId) setReportsProcessingList((idList) => [...idList, reportId]);
  };

  const removeReportFromProcessingList = (reportId: string | undefined) => {
    if (reportId)
      setReportsProcessingList((idList) => [
        ...idList.filter((id: string) => reportId !== id),
      ]);
  };

  const hasReportsInProcess = (reportsList?: DTOReport[]) =>
    !!(reportsList ?? reports).find(
      (item: DTOReport) => item.status === "processing",
    );

  const lookForUpdates = (reportList: DTOReport[]) => {
    if (!isChangingPage) {
      if (!intervalRef.current && !isLookingForUpdates.current && visibility) {
        isLookingForUpdates.current = true;
        let nReports = reportList as DTOReport[];
        setIsRefreshing(true);
        if (hasReportsInProcess(nReports)) {
          const interval = setIntervalAsync(async () => {
            intervalRef.current = interval;
            const { data } = await fetchReportsToExport();
            if (hasReportsInProcess(data)) nReports = data;
            else {
              (async () => clearIntervalAsync(intervalRef.current))();
              intervalRef.current = null;
              isLookingForUpdates.current = false;
            }
          }, PROCESSING_TIMER);
        } else isLookingForUpdates.current = false;
      }
    } else setIsChangingPage(false);
  };

  const manageProcessingList = (report: DTOReport) => {
    return report.status === "processing"
      ? addReportToProcessingList(report.id)
      : removeReportFromProcessingList(report.id);
  };

  const fetchReportsToExport = async (
    pagination = defaultPagination,
    filters = {},
    sorter = {},
  ) => {
    setIsFetchingReports(true);
    setIsChangingPage(true);
    const params = {
      params: {
        ...{ per_page: pagination.pageSize, page: pagination.current },
        ...filters,
        ...sorter,
      },
    };

    const apiPromise = reportApiId
      ? listReportApi(reportApiId, params)
      : listReportApi(params);

    const response = await apiPromise
      .catch((err: DTOErrorResponse) => exceptionHandler(err))
      .finally(() => {
        setIsFetchingReports(false);
      });

    const { data, total } = response.data;

    setReports(
      data.map((item: DTOReport) => {
        manageProcessingList(item);
        return item;
      }),
    );

    return { data, total };
  };

  const formatTableFilters = (filters: Record<string, React.Key[] | null>) => {
    const search = {} as any;
    Object.keys(filters).forEach((key: string) => {
      if (filters[key] && filters[key]![0]) {
        search[key] = filters[key]![0].toString();
      } else if (filters[key] !== null && filters[key] !== undefined) {
        search[key] = filters[key]!.toString();
      } else {
        search[key] = "";
      }
    });

    return search;
  };

  const handleReportsTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, React.Key[] | null>,
    sorter: any,
  ) => {
    const search = filters ? formatTableFilters(filters) : {};
    fetchReportsToExport(pagination, search, sorter);
  };

  const handleGenerateReport = async (reportId = "", values: any = {}) => {
    setIsExportModalLoading(true);

    const start = values.date_range
      ? moment(values?.date_range[0]).format("YYYY-MM-DD")
      : null;
    const end = values.date_range
      ? moment(values?.date_range[1]).format("YYYY-MM-DD")
      : null;

    const apiPromise = reportId
      ? generateReportApi({ ...values, start, end }, reportId)
      : generateReportApi(values);

    apiPromise
      .then((response) => {
        const reportList = response.data as DTOReport;
        manageProcessingList(reportList);
        setReports([reportList, ...reports]);

        const oldData = [...controller.data];
        oldData.pop();

        controller.setData([reportList, ...oldData]);
        // lookForUpdates([reportList, ...(oldData as DTOReport[])]);
      })
      .catch((err: DTOErrorResponse) => exceptionHandler(err))
      .finally(() => {
        setIsExportModalLoading(false);
      });
  };

  const handleDownloadReport = async (record: DTOReport, isCSV = false) => {
    addReportToProcessingList(record.id);
    if (record.document) {
      const id = isCSV ? record.document?.document?.id : record.document?.id;

      if (id) {
        await downloadReportApi(id)
          .then((response: any) => downloadHandler(response))
          .catch((err: DTOErrorResponse) => exceptionHandler(err))
          .finally(() => {
            removeReportFromProcessingList(record.id);
          });
      } else ModalStatus({ title: "Invalid ID", type: "error" });
    }
  };

  const stopTimeout = async () => {
    if (intervalRef.current) {
      await clearIntervalAsync(intervalRef.current);
      intervalRef.current = null;
      isLookingForUpdates.current = false;
    }
  };

  useEffect(() => {
    lookForUpdates(reports);
  }, [reports]);

  return {
    reportsProcessingList,
    manageProcessingList,
    addReportToProcessingList,
    removeReportFromProcessingList,
    fetchReportsToExport,
    isFetchingReports,
    isExportModalLoading,
    reports,
    isRefreshing,
    handleReportsTableChange,
    handleGenerateReport,
    handleDownloadReport,
    stopTimeout,
  };
};
