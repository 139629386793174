import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { DataTable } from 'components/ui/data-table';
import { useDeleteCreditRequestAssigneeMutation } from 'pages/CreditRequest/api/use-delete-credit-request-assignee.mutation';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { AssigneeData } from 'types/CreditRequest/AssigneeData';
import { Formatter } from 'utils/Formatter';

export const AssigneesTable = () => {
  const { t } = useTranslation();

  const assigneeList = useCreditRequestStore(state => state.assigneeList);
  const setAssigneeList = useCreditRequestStore(state => state.setAssigneeList);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const deleteAssigneeMutation = useDeleteCreditRequestAssigneeMutation();

  const handleDelete = async (assignee: AssigneeData) => {
    if (!assignee.id.includes('new-')) await deleteAssigneeMutation.mutateAsync(assignee.id);
    setAssigneeList(assigneeList.filter(a => a.id !== assignee.id));
  };

  const columns: ColumnDef<AssigneeData>[] = [
    {
      accessorKey: 'name',
      header: t('pages.registered-customers.form.pj.partner.name'),
      cell: ({ row }) => {
        return <span>{row.original.name}</span>;
      },
    },
    {
      accessorKey: 'cod_gatec',
      header: t('assignee-gatec-code'),
      cell: ({ row }) => {
        return <span>{row.original.cod_gatec}</span>;
      },
    },
    {
      accessorKey: 'value',
      header: t('value-to-pay'),
      cell: ({ row }) => {
        return <span>{Formatter.money(row.original.value, 2, 'R$', true)}</span>;
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 50,
      meta: { body: { cell: { className: 'max-w-[50px]' } } },
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original;
        const [isDeleting, setIsDeleting] = useState(false);

        return !pageStatus.viewing ? (
          <div className="flex gap-2 items-center max-w-[50px]">
            <IconWithTooltip
              // permission="credit.request.assignee.destroy"
              permission="credit.request.registered_assignees.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              loading={isDeleting}
              onClick={async () => {
                setIsDeleting(true);
                await handleDelete(partner);
                setIsDeleting(false);
              }}
            />
            {/* <Can I="credit.request.assignee.update"> */}
            {/* <IconWithTooltip
              action="edit"
              onClick={() => {
                setAssigneeToEdit(partner);
              }}
              title={t('pages.registered-customers.table.icons.edit')}
            /> */}
            {/* </Can> */}
          </div>
        ) : null;
      },
    },
  ];

  return <DataTable columns={columns} data={assigneeList ?? []} />;
};
