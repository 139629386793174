import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineClipboardList } from 'react-icons/hi';

import { MoneyInput } from '@agrodatabr/masked-inputs';
import { Input as InputAnt } from 'antd';
import { FormItem } from 'components';
import PermissionedComponent from 'components/PermissionedComponent';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/ui/button';
import { FormGrid } from 'components/ui/form-grid';
import { GroupForm } from 'components/ui/group-form';
import { Switch } from 'components/ui/switch';
import { CURRENCY_EUR_ID, WEIGHT_UNIT_SACK_ID, WEIGHT_UNIT_TON_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { Can } from 'hooks/ability';
import { cn } from 'lib/utils';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { ContractNumberInput } from '../atoms/ContractNumberInput';
import { CustodiaApprovalStatusInput } from '../atoms/CustodiaApprovalStatusInput';
import { CustodiaCityIdSelect } from '../atoms/CustodiaCityIdSelect';
import { CustodiaStateIdSelect } from '../atoms/CustodiaStateIdSelect';
import { CustodiaStorageCategoryInput } from '../atoms/CustodiaStorageCategoryInput';
import { CustodiaStorageCommodityDivisionIdSelect } from '../atoms/CustodiaStorageCommodityDivisionIdSelect';
import { CustodiaStorageUnitIdSelect } from '../atoms/CustodiaStorageUnitIdSelect';
import { EntityTypeSelect } from '../atoms/entity-type.select';
import { HarvestIdSelect } from '../atoms/HarvestIdSelect';
import { HasAssignesInput } from '../atoms/HasAssignesInput';
import { IntervenerGatecInput } from '../atoms/intervener-gatec.input';
import { IntervenerNameInput } from '../atoms/intervener-name.input';
import { JsonDataCurrencyTypeIdSelect } from '../atoms/JsonDataCurrencyTypeIdSelect';
import { JsonDataQuantityKgInput } from '../atoms/JsonDataQuantityKgInput';
import { JsonDataWarehouseStreetInput } from '../atoms/JsonDataWarehouseStreetInput';
import { JsonDataWeightTypeIdSelect } from '../atoms/JsonDataWeightTypeIdSelect';
import { JustificationSelect } from '../atoms/justification.select';
import { NumberInput } from '../atoms/NumberInput';
import { ObservationsInput } from '../atoms/observations.input';
import { PaymentDateInput } from '../atoms/payment-date.input';
import { ProviderGatecInput } from '../atoms/provider-gatec.input';
import { QuantityContractInput } from '../atoms/QuantityContractInput';
import { QuantityDeliveryInput } from '../atoms/QuantityDeliveryInput';
import { RequestedAmountInput } from '../atoms/RequestedAmountInput';
import { RequesterNameInput } from '../atoms/RequesterNameInput';
import { RequesterPersonDocumentInput } from '../atoms/RequesterPersonDocumentInput';
import { RequesterPersonIdInput } from '../atoms/RequesterPersonIdInput';
import { DocumentsList } from '../molecules/DocumentsList';
import { Assignees } from '../organisms/assignees/assignees';
import { StatusHistory } from '../organisms/StatusHistory';

export const CottonPrepaymentForm = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const [commodities, setCommodities] = React.useState<any[]>([]);

  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const hasConsentingIntervener = useCreditRequestStore(state => state.hasConsentingIntervener);
  const setHasConsentingIntervener = useCreditRequestStore(state => state.setHasConsentingIntervener);
  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const justificationOther = useCreditRequestStore(state => state.justificationOther);
  const hasAssignees = useCreditRequestStore(state => state.hasAssignees);

  const handleChangeJsonDataCurrencyTypeId = (value: any, option: any) => {
    if (value) setIsDolarSelected(option.label);
  };

  return (
    <>
      <div className="group-form">
        <GroupForm title={t('general-data')}>
          <RequesterPersonIdInput />
          <FormGrid>
            <NumberInput />
            <RequesterPersonDocumentInput />
            <ProviderGatecInput disabled />
            <div className={cn(pageStatus.new ? 'col-span-2' : '')}>
              <RequesterNameInput disabled isProvider />
            </div>
          </FormGrid>

          <FormGrid>
            <ContractNumberInput noIntegration />
            <QuantityContractInput />
            <QuantityDeliveryInput
              disabled={
                pageStatus.viewing ||
                (contractFieldsValue && contractFieldsValue['json_data.quantity_delivery'] !== undefined)
              }
              required
            />
            <HarvestIdSelect />
            <EntityTypeSelect />
          </FormGrid>

          <FormItem name="is_intervener" label={t('has-consenting-intervener')}>
            <Switch onValueChange={setHasConsentingIntervener} disabled={pageStatus.viewing} />
          </FormItem>

          <ShouldRender condition={hasConsentingIntervener}>
            <FormGrid>
              <IntervenerGatecInput />
              <IntervenerNameInput />
            </FormGrid>
          </ShouldRender>

          <HasAssignesInput useSwitch required={false} />
        </GroupForm>

        <GroupForm title={t('advance-data')}>
          <FormGrid>
            <JsonDataQuantityKgInput />
            <FormItem label={t('total-contract-value')} name="json_data.price" rules={[{ required: true }]}>
              <MoneyInput disabled={pageStatus.viewing} />
            </FormItem>
            <JsonDataCurrencyTypeIdSelect
              disabled={pageStatus.viewing}
              blacklist={[CURRENCY_EUR_ID]}
              onChange={handleChangeJsonDataCurrencyTypeId}
            />
          </FormGrid>

          <FormGrid>
            <JsonDataWeightTypeIdSelect blacklist={[WEIGHT_UNIT_TON_ID, WEIGHT_UNIT_SACK_ID]} />
            <RequestedAmountInput disabled={pageStatus.viewing} required />
            <PaymentDateInput required />
          </FormGrid>

          <FormGrid>
            <JustificationSelect />
          </FormGrid>

          {justificationOther ? (
            <FormGrid>
              <FormItem rules={[{ required: true }]} name="justify" label={t('declare-other-justification')}>
                <InputAnt.TextArea maxLength={255} />
              </FormItem>
            </FormGrid>
          ) : null}

          <FormGrid>
            <ObservationsInput required={false} />
          </FormGrid>
        </GroupForm>

        <ShouldRender condition={hasAssignees}>
          <Assignees />
        </ShouldRender>

        <GroupForm title={t('pages.credit-request.form.titles.contract')}>
          <FormGrid>
            <CustodiaStateIdSelect />
            <CustodiaCityIdSelect />
            <CustodiaStorageUnitIdSelect setCommodities={setCommodities} />
          </FormGrid>

          <FormGrid>
            <CustodiaStorageCommodityDivisionIdSelect commodities={commodities} />
            <CustodiaApprovalStatusInput />
            <CustodiaStorageCategoryInput />
          </FormGrid>

          <FormGrid>
            <JsonDataWarehouseStreetInput />
            <Button
              title={t('clipboardCopy')}
              onClick={() => navigator.clipboard.writeText(creditForm.getFieldValue('json_data.warehouse_street'))}
            >
              <span>
                <HiOutlineClipboardList />
              </span>
            </Button>
          </FormGrid>
        </GroupForm>

        <Can I="credit.request.attachment.index">
          <DocumentsList />
        </Can>

        {!pageStatus.new ? (
          <PermissionedComponent
            permission="credit.request.attachment.index"
            description={t('permission.message.credit.request.history.index')}
          >
            <StatusHistory />
          </PermissionedComponent>
        ) : null}
      </div>
    </>
  );
};
