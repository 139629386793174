import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import UserData from 'types/User/UserData';

export const useGetUserQuery = (id: string) => {
  return useQuery({
    queryKey: [`admin/user/${id}`, id],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: async () => {
      try {
        if (!id) return null;
        const response = await api.get<UserData>(`admin/user/${id}`);
        return response.data;
      } catch (err) {
        exceptionHandler(err);
        return null;
      }
    },
  });
};
