import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import UserData from 'types/User/UserData';

type Params = PaginatedQueryParams;

export const useGetUsersQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<UserData>({
    queryKey: ['admin/user', params],
    url: `admin/user`,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};
