import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';

type Props = {
  userId: string;
};

export const useGetNotificationConfigurationQuery = ({ userId }: Props) => {
  const url = `admin/user/${userId}/message`;
  return useQuery({
    queryKey: [url],
    enabled: !!userId,
    queryFn: async () => {
      const response = await api.get(url);

      return response.data;
    },
  });
};
