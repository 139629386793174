import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

export const BalanceToBeFixedInput = () => {
  const { t } = useTranslation();

  const { ADVANCE_PRODUCT_DELIVERED_RULES } = useCreditRequestRules();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.to_fix_balance')}
      name="balance_to_be_fixed"
      rules={[
        { required: false },
        {
          validator(_, value) {
            const validation = ADVANCE_PRODUCT_DELIVERED_RULES.toFixEqualsContractQuantityValidate(
              selectedOperationId,
              value,
            );
            if (validation) {
              return Promise.reject(new Error(validation));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value, 2, null, true)}
        parser={(value: string) => Formatter.moneyParser(value, 2, false)}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
        disabled
      />
    </FormItem>
  );
};
