import { useState } from "react";

import { TablePaginationConfig } from "antd";
import { useIntegrations } from "pages/Serasa/Integrations/hooks/useIntegrations";

export const useFilesController = () => {
  const { getFileTypes, isFetchingFileTypes } = useIntegrations();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [templateBeingEditedId, setTemplateBeingEditedId] = useState("");
  const [searchFilters, setSearchFilters] = useState<object>();

  const closeForm = () => {
    setIsFormOpen(false);
    setTemplateBeingEditedId("");
    setIsEditing(false);
  };

  const openForm = () => setIsFormOpen(true);

  const toggleForm = () => {
    if (!isFormOpen) {
      openForm();
    } else closeForm();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: object,
  ) => {
    const { data, total } = await getFileTypes({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        ...filters,
      },
    });

    return { data, total };
  };

  return {
    closeForm,
    openForm,
    isFormOpen,
    toggleForm,
    isEditing,
    setIsEditing,
    isFetchingFileTypes,
    handleTableChange,
    templateBeingEditedId,
    setSearchFilters,
    searchFilters,
  };
};
