import React, { useEffect, useState } from "react";

import { useTable } from "compositions/TableDataAsync/hooks/useTable";

import { useRequestsContract } from "./hooks/useRequestsContract";
import { Table } from "./Table";

export interface Props {
  form: any;
}

export const RequestsContract = ({ form }: Props) => {
  const [data, setData] = useState({
    value: null,
    type: null,
  });
  const formValues = form.getFieldsValue();

  useEffect(() => {
    if (formValues.contract_number) {
      setData(({ value }) => {
        if (value && value !== formValues.contract_number)
          tableController.refresh();
        return { value: formValues.contract_number, type: "contract" };
      });
    } else if (formValues.doc_number && !formValues.contract_number) {
      setData(({ value }) => {
        if (value && value !== formValues.doc_number) tableController.refresh();
        return { value: formValues.doc_number, type: "doc_number" };
      });
    } else {
      setData({ value: null, type: null });
    }
  }, [formValues.contract_number, formValues.doc_number]);

  const tableController = useTable();

  const { handleTableChange, isFetching } = useRequestsContract(data);

  return (
    <div>
      {data.value && (
        <Table
          handleChange={handleTableChange}
          isFetching={isFetching}
          controller={tableController}
        />
      )}
    </div>
  );
};
