import React from 'react';

import CreditKanban from '../organisms/CreditKanban';

export interface KanbanProps {
  filterData?: {
    division: string;
    harvests: string[];
    number: string;
    operation: string;
    search: string;
    subdivision: string;
  };
}

const KanbanView: React.FC<KanbanProps> = ({ filterData }) => {
  return (
    <div className="flex h-full w-full flex-col overflow-y-scroll">
      <CreditKanban filterValues={filterData} />
    </div>
  );
};

export default KanbanView;
