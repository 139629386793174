import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaSync } from "react-icons/fa";
import { GiHand } from "react-icons/gi";

import { Popover } from "antd";
import {
  Button,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
} from "components";
import { SapStatusEnum } from "components/SapStatus/types";
import {
  OPERATION_PREFIN_ID,
  OPERATION_PREPAY_ID,
  SapStatusAdvanceProgEnum,
} from "configs/constants";
import { useCreditRequestContext } from "context/CreditRequestContext";
import { Can } from "hooks/ability";
import { useCreditRequestStore } from "pages/CreditRequest/store/use-credit-request-store";
import { ModalErrors } from "pages/Integrations/DataProviders/ModalErrors";

import { DataIntegrationErros } from "../../../../../@types/data/IntegrationDataProvider";
import { Container } from "./styles";

export const SapStatusInput = () => {
  const { t } = useTranslation();
  const {
    changeSapStatusManually,
    isUpdatingSapStatus,
    forceIntegrationStatus,
  } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(
    (state) => state.selectedOperationId,
  );
  const creditRequestId = useCreditRequestStore(
    (state) => state.creditRequestId,
  );
  const creditRequestData = useCreditRequestStore(
    (state) => state.creditRequestData,
  );

  const permissions = useMemo(() => {
    switch (selectedOperationId) {
      case OPERATION_PREPAY_ID:
        return {
          manual: "credit.request.partial.limit.import.prepay.update",
          force: "sap.commodity.limits.prepay.store",
        };
      case OPERATION_PREFIN_ID:
        return {
          manual: "credit.request.partial.limit.import.prefin.update",
          force: "sap.commodity.limits.prefin.store",
        };
      default:
        return {
          manual: "credit.request.import.update",
          force: "sap.commodity.limits.store",
        };
    }
  }, [selectedOperationId]);

  const [listErrorsIntegration, setListErrorsIntegration] = useState<
    DataIntegrationErros[]
  >([]);
  const [modalErrorIntegrationVisible, setModalErrorIntegrationVisible] =
    useState(false);

  const handleChangeSapStatusManually = async () => {
    if (!creditRequestData?.last_import_sap.group.route) return;
    ModalStatus({
      type: "warning",
      title: t("pages.credit-request.form.action.sap_status_manually"),
      subTitle: t(
        "pages.credit-request.form.action.sap_status_manually_description",
      ),
      cancelText: t("pages.admin.operation.table.actions.cancel-delete"),
      okText: t("pages.admin.operation.table.actions.confirm-delete"),
      onOk: async () => {
        const response = await changeSapStatusManually(
          creditRequestData?.last_import_sap?.id,
        );
        if (response)
          ModalStatus({
            type: "success",
            title: t("modal.success"),
            subTitle: t(
              "pages.credit-request.form.action.sap_status_manually_success",
            ),
          });
      },
    });
  };

  const handleChangeForceSapStatusIntegration = async () => {
    if (!creditRequestId) return;

    const response = await forceIntegrationStatus(
      creditRequestData?.last_import_sap.group.route,
    );
    if (response)
      ModalStatus({
        type: "success",
        title: t("modal.success"),
        subTitle: t(
          "pages.credit-request.form.action.sap_status_manually_success",
        ),
      });
  };

  const shouldShowIntegrationButtons =
    creditRequestData?.last_import_sap?.description === SapStatusEnum.ERROR ||
    creditRequestData?.last_import_sap?.description === SapStatusEnum.FAILED ||
    (creditRequestData?.last_import_sap?.description ===
      SapStatusEnum.SUCCESS &&
      (creditRequestData.status_sap?.toLowerCase() ===
        SapStatusAdvanceProgEnum.RETURNED ||
        creditRequestData.status_sap?.toLowerCase() ===
          SapStatusAdvanceProgEnum.REPROVED));

  return (
    <Container>
      <span style={{ width: "100%" }}>
        <FormItem
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {selectedOperationId === OPERATION_PREPAY_ID
                ? t("pages.credit-request.form.sap_status_prepay")
                : t("pages.credit-request.form.sap_status")}
              <Popover
                title="SAP Status"
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <span>
                      <strong>E: </strong>
                      {t("sap.status.error")}
                    </span>
                    <span>
                      <strong>S: </strong>
                      {t("sap.status.success")}
                    </span>
                    <span>
                      <strong>W: </strong>
                      {t("sap.status.warning")}
                    </span>
                    <span>
                      <strong>MANUAL: </strong>
                      {t("sap.status.manual")}
                    </span>
                    <span>
                      <strong>F: </strong>
                      {t("sap.status.failure")}
                    </span>
                  </div>
                }
              >
                <span style={{ display: "flex" }}>
                  <FaInfoCircle />
                </span>
              </Popover>
            </span>
          }
          name="last_import_sap.description"
        >
          <Input disabled />
        </FormItem>
      </span>
      {shouldShowIntegrationButtons ? (
        <>
          {creditRequestData?.last_import_error_sap ? (
            <span>
              <IconWithTooltip
                action="view"
                title={t("pages.integration.dataProviders.table.icons.errors")}
                onClick={() => {
                  setListErrorsIntegration([
                    creditRequestData?.last_import_error_sap,
                  ]);
                  setModalErrorIntegrationVisible(true);
                }}
              />
            </span>
          ) : null}
          <Can I={permissions.manual} a="">
            <Button
              size="small"
              onClick={handleChangeSapStatusManually}
              loading={isUpdatingSapStatus}
              disabled={isUpdatingSapStatus || !creditRequestId}
              title={t("pages.credit-request.form.sap_status_manually")}
            >
              <span style={{ display: "flex" }}>
                <GiHand />
              </span>
            </Button>
          </Can>
          <Can I={permissions.force} a="">
            <Button
              size="small"
              onClick={handleChangeForceSapStatusIntegration}
              loading={isUpdatingSapStatus}
              disabled={isUpdatingSapStatus || !creditRequestId}
              title={t("pages.credit-request.form.sap_status_force")}
            >
              <span style={{ display: "flex" }}>
                <FaSync />
              </span>
            </Button>
          </Can>
        </>
      ) : null}

      <ModalErrors
        listErrors={listErrorsIntegration}
        setIsVisible={setModalErrorIntegrationVisible}
        isVisible={modalErrorIntegrationVisible}
      />
    </Container>
  );
};
