import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { GroupForm } from 'components/ui/group-form';
import { useDeleteDraftCommentMutation } from 'pages/CreditRequest/api/use-delete-draft-comment.mutation';
import { useDraftFormStateStore } from 'pages/CreditRequest/store/use-draft-form-state-store';

import { Add } from '../add';
import { BaseCommentData, CommentsTable } from '../comments-table';
import { DraftCommentModal } from './draft-comment.modal';

export const DraftCommentSection = () => {
  const form = useFormContext();
  const { t } = useTranslation();
  const { alert } = useAlertDialog();
  const queryClient = useQueryClient();

  const comments = useWatch({ name: 'comments', exact: true, control: form.control });

  const setCommentBeingRemovedId = useDraftFormStateStore(state => state.setCommentBeingRemovedId);
  const commentBeingRemovedId = useDraftFormStateStore(state => state.commentBeingRemovedId);

  const deleteDraftCommentMutation = useDeleteDraftCommentMutation();

  const handleDeleteComment = (comment: BaseCommentData) => {
    if (!comment.isNew) {
      return alert({
        type: 'confirm',
        title: t(
          'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.actions.confirm_delete_comment',
        ),
        onOk: async () => {
          setCommentBeingRemovedId(comment.id);
          await deleteDraftCommentMutation.mutateAsync(comment.id, {
            onSuccess: () => {
              const updatedComments = form.getValues('comments').filter((c: BaseCommentData) => c.id !== comment.id);
              form.setValue('comments', updatedComments);
              queryClient.invalidateQueries({
                queryKey: ['drafts'],
              });
              alert({
                title: t(
                  'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.actions.message_success',
                ),
                type: 'success',
              });
            },
          });
          setCommentBeingRemovedId(null);
        },
      });
    }
    const comments = form.getValues('comments');
    const updatedComments = comments.filter((c: BaseCommentData) => c.id !== comment.id);
    form.setValue('comments', updatedComments);
  };

  return (
    <GroupForm title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.comments')}>
      <CommentsTable
        comments={comments}
        handleDeleteComment={handleDeleteComment}
        entityBeingDeletedId={commentBeingRemovedId}
        deletePermission="credit.request.envelope.destroy"
      />
      {form.formState.disabled ? null : (
        <DraftCommentModal>
          <Add
            text={t(
              'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.buttonAddComment',
            )}
            className="h-auto min-h-0 py-10"
          />
        </DraftCommentModal>
      )}
    </GroupForm>
  );
};
