import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Col, DatePicker, Select as SelectAnt, Input, DatePickerProps } from 'antd';
import moment from 'moment';

import 'moment/locale/pt-br';

import { Divisions, SubDivisions } from 'pages/CreditRequest/store/slices/credit-request-slices/division.slice';

import DTOOwner from '../../../../@types/dtos/credit-request/DTOOwner';
import { Button, FormItem, Row, Select } from '../../../../components';
import { SelectOptionType } from '../../../../components/Select/types';
import appConfigs from '../../../../configs/app';
import { PRESENTIAL_SURVEY, REMOTE_SURVEY } from '../../../../configs/constants';
import { divisions, subDivisions } from '../../../../configs/divisions';
import { useCache } from '../../../../hooks/cache';
import { GridFilters } from '../../../../styles';
import { useFilters } from './hooks/useFilters';
import { PropsFilters, FilterDashboard } from './types';

import { PermissionedField } from 'components/PermissionedField';

export const Filters = ({
  props,
  filterForm,
  handleGet,
  setSearchFiltersTableFirst,
  setSearchFiltersTableSecond,
  searchForm,
  monitoring = false,
  noInitialRequesterDate = false,
  initialData,
}: PropsFilters) => {
  const { Option } = SelectAnt;
  const { RangePicker } = DatePicker;
  const [dataRequester, setDataRequester] = useState([]);
  const [dataProviderByRange, setDataProviderByRange] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const { getCreditOptions, getPersonOptions, current_harvest } = useCache();
  const [loadingField, setLoadingField] = useState<string>(null);
  const [options, setOptions] = useState(() => ({
    division: [],
    sub_division: [],
    operation: [],
  }));
  const [owners, setOwners] = useState([]);
  const { t } = useTranslation();

  const { getCounterparty, getOwners, getProviders, isFetchingProviders } = useFilters();

  useEffect(() => {
    const filterProps = props.filter(
      (item: any) => item.name === 'requester' || item.name === 'provider' || item.name === 'provider_multiple',
    );
    const handleFilterCounterparty = async () => {
      const { data } = await getCounterparty();
      setDataRequester(data);
    };
    if (filterProps.length) handleFilterCounterparty();
  }, []);

  useEffect(() => {
    const filterProps = props.filter((item: any) => item.name === 'owner');
    const handleFilterOwner = async () => {
      const { data } = await getOwners();
      setOwners(data);
    };
    if (filterProps.length) handleFilterOwner();
  }, []);

  const handleFilterTable = () => {
    const values: FilterDashboard = filterForm.getFieldsValue() as FilterDashboard;
    if (setSearchFiltersTableFirst) setSearchFiltersTableFirst(values);
    if (setSearchFiltersTableSecond) setSearchFiltersTableSecond(values);
    searchForm.forEach((element: any) => {
      element.resetFields();
    });
  };

  const handleFilter = useCallback(() => {
    const values: FilterDashboard = filterForm.getFieldsValue() as FilterDashboard;
    if (values.requesterDate) {
      if (values.requesterDate[0] !== null) {
        filterForm.setFieldsValue({
          start: moment(values.requesterDate[0]).format('YYYY-MM-DD'),
          end: moment(values.requesterDate[1]).format('YYYY-MM-DD'),
        });
      } else {
        filterForm.setFieldsValue({
          start: undefined,
          end: undefined,
        });
      }
    }

    handleFilterTable();
    if (handleGet) handleGet();
  }, [filterForm]);

  const requesterOptions: SelectOptionType[] = useMemo(() => {
    if (dataRequester && dataRequester.length > 0) {
      return dataRequester.map((requesterItem: DTOOwner) => ({
        value: requesterItem.id,
        key: requesterItem.id,
        label: requesterItem.name,
      }));
    }

    return [];
  }, [dataRequester]);

  const ownerOptions: SelectOptionType[] = useMemo(() => {
    if (owners && owners.length > 0) {
      return owners.map((owner: DTOOwner) => ({
        value: owner.id,
        key: owner.id,
        label: owner.name,
      }));
    }

    return [];
  }, [owners]);

  const handleChange = (value: any) => {
    setOptionsSelected(value);
  };

  const disabledOptions = (value: any) => {
    if (optionsSelected.length >= 3) {
      const filter = optionsSelected.filter((item: any) => item === value);
      if (filter.length) return false;
      return true;
    }
    return false;
  };

  const loadSubDivisions = (divisionId: Divisions) => {
    setLoadingField('sub_division');
    let listOfSubDivisions = getCreditOptions('sub_division');
    if (divisions[divisionId]) {
      listOfSubDivisions = getCreditOptions('sub_division').filter(s => divisions[divisionId].includes(s.value));
    }
    setOptions(stateOptions => ({ ...stateOptions, sub_division: listOfSubDivisions }));
    setLoadingField(null);
  };

  const loadProvidersByDate = async () => {
    const [start, end] = filterForm.getFieldValue('weekRange');
    const providers = await getProviders({
      params: { start: start.format(appConfigs.formatApiDate), end: end.format(appConfigs.formatApiDate) },
    });

    setDataProviderByRange(
      providers.map((requesterItem: DTOOwner) => ({
        value: requesterItem.provider_id,
        key: requesterItem.provider_id,
        label: requesterItem.provider_name,
      })),
    );
  };

  const loadOperations = (subDivisionId: SubDivisions) => {
    setLoadingField('operation');
    let listOfOperations = getCreditOptions('operation');
    if (subDivisions[subDivisionId]) {
      listOfOperations = getCreditOptions('operation').filter(s => subDivisions[subDivisionId].includes(s.value));
    }
    setOptions(stateOptions => ({ ...stateOptions, operation: listOfOperations }));
    setLoadingField(null);
  };

  const customFormat: DatePickerProps['format'] = value => {
    return value.format(appConfigs.formatDate);
  };

  const getFilters = (itemName: any, item: any) => {
    switch (itemName) {
      case 'harvest':
        return (
          <SelectAnt onChange={handleChange} mode="multiple">
            {getCreditOptions(itemName)
              .filter((itemValue, index, self) => self.indexOf(itemValue) === index)
              .map(option => (
                <Option key={option.value} disabled={disabledOptions(option.value)} {...option}>
                  {option.label}
                </Option>
              ))}
          </SelectAnt>
        );
      case 'numbers':
        return <Input placeholder="230000264,230000265..." />;
      case 'division':
        return (
          <Select
            options={getCreditOptions(itemName)}
            onChange={(value: any, option: any) => {
              filterForm.setFieldsValue({
                sub_division: undefined,
                operation: undefined,
              });
              if (option) loadSubDivisions(option.value);
              else setOptions(stateOptions => ({ ...stateOptions, sub_division: [], operation: [] }));
            }}
          />
        );
      case 'sub_division':
        return (
          <Select
            options={options.sub_division}
            disabled={loadingField === 'sub_division' || !filterForm.getFieldValue('division')}
            loading={loadingField === 'sub_division'}
            onChange={(value: any, option: any) => {
              filterForm.setFieldsValue({
                operation: undefined,
              });
              if (option) loadOperations(option.value);
              else setOptions(stateOptions => ({ ...stateOptions, operation: [] }));
            }}
          />
        );
      case 'operation':
        return (
          <Select
            options={options.operation.length ? options.operation : getCreditOptions(itemName)}
            loading={loadingField === 'operation'}
          />
        );
      case 'requester':
        return (
          <PermissionedField permission="credit.request.requester.all">
            <Select options={requesterOptions} />
          </PermissionedField>
        );
      case 'provider':
        return <Select options={requesterOptions} />;
      case 'provider_multiple':
        return (
          <Select
            options={dataProviderByRange}
            mode="multiple"
            loading={isFetchingProviders}
            disabled={!dataProviderByRange.length}
          />
        );
      case 'owner':
        return <Select options={ownerOptions} />;
      case 'requesterDate':
        return (
          <RangePicker
            style={{ width: '300px' }}
            format="DD/MM/YYYY"
            disabledDate={current => {
              return item.disableRangeValidation ? false : current && current > moment();
            }}
          />
        );
      case 'weekRange':
        return (
          <RangePicker style={{ width: '300px' }} format={customFormat} picker="date" onChange={loadProvidersByDate} />
        );
      case 'culture':
        return <Select options={getPersonOptions(itemName)} />;
      case 'surveytype':
        return (
          <SelectAnt allowClear>
            <Option value={PRESENTIAL_SURVEY}>{t('pages.dashboard.filters.presential')}</Option>
            <Option value={REMOTE_SURVEY}>{t('pages.dashboard.filters.remote')}</Option>
          </SelectAnt>
        );
      case 'start':
        return <Input />;
      case 'end':
        return <Input />;
      default:
        return <Select options={getCreditOptions(itemName)} />;
    }
  };

  useEffect(() => {
    if (filterForm.getFieldValue('weekRange')) loadProvidersByDate();
    else setDataProviderByRange([]);
  }, [filterForm.getFieldsValue().weekRange]);

  return (
    <Row gutter={[8, 24]}>
      <Col xs={24}>
        <Form
          form={filterForm}
          initialValues={
            initialData || {
              harvest: [current_harvest],
              requesterDate: noInitialRequesterDate ? undefined : [moment().subtract(1, 'month'), moment()],
              start: moment().subtract(1, 'month').format(appConfigs.formatApiDate),
              end: moment().format(appConfigs.formatApiDate),
            }
          }
          onFinish={() => handleFilter()}
          className="form-secondary"
        >
          <GridFilters style={{ gridTemplateColumns: `repeat(${monitoring ? 6 : props.length - 2}, 1fr) 1.2fr` }}>
            {props.map((item: any) => (
              <FormItem
                rules={
                  item.name === 'harvest' || item.required
                    ? [
                        {
                          required: item.name === 'harvest' || item.required,
                        },
                      ]
                    : []
                }
                key={item.key}
                name={item.name}
                label={
                  item.name === 'requester'
                    ? t('pages.dashboard.filters.counterparty')
                    : t(`pages.dashboard.filters.${item.name}`)
                }
                hidden={item.name === 'start' || item.name === 'end'}
              >
                {getFilters(item.name, item)}
              </FormItem>
            ))}
            <div className="grid-buttons">
              <Button status="primary" htmlType="submit">
                {t('pages.dashboard.filters.button-filter')}
              </Button>
              <Button
                status="secondary"
                onClick={() => {
                  filterForm.resetFields();
                  handleFilterTable();
                  handleGet();
                }}
              >
                {t('pages.dashboard.filters.button-clear')}
              </Button>
            </div>
          </GridFilters>
        </Form>
      </Col>
    </Row>
  );
};
