import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus, FaFileExcel } from "react-icons/fa";

import { ReportModal } from "components/ReportModal";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import serasaApi from "services/api/serasa";

import { Breadcrumb, Button, DropdownButton } from "../../../components";
import { useButtonHover } from "../../../components/hooks";
import { getReportModalTranslation } from "../../../componentsTranslation/reportModal";
import { Can } from "../../../hooks/ability";
import documentApi from "../../../services/api/document";
import { Form } from "./Form";
import { useSerasaCounterparties } from "./hooks/useSerasaCounterparties";
import { SContainer, SHeader, STitlePage } from "./styles";
import { Table } from "./Table";

export type ReporProps = {
  visible: boolean;
  type: any;
};

export const SerasaCounterparty = () => {
  const { t } = useTranslation();
  const tableController = useTable();
  const [onButtonHover, setButtonHover] = useButtonHover();
  const search = new URLSearchParams(window.location.search).get("search");
  const [exporting, setExporting] = useState<ReporProps>({
    visible: false,
    type: "",
  });

  const listPropsReport = {
    counterparty: {
      getReport: serasaApi.counterparties.listReport,
      postReport: serasaApi.counterparties.generateExport,
    },
    restrictionRow: {
      getReport: serasaApi.counterparties.listReportRestrictionRow,
      postReport: serasaApi.counterparties.generateExportRestrictionRow,
    },
    restrictionColumn: {
      getReport: serasaApi.counterparties.listReportRestrictionColumn,
      postReport: serasaApi.counterparties.generateExportRestrictionColumn,
    },
  };

  const {
    toggleForm,
    handleTableChange,
    handleEdit,
    handleRemove,
    isFetching,
    searchFilters,
    setSearchFilters,
    isFormOpen,
    form,
    handleSave,
    handleChangeDocStatus,
    loading,
    setLoading,
    typePerson,
    setTypePerson,
    filterForm,
  } = useSerasaCounterparties({ refresh: tableController.refresh, search });

  useEffect(() => {
    if (search) {
      setSearchFilters({ search });
      filterForm.setFieldsValue({ search });
    }
  }, [search]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.serasa") },
          { title: t("breadcrumb.counterparty") },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>
            {t("pages.serasacounterparty.title.register")}
          </STitlePage>

          <Can I="serasa.counterparty.restriction.index" a="">
            <DropdownButton
              text="uppercase"
              hoverstate={[onButtonHover, setButtonHover]}
              listnames={[
                t("pages.serasacounterparty.reports.general"),
                t("pages.serasacounterparty.reports.restrictionRow"),
                t("pages.serasacounterparty.reports.restrictionColumn"),
              ]}
              handlers={[
                () =>
                  setExporting({
                    visible: true,
                    type: listPropsReport.counterparty,
                  }),
                () =>
                  setExporting({
                    visible: true,
                    type: listPropsReport.restrictionRow,
                  }),
                () =>
                  setExporting({
                    visible: true,
                    type: listPropsReport.restrictionColumn,
                  }),
              ]}
              childnode={
                <div className="flex items-center gap-2">
                  <span>
                    <FaFileExcel />
                  </span>
                  <span>{t("form.actions.export")}</span>
                </div>
              }
            />
          </Can>
          <Can I="serasa.counterparty.store" a="">
            <Button
              status="primary"
              icon={isFormOpen ? <FaMinus /> : <FaPlus />}
              onClick={toggleForm}
            >
              {t("pages.serasacounterparty.btnAdd")}
            </Button>
          </Can>
        </SHeader>

        <Form
          form={form}
          setLoading={setLoading}
          handleSave={handleSave}
          loading={loading}
          isOpen={isFormOpen}
          setIsDocSerasa={handleChangeDocStatus}
          typePerson={typePerson}
          setTypePerson={setTypePerson}
        />

        <Table
          handleChange={handleTableChange}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          isLoading={isFetching}
          controller={tableController}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          filterForm={filterForm}
        />
        <ReportModal
          translation={getReportModalTranslation(t)}
          width="80%"
          visibility={exporting.visible}
          afterClose={() => {
            setExporting({ visible: false, type: "" });
          }}
          listReportApi={exporting.type.getReport}
          generateReportApi={exporting.type.postReport}
          downloadReportApi={documentApi.download}
        />
      </SContainer>
    </>
  );
};
