import { FormInstance } from 'antd/lib/form';
import {
  Divisions,
  Operations,
  SubDivisions,
} from 'pages/CreditRequest/store/slices/credit-request-slices/division.slice';

import DTOTax from '../../../../../@types/dtos/credit-request/DTOTax';
import {
  DIVISION_GEO_ID,
  LEGAL_PERSON_TYPE,
  OPERATION_PREPAY_ID,
  PHYSICAL_PERSON_TYPE,
  STATE_GO,
  SUBDIVISION_CORN_ID,
  SUBDIVISION_SOYBEAN_ID,
} from '../../../../../configs/constants';
import { CacheContextData } from '../../../../../types/Hooks/cache';

const findCurrencyType = (cache: CacheContextData, currencyId: string) =>
  cache.currency_type.find((x: any) => x.id === currencyId);

export const roundNumberDecimals = (number: number, decimalPlaces: number): number => {
  return Number(`${Math.round(Number(`${number}e${decimalPlaces}`))}e-${decimalPlaces}`);
};

export function prepaymentCalculation(
  creditForm: FormInstance<any>,
  cache: CacheContextData,
  divisionId: Divisions,
  subDivisionId: SubDivisions,
  operationId: Operations,
) {
  if (
    divisionId === DIVISION_GEO_ID &&
    operationId === OPERATION_PREPAY_ID &&
    (subDivisionId === SUBDIVISION_CORN_ID || subDivisionId === SUBDIVISION_SOYBEAN_ID)
  ) {
    const formValues: any = creditForm.getFieldsValue();

    const taxes = [
      'fethab_soy',
      'fethab_corn',
      'senar',
      'inss',
      'royalties_soy_monsanto',
      'fundeinfra_soja',
      'fundeinfra_milho',
    ];
    const percentageTaxes = cache?.tax.filter((x: any) => taxes.includes(x.tag));
    const taxasImpostos: any = {
      fethab_soy: 0.0,
      fethab_corn: 0.0,
      senar: 0.0,
      inss: 0.0,
      royalties_soy_monsanto: 0.0,
      fundeinfra_soja: 0.0,
      fundeinfra_milho: 0.0,
    };
    percentageTaxes.forEach((x: DTOTax) => {
      if (x.tag.indexOf('fethab') < 0) {
        taxasImpostos[x.tag] = Number(x.value) / 100;
      } else {
        taxasImpostos[x.tag] = Number(x.value);
      }
    });

    const camposForm = {
      tipoFornecedor: formValues.requester_person_type,
      quantidade: formValues['json_data.quantity_kg'],
      valorUnitFixacao: formValues['json_data.price_value'],
      unidadePesoPrecoId: formValues['json_data.weight_type_id'],
      inss: formValues['json_data.opting_inss'],
      fethab: formValues['json_data.fethab'],
      moedaId: formValues['json_data.currency_type_id'],
      azParticipante: formValues['json_data.az_participant'],
      exportacao: formValues['json_data.is_exportation'],
      cotacaoFethab: formValues['json_data.fethab_quotation'],
      isFundeinfra: formValues.is_fundeinfra,
      state: formValues.state_id,
    };

    const findWeightType: any = cache.weight_type.find((x: any) => x.id === camposForm.unidadePesoPrecoId);
    const unidadePesoPreco = findWeightType ? findWeightType.slug : null;

    const currencyType = findCurrencyType(cache, camposForm.moedaId);
    const moeda = currencyType ? currencyType.name : null;

    /* Dividir por 100, porque no cálculo é usada a unidade de kg sem casas decimais e no campo numérico tem casas decimais (mas o valor vem como inteiro, concatenando as casas decimais) */
    const quantidadeFloat = Number(camposForm.quantidade);

    const calculos = {
      azParticipante: camposForm.azParticipante,
      cotacaoFethab: Number(camposForm.cotacaoFethab),
      fethab: Boolean(camposForm.fethab),
      impostos: 0.0,
      inss: Boolean(camposForm.inss),
      aliquotaImpostos: taxasImpostos,
      quantidade: quantidadeFloat,
      royaltiesMonsanto: 0.0,
      tipoFornecedor: camposForm.tipoFornecedor,
      exportacao: Boolean(camposForm.exportacao),
      unidadePesoPreco,
      valorBruto: 0.0,
      valorLiquido: 0.0,
      valorUnitFixacao: Number(camposForm.valorUnitFixacao),
    };

    // Cálculo do Valor Bruto
    if (calculos.unidadePesoPreco === 'sc') {
      /* Dividido por 100 para usar a unidade de R$ e não centavos (que vem do campo) */
      calculos.valorBruto = (calculos.quantidade * calculos.valorUnitFixacao) / 60;
    }
    if (calculos.unidadePesoPreco === 'tn') {
      /* Dividido por 100 para usar a unidade de R$ e não centavos (que vem do campo) */
      calculos.valorBruto = (calculos.quantidade * calculos.valorUnitFixacao) / 60 / 1000;
    }
    if (calculos.unidadePesoPreco === 'kg') {
      /* Dividido por 100 para usar a unidade de R$ e não centavos (que vem do campo) */
      calculos.valorBruto = (calculos.quantidade * calculos.valorUnitFixacao) / 60;
    }

    // Cálculo dos Impostos
    if (subDivisionId === SUBDIVISION_SOYBEAN_ID) {
      // Pessoa Jurídica
      if (calculos.tipoFornecedor === LEGAL_PERSON_TYPE) {
        if (calculos.fethab === true) {
          if (moeda === 'BRL') {
            calculos.impostos += (calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_soy;
          }

          if (moeda === 'USD') {
            calculos.impostos +=
              ((calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_soy) / calculos.cotacaoFethab;
          }
        }
      }

      // Pessoa Física
      if (calculos.tipoFornecedor === PHYSICAL_PERSON_TYPE) {
        if (calculos.fethab === true) {
          if (moeda === 'BRL') {
            calculos.impostos += (calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_soy;
          }

          if (moeda === 'USD') {
            calculos.impostos +=
              ((calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_soy) / calculos.cotacaoFethab;
          }
        }

        // Senar
        calculos.impostos += calculos.valorBruto * calculos.aliquotaImpostos.senar;

        // Sem Fim Exportação & INSS
        if (calculos.exportacao === false && calculos.inss === false) {
          calculos.impostos += calculos.valorBruto * calculos.aliquotaImpostos.inss;
        }
      }

      if (calculos.azParticipante === 'not_az_participant') {
        calculos.royaltiesMonsanto = calculos.valorBruto * calculos.aliquotaImpostos.royalties_soy_monsanto;
      }

      if (camposForm.state === STATE_GO && camposForm.isFundeinfra === 1)
        calculos.impostos += calculos.valorBruto * taxasImpostos.fundeinfra_soja;
    }

    if (subDivisionId === SUBDIVISION_CORN_ID) {
      // Pessoa Jurídica
      if (calculos.tipoFornecedor === LEGAL_PERSON_TYPE) {
        if (calculos.fethab === true) {
          if (moeda === 'BRL') {
            calculos.impostos += (calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_corn;
          }

          if (moeda === 'USD') {
            calculos.impostos +=
              ((calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_corn) / calculos.cotacaoFethab;
          }
        }
      }

      // Pessoa Física
      if (calculos.tipoFornecedor === PHYSICAL_PERSON_TYPE) {
        // Fethab
        if (calculos.fethab === true) {
          if (moeda === 'BRL') {
            calculos.impostos += (calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_corn;
          }

          if (moeda === 'USD') {
            calculos.impostos +=
              ((calculos.quantidade / 1000) * calculos.aliquotaImpostos.fethab_corn) / calculos.cotacaoFethab;
          }
        }

        // Senar
        calculos.impostos += calculos.valorBruto * calculos.aliquotaImpostos.senar;

        // Sem Fim Exportação & INSS
        if (calculos.exportacao === false && calculos.inss === false) {
          calculos.impostos += calculos.valorBruto * calculos.aliquotaImpostos.inss;
        }
      }

      if (camposForm.state === STATE_GO && camposForm.isFundeinfra === 1)
        calculos.impostos += calculos.valorBruto * taxasImpostos.fundeinfra_milho;
    }

    calculos.valorLiquido = calculos.valorBruto - ((calculos.impostos ?? 0) + calculos.royaltiesMonsanto);

    const apresentarValores = {
      bruto: (roundNumberDecimals(calculos.valorBruto, 2) || 0).toFixed(2),
      impostos: (roundNumberDecimals(calculos.impostos, 2) || 0).toFixed(2),
      royalties: (roundNumberDecimals(calculos.royaltiesMonsanto, 2) || 0).toFixed(2),
      liquido: (roundNumberDecimals(calculos.valorLiquido, 2) || 0).toFixed(2),
    };

    creditForm.setFieldsValue({
      'json_data.gross_value': apresentarValores.bruto,
      'json_data.taxes': apresentarValores.impostos,
      'json_data.royalties_monsanto': apresentarValores.royalties,
      'json_data.net_amount': apresentarValores.liquido,
      requested_amount: apresentarValores.liquido,
    });
  }
}

export function advanceProductDeliveredCalculation(
  creditForm: FormInstance<any>,
  cache: CacheContextData,
  // subDivision: string,
) {
  const formValues = creditForm.getFieldsValue();
  // const formValueFethabQuotation = formValues['json_data.fethab_quotation'];
  // const providerType = formValues.requester_person_type;
  // const fethab = Boolean(formValues['json_data.fethab']);
  // const currencyId = formValues['json_data.currency_type_id'];
  // const quantity = Number(formValues['json_data.quantity_kg']);
  // const fethabQuotation = Number(Number.isNaN(Number(formValueFethabQuotation)) ? 0 : formValueFethabQuotation);
  const grossValue = Number(formValues['json_data.gross_value']);
  // const isExportation = Boolean(formValues['json_data.is_exportation']);
  // const isInss = Boolean(formValues['json_data.opting_inss']);
  // const azParticipant = formValues['json_data.az_participant'];
  // const isFundeinfra = formValues.is_fundeinfra;
  // const dailyPrice = formValues.daily_price_value;
  // const stateId = formValues.state_id;

  const taxes = [
    'fethab_soy',
    'fethab_corn',
    'senar',
    'inss',
    'royalties_soy_monsanto',
    'fundeinfra_soja',
    'fundeinfra_milho',
  ];
  const percentageTaxes = cache?.tax.filter((x: any) => taxes.includes(x.tag));
  const taxRates: any = {
    fethab_soy: 0.0,
    fethab_corn: 0.0,
    senar: 0.0,
    inss: 0.0,
    royalties_soy_monsanto: 0.0,
  };
  percentageTaxes.forEach((x: DTOTax) => {
    if (x.tag.indexOf('fethab') < 0) taxRates[x.tag] = Number(x.value) / 100;
    else taxRates[x.tag] = Number(x.value);
  });

  const taxValue = 0;
  const royaltiesMonsanto = 0;

  // const currencyType = findCurrencyType(cache, currencyId);

  // if (subDivision === 'soybean') {
  //   // Pessoa Jurídica
  //   if (providerType === LEGAL_PERSON_TYPE) {
  //     if (fethab === true && stateId === STATE_MT) {
  //       if (currencyType.name === 'BRL') taxValue += grossValue * taxRates.fethab_soy;
  //       if (currencyType.name === 'USD') taxValue += ((grossValue / 1000) * taxRates.fethab_soy) / fethabQuotation;
  //     }
  //   }

  //   // Pessoa Física
  //   if (providerType === PHYSICAL_PERSON_TYPE) {
  //     if (fethab === true && stateId === STATE_MT) {
  //       if (currencyType.name === 'BRL') taxValue += grossValue * taxRates.fethab_soy;
  //       if (currencyType.name === 'USD') taxValue += ((grossValue / 1000) * taxRates.fethab_soy) / fethabQuotation;
  //     }

  //     // Senar
  //     taxValue += grossValue * taxRates.senar;

  //     // Sem Fim Exportação & INSS
  //     if (isExportation === false && isInss === false) taxValue += grossValue * taxRates.inss;
  //   }

  //   if (stateId === STATE_GO && isFundeinfra === 1) taxValue += grossValue * taxRates.fundeinfra_soja;

  //   if (azParticipant === 'not_az_participant') royaltiesMonsanto = grossValue * taxRates.royalties_soy_monsanto;
  // }

  creditForm.setFieldsValue({
    'json_data.taxes': taxValue.toFixed(2),
    'json_data.royalties_monsanto': royaltiesMonsanto.toFixed(2),
    // 'json_data.net_amount': (((grossValue * 60) / dailyPrice) * (taxValue / 100)).toFixed(2),
    'json_data.net_amount': grossValue,
    requested_amount: grossValue,
  });
}
