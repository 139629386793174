import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { Button } from 'components/ui/button';
import { DataTableAsync } from 'components/ui/data-table';
import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { Input } from 'components/ui/input';
import { useCache } from 'hooks/cache';
import { usePagination } from 'hooks/usePagination';
import { Search } from 'lucide-react';
import { useDeleteteamStatusSlaMutation } from 'pages/Admin/api/use-delete-team-status-sla.mutation';
import { useGetTeamStatusSlasQuery } from 'pages/Admin/api/use-get-team-status-slas.query';
import { TeamStatusSlaData } from 'types/Admin/TeamStatus/TeamStatusSlaData';

import { useTeamStatusStore } from '../stores/useTeamStatusStore';

type SlaTableProps = {
  teamStatusId: string;
};

export const SlaTable = ({ teamStatusId }: SlaTableProps) => {
  const { paginationConfig, dispatchPagination } = usePagination();
  const { t } = useTranslation();
  const { operation, division } = useCache();
  const form = useForm();

  const [searchValue, setSearchValue] = useState('');
  const [removingSlaIds, setRemovingSlaIds] = useState<string[]>([]);

  const setSlaToEdit = useTeamStatusStore(state => state.setSlaToEdit);

  const getTeamStatusSlasQuery = useGetTeamStatusSlasQuery({
    ...paginationConfig,
    dispatchPagination,
    teamStatusId,
    search: searchValue,
  });

  const deleteTeamStatusSlaMutation = useDeleteteamStatusSlaMutation();

  const columns: ColumnDef<TeamStatusSlaData>[] = [
    {
      header: t('division'),
      accessorKey: 'division_id',
      cell: ({ row }) => {
        const { division_id } = row.original;
        return division.find(division => division.id === division_id)?.name;
      },
    },
    {
      header: t('operation'),
      accessorKey: 'operation_id',
      cell: ({ row }) => {
        const { operation_id } = row.original;
        return operation.find(operation => operation.id === operation_id)?.name;
      },
    },
    {
      header: t('sla-in-hours'),
      accessorKey: 'sla',
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 50,
      meta: { body: { cell: { className: 'max-w-[50px]' } } },
      header: t('actions'),
      cell: ({ row }) => {
        const teamStatusSla = row.original;

        // eslint-disable-next-line react/jsx-indent

        return (
          <div className="flex max-w-[50px] items-center gap-2">
            <IconWithTooltip
              permission="credit.request.teamstatussla.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              loading={removingSlaIds.includes(teamStatusSla.id)}
              onClick={async () => {
                setRemovingSlaIds(prev => [...prev, teamStatusSla.id]);
                await deleteTeamStatusSlaMutation.mutateAsync(teamStatusSla.id, {
                  onSuccess: () => {
                    getTeamStatusSlasQuery.refetch();
                    setSlaToEdit(null);
                  },
                });
                setRemovingSlaIds(prev => prev.filter(id => id !== teamStatusSla.id));
              }}
            />
            <IconWithTooltip
              action="edit"
              permission="credit.request.teamstatussla.update"
              onClick={() => {
                setSlaToEdit(teamStatusSla);
              }}
              title={t('pages.registered-customers.table.icons.edit')}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col overflow-hidden px-4">
      <Form
        form={form}
        onSubmit={form.handleSubmit(values => {
          setSearchValue(values.search);
        })}
        className="mb-2 flex items-end"
      >
        <FormItem name="search" hideLabel className="w-full">
          <Input maxLength={255} className="w-full rounded-br-none rounded-tr-none" />
        </FormItem>

        <Button
          size="icon"
          className="h-[40px] w-[40px] min-w-[40px] rounded-bl-none rounded-tl-none"
          isLoading={getTeamStatusSlasQuery.isLoading || getTeamStatusSlasQuery.isFetching}
        >
          <Search size={16} />
        </Button>
      </Form>

      <DataTableAsync
        {...getTeamStatusSlasQuery}
        columns={columns}
        dispatchPagination={dispatchPagination}
        paginationConfig={paginationConfig}
        data={getTeamStatusSlasQuery?.data?.data ?? []}
        pageCount={getTeamStatusSlasQuery?.data?.totalPages}
      />
    </div>
  );
};
