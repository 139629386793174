import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEraser } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { GiClick } from "react-icons/gi";

import { DatePicker, Drawer, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import ApiSelect from "components/ApiSelect";
import { useCache } from "hooks/cache";

import { Button, FormItem, Input, Select } from "../../../../components";
import { useContractType } from "../../../../hooks/fetch/useContractType";
import { useSubscriber } from "../../../../hooks/fetch/useSubscriber";
import { Formatter } from "../../../../utils/Formatter";
import { useContractStatus } from "../../constants/status";
import { ContractListFilterContext } from "../../context/ContractListFilterContext";
import { CheckBoxList, Title } from "./styles";
import { TagCheckBox } from "./TagCheckBox";
import { ContractListFilterMenuProps } from "./types";

export const ContractListFilterMenu = ({
  close,
  isOpen,
}: ContractListFilterMenuProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const cache = useCache();
  const { getTypesOptions } = useContractType();
  const { getOptions: getSubscribers } = useSubscriber();
  const {
    clearFilterOnlyMenu,
    addFilter,
    hasFilterReseted,
    setMinEndDate,
    minEndDate,
    setMinStartDate,
    minStartDate,
  } = useContext(ContractListFilterContext);
  const { contractStatusData } = useContractStatus();

  const [selectedStatus, setSelectedStatus] = useState<{
    [name: string]: boolean;
  }>({});
  const [selectedFormat, setSelectedFormat] = useState("");

  const handleToggleStatus = (name: string) =>
    setSelectedStatus({
      ...selectedStatus,
      [contractStatusData[name].id]:
        !selectedStatus[contractStatusData[name].id],
    });
  const handleToggleFormat = (name: string) =>
    setSelectedFormat(selectedFormat === name ? "" : name);

  const { icon: CreatedIcon } = contractStatusData.created;
  const { icon: IssuedIcon } = contractStatusData.issued;
  const { icon: SignatureIcon } = contractStatusData.signature;
  const { icon: ValidationIcon } = contractStatusData.validation;
  const { icon: ValidatedIcon } = contractStatusData.validated;
  const { icon: ValidationSignaturesIcon } =
    contractStatusData.validationSignatures;
  const { icon: ValidatedSignaturesIcon } =
    contractStatusData.validatedSignatures;
  const { icon: ReissueIcon } = contractStatusData.reissueDraft;
  const { icon: WaitingIcon } = contractStatusData.waiting;
  const { icon: FullySignedIcon } = contractStatusData.fullySigned;

  const handleClearFilter = () => {
    form.resetFields();
    setSelectedStatus({});
    setSelectedFormat("");
    clearFilterOnlyMenu();
  };

  useEffect(() => {
    if (hasFilterReseted) form.resetFields();
  }, [hasFilterReseted]);

  return (
    <Drawer
      title={t("pages.edocuments.contractlist.filters.menu.title")}
      placement="right"
      onClose={close}
      visible={isOpen}
      width={520}
    >
      <Form form={form}>
        <Title>
          Filtrar por:{" "}
          <a onClick={handleClearFilter}>
            <FaEraser />
            Limpar filtro
          </a>
        </Title>
        <FormItem
          name="number"
          label={t("pages.edocuments.contractlist.filters.menu.number")}
        >
          <Input />
        </FormItem>
        <FormItem
          name="reference"
          label={t("pages.edocuments.contractlist.filters.menu.reference")}
        >
          <Input />
        </FormItem>
        <FormItem
          name="product"
          label={t("pages.edocuments.contractlist.filters.menu.product")}
        >
          <Select
            mode="multiple"
            options={cache.getCreditOptions("product")}
            showSearch
          />
        </FormItem>
        <FormItem
          name="status"
          label={t("pages.edocuments.contractlist.filters.menu.status")}
        >
          <CheckBoxList>
            <TagCheckBox
              name="created"
              isSelected={selectedStatus[contractStatusData.created.id]}
              onClick={handleToggleStatus}
            >
              <CreatedIcon /> <span>{contractStatusData.created.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="issued"
              isSelected={selectedStatus[contractStatusData.issued.id]}
              onClick={handleToggleStatus}
            >
              <IssuedIcon /> <span>{contractStatusData.issued.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="reissueDraft"
              isSelected={selectedStatus[contractStatusData.reissueDraft.id]}
              onClick={handleToggleStatus}
            >
              <ReissueIcon />{" "}
              <span>{contractStatusData.reissueDraft.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="validation"
              isSelected={selectedStatus[contractStatusData.validation.id]}
              onClick={handleToggleStatus}
            >
              <ValidationIcon />{" "}
              <span>{contractStatusData.validation.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="validated"
              isSelected={selectedStatus[contractStatusData.validated.id]}
              onClick={handleToggleStatus}
            >
              <ValidatedIcon /> <span>{contractStatusData.validated.name}</span>
            </TagCheckBox>

            <TagCheckBox
              name="sent"
              isSelected={selectedStatus[contractStatusData.sent.id]}
              onClick={handleToggleStatus}
            >
              <ValidatedIcon /> <span>{contractStatusData.sent.name}</span>
            </TagCheckBox>

            <TagCheckBox
              name="signature"
              isSelected={selectedStatus[contractStatusData.signature.id]}
              onClick={handleToggleStatus}
            >
              <SignatureIcon /> <span>{contractStatusData.signature.name}</span>
            </TagCheckBox>

            <TagCheckBox
              name="validationSignatures"
              isSelected={
                selectedStatus[contractStatusData.validationSignatures.id]
              }
              onClick={handleToggleStatus}
            >
              <ValidationSignaturesIcon />{" "}
              <span>{contractStatusData.validationSignatures.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="waiting"
              isSelected={selectedStatus[contractStatusData.waiting.id]}
              onClick={handleToggleStatus}
            >
              <WaitingIcon /> <span>{contractStatusData.waiting.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="fullySigned"
              isSelected={selectedStatus[contractStatusData.fullySigned.id]}
              onClick={handleToggleStatus}
            >
              <FullySignedIcon />{" "}
              <span>{contractStatusData.fullySigned.name}</span>
            </TagCheckBox>
            <TagCheckBox
              name="validatedSignatures"
              isSelected={
                selectedStatus[contractStatusData.validatedSignatures.id]
              }
              onClick={handleToggleStatus}
            >
              <ValidatedSignaturesIcon />{" "}
              <span>{contractStatusData.validatedSignatures.name}</span>
            </TagCheckBox>
          </CheckBoxList>
        </FormItem>
        <FormItem
          name="contract_type"
          label={t("pages.edocuments.contractlist.filters.menu.type")}
        >
          <ApiSelect
            fetchOptions={async (value, page) =>
              getTypesOptions({ search: value, page })
            }
            showSearch
            listHeight={221}
            breakOptions
          />
        </FormItem>
        <FormItem
          name="effective_start_date"
          label={t("pages.edocuments.contractlist.filters.menu.start_date")}
        >
          <DatePicker
            format="DD/MM/YYYY"
            disabledDate={(current) =>
              minStartDate ? current > minStartDate : false
            }
            onChange={(value) => setMinEndDate(Formatter.dateAmerican(value))}
          />
        </FormItem>
        <FormItem
          name="effective_end_date"
          label={t("pages.edocuments.contractlist.filters.menu.end_date")}
        >
          <DatePicker
            format="DD/MM/YYYY"
            disabledDate={(current) =>
              minEndDate ? current < minEndDate : false
            }
            onChange={(value) => setMinStartDate(Formatter.dateAmerican(value))}
          />
        </FormItem>
        <FormItem
          name="format"
          label={t("pages.edocuments.contractlist.filters.menu.status")}
        >
          <CheckBoxList>
            <TagCheckBox
              name="digital"
              isSelected={selectedFormat === "digital"}
              onClick={handleToggleFormat}
            >
              <FiMonitor />{" "}
              <span>{t("pages.edocuments.contractlist.form.digital")}</span>
            </TagCheckBox>

            <TagCheckBox
              name="manual"
              isSelected={selectedFormat === "manual"}
              onClick={handleToggleFormat}
            >
              <GiClick />{" "}
              <span>{t("pages.edocuments.contractlist.form.manual")}</span>
            </TagCheckBox>
          </CheckBoxList>
        </FormItem>
        <FormItem
          name="subscribers"
          label={t("pages.edocuments.contractlist.filters.menu.signatures")}
        >
          <ApiSelect
            mode="multiple"
            fetchOptions={async (value, page) =>
              getSubscribers({ params: { search: value, page } })
            }
          />
        </FormItem>

        <Button
          style={{ width: "100%" }}
          onClick={() => {
            const values = form.getFieldsValue();
            const filterObject = {
              ...values,
              status: Object.entries(selectedStatus)
                .filter(([key, selected]) => {
                  return key && selected;
                })
                .map(([key]) => key),
            };

            if (values.effective_start_date)
              filterObject.effective_start_date = Formatter.dateAmerican(
                values.effective_start_date,
              );
            if (values.effective_end_date)
              filterObject.effective_end_date = Formatter.dateAmerican(
                values.effective_end_date,
              );

            if (selectedFormat)
              filterObject.is_digital = selectedFormat === "digital";

            addFilter(filterObject);
          }}
        >
          Filtrar
        </Button>
      </Form>
    </Drawer>
  );
};
