import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, InputNumber } from 'antd';
import { FormItem } from 'components';
import { OPERATION_PREPAY_ID, SAP_OPERATIONS } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

import { BPCodeInput } from '../../atoms/BPCodeInput';
import { BusinessUnitSelect } from '../../atoms/BusinessUnitSelect';
import { HarvestIdSelect } from '../../atoms/HarvestIdSelect';
import { RegionIdSelect } from '../../atoms/RegionIdSelect';
import { SapStatusAdvanceInput } from '../../atoms/SapStatusAdvanceInput';
import { SapStatusInput } from '../../atoms/SapStatusInput';
import { SapStatusProgrammedInput } from '../../atoms/SapStatusProgrammedInput';
import { StateIdSelect } from '../../atoms/StateIdSelect';
import { SubsidiaryIdSelect } from '../../atoms/SubsidiaryIdSelect';
import { UserIdInput } from '../../atoms/UserIdInput';
import { UserNameInput } from '../../atoms/UserNameInput';

export const OperationalFields = () => {
  const { creditForm } = useCreditRequestContext();
  const { t } = useTranslation();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const shouldShowSap = useCreditRequestStore(state => state.shouldShowSap);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  return (
    <>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <RegionIdSelect />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <SubsidiaryIdSelect />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <StateIdSelect />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <HarvestIdSelect />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <BusinessUnitSelect />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <UserIdInput />
        <UserNameInput />
      </Col>

      {SAP_OPERATIONS.includes(selectedOperationId) && creditRequestId ? (
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowSap ? '' : 'none' }}>
          <SapStatusInput />
        </Col>
      ) : null}
      {selectedOperationId === OPERATION_PREPAY_ID && creditRequestId ? (
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowSap ? '' : 'none' }}>
          <SapStatusAdvanceInput />
        </Col>
      ) : null}
      <Col
        xs={24}
        sm={18}
        md={12}
        lg={8}
        xl={6}
        xxl={6}
        style={{ display: creditForm.getFieldValue('code_bp') ? '' : 'none' }}
      >
        <BPCodeInput />
      </Col>
      {SAP_OPERATIONS.includes(selectedOperationId) && creditRequestId ? (
        <Col
          xs={24}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          xxl={6}
          style={{ display: creditForm.getFieldValue('status_sap') ? '' : 'none' }}
        >
          <SapStatusProgrammedInput />
        </Col>
      ) : null}

      <Col
        xs={24}
        sm={18}
        md={12}
        lg={8}
        xl={6}
        xxl={6}
        style={{ display: creditForm.getFieldValue('ptax') ? '' : 'none' }}
      >
        <FormItem label={t('credit-limit-ptax')} name="ptax">
          <InputNumber<string>
            min="0.00001"
            maxLength={8}
            formatter={(value: string | number) => Formatter.money(value, 5, null)}
            parser={(value: string) => Formatter.moneyParser(value, 5)}
            disabled
            style={{ width: '100%' }}
          />
        </FormItem>
      </Col>
    </>
  );
};
