import { useMutation } from '@tanstack/react-query';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';

export const useToggleActiveUserMutation = () => {
  return useMutation({
    mutationFn: async (userId: string) => {
      const response = await api.post(`admin/user/${userId}`);
      return response.data.data;
    },
    mutationKey: ['admin/user/delete'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
