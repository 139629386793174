import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { message, RadioChangeEvent } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useUpdateDatasulSyncStatus } from "hooks/fetch/useUpdateDatasulSyncStatus";
import { OptionsType } from "rc-select/lib/interface";
import { ContractSubscriberData } from "types/Contract/ContractSubscriberData";
import { v4 as uuid } from "uuid";

import { ModalStatus } from "../../../../../../components";
import {
  CONTRACT_SIGNATURE_PART_BUYER,
  CONTRACT_SIGNATURE_PART_FOLLOWER,
  CONTRACT_STATUS_UPLOADED_SIGNED_CONTRACT,
  CONTRACT_SIGNATURE_DSELETRONIC,
  CONTRACT_SIGNATURE_ICPBRASIL,
} from "../../../../../../configs/constants";
import { useContract } from "../../../../../../hooks/fetch/useContract";
import { useDraftSignatures } from "../../../../../../hooks/fetch/useDraftSignatures";
import { useSubscriberPart } from "../../../../../../hooks/fetch/useSubscriberPart";
import { useModalMessage } from "../../../../../../hooks/helpers/useModalMessage";
import { useModal } from "../../../../../../hooks/modals/useModal";
import { useContractContext } from "../../../../context/ContractContext";

export const useSignaturesController = () => {
  const {
    send: sendSignatures,
    sendDocusign: sendSignaturesToDocusign,
    isFetching: isSendingSignatures,
    isChangingStatus: isChangingSignatureStatus,
    changeSignatureStatus,
    validateSignatures,
    destroySubscriber,
  } = useDraftSignatures();
  const { getOptions: getSubscribersPart } = useSubscriberPart();
  const {
    contractId,
    updateContract,
    contract,
    hasUploadedSignedContract,
    isValidatedOnSignature,
    isFinished,
    needSignatureValidation,
    canCurrentUserValidateOnSignature,
    isStored,
    isSelectedDocumentMain,
    setSelectedStage,
    updateDocumentProperties,
    isUpdatingDocument,
    isSelectedContractInStatus,
    hasSelectedContractPastStatus,
    isSelectedContractCancelled,
  } = useContractContext();
  const {
    isUploading,
    uploadSignedContract,
    open: getRedirectToDocusignLink,
  } = useContract();
  const { updateDatasulSyncStatus, isUpdating: isUpdatingDatasulSyncStatus } =
    useUpdateDatasulSyncStatus();

  const { handleSuccess } = useModalMessage();
  const { t } = useTranslation();

  const {
    isOpen: isSignaturesSuccessModalOpen,
    close: closeSignaturesSuccessModal,
    open: openSignaturesSuccessModal,
  } = useModal();
  const {
    isOpen: isConfirmSignaturesModalOpen,
    close: closeConfirmSignaturesModal,
    open: openConfirmSignaturesModal,
  } = useModal();
  const {
    isOpen: isCancelSignaturesModalOpen,
    close: closeCancelSignaturesModal,
    open: openCancelSignaturesModal,
  } = useModal();
  const {
    isOpen: isSignatureRequestStatusChangeModalOpen,
    close: closeSignatureRequestStatusChangeModal,
    open: openSignatureRequestStatusChangeModal,
  } = useModal();
  const {
    isOpen: isUploadSignedContractModalOpen,
    close: closeUploadSignedContractModal,
    open: openUploadSignedContractModal,
  } = useModal();

  const [digitalForm] = useForm();

  const [selectedSignatureFormat, setSelectedSignatureFormat] = useState<
    "manual" | "digital" | ""
  >("");
  const [selectedSignatureType, setSelectedSignatureType] = useState("");
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const [signatureRequests, setSignatureRequests] = useState([]);
  const [isEditingSignatures, setIsEditingSignatures] = useState(true);
  const [subscriberPartsOptions, setSubscriberPartsOptions] =
    useState<OptionsType>([]);
  const [isSignedContractUploaded, setIsSignedContractUploaded] =
    useState(false);

  const [signatureRequestBeingEdited, setSignatureRequestBeingEdited] =
    useState(null);

  const handleChangeSignatureFormat = (evt: RadioChangeEvent) =>
    setSelectedSignatureFormat(evt.target.value);

  const handleChangeSignatureType = (evt: RadioChangeEvent) => {
    updateDocumentProperties({ signature_type_id: evt.target.value });
    setSelectedSignatureType(evt.target.value);
  };

  const getNextRoutingOrder = () => {
    subscribers.sort((a, b) => a.routing_order - b.routing_order);
    return subscribers.length
      ? subscribers[subscribers.length - 1].routing_order + 1
      : 1;
  };

  const addNewSubscriber = () => {
    const routingOrder = getNextRoutingOrder();
    const id = uuid();
    setSubscribers((oldSubscribers) => [
      ...oldSubscribers,
      { id, isNew: true, routing_order: routingOrder },
    ]);
    digitalForm.setFieldsValue({
      [`subscribers[${id}][routing_order]`]: routingOrder,
    });
  };

  const clearSubscribers = () => setSubscribers([]);

  const updateSubscribers = (newSubcribers: ContractSubscriberData[]) => {
    setSubscribers(newSubcribers);
    newSubcribers.forEach((subscriber) =>
      digitalForm.setFieldsValue(
        convertContractSubscribersToStateSubscribers({
          ...subscriber,
          social_id: subscriber.social_id ?? "",
          contract_subscriber_part_id:
            subscriber.contract_subscriber_part_id ??
            CONTRACT_SIGNATURE_PART_BUYER,
        }),
      ),
    );
  };

  const checkIsKeyFromRemovedSubscriber = (
    key: string,
    removedSubscriberIndex: number,
  ) => key.match(new RegExp(`subscribers\\[${removedSubscriberIndex}\\]`));

  const findSignatureRequestById = (id: string) =>
    signatureRequests.find((signatureRequest) => signatureRequest.id === id);

  const removeSubscriberFromForm = (
    values: any,
    removedSubscriberIndex: number,
  ) => {
    const newValues = {} as { [name: string]: any };
    Object.keys(values).forEach((key) => {
      if (!checkIsKeyFromRemovedSubscriber(key, removedSubscriberIndex))
        newValues.key = values.key;
      else
        newValues[key] = key.match(".role")
          ? subscriberPartsOptions[0].key
          : "";
    });
    digitalForm.setFieldsValue({ ...newValues });
  };

  const removeSubscriberFromState = (removedSubscriberIndex: number) => {
    const newSubscribers = [...subscribers];
    newSubscribers.splice(removedSubscriberIndex, 1);
    const newSubscribersOrdered = removeRoutingOrderGap(
      removedSubscriberIndex,
      newSubscribers,
    );
    setSubscribers(newSubscribersOrdered);
  };

  const removeRoutingOrderGap = (
    removedSubscriberIndex: number,
    subscribersList: ContractSubscriberData[],
  ) => {
    subscribersList.forEach((subscriber, index) => {
      if (index >= removedSubscriberIndex) {
        subscribersList[index].routing_order = subscriber.routing_order - 1;
        digitalForm.setFieldsValue({
          [`subscribers[${subscriber.id}][routing_order]`]:
            subscriber.routing_order,
        });
      }
    });
    return subscribersList;
  };

  const removeSubscriber = async (id: string) => {
    const subscriberIndex = subscribers.findIndex(
      (subscriber: any) => subscriber.id === id,
    );

    if (subscriberIndex > -1) {
      const subscriberToRemove = subscribers[subscriberIndex];
      if (!subscriberToRemove.isNew)
        await destroySubscriber(subscriberToRemove.id);
      handleSuccess(t("pages.edocuments.emission.signatures.remove.success"));
      const values = digitalForm.getFieldsValue();
      removeSubscriberFromForm(values, subscriberIndex);
      removeSubscriberFromState(subscriberIndex);
      setSignatureRequests((oldSignatureRequests) =>
        oldSignatureRequests.filter((signature) => signature.id === id),
      );
    }
  };

  const handleEditSignatures = () => {
    subscribers.forEach((subscriber) =>
      digitalForm.setFieldsValue(
        convertContractSubscribersToStateSubscribers({
          ...subscriber,
          social_id: subscriber.social_id ?? "",
        }),
      ),
    );
    setIsEditingSignatures(true);
    if (document.activeElement !== document.body)
      (document.activeElement as HTMLElement).blur();
  };

  const handleSaveStatusChange = (values: any) => {
    const statusValue =
      values.status === "pendant"
        ? null
        : values.updateDate.format("YYYY-MM-DD HH:mm:ss");
    changeSignatureRequestStatus(signatureRequestBeingEdited.id, statusValue);
    closeSignatureRequestStatusChangeModal();
  };

  const handleUpdateDatasulSyncStatus = async () => {
    const response = await updateDatasulSyncStatus(contractId);

    if (response) {
      updateContract();
      message.success(
        t("pages.edocuments.emission.signatures.datasulSyncStatusUpdated"),
      );
    }
  };

  const formatSignaturesToApiFormat = (values: any) => {
    const formattedSignatures: any[] = [];
    const foundSubscribers: any[] = [];

    const addNewFormattedSignature = (subscriberId: string) => {
      formattedSignatures.push({});
      foundSubscribers.push(subscriberId);
    };

    const updateFormattedSignature = (
      subscriberId: string,
      formattedKey: string,
      value: any,
    ) => {
      const foundSubscriberIndex = foundSubscribers.findIndex(
        (foundSubscriber) => foundSubscriber === subscriberId,
      );
      formattedSignatures[foundSubscriberIndex] = {
        ...formattedSignatures[foundSubscriberIndex],
        [formattedKey]: formattedKey !== "id" ? value : subscriberId,
      };
    };

    const createOrUpdateFormattedSignatureEntityUsingKey = (key: string) => {
      const value = values[key];
      const formattedKeyRegex = new RegExp(
        "subscribers\\[(.*)\\]\\[(.*)\\]",
        "g",
      );
      const match = formattedKeyRegex.exec(key);

      if (match && match.length > 2) {
        const subscriberId = match[1];
        const formattedKey = match[2];
        const signatureObjectAlreadyExistsInArray =
          foundSubscribers.includes(subscriberId);
        if (!signatureObjectAlreadyExistsInArray)
          addNewFormattedSignature(subscriberId);
        updateFormattedSignature(subscriberId, formattedKey, value);
      }
    };

    const orderFormattedSignatures = () =>
      subscribers.map((subscriber) =>
        formattedSignatures.find(
          (formattedSignature) => formattedSignature.id === subscriber.id,
        ),
      );

    Object.keys(values).forEach(createOrUpdateFormattedSignatureEntityUsingKey);

    return orderFormattedSignatures().map((formattedSignature: any) => ({
      ...formattedSignature,
      email: formattedSignature.email.trim(),
    }));
  };

  const orderSubscribersByRoutingOrder = (
    subscribersToOrder: ContractSubscriberData[],
  ) => {
    subscribersToOrder.sort((a, b) => a.routing_order - b.routing_order);
  };

  const updateRoutingOrder = (subscriberId: string, routingOrder: number) => {
    const foundSubscriberIndex = subscribers.findIndex(
      (subscriber) => subscriber.id === subscriberId,
    );
    const oldSubscribers = [...subscribers];
    oldSubscribers[foundSubscriberIndex] = {
      ...subscribers[foundSubscriberIndex],
      routing_order: routingOrder,
    };
    orderSubscribersByRoutingOrder(oldSubscribers);
    setSubscribers(oldSubscribers);
  };

  const convertContractSubscribersToStateSubscribers = ({
    name,
    email,
    phone_number,
    contract_subscriber_part_id,
    id,
    signed_at,
    social_id,
    routing_order,
  }: ContractSubscriberData) => {
    return {
      [`subscribers[${id}][name]`]: name,
      [`subscribers[${id}][email]`]: email,
      [`subscribers[${id}][phone_number]`]: phone_number,
      [`subscribers[${id}][contract_subscriber_part_id]`]:
        contract_subscriber_part_id,
      [`subscribers[${id}][id]`]: id,
      [`subscribers[${id}][signed_at]`]: signed_at,
      [`subscribers[${id}][social_id]`]: social_id,
      [`subscribers[${id}][routing_order]`]: routing_order,
    };
  };

  const clearSignaturesRequests = () => setSignatureRequests([]);

  const convertContractSubscribersToStateSignatureRequests = (
    subscribersList: ContractSubscriberData[],
  ) => {
    return subscribersList?.map(
      ({
        name,
        email,
        phone_number,
        subscriber_part,
        id,
        signed_at,
        social_id,
        status,
        sync,
      }: any) => ({
        id,
        name,
        email,
        signed_at,
        phoneNumber: phone_number,
        social_id,
        role: {
          name: subscriber_part?.name ?? "teste",
        },
        status,
        sync,
      }),
    );
  };

  const handleSubmitForm = () => {
    digitalForm.validateFields().then(async (values: any) => {
      const formattedSignatures = formatSignaturesToApiFormat(values);

      if (selectedSignatureType === CONTRACT_SIGNATURE_DSELETRONIC) {
        const duplicatedEmails = formattedSignatures.reduce(
          (acc: any, signature: ContractSubscriberData, index: number) => {
            const foundDuplicatedEmails = formattedSignatures.filter(
              (s: any, i: number) => s.email === signature.email && i !== index,
            );
            if (foundDuplicatedEmails)
              foundDuplicatedEmails.forEach((foundDuplicatedEmail) =>
                acc.push({ ...foundDuplicatedEmail }),
              );
            return acc;
          },
          [],
        );

        const duplicatedPhones = formattedSignatures.reduce(
          (acc: any, signature: ContractSubscriberData, index: number) => {
            const foundDuplicatedPhones = formattedSignatures.filter(
              (s: any, i: number) =>
                s.phone_number &&
                s.phone_number === signature.phone_number &&
                i !== index,
            );
            if (foundDuplicatedPhones)
              foundDuplicatedPhones.forEach((foundDuplicatedPhone) =>
                acc.push({ ...foundDuplicatedPhone }),
              );
            return acc;
          },
          [],
        );

        // const duplicatedDocuments = formattedSignatures.reduce(
        //   (acc: any, signature: ContractSubscriberData, index: number) => {
        //     const foundDuplicatedDocuments = formattedSignatures.filter(
        //       (s: any, i: number) => s.social_id && s.social_id === signature.social_id && i !== index,
        //     );
        //     if (foundDuplicatedDocuments)
        //       foundDuplicatedDocuments.forEach(foundDuplicatedDocument => acc.push({ ...foundDuplicatedDocument }));
        //     return acc;
        //   },
        //   [],
        // );

        if (duplicatedEmails.length > 0) {
          digitalForm.setFields(
            duplicatedEmails.map((duplicatedEmail: any) => ({
              name: `subscribers[${duplicatedEmail.id}][email]`,
              errors: [
                t("pages.edocuments.emission.signatures.emailsDuplicated"),
              ],
            })),
          );
        }

        if (duplicatedPhones.length > 0) {
          digitalForm.setFields(
            duplicatedPhones.map((duplicatedEmail: any) => ({
              name: `subscribers[${duplicatedEmail.id}][phone_number]`,
              errors: [
                t("pages.edocuments.emission.signatures.phonesDuplicated"),
              ],
            })),
          );
        }

        // if (duplicatedDocuments.length > 0) {
        //   digitalForm.setFields(
        //     duplicatedDocuments.map((duplicatedEmail: any) => ({
        //       name: `subscribers[${duplicatedEmail.id}][social_id]`,
        //       errors: [t('pages.edocuments.emission.signatures.documentDuplicated')],
        //     })),
        //   );
        // }

        if (duplicatedEmails.length > 0 || duplicatedPhones.length > 0) {
          ModalStatus({
            type: "error",
            description: t("pages.edocuments.emission.signatures.repeatedInfo"),
          });
          return;
        }
      }

      if (
        selectedSignatureType === CONTRACT_SIGNATURE_ICPBRASIL ||
        selectedSignatureType === CONTRACT_SIGNATURE_DSELETRONIC
      ) {
        const duplicatedDocuments = formattedSignatures.reduce(
          (acc: any, signature: ContractSubscriberData, index: number) => {
            const foundDuplicatedDocuments = formattedSignatures.filter(
              (s: any, i: number) =>
                s.social_id &&
                s.social_id === signature.social_id &&
                i !== index,
            );
            if (foundDuplicatedDocuments)
              foundDuplicatedDocuments.forEach((foundDuplicatedDocument) =>
                acc.push({ ...foundDuplicatedDocument }),
              );
            return acc;
          },
          [],
        );

        if (duplicatedDocuments.length > 0) {
          digitalForm.setFields(
            duplicatedDocuments.map((duplicatedEmail: any) => ({
              name: `subscribers[${duplicatedEmail.id}][social_id]`,
              errors: [
                t("pages.edocuments.emission.signatures.documentDuplicated"),
              ],
            })),
          );
        }

        if (duplicatedDocuments.length > 0) {
          ModalStatus({
            type: "error",
            description: t(
              "pages.edocuments.emission.signatures.repeatedInfoIcpBrasil",
            ),
          });
          return;
        }
      }

      const response = await sendSignatures(
        contractId,
        formattedSignatures,
        selectedSignatureFormat,
      );
      if (response) {
        openSignaturesSuccessModal();
        updateContract(response, isSelectedDocumentMain);
        setIsEditingSignatures(false);
        const responseSubscribers = response.subscribers ?? [];
        orderSubscribersByRoutingOrder(responseSubscribers);
        setSubscribers(
          responseSubscribers.map((subscriber: ContractSubscriberData) => ({
            ...subscriber,
            phone_number: subscriber.phone_number.includes("+")
              ? subscriber.phone_number
              : `+55${subscriber.phone_number.replaceAll(/\D/g, "")}`,
          })),
        );
        setSignatureRequests(
          convertContractSubscribersToStateSignatureRequests(
            response.subscribers,
          ),
        );
      }
    });
  };

  const sendToDocusign = async () => {
    ModalStatus({
      subTitle: "Tem certeza que deseja enviar as assinaturas para o Docusign?",
      type: "confirm",
      onOk: async () => {
        const response = await sendSignaturesToDocusign(
          contractId,
          { subscribers: contract.subscribers },
          selectedSignatureFormat,
        );
        if (response) {
          const newWindow = window.open(
            response[1].redirect_url,
            "_blank",
            "noopener,noreferrer",
          );
          if (newWindow) newWindow.opener = null;
          updateContract(response[0], isSelectedDocumentMain);
          setIsSignedContractUploaded(true);
        }
      },
    });
  };

  const handleConfirmSignatures = async () => {
    const response = await validateSignatures(contractId, "after", "approval");
    if (response) {
      updateContract(response, isSelectedDocumentMain);
      closeConfirmSignaturesModal();
    }
  };

  const handleCancelSignatures = async (values: any) => {
    const response = await validateSignatures(
      contractId,
      "after",
      "refuse",
      values,
    );
    if (response) {
      clearSubscribers();
      clearSignaturesRequests();
      setIsEditingSignatures(true);
      updateContract(response, isSelectedDocumentMain);
      closeCancelSignaturesModal();
      setSelectedStage(2);
    }
  };

  const handleOpenSignatureRequestStatusChangeModal = (id: string) => {
    const foundSignatureRequest = findSignatureRequestById(id);
    if (foundSignatureRequest) {
      openSignatureRequestStatusChangeModal();
      setSignatureRequestBeingEdited(foundSignatureRequest);
    }
  };

  const handleUploadSignedContract = async (signedContract: any) => {
    const formData = new FormData();
    formData.append("minuta", signedContract.originFileObj);
    const response = await uploadSignedContract(contractId, formData);
    if (response) {
      updateContract(response, isSelectedDocumentMain);
      setIsSignedContractUploaded(true);
      closeUploadSignedContractModal();
    }
  };

  const changeSignatureRequestStatus = async (
    id: string,
    status: string | null,
  ) => {
    const response = await changeSignatureStatus(id, status);
    if (response) updateContract(response, isSelectedDocumentMain);
  };

  const updateSubscriberPartOptions = async () => {
    const response = await getSubscribersPart();
    if (response) setSubscriberPartsOptions(response.data);
  };
  const redirectToDocusign = async () => {
    const response = await getRedirectToDocusignLink(contract.id);
    if (response) {
      const newWindow = window.open(
        response[0].url,
        "_blank",
        "noopener,noreferrer",
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const hasSignatures = !!signatureRequests?.length;

  const shouldShowBackButton = !!selectedSignatureFormat && !hasSignatures;

  const back = () => {
    if (selectedSignatureType) setSelectedSignatureType("");
    else setSelectedSignatureFormat("");
  };

  const isManual = selectedSignatureFormat === "manual";

  const isSignaturesFormatSelected = selectedSignatureFormat !== "";

  const hasSubscribers = !!subscribers.length;

  const isFinishingSubscriptions = !isEditingSignatures && hasSignatures;

  const isSelectedSignatureFormatDigital =
    selectedSignatureFormat === "digital";

  const shouldShowAddSubscriberButton =
    selectedSignatureFormat &&
    ((isSelectedSignatureFormatDigital && selectedSignatureType) ||
      !isSelectedSignatureFormatDigital) &&
    !subscribers.length &&
    isEditingSignatures &&
    !isStored &&
    !hasSelectedContractPastStatus("sentSignature") &&
    !isSelectedContractCancelled;

  const shouldShowEditSignaturesButton =
    hasSignatures &&
    isSignaturesFormatSelected &&
    hasSubscribers &&
    !isEditingSignatures &&
    !isFinished &&
    !isStored &&
    !hasSelectedContractPastStatus("fullySigned") &&
    !isSelectedContractCancelled;

  const shouldShowCloneSignaturesButton =
    ((isManual && isSignaturesFormatSelected) ||
      (!isManual && selectedSignatureType)) &&
    !isFinished &&
    !isStored &&
    isEditingSignatures &&
    !hasSelectedContractPastStatus("sentSignature") &&
    isSelectedDocumentMain &&
    !isSelectedContractCancelled;

  const shouldShowCloneParentSignaturesButton =
    isSignaturesFormatSelected &&
    !isFinished &&
    !isStored &&
    isEditingSignatures &&
    !hasSelectedContractPastStatus("sentSignature") &&
    !isSelectedDocumentMain &&
    !isSelectedContractCancelled;

  const shouldShowSignaturesForm =
    isSignaturesFormatSelected && hasSubscribers && isEditingSignatures;

  const shouldShowSignaturesRequests =
    hasSignatures && isSignaturesFormatSelected && !isEditingSignatures;

  const shouldShowUploadSignedContractButton =
    isManual &&
    !isSignedContractUploaded &&
    !isValidatedOnSignature &&
    !isSelectedContractCancelled;

  const shouldShowButtons =
    (hasSignatures || hasSubscribers) &&
    !isStored &&
    !isSelectedContractCancelled;

  const shouldShowSendToSignatureButton =
    hasSubscribers && isEditingSignatures && !isSelectedContractCancelled;

  const shouldShowCancelSignaturesButton =
    !isFinished &&
    !isValidatedOnSignature &&
    needSignatureValidation &&
    !isSelectedContractCancelled;

  const areAllSignaturesSigned = !signatureRequests.filter(
    (signatureRequest) => !signatureRequest.signed_at,
  ).length;

  const shouldShowValidateButton =
    needSignatureValidation &&
    isFinishingSubscriptions &&
    !isValidatedOnSignature &&
    isSignedContractUploaded &&
    !isSelectedContractCancelled;

  const shouldDisableUploadSignedContractButton = areAllSignaturesSigned;

  const hasUploadedContractToDocusign =
    contract?.status?.id === CONTRACT_STATUS_UPLOADED_SIGNED_CONTRACT;

  const shouldShowSendToDocusignButton =
    !isManual &&
    isFinishingSubscriptions &&
    !hasUploadedSignedContract &&
    !isValidatedOnSignature &&
    !isSignedContractUploaded &&
    !hasUploadedContractToDocusign &&
    !hasSelectedContractPastStatus("waiting") &&
    !isSelectedContractInStatus("waiting") &&
    !isSelectedContractCancelled;

  const shouldShowRedirectToDocusignButton =
    !isManual &&
    isFinishingSubscriptions &&
    isSelectedContractInStatus("waiting");

  const shouldDisableValidateButton =
    (!!contract?.subscribers?.filter(
      (subscriber) =>
        !subscriber.signed_at &&
        subscriber.contract_subscriber_part_id !==
          CONTRACT_SIGNATURE_PART_FOLLOWER,
    ).length ||
      !canCurrentUserValidateOnSignature) &&
    !isSelectedContractInStatus("validateSignature");

  const fillSubscriber = (
    {
      email,
      phone_number,
      social_id,
      contract_subscriber_part_id,
      name,
    }: ContractSubscriberData,
    subscriberId: string,
  ) => {
    const subscriberIndex = subscribers.findIndex(
      (subscriber) => subscriber.id === subscriberId,
    );
    const oldSubscribers = [...subscribers];
    oldSubscribers[subscriberIndex] = {
      ...oldSubscribers[subscriberIndex],
      name,
      email,
      phone_number: phone_number.includes("+")
        ? phone_number
        : `+55${phone_number.replaceAll(/\D/g, "")}`,
      social_id,
      contract_subscriber_part_id,
    };

    digitalForm.setFieldsValue({
      [`subscribers[${subscriberId}][name]`]: name,
      [`subscribers[${subscriberId}][email]`]: email,
      [`subscribers[${subscriberId}][social_id]`]: social_id ?? "",
      [`subscribers[${subscriberId}][phone_number]`]:
        oldSubscribers[subscriberIndex].phone_number,
    });

    setSubscribers(oldSubscribers);
  };

  const handleCloneMainSignatures = (signatures: ContractSubscriberData[]) => {
    const subscribersToClone = signatures
      .filter(
        (b) => subscribers.filter((c) => c.email === b.email).length === 0,
      )
      .map(
        ({
          email,
          phone_number,
          name,
          social_id,
          contract_subscriber_part_id,
          routing_order,
        }) => ({
          id: uuid(),
          email,
          name,
          social_id,
          contract_subscriber_part_id,
          phone_number: phone_number.includes("+")
            ? phone_number
            : `+55${phone_number.replaceAll(/\D/g, "")}`,
          routing_order,
          isNew: true,
        }),
      );
    setSubscribers((oldSubscribers) => [
      ...oldSubscribers,
      ...subscribersToClone,
    ]);

    subscribersToClone.forEach((subscriber) => {
      digitalForm.setFieldsValue({
        [`subscribers[${subscriber.id}][name]`]:
          subscriber.name.toString() as any,
        [`subscribers[${subscriber.id}][email]`]: subscriber.email,
        [`subscribers[${subscriber.id}][social_id]`]:
          subscriber.social_id ?? "",
        [`subscribers[${subscriber.id}][phone_number]`]:
          subscriber.phone_number,
        [`subscribers[${subscriber.id}][contract_subscriber_part_id]`]:
          subscriber.contract_subscriber_part_id,
        [`subscribers[${subscriber.id}][routing_order]`]:
          subscriber.routing_order,
      });
    });
  };

  useEffect(() => {
    updateSubscriberPartOptions();
  }, []);

  useEffect(() => {
    if (contract && contract?.subscribers?.length) {
      const contractSubscribers = contract.subscribers ?? [];
      orderSubscribersByRoutingOrder(contractSubscribers);
      setSubscribers(
        contractSubscribers.map((subscriber) => ({
          ...subscriber,
          phone_number: subscriber.phone_number.includes("+")
            ? subscriber.phone_number
            : `+55${subscriber.phone_number.replaceAll(/\D/g, "")}`,
        })),
      );
      setSignatureRequests(
        convertContractSubscribersToStateSignatureRequests(
          contract.subscribers,
        ),
      );
      setSelectedSignatureFormat(contract.is_digital ? "digital" : "manual");
      setSelectedSignatureType(contract.signature_type_id as any);
      setIsEditingSignatures(false);
      setIsSignedContractUploaded(hasUploadedSignedContract);
    }
  }, [contract]);

  return {
    shouldShowSignaturesForm,
    shouldShowSendToSignatureButton,
    shouldShowCancelSignaturesButton,
    shouldShowSignaturesRequests,
    shouldShowButtons,
    isManual,
    shouldShowAddSubscriberButton,
    shouldShowEditSignaturesButton,
    handleUploadSignedContract,
    handleOpenSignatureRequestStatusChangeModal,
    handleCancelSignatures,
    handleConfirmSignatures,
    handleSubmitForm,
    handleEditSignatures,
    isSignaturesSuccessModalOpen,
    closeSignaturesSuccessModal,
    isConfirmSignaturesModalOpen,
    openConfirmSignaturesModal,
    isCancelSignaturesModalOpen,
    openCancelSignaturesModal,
    isSignatureRequestStatusChangeModalOpen,
    isUploadSignedContractModalOpen,
    openUploadSignedContractModal,
    handleChangeSignatureFormat,
    addNewSubscriber,
    removeSubscriber,
    handleSaveStatusChange,
    selectedSignatureFormat,
    isValidatedOnSignature,
    isEditingSignatures,
    digitalForm,
    subscribers,
    subscriberPartsOptions,
    signatureRequests,
    isSignedContractUploaded,
    closeConfirmSignaturesModal,
    closeCancelSignaturesModal,
    closeUploadSignedContractModal,
    closeSignatureRequestStatusChangeModal,
    isSendingSignatures,
    isChangingSignatureStatus,
    changeSignatureRequestStatus,
    signatureRequestBeingEdited,
    isUploading,
    hasUploadedSignedContract,
    shouldDisableUploadSignedContractButton,
    shouldShowValidateButton,
    handleUpdateDatasulSyncStatus,
    isUpdatingDatasulSyncStatus,
    shouldShowSendToDocusignButton,
    sendToDocusign,
    shouldShowUploadSignedContractButton,
    shouldShowRedirectToDocusignButton,
    redirectToDocusign,
    shouldDisableValidateButton,
    updateSubscribers,
    areAllSignaturesSigned,
    handleChangeSignatureType,
    selectedSignatureType,
    back,
    shouldShowBackButton,
    isUpdatingDocument,
    fillSubscriber,
    updateRoutingOrder,
    shouldShowCloneParentSignaturesButton,
    handleCloneMainSignatures,
    shouldShowCloneSignaturesButton,
  };
};
