import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus } from "react-icons/fa";

import { useTable } from "compositions/TableDataAsync/hooks/useTable";

import { Breadcrumb, Button } from "../../../components";
// import { Can } from '../../../hooks/ability';
import { Can } from "../../../hooks/ability";
import {
  SContainer,
  SHeader,
  STitlePage,
} from "../../Integrations/FileUpload/styles";
import { useTemplatesController } from "./controllers/useTemplatesController";
import { Form } from "./Form";
import { Table } from "./Table";

export const Templates = () => {
  const { t } = useTranslation();
  const tableController = useTable();
  const queryParameters = new URLSearchParams(window.location.search);
  const templateIdToEdit = queryParameters.get("templateId");

  const {
    isFormOpen,
    toggleForm,
    isEditing,
    closeForm,
    handleTableChange,
    handleEdit,
    handleRemove,
    isFetching,
    templateBeingEditedId,
    searchFilters,
    setSearchFilters,
    setIsEditing,
  } = useTemplatesController({ refresh: tableController.refresh });

  useEffect(() => {
    if (templateIdToEdit) {
      toggleForm();
      setIsEditing(true);
    }
  }, [templateIdToEdit]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t("breadcrumb.edocuments") },
          { title: t("breadcrumb.templates") },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t("pages.edocuments.templates.title")}</STitlePage>

          <Can I="contract.template.store" a="">
            <Button
              status="primary"
              icon={isFormOpen ? <FaMinus /> : <FaPlus />}
              onClick={toggleForm}
            >
              {isEditing
                ? t("pages.edocuments.templates.buttonEdit")
                : t("pages.edocuments.templates.buttonAdd")}
            </Button>
          </Can>
        </SHeader>

        <div>
          {isFormOpen && (
            <Form
              isEditing={isEditing}
              closeForm={closeForm}
              templateId={templateBeingEditedId || templateIdToEdit}
              refresh={tableController.refresh}
            />
          )}

          <Table
            handleChange={handleTableChange}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            isLoading={isFetching}
            controller={tableController}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
          />
        </div>
      </SContainer>
    </>
  );
};
