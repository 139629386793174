import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, TablePaginationConfig } from 'antd';
import { IconWithTooltip } from 'components';
import { TableData } from 'compositions';
import { usePagination } from 'hooks/usePagination';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import sustainabilityApi from 'services/api/sustainability';

import type SustainabilityReportTypeData from '../../../../../@types/data/SustainabilityReportTypeData';
import type DTOStatusHistory from '../../../../../@types/dtos/credit-request/DTOStatusHistory';
import appConfigs from '../../../../../configs/app';
import { columns } from './columns';

export const Sustainability = () => {
  const { t } = useTranslation();

  const { paginationConfig, dispatchPagination } = usePagination({
    initialPagination: { current: 1, total: 1, pageSize: 10 },
  });

  const sustainabilityHistory = useCreditRequestStore(state => state.sustainabilityHistory);
  const setSustainabilityHistory = useCreditRequestStore(state => state.setSustainabilityHistory);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  if (pageStatus.new) return null;

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <TableData
          rowKey="id"
          columns={columns.map(col => {
            switch (col.key) {
              case 'user':
                return {
                  ...col,
                  render: (active: number, record: any) => {
                    return record.user?.name || ' - ';
                  },
                };
              case 'created_at':
                return {
                  ...col,
                  render: (active: number, record: DTOStatusHistory) => {
                    return record.created_at ? moment(record.created_at).format(appConfigs.formatDateFullTime) : ' - ';
                  },
                };
              case 'sustainability_report_status':
                return {
                  ...col,
                  render: (sustainability_report_status: SustainabilityReportTypeData) => {
                    return <span>{sustainability_report_status?.name}</span>;
                  },
                };
              case 'sustainability_decision':
                return {
                  ...col,
                  render: (sustainability_decision: SustainabilityReportTypeData) => {
                    return (
                      <span style={{ color: sustainability_decision?.color }}>{sustainability_decision?.name}</span>
                    );
                  },
                };
              case 'action':
                return {
                  ...col,
                  render: (active: number, record: any) => {
                    return (
                      <>
                        <IconWithTooltip
                          action="view"
                          title={t('pages.sustainability.table.view')}
                          onClick={async () => {
                            window.open(`/sustainability/request-report/${record.id}`, '_blank');
                          }}
                        />
                      </>
                    );
                  },
                };

              default:
                return {
                  ...col,
                  render: (active: number, record: DTOStatusHistory) => {
                    const value: any = record;
                    return value[col.key];
                  },
                };
            }
          })}
          dataSource={sustainabilityHistory ?? []}
          onChange={(pagination: TablePaginationConfig) => {
            dispatchPagination({
              type: 'SET_CURRENT',
              payload: pagination.current,
            });

            sustainabilityApi.reports
              .get({
                credit_request: creditRequestId,
                page: pagination.current,
                per_page: paginationConfig.pageSize,
              })
              .then((res: any) => {
                dispatchPagination({
                  type: 'SET_TOTAL',
                  payload: res.data.data.total,
                });

                setSustainabilityHistory(res.data.data.data ?? []);
              });
          }}
          pagination={paginationConfig}
          tableConfig={{ nopagination: false }}
        />
      </Col>
    </>
  );
};
