import React from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

import { Form } from "antd";
import ApiSelect from "components/ApiSelect";
import { useSerasaCounterparty } from "hooks/fetch/useSerasaCounterparty";

import { Input, Button, FormItem } from "../../../../../components";
import { SFilterContainer } from "./styles";
import { FilterProps } from "./types";

export const Filter = ({ handleFastSearch, form: filterForm }: FilterProps) => {
  const { t } = useTranslation();
  const { getStatusListOptions } = useSerasaCounterparty();

  const form = filterForm ?? Form.useForm()[0];

  const tableDataClearAllFilters = () => {
    form.resetFields();
    handleFastSearch();
  };

  const handleFilter = () => {
    const values = form.getFieldsValue();
    handleFastSearch(values);
  };

  return (
    <SFilterContainer>
      <Form
        form={form}
        name="filter-owner"
        className="form-secondary form-filters grid-filters"
      >
        <div className="filter-search">
          <FormItem
            name="search"
            label={t("pages.serasacounterparty.search_title")}
          >
            <Input placeholder={t(`pages.serasacounterparty.search`)} />
          </FormItem>
        </div>
        <div className="filter-search2">
          <FormItem
            name="status"
            label={t("pages.serasacounterparty.table.status")}
          >
            <ApiSelect fetchOptions={getStatusListOptions} />
          </FormItem>
        </div>
        <div className="filter-button">
          <FormItem label="" className="form-item-without-label">
            <Button
              status="primary"
              text="uppercase"
              htmlType="submit"
              onClick={handleFilter}
            >
              <FaSearch /> {t("form.actions.search")}
            </Button>
          </FormItem>
        </div>
        <div className="filter-clear">
          <Button
            status="secondary"
            size="middle"
            onClick={tableDataClearAllFilters}
          >
            {t("pages.edocuments.contracttypes.buttonClearFilter")}
          </Button>
        </div>
      </Form>
    </SFilterContainer>
  );
};
