import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { REGION_COTTON, SUBSIDIARY_COTTON } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { usePage } from 'hooks/page';
import { PageParams } from 'pages/CreditRequest/types';

import creditRequestApi from '../../../services/api/credit';
import { formatValuesToFormData } from '../../../services/form';
import { useCreditRequestStore } from '../store/use-credit-request-store';
import { useFormatDataToApi } from './useFormatDataToApi';
import { useLoadCreditRequestData } from './useLoadCreditRequestData';

export const useHandleSubmit = () => {
  const navigate = useNavigate();
  const { formatDataToApi } = useFormatDataToApi();
  const { setFormStatus } = useCreditRequestContext();
  const { i18n, t } = useTranslation();
  const cache = useCache();
  const { alertStatus } = usePage();
  const { loadCreditRequestData } = useLoadCreditRequestData();

  const params = useParams<PageParams>();
  const { id: idParam } = params;

  const documentList = useCreditRequestStore(state => state.documentList);
  const depositaryList = useCreditRequestStore(state => state.depositaryList);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const assigneeList = useCreditRequestStore(state => state.assigneeList);
  const isCottonPrepay = useCreditRequestStore(state => state.isCottonPrepay);

  async function handleSubmit() {
    setIsLoading(true);

    const dataFormattedToApi = formatDataToApi();
    let formDataValues = new FormData();
    formDataValues = formatValuesToFormData(
      { ...dataFormattedToApi, number: pageStatus.new ? '' : dataFormattedToApi.number },
      formDataValues,
      ['documents'],
      ['documents'],
      true,
    );
    if (pageStatus.editing || pageStatus.viewing) {
      formDataValues.append('_method', 'PUT');
    }
    formDataValues.append('language', i18n.language);

    if (dataFormattedToApi.is_fundeinfra) {
      formDataValues.append(
        `fundeinfra`,
        cache.getCreditOptions('tax').find(tax => tax.tag === 'fundeinfra')?.tax ?? '0',
      );
    }

    if (isCottonPrepay) {
      formDataValues.append('region_id', REGION_COTTON);
      formDataValues.append('subsidiary_id', SUBSIDIARY_COTTON);
    }

    documentList
      .filter(entry => entry.file)
      .forEach((document, index) => {
        formDataValues.append(`documents[${index}][file]`, document.file);
        formDataValues.append(`documents[${index}][name]`, document.name);
        formDataValues.append(`documents[${index}][type_id]`, document.type_id);
      });

    if (depositaryList.length) {
      depositaryList.forEach((depositary, index) => {
        if (depositary.id !== undefined) formDataValues.append(`depositary[${index}][id]`, depositary.id);
        formDataValues.append(`depositary[${index}][name]`, depositary.name);
        formDataValues.append(`depositary[${index}][rg]`, depositary.rg);
        formDataValues.append(`depositary[${index}][cpf]`, depositary.cpf);
        formDataValues.append(`depositary[${index}][has_spouse]`, depositary.has_spouse ? '1' : '0');
        if (depositary.has_spouse) {
          formDataValues.append(`depositary[${index}][spouse_name]`, depositary.spouse_name);
          formDataValues.append(`depositary[${index}][spouse_rg]`, depositary.spouse_rg);
          formDataValues.append(`depositary[${index}][spouse_cpf]`, depositary.spouse_cpf);
        }
      });
    }

    if (assigneeList.length) {
      assigneeList
        .filter(assignee => assignee.id.includes('new-'))
        .forEach((assignee, index) => {
          formDataValues.append(`registered_assignees[${index}][name]`, assignee.name);
          formDataValues.append(`registered_assignees[${index}][cod_gatec]`, assignee.cod_gatec);
          formDataValues.append(`registered_assignees[${index}][value]`, assignee.value.toString());
        });
    }

    let apiCreditRequest;

    if (idParam && (pageStatus.editing || pageStatus.viewing)) {
      apiCreditRequest = creditRequestApi.requests.update(idParam, formDataValues);
    } else {
      apiCreditRequest = creditRequestApi.requests.store(formDataValues);
    }

    await apiCreditRequest
      .then(async (response: any) => {
        const creditRequestResponse = response.data;

        alertStatus(t('pages.credit-request.messages.success'));
        setFormStatus(null);
        setIsBlocking(false);
        setIsLoading(false);

        if (pageStatus.new) {
          navigate(`/credit-request/view/${creditRequestResponse.id}`, { replace: true });
        } else {
          loadCreditRequestData();
        }
      })
      .catch((err: any) => {
        alertStatus(err, 'error');
        console.warn('error-saving', err);
      });
    // }
  }
  return { handleSubmit };
};
