import React from "react";
import { useTranslation } from "react-i18next";

import { TableData } from "compositions/TableDataAsync";
import { TableTranslation } from "compositions/TableDataAsync/types";
import { ColumnType } from "antd/lib/table";

import { IconWithTooltip } from "../../../../components";
import { Can } from "../../../../hooks/ability";
import { columns } from "./columns";
import { Filter } from "./Filter";
import { TableProps } from "./types";

export const Table = ({
  handleChange,
  handleEdit,
  handleRemove,
  isLoading = false,
  controller,
  setDataForm,
  searchFilters,
  setSearchFilters,
}: TableProps) => {
  const { t } = useTranslation();

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };

    switch (column.key) {
      case "status_origin":
      case "status_next":
      case "position":
        column = {
          ...column,
          render: (record: any) => {
            return record?.name ?? "";
          },
        };
        break;
      case "is_attachment_required":
      case "is_comments_required":
        column = {
          ...column,
          render: (record: any) => {
            if (record)
              return t("pages.sustainability.team-status.table.required.true");
            return t("pages.sustainability.team-status.table.required.false");
          },
        };
        break;
      default:
        break;
    }

    return column;
  };

  const getActions = () => ({
    render: (_: unknown, record: any) => (
      <>
        <Can I="sustainability.rule.update" a="">
          <IconWithTooltip
            action="edit"
            title={t("pages.edocuments.contracttypes.table.edit")}
            onClick={() => handleEdit(record, setDataForm)}
          />
        </Can>
        <Can I="sustainability.rule.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t("pages.admin.changelog.table.icons.remove")}
            onClick={() => handleRemove(record)}
          />
        </Can>
      </>
    ),
  });

  const handleFastSearch = (values = {}) => {
    setSearchFilters(values);
  };

  return (
    <div>
      <Filter handleFastSearch={handleFastSearch} />
      <TableData
        searchRequest={handleChange}
        searchFilters={searchFilters}
        customColumns={customColumns}
        tableConfig={{ getActions }}
        columns={columns}
        width="100%"
        pagination={{ position: ["bottomCenter"] }}
        controller={controller}
        translation={
          {
            pagination: { totalOfRegisters: t("pagination.totalOfRegisters") },
          } as TableTranslation
        }
        rowKey="id"
        loading={isLoading}
      />
    </div>
  );
};
