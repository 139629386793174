import React from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { Can } from 'hooks/ability';
import { DocumentsList } from 'pages/CreditRequest/components/molecules/DocumentsList';
import { AdvanceDataAdvanceProductDelivered } from 'pages/CreditRequest/components/organisms/AdvanceDataAdvanceProductDelivered';
import { AdvancePaymentAdvanceProductDelivered } from 'pages/CreditRequest/components/organisms/AdvancePaymentAdvanceProductDelivered';
import { StatusHistory } from 'pages/CreditRequest/components/organisms/StatusHistory';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { ContractIdInput } from '../atoms/ContractIdInput';

export const AdvanceProductDeliveredForm = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <div>
      <ContractIdInput />

      <AdvancePaymentAdvanceProductDelivered />

      <AdvanceDataAdvanceProductDelivered />

      <Can I="credit.request.attachment.index">
        <DocumentsList />
      </Can>

      {!pageStatus.new ? (
        <PermissionedComponent
          permission="credit.request.attachment.index"
          description={t('permission.message.credit.request.history.index')}
        >
          <StatusHistory />
        </PermissionedComponent>
      ) : null}
    </div>
  );
};
