import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const JsonDataRequesterPersonNameInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t(`pages.credit-request.form.requester_person_id`)}
      name="requester_person.name"
      rules={[{ required: true }]}
    >
      <Input
        disabled={
          pageStatus.viewing || (contractFieldsValue && contractFieldsValue['requester_person.name'] !== undefined)
        }
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
