import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { Can } from 'hooks/ability';
import { CommentsInput } from 'pages/CreditRequest/components/atoms/comments.input';
import { JsonDataContractTypeIdSelect } from 'pages/CreditRequest/components/atoms/JsonDataContractTypeIdSelect';
import { JsonDataPriceInput } from 'pages/CreditRequest/components/atoms/JsonDataPriceInput';
import { JsonDataQuantityInput } from 'pages/CreditRequest/components/atoms/JsonDataQuantityInput';
import { NumberInput } from 'pages/CreditRequest/components/atoms/NumberInput';
import { DocumentsList } from 'pages/CreditRequest/components/molecules/DocumentsList';
import { OperationalFields } from 'pages/CreditRequest/components/organisms/OperationalFields';
import { PersonFieldsMtmInternMarket } from 'pages/CreditRequest/components/organisms/PersonFieldsMtmInternMarket';
import { StatusHistory } from 'pages/CreditRequest/components/organisms/StatusHistory';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { ApprovedValueInput } from '../atoms/ApprovedValueInput';
import { CreditLimitDateInput } from '../atoms/CreditLimitDateInput';

export const OperationMTMForm = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const contractTypeVisible = useCreditRequestStore(state => state.contractTypeVisible);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <>
      {/* Dados Gerais */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.general-data')}</h2>

        <Row gutter={[8, 8]}>
          {!pageStatus.new ? (
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <NumberInput />
            </Col>
          ) : null}

          <PersonFieldsMtmInternMarket />
        </Row>

        <Row gutter={[8, 8]}>
          {/* Contract Type */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataContractTypeIdSelect />
          </Col>

          {contractTypeVisible && (
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <JsonDataPriceInput disabled={pageStatus.viewing} />
            </Col>
          )}

          {/* Quantity */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataQuantityInput />
          </Col>

          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: creditForm.getFieldValue('approved_value') ? '' : 'none' }}
          >
            <ApprovedValueInput disabled />
          </Col>

          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: creditForm.getFieldValue('credit_due_date') ? '' : 'none' }}
          >
            <CreditLimitDateInput
              required={!!creditForm.getFieldValue('credit_due_date')}
              disabled={pageStatus.viewing}
            />
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <OperationalFields />

          {/* Comments */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <CommentsInput rows={5} />
          </Col>
        </Row>
      </div>

      <Can I="credit.request.attachment.index">
        <DocumentsList />
      </Can>

      {!pageStatus.new ? (
        <PermissionedComponent
          permission="credit.request.attachment.index"
          description={t('permission.message.credit.request.history.index')}
        >
          <StatusHistory />
        </PermissionedComponent>
      ) : null}
    </>
  );
};
