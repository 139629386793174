import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';

export const useGetNotificationConfigurationTreeQuery = () => {
  return useQuery({
    queryKey: ['admin/message/tree'],
    queryFn: async () => {
      const response = await api.get('admin/message/tree');
      return response.data;
    },
  });
};
