import React, { useEffect, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import { GrDrag } from "react-icons/gr";

import { AutoComplete } from "@agrodatabr/react-auto-complete";
import { Col, Popover } from "antd";
import { PhoneInput } from "components/PhoneInput";
import { SelectOptionType } from "components/Select/types";
import {
  CONTRACT_SIGNATURE_PART_BUYER,
  CONTRACT_SIGNATURE_PART_FOLLOWER,
  CONTRACT_SIGNATURE_STATUS_AUTORESPONDED,
  CONTRACT_SIGNATURE_STATUS_DECLINED,
} from "configs/constants";
import { OptionData, OptionsType } from "rc-select/lib/interface";
import { useTheme } from "styled-components";
import type { ContractSubscriberData } from "types/Contract/ContractSubscriberData";

import { FormItem, Input, Select } from "../../../../components";
import { Validate } from "../../../../utils/Validate";
import { SubscriberAutoCompleteOption } from "../SubscriberAutoCompleteOption";
import {
  CloseIconWrapper,
  Container,
  RoleSelectWrapper,
  IconsWrapper,
  SorterWrapper,
} from "./styles";

interface Props {
  index: number;
  destroy?: () => void | null | Promise<void>;
  subscriberParts: any[];
  isNew?: boolean;
  canRemove?: boolean;
  isIcpBrasil?: boolean;
  isDigital?: boolean;
  selectSubscriber: (
    option: ContractSubscriberData,
    subscriberId: string,
  ) => void;
  subscriber: ContractSubscriberData;
  updateRoutingOrder: (suscriberId: string, routingOrder: number) => void;
  updateSubscriber?: (subscriber: ContractSubscriberData) => void;
  subscriberOptions: OptionsType;
}

export const Subscriber = ({
  index = 0,
  destroy = () => null,
  subscriberParts = [],
  canRemove,
  isIcpBrasil,
  selectSubscriber,
  isDigital,
  subscriber,
  updateRoutingOrder,
  updateSubscriber,
  subscriberOptions,
}: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const { id, status, signed_at, contract_subscriber_part_id } = subscriber;
  const hasSigned = useMemo(() => !!signed_at, [signed_at]);

  const [selectedSubscriberPart, setSelectedSubscriberPart] =
    React.useState<string>(contract_subscriber_part_id);
  const [routingOrder, setRoutingOrder] = React.useState<number>(
    subscriber.routing_order,
  );

  const isAlertStatus = useMemo(
    () =>
      status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED ||
      status?.id === CONTRACT_SIGNATURE_STATUS_AUTORESPONDED,
    [status?.id],
  );
  const isDeclined = useMemo(
    () => status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED,
    [status?.id],
  );

  const cardStatus = () => {
    if (!isAlertStatus) return "normal";
    if (isDeclined) return "declined";
    return "autoResponded";
  };

  // const getData = async (page?: number, pageSize?: number, search?: string) => {
  //   const response = await getSubscribersOptions({ page, per_page: pageSize, search });
  //   return response;
  // };

  const handleSelectSubscriber = (
    _: unknown,
    option: Omit<
      OptionData & ContractSubscriberData,
      "key" | "label" | "value"
    >,
  ) => {
    selectSubscriber(option as ContractSubscriberData, id);
  };

  const handleChangeSubscriberPart = (value: string | SelectOptionType[]) => {
    const currentValue = Array.isArray(value) ? value[0].key : value;
    setSelectedSubscriberPart(currentValue);
    updateSubscriber({
      ...subscriber,
      contract_subscriber_part_id: currentValue,
    });
  };

  const handleChangeRoutingOrderInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setRoutingOrder(Number(value));
  };

  const handleBlurRoutingOrderInput = () =>
    updateRoutingOrder(subscriber.id, Number(routingOrder));

  const handleChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    updateSubscriber({
      ...subscriber,
      email: evt.target.value,
    });
  };

  const handleTypeSubscriber = (value: string) => {
    updateSubscriber({
      ...subscriber,
      name: value,
    });
  };

  const handleChangeDocument = (evt: React.ChangeEvent<HTMLInputElement>) => {
    updateSubscriber({
      ...subscriber,
      social_id: evt.target.value,
    });
  };

  const handleChangePhoneNumber = (value: string) => {
    updateSubscriber({
      ...subscriber,
      phone_number: value,
    });
  };

  useEffect(() => {
    if (subscriber) {
      setSelectedSubscriberPart(contract_subscriber_part_id);
    }
  }, [subscriber]);

  return (
    <Draggable
      draggableId={id}
      key={id}
      index={index}
      isDragDisabled={!!subscriber.signed_at}
    >
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          status={cardStatus()}
        >
          <IconsWrapper>
            <SorterWrapper>
              {isAlertStatus ? (
                <Popover
                  content={
                    JSON.parse(status?.translations ?? "{}")?.[i18n.language]
                      ?.title ?? ""
                  }
                >
                  <GoAlert
                    color={theme.colors[isDeclined ? "danger" : "warning"]}
                    size={12}
                  />
                </Popover>
              ) : (
                ""
              )}
              {!subscriber.signed_at ? <GrDrag size={12} /> : null}

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem name={`subscribers[${id}][routing_order]`}>
                  <Input
                    type="number"
                    disabled
                    onChange={handleChangeRoutingOrderInput}
                    allowClear={false}
                    min={1}
                    onBlur={handleBlurRoutingOrderInput}
                  />
                </FormItem>
              </Col>
            </SorterWrapper>
            {canRemove && (
              <CloseIconWrapper onClick={destroy}>
                <FaTimes />
              </CloseIconWrapper>
            )}
          </IconsWrapper>

          <FormItem
            style={{
              visibility: "hidden",
              height: 0,
              margin: "0",
              padding: "0",
            }}
            name={`subscribers[${id}][id]`}
          >
            <Input hidden disabled={hasSigned} />
          </FormItem>
          <FormItem
            label={t("pages.edocuments.emission.signatures.subscriberName")}
            name={`subscribers[${id}][name]`}
            rules={[{ required: !subscriber.signed_at }]}
          >
            <AutoComplete
              options={subscriberOptions}
              onSearch={handleTypeSubscriber}
              onSelect={handleSelectSubscriber}
              customOption={(option) => (
                <SubscriberAutoCompleteOption option={option} />
              )}
              disabled={hasSigned}
              listHeight={211}
            />
          </FormItem>
          <FormItem
            label={t("pages.edocuments.emission.signatures.subscriberEmail")}
            name={`subscribers[${id}][email]`}
            rules={[{ required: !subscriber.signed_at, type: "email" }]}
          >
            <Input
              type="email"
              maxLength={255}
              autoComplete="none"
              disabled={hasSigned}
              onChange={handleChangeEmail}
            />
          </FormItem>
          {isDigital ? (
            <FormItem
              label={t(
                "pages.edocuments.emission.signatures.subscriberDocument",
              )}
              name={`subscribers[${id}][social_id]`}
              rules={[
                {
                  required: !subscriber.signed_at
                    ? isIcpBrasil &&
                      selectedSubscriberPart !==
                        CONTRACT_SIGNATURE_PART_FOLLOWER
                    : false,
                },
                {
                  validator(_, value) {
                    const formattedValue = value?.replaceAll(/\D/g, "") ?? "";
                    if (
                      formattedValue.length !== 0 &&
                      ((formattedValue.length !== 11 &&
                        formattedValue.length !== 14) ||
                        (!Validate.cpf(value) && !Validate.cnpj(value)))
                    ) {
                      return Promise.reject(
                        t(
                          "pages.edocuments.emission.signatures.errors.invalidDocument",
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                allowClear={false}
                disabled={hasSigned}
                autoFocus={false}
                onChange={handleChangeDocument}
              />
            </FormItem>
          ) : (
            <div />
          )}
          <FormItem
            label={t("pages.edocuments.emission.signatures.smsConfirm")}
            name={`subscribers[${id}][phone_number]`}
            rules={[
              { required: !subscriber.signed_at },
              {
                validator(_, value) {
                  if (!Validate.phone(value ?? "")) {
                    return Promise.reject(
                      t(
                        "pages.edocuments.emission.signatures.errors.invalidPhone",
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              type="phone"
              placeholder="(__) _____-____"
              disabled={hasSigned}
              onChange={handleChangePhoneNumber}
            />
          </FormItem>

          <div>
            <RoleSelectWrapper>
              <span>{t("pages.edocuments.emission.signatures.role")}:</span>
              <FormItem
                style={{ width: "150px" }}
                name={`subscribers[${id}][contract_subscriber_part_id]`}
                rules={[{ required: !subscriber.signed_at }]}
                initialValue={
                  contract_subscriber_part_id || CONTRACT_SIGNATURE_PART_BUYER
                }
              >
                <Select
                  options={subscriberParts}
                  allowClear
                  disabled={hasSigned}
                  onChange={handleChangeSubscriberPart}
                />
              </FormItem>
            </RoleSelectWrapper>
          </div>
        </Container>
      )}
    </Draggable>
  );
};
