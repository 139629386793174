import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TablePaginationConfig } from "antd";

import { ModalStatus } from "../../../../components";
import { useContractType } from "../../../../hooks/fetch/useContractType";
import { ContractTypesProps } from "./types";

export const useContractTypes = ({ refresh }: ContractTypesProps) => {
  const { t } = useTranslation();
  const { getTypes, isFetching, deleteType } = useContractType();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [contractBeingEditedId, setContractBeingEditedId] = useState("");
  const [searchFilters, setSearchFilters] = useState<object>();

  const closeForm = () => {
    setIsFormOpen(false);
    setContractBeingEditedId("");
    setIsEditing(false);
  };

  const openForm = () => setIsFormOpen(true);

  const toggleForm = () => {
    if (!isFormOpen) openForm();
    else closeForm();
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    { parent, ...filters }: any,
  ) => {
    const { data, total } = await getTypes(
      {
        page: pagination.current,
        per_page: pagination.pageSize,
        ...filters,
      },
      parent,
    );

    return { data, total };
  };

  const handleEdit = (id: string) => {
    openForm();
    setContractBeingEditedId(id);
    setIsEditing(true);
  };

  const remove = async (id: string) => {
    const response = await deleteType(id);
    if (response) {
      refresh();
      ModalStatus({
        type: "success",
        title: t(`success`),
        description: t("pages.edocuments.templates.table.deleteSuccess"),
      });
    }
  };

  const handleRemove = async (id: string) => {
    ModalStatus({
      type: "delete",
      description: t("pages.edocuments.templates.table.okDelete"),
      onOk: () => remove(id),
    });
  };

  return {
    closeForm,
    openForm,
    isFormOpen,
    toggleForm,
    isEditing,
    setIsEditing,
    isFetching,
    handleTableChange,
    handleEdit,
    handleRemove,
    contractBeingEditedId,
    setSearchFilters,
    searchFilters,
  };
};
