import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { RadioChangeEvent } from "antd/lib/radio";
import { SelectValue } from "antd/lib/select";
import { ModalStatus } from "components";
import { OptionsType } from "rc-select/lib/interface";
import type { ContractTypeData } from "types/Contract/ContractTypeData";
import { v4 as uuid } from "uuid";

import { CONTRACT_TYPE_FIELD_TYPE_COMBO } from "../../../../configs/constants";
import { useContractType } from "../../../../hooks/fetch/useContractType";
// import { formatValuesToFormData } from '../../../../services/form';
import { useUser } from "../../../../hooks/fetch/useUsers";
import { DTOContractTypeField } from "../../../../types/Contract/DTOContractTypeField";
import { FormControllerProps, PropNameOptions } from "./types";

export const useFormController = ({
  closeForm,
  refresh,
  form,
  contractId,
}: FormControllerProps) => {
  const {
    storeType,
    updateType,
    showType,
    getTypesOptions,
    isStoring,
    destroyField,
  } = useContractType();
  const { t } = useTranslation();
  const { getOptionsUsers } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isMain, setIsMain] = useState<boolean | undefined>();
  const [fields, setFields] = useState<DTOContractTypeField[]>([]);
  const [isVerifyBeforeSignChecked, setIsVerifyBeforeSignChecked] =
    useState<boolean>(false);
  const [isVerifyAfterSignChecked, setIsVerifyAfterSignChecked] =
    useState<boolean>(false);
  const [isFixation, setIsFixation] = useState<boolean>(false);
  const [
    afterUsersThatAlreadyCanValidate,
    setAfterUsersThatAlreadyCanValidate,
  ] = useState<OptionsType>([]);
  const [
    beforeUsersThatAlreadyCanValidate,
    setBeforeUsersThatAlreadyCanValidate,
  ] = useState<OptionsType>([]);
  const [editedContractType, setEditedContractType] = useState<
    undefined | ContractTypeData
  >();
  const [hideAllowNotSigned, setHideAllowNotSigned] = useState(false);
  const [contractTypeData, setContractTypeData] = useState<ContractTypeData>();

  const handleChangeVerifyBeforeSign = (e: RadioChangeEvent) => {
    if (!e.target.value) form.setFieldsValue({ beforeUsers: [] });
    setIsVerifyBeforeSignChecked(e.target.value);
  };

  const handleChangeVerifyAfterSign = (e: RadioChangeEvent) => {
    if (!e.target.value) form.setFieldsValue({ afterUsers: [] });
    setIsVerifyAfterSignChecked(e.target.value);
  };

  const handleChangeIsFixation = (e: RadioChangeEvent) => {
    setIsFixation(e.target.value);
  };

  const handleChangeIsMainContract = ({
    target: { value },
  }: RadioChangeEvent) => setIsMain(value);

  const formatAfterAndBeforeUsersToOptionsType = (afterOrBeforeUsers: any[]) =>
    afterOrBeforeUsers
      .map((afterOrBeforeUser) => afterOrBeforeUser.user)
      .filter((user) => user)
      .map((user) => ({ key: user.id, value: user.id, label: user.name }));

  const getEditedContractData = async () => {
    setIsLoading(true);
    const contractData = await showType(contractId);
    if (contractData) {
      setEditedContractType(contractData);
      setContractTypeData(contractData);
      setIsMain(contractData.is_parent);
      setIsVerifyBeforeSignChecked(contractData.verify_before_sign);
      setIsVerifyAfterSignChecked(contractData.verify_after_sign);
      setAfterUsersThatAlreadyCanValidate(
        formatAfterAndBeforeUsersToOptionsType(contractData.after_users ?? []),
      );
      setBeforeUsersThatAlreadyCanValidate(
        formatAfterAndBeforeUsersToOptionsType(contractData.before_users ?? []),
      );
      setIsFixation(contractData.is_fixation);
      setHideAllowNotSigned(contractData.allow_not_issued_dependency);
      form.setFieldsValue({
        ...contractData,
        parents: contractData.parents.map((parent) => parent.parent.id),
        products: contractData.products.map((product) => product.product_id),
        beforeUsers: contractData.before_users
          .filter((beforeUser) => beforeUser.user)
          .map((beforeUser) => beforeUser.user.id),
        afterUsers: contractData.after_users
          .filter((afterUser) => afterUser.user)
          .map((afterUser) => afterUser.user.id),
        group_type_id: contractData.group?.id,
      });
      setFields(contractData.fields.sort((a, b) => a.order - b.order));
    }
    setIsLoading(false);
  };

  const searchRequest = async (value: string, page?: number) => {
    const response = await getTypesOptions({ search: value, page }, "parent");
    return response ?? { data: [] as any[], lastPage: 0 };
  };

  const searchRequestValidators = async (value: string, page: number) => {
    const response = await getOptionsUsers({
      params: { search: value, page, active: "1" },
    });
    return { data: response.data, lastPage: response.lastPage };
  };

  const storeOrUpdate = async (values: any, action: string, id?: string) => {
    const {
      name,
      is_parent,
      parent_id,
      verify_before_sign,
      verify_after_sign,
      beforeUsers,
      afterUsers,
      is_fixation,
      doc_type,
      products,
      purchase_type_id,
      allow_accessory_complete,
      is_export,
      allow_not_signed_dependency,
      allow_not_issued_dependency,
      can_template,
      can_upload,
      parents,
      contract_group_id,
    } = values;

    const sendFields = [] as any;

    fields.forEach((value: DTOContractTypeField, index: number) => {
      const newField = {
        name: value.name,
        display: value.display,
        is_required: value.is_required,
        type_id: value.type_id,
        default_value: value.default_value,
        order: index,
      } as any;

      if (value.type_id === CONTRACT_TYPE_FIELD_TYPE_COMBO)
        newField.json_data = value.json_data;

      if (contractId && !value.isNew) newField.id = value.id;

      newField.columns = [];

      value.columns.forEach((column: DTOContractTypeField) => {
        const newColumn = {
          name: column.name,
          display: column.display,
          is_required: column.is_required,
          type_id: column.type_id,
          default_value: value.default_value,
          order: value.order,
        } as any;

        if (column.type_id === CONTRACT_TYPE_FIELD_TYPE_COMBO)
          newColumn.json_data = column.json_data;

        if (contractId) {
          if (!column.isNew) newColumn.id = column.id;
        }

        newField.columns.push(newColumn);
      });
      sendFields.push(newField);
    });

    const type = {
      name,
      parent_id,
      is_parent,
      fields: sendFields,
      verify_before_sign,
      verify_after_sign,
      beforeUsers,
      afterUsers,
      is_fixation,
      purchase_type_id,
      products,
      doc_type,
      allow_accessory_complete,
      is_export,
      allow_not_issued_dependency,
      allow_not_signed_dependency:
        allow_not_issued_dependency || allow_not_signed_dependency,
      can_template,
      can_upload,
      parents,
      contract_group_id,
    };

    return action === "edit" ? updateType(id, type) : storeType(type);
  };

  const handleSend = async (values: any, action: string, id?: string) => {
    form.validateFields().then(async () => {
      const response = await storeOrUpdate(values, action, id);
      if (response) {
        refresh();
        ModalStatus({
          onOk: closeForm,
          type: "success",
          description:
            action === "register"
              ? t("pages.edocuments.contracttypes.form.register.success")
              : t("pages.edocuments.contracttypes.form.edit.success"),
        });
      }
    });
  };

  const handleEdit = (id: string, values: any) =>
    handleSend(values, "edit", id);

  const handleRegister = (values: any) => handleSend(values, "register");

  const addField = () =>
    setFields((oldFields) => [
      ...oldFields,
      {
        id: uuid(),
        type_id: "",
        display: "",
        is_required: false,
        columns: [],
        isNew: true,
      },
    ]);

  const removeCustomField = (id: string) => {
    const removedField = fields.find((field) => field.id === id);
    setFields((oldFields) => [...oldFields.filter((field) => field.id !== id)]);
    if (contractId && removedField && !removedField.isNew) destroyField(id);
  };

  const updateField = (propName: PropNameOptions, value: string, id: string) =>
    setFields((oldFields) => [
      ...oldFields.map((field) =>
        field.id === id ? { ...field, [propName]: value } : field,
      ),
    ]);

  const updateTableField = (
    propName: PropNameOptions,
    value: string,
    id: string,
    parentId: string,
  ) => {
    setFields((oldFields) => [
      ...oldFields.map((field) => {
        if (field.id === parentId)
          field.columns = field.columns.map((column) =>
            column.id === id ? { ...column, [propName]: value } : column,
          );
        return field;
      }),
    ]);
  };

  const updateFields = (updatedFields: DTOContractTypeField[]) =>
    setFields(updatedFields);

  const handleChangeSelectField = (
    propName: PropNameOptions,
    value: SelectValue,
    id: string,
    parentId = "",
  ) =>
    parentId
      ? updateTableField(propName, value.toString(), id, parentId)
      : updateField(propName, value.toString(), id);

  const handleChangeField = (
    propName: PropNameOptions,
    {
      target,
    }:
      | ChangeEvent<HTMLInputElement>
      | RadioChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
    id: string,
    parentId = "",
  ) =>
    parentId
      ? updateTableField(propName, target.value, id, parentId)
      : updateField(propName, target.value, id);

  const getNewEmptyField = (parentId = ""): DTOContractTypeField =>
    parentId
      ? {
          display: "",
          id: uuid(),
          type_id: "",
          is_required: false,
          parent_id: parentId,
          columns: [],
          isNew: true,
        }
      : {
          display: "",
          id: uuid(),
          type_id: "",
          is_required: false,
          columns: [],
          isNew: true,
        };

  const addNewFieldToTable = (tableFieldId: string) => {
    setFields((oldFields) =>
      oldFields.map((field) => {
        if (field.id === tableFieldId)
          field.columns.push(getNewEmptyField(tableFieldId));
        return field;
      }),
    );
  };

  const removeFieldFromTable = (tableFieldId: string, childFieldId: string) => {
    setFields((oldFields) =>
      oldFields.map((field) => {
        if (field.id === tableFieldId)
          field.columns = field.columns.filter(
            (child) => child.id !== childFieldId,
          );
        return field;
      }),
    );
    if (contractId) destroyField(childFieldId);
  };

  return {
    setIsMain,
    isMain,
    isLoading,
    setIsLoading,
    handleEdit,
    handleRegister,
    handleChangeIsMainContract,
    getEditedContractData,
    searchRequest,
    fields,
    addField,
    handleChangeField,
    removeCustomField,
    addNewFieldToTable,
    removeFieldFromTable,
    handleChangeSelectField,
    isStoring,
    searchRequestValidators,
    handleChangeVerifyBeforeSign,
    isVerifyBeforeSignChecked,
    handleChangeVerifyAfterSign,
    isVerifyAfterSignChecked,
    afterUsersThatAlreadyCanValidate,
    beforeUsersThatAlreadyCanValidate,
    handleChangeIsFixation,
    isFixation,
    updateFields,
    editedContractType,
    setHideAllowNotSigned,
    hideAllowNotSigned,
    contractTypeData,
  };
};
