import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { Formatter } from 'utils/Formatter';

type JsonDataInterestRateInputProps = {
  isRequired?: boolean;
};

export const JsonDataInterestRateInput = ({ isRequired = true }: JsonDataInterestRateInputProps) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_interest_rate')}
      name="json_data.interest_rate"
      rules={[{ required: isRequired }]}
      initialValue={0}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.decimal(value, 2, null, null, '%')}
        parser={(value: string) => Formatter.decimalParser(value)}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
