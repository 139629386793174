import React from "react";
import { useTranslation } from "react-i18next";

import { TableData } from "compositions/TableDataAsync";
import { TableTranslation } from "compositions/TableDataAsync/types";
import { ColumnType } from "antd/lib/table";

import SerasaParameterizationData from "../../../../@types/data/SerasaParameterizationData";
import { IconWithTooltip } from "../../../../components";
import { Can } from "../../../../hooks/ability";
import { Formatter } from "../../../../utils/Formatter";
import { columns } from "./columns";
import { TableProps } from "./types";

export const Table = ({
  handleChange,
  handleEdit,
  setVisibleForm,
  setDataForm,
  isFetching = false,
  controller,
}: TableProps) => {
  const { t } = useTranslation();
  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };
    switch (column.key) {
      case "value":
        column = {
          ...column,
          render: (value: any, row: any) => {
            if (row.name === "restriction_value")
              return `${Formatter.money(value, 2)}`;
            return value;
          },
        };
        break;
      default:
        break;
    }
    return column;
  };

  const getActions = () => ({
    render: (_: unknown, record: SerasaParameterizationData) => (
      <>
        <Can I="serasa.config.update" a="">
          <IconWithTooltip
            action="edit"
            title={t("pages.edocuments.contracttypes.table.edit")}
            onClick={() => handleEdit(record, setVisibleForm, setDataForm)}
          />
        </Can>
      </>
    ),
  });

  return (
    <div>
      <TableData
        searchRequest={handleChange}
        customColumns={customColumns}
        tableConfig={{ getActions }}
        columns={columns}
        width="100%"
        pagination={{ position: ["bottomCenter"] }}
        controller={controller}
        loading={isFetching}
        translation={
          {
            pagination: { totalOfRegisters: "Total de registros" },
          } as TableTranslation
        }
      />
    </div>
  );
};
