import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { message } from "antd";
import { ModalStatus } from "components";
import { downloadHandler } from "services/download";

import AttachmentData, { AttachmentInputTranslation } from "../types";

const defaultPagination = {
  page: 1,
  perPage: 10,
  total: 1,
};

export const useAttachment = (
  fileUploadList: any[],
  setFileUploadList: any,
  translation: AttachmentInputTranslation,
  downloadMultiple?: (ids: string[]) => Promise<void>,
) => {
  const { t } = useTranslation();
  const fileUploadListRef = useRef(0);

  const [pagination, setPagination] = useState(defaultPagination);
  const [fileList, setFileList] = useState<AttachmentData[]>([]);
  const [, setSideFileUploadList] = useState<unknown[]>([]);

  const loadFiles = async (
    getFiles: (params: any) => Promise<{
      data: {
        data: AttachmentData[];
        total: number;
        per_page: number;
        page: number;
      };
    }>,
    params: any = {},
  ) => {
    const response = await getFiles(params);
    setFileList(response.data.data);
  };

  const clearFileUploadList = () => setFileUploadList([]);

  const handleBeforeUpdate = () => {
    // setFileUploadList([file, ...fileUploadList]);
    return false;
  };

  const handleRemoveUpdate = (file: any) => {
    const index = fileUploadList.indexOf(file);
    const fileRemoved = fileUploadList[index];
    const newFileUploadList = fileUploadList.filter(
      (fileItem) => fileItem !== fileRemoved,
    );
    setFileUploadList(newFileUploadList);
    return false;
  };

  const updatePagination = (response: any) => {
    setPagination({
      page: response.data.page ?? 1,
      perPage: response.data.per_page ?? 10,
      total: response.data.total ?? response.length,
    });
  };

  const removeFileFromState = (id: string) =>
    setFileList((oldFileList) =>
      oldFileList.filter((oldFile) => oldFile.id !== id),
    );

  const removeFile = async (
    handleRemove: (id: any, params: any) => Promise<any>,
    file: AttachmentData,
  ) => {
    try {
      const response = await handleRemove(file.id, {
        params: {
          page: pagination.page,
          per_page: pagination.perPage,
        },
      });
      updatePagination(response);
      removeFileFromState(file.id);
      ModalStatus({
        title: t("success"),
        type: "success",
        description: translation.alerts?.destroy?.success,
      });
    } catch (err) {
      ModalStatus({
        title: t("error"),
        description: translation.alerts?.destroy?.error,
        type: "error",
      });
    }
  };

  const downloadFile = async (
    handleDownload: (params: any) => Promise<any>,
    file: AttachmentData,
  ) => {
    downloadHandler(await handleDownload(file.id));
  };

  const previewFile = async (
    file: AttachmentData,
    handlePreview?: (file: AttachmentData) => Promise<any>,
  ) => {
    if (handlePreview) downloadHandler(await handlePreview(file));
  };

  const handleDownloadAllFiles = async () => {
    if (downloadMultiple) {
      message.loading(translation.downloadingAllMessage, 3);
      const savedFiles = fileList.filter((document: any) => !!document.id);
      await downloadMultiple(savedFiles.map((document: any) => document.id));
    }
  };

  // useEffect(() => {
  //   if (sideFileUploadList.length !== fileUploadList.length)
  //     setFileUploadList(sideFileUploadList);
  // }, [sideFileUploadList]);

  useEffect(() => {
    if (fileUploadList.length !== fileUploadListRef.current) {
      setSideFileUploadList(fileUploadList);
      fileUploadListRef.current = fileUploadList.length;
    }
  }, [fileUploadList]);

  return {
    handleBeforeUpdate,
    handleRemoveUpdate,
    fileList,
    fileUploadList,
    loadFiles,
    removeFile,
    downloadFile,
    pagination,
    previewFile,
    clearFileUploadList,
    handleDownloadAllFiles,
  };
};
