import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber, InputNumberProps } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const ProviderGatecInput = (props: InputNumberProps) => {
  const { t } = useTranslation();
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem label={t('gatec-provider-code')} name="requester_person.cod_gatec" rules={[{ required: true }]}>
      <InputNumber disabled={pageStatus.viewing} {...props} />
    </FormItem>
  );
};
