import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { cn } from 'lib/utils';

type Props = {
  missingHours: number;
  metaHours: number;
  isKanban?: boolean;
};

const HoursTooltip = ({
  missingHours,
  metaHours,
  children,
}: {
  missingHours: number;
  metaHours: number;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent side="bottom" className="z-20">
          <div className="flex flex-col gap-2">
            <span>
              <strong>{t('remainig-hours')}:</strong> <span className="font-normal">{missingHours}h</span>
            </span>
            <span>
              <strong>Meta:</strong>{' '}
              <span className="font-normal">{typeof metaHours !== 'number' ? t('not-provided') : `${metaHours}h`}</span>
            </span>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const SlaTimer = ({ missingHours, metaHours, isKanban }: Props) => {
  const { t } = useTranslation();

  const isOk = missingHours > 4;
  const isWarning = missingHours > 0 && missingHours <= 4;
  const isDanger = missingHours <= 0;

  const pulsingBg = cn(
    'w-full h-full animate-pulse opacity-[0.005] flex justify-center items-center pointer-events-none',
    isWarning && 'bg-yellow-400/20',
    isDanger && 'bg-red-400/20',
  );

  const parsedMissingHours = missingHours < -999 ? -999 : missingHours;

  if (typeof missingHours !== 'number' || !metaHours) return null;

  return (
    <div className={cn('pointer-events-none absolute right-0 top-0 z-10 h-full w-full')}>
      <div className={pulsingBg} />

      {isKanban ? (
        <div
          className={cn(
            'pointer-events-auto absolute -top-1 left-1/2 flex -translate-x-1/2 transform cursor-help items-center justify-center rounded-md px-2 py-1 text-[0.6rem] font-bold text-white',
            isOk && 'bg-green-600',
            isWarning && 'bg-primary',
            isDanger && 'bg-red-600',
          )}
        >
          <HoursTooltip missingHours={missingHours} metaHours={metaHours}>
            {`${parsedMissingHours} ${t('hours')}`}
          </HoursTooltip>
        </div>
      ) : (
        <div className="pointer-events-auto absolute left-0 top-0 flex h-full w-full cursor-help items-center justify-center px-4 font-bold">
          <HoursTooltip missingHours={missingHours} metaHours={metaHours}>
            {`${parsedMissingHours} ${t('hours')}`}
          </HoursTooltip>
        </div>
      )}
    </div>
  );
  return;
};
