import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaComment, FaFileCode, FaFileUpload, FaHourglassStart } from 'react-icons/fa';
import { GiPeanut } from 'react-icons/gi';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip, ModalStatus } from 'components';
import { DataTableAsync } from 'components/ui/data-table';
import Loading from 'components/ui/loading';
import { NotificationBadge } from 'components/ui/notification-badge';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Progress } from 'components/ui/progress';
import { Switch } from 'components/ui/switch';
import { CONTRACT_STATUS_REISSUE_DRAFT, CONTRACT_STATUS_REQUEST_NEW_SIGNATURES } from 'configs/constants';
import { ContractDatasource } from 'configs/enum';
import { useAuth } from 'hooks/auth';
import { usePagination } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { Calendar } from 'lucide-react';
import { ContractData } from 'types/Contract/ContractData';

import { useGetContractDependenciesQuery } from '../api/use-get-contract-dependencies.query';
import { useGetContractsQuery } from '../api/use-get-contracts.query';
import { useToggleActiveContractMutation } from '../api/use-toggle-active-contract.mutation';
import { RefusalsPopover } from '../components/refusals-popover';
import { SignaturesPopover } from '../components/signatures-popover';
import { Tag } from '../components/Tag';
import { UserIcon } from '../components/user-icon';
import { useContractStatus } from '../constants/status';
import { ContractListFilterContext } from '../context/ContractListFilterContext';
import { useContractListStore } from '../store/useContractListStore';
import { EmptyTable } from './EmptyTable';

export const ContractTable = ({ acessoryId }: { acessoryId?: string }) => {
  const { dispatchPagination, paginationConfig } = usePagination();
  const { t } = useTranslation();
  const { getContractStatusDataById } = useContractStatus();
  const { permissions } = useAuth();
  const { filter } = useContext(ContractListFilterContext);
  const setTotal = useContractListStore(state => state.setTotal);

  const getContractsQuery = acessoryId
    ? useGetContractDependenciesQuery(acessoryId, {
        ...paginationConfig,
        filter,
        dispatchPagination,
      })
    : useGetContractsQuery({
        ...paginationConfig,
        filter,
        dispatchPagination,
      });

  const toggleContractActiveMutation = useToggleActiveContractMutation();

  const handleSwitchChange = async (record: any) => {
    if (!permissions.includes('contract.active'))
      return ModalStatus({
        type: 'error',
        title: t('modal.error'),
        subTitle: t('noPermission'),
      });
    await toggleContractActiveMutation.mutateAsync(record.id);
    getContractsQuery.refetch();
  };

  useEffect(() => {
    setTotal(getContractsQuery.data?.total ?? 0);
  }, [getContractsQuery.data?.total]);

  const columns = useMemo(
    (): ColumnDef<ContractData>[] => [
      {
        header: ({ table }) => (
          <div className="flex gap-2 items-center">
            {acessoryId ? null : (
              <button
                className="h-6 w-6 min-w-6 flex items-center justify-center border border-gray-200 bg-gray-50 rounded-lg"
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  table.toggleAllRowsExpanded();
                }}
              >
                {table.getIsAllRowsExpanded() ? '-' : '+'}
              </button>
            )}
            <span>{t('number')}</span>
          </div>
        ),
        accessorKey: 'number',

        cell: ({ row }) => {
          const { number, dependency_count } = row.original;
          return (
            <div className="flex gap-2 items-center">
              <NotificationBadge count={dependency_count} badgeClassName="-top-2 left-[60%]">
                <button
                  className={cn(
                    'p-1 rounded-md border-gray-200 border w-6 h-6 flex items-center justify-center',
                    row.getCanExpand() ? '' : 'opacity-0',
                  )}
                  type="button"
                  {...{
                    onClick: () => {
                      const handler = row.getToggleExpandedHandler();
                      handler();
                    },
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? '-' : '+'}
                </button>
              </NotificationBadge>

              <span className="whitespace-nowrap">{number}</span>
            </div>
          );
        },
      },
      {
        header: t('type-and-format'),
        accessorKey: 'type',
        cell: ({ row }) => {
          const contract = row.original;
          return (
            <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
              <span>{contract?.type?.name}</span>
              {contract.contract_type_id && contract.subscribers?.length > 0 ? (
                <Tag className="w-min">
                  {contract.is_digital ? (
                    <>
                      {t('pages.edocuments.contractlist.table.digital')}
                      <FaFileCode />
                    </>
                  ) : (
                    <>
                      {t('pages.edocuments.contractlist.table.manual')}
                      <FaFileUpload />
                    </>
                  )}
                </Tag>
              ) : contract?.type ? (
                t('pages.edocuments.contractlist.table.signatureTypeNotDefined')
              ) : (
                t('pages.edocuments.contractlist.table.contractTypeNotDefined')
              )}
            </div>
          );
        },
      },
      {
        header: t('provider'),
        accessorKey: 'provider',
        cell: ({ row }) => {
          const contract = row.original;
          return <span className="whitespace-nowrap">{contract.provider_name ?? t('notProvided')}</span>;
        },
      },
      {
        header: t('products'),
        accessorKey: 'products',
        cell: ({ row }) => {
          const contract = row.original;
          return contract?.product?.name ? (
            <Tag className="p-[0px_10px] w-min text-gray-700 bg-gray-300">
              <GiPeanut />
              {contract?.product?.name}
            </Tag>
          ) : (
            ''
          );
        },
      },
      {
        header: t('harvest'),
        accessorKey: 'harvest',
        cell: ({ row }) => {
          const contract = row.original;
          return <span>{contract?.harvest?.name}</span>;
        },
      },
      {
        header: t('buy-type'),
        accessorKey: 'buy-type',
        cell: ({ row }) => {
          const contract = row.original;
          return <span>{contract?.purchase_type?.name}</span>;
        },
      },
      {
        header: t('status'),
        accessorKey: 'status',
        cell: ({ row }) => {
          const contract = row.original;
          if (!contract.status) return '';
          const tagData = getContractStatusDataById(contract.status.id);
          const refusalStatus = contract.refusals?.length
            ? getContractStatusDataById(
                `${contract.refusals[contract.refusals?.length - 1].new_contract_status_id}-refused`,
              )
            : null;
          const shouldShowRefusalStatus =
            contract.status.id === CONTRACT_STATUS_REISSUE_DRAFT ||
            contract.status.id === CONTRACT_STATUS_REQUEST_NEW_SIGNATURES;
          if (tagData) {
            const { background, color, icon: Icon } = tagData;

            const statusTag = (
              <span style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Tag
                  className="text-[var(--tag-color)] bg-[var(--tag-background)] p-[0px_10px] w-min"
                  style={
                    {
                      '--tag-color': color,
                      '--tag-background': background,
                    } as React.CSSProperties
                  }
                >
                  <Icon />
                  {contract.status.name}
                </Tag>
                {refusalStatus && shouldShowRefusalStatus && (
                  <Tag
                    className="text-[var(--tag-color)] bg-[var(--tag-background)] p-[0px_10px] w-min"
                    style={
                      {
                        '--tag-color': refusalStatus.color,
                        '--tag-background': refusalStatus.background,
                      } as React.CSSProperties
                    }
                  >
                    <Icon />
                    {refusalStatus.name}
                  </Tag>
                )}
              </span>
            );

            return refusalStatus ? (
              <Popover>
                <PopoverTrigger>{statusTag}</PopoverTrigger>
                <PopoverContent>
                  <RefusalsPopover refusals={contract.refusals} contractData={contract} />
                </PopoverContent>
              </Popover>
            ) : (
              statusTag
            );
          }

          return '';
        },
      },
      {
        header: t('status-sap-datasul'),
        accessorKey: 'external_status',
        cell: ({ row }) => {
          const contract = row.original;
          return <span>{contract?.external_status}</span>;
        },
      },
      {
        header: t('signatures'),
        accessorKey: 'subscribers',
        cell: ({ row }) => {
          const contract = row.original;
          return (
            contract?.subscribers?.length > 0 && (
              <Popover>
                <PopoverTrigger>
                  <div className="flex overflow-x-hidden gradient-mask-r-0">
                    {contract.subscribers?.map((signature: any) => (
                      <UserIcon
                        key={signature.name}
                        letter={signature.name.charAt(0)}
                        offsetSize="25px"
                        status={signature.status}
                        signedAt={signature.signed_at}
                      />
                    ))}
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <SignaturesPopover signatures={contract?.subscribers} />
                </PopoverContent>
              </Popover>
            )
          );
        },
      },
      {
        header: t('signatures'),
        accessorKey: 'subscribers',
        cell: ({ row }) => {
          const contract = row.original;
          const percent = (contract?.validity.days * 100) / 30;
          // const percent = (10 * 100) / 30;
          return (
            <span>
              {contract?.validity ? (
                <div className="flex gap-[10px] items-center text-[12px] w-[150px]">
                  <Calendar size={14} />
                  <span
                    style={{
                      lineHeight: '34px',
                      height: '35px',
                    }}
                  >
                    {contract?.validity.days}
                  </span>
                  <Progress
                    value={percent}
                    className="bg-orange-200 border border-orange-500"
                    barClassName="bg-[var(--progress-bar-color)]"
                    style={
                      {
                        '--progress-bar-color': contract?.validity?.data?.color,
                      } as React.CSSProperties
                    }
                  />
                </div>
              ) : (
                <div className="flex gap-[10px] items-center text-[12px] w-[150px]">
                  <FaHourglassStart /> {t('pages.edocuments.contractlist.table.notValidity')}
                </div>
              )}
            </span>
          );
        },
      },
      {
        header: t('justifications'),
        accessorKey: 'comments',
        cell: ({ row }) => {
          const contract = row.original;
          return (
            <Tag className="p-[0px_10px] w-min text-blue-950 bg-blue-200">
              <FaComment />
              {contract.comments?.length ?? 0}
            </Tag>
          );
        },
      },
      {
        header: t('active'),
        accessorKey: 'active',
        cell: ({ row }) => {
          const contract = row.original;
          return <Switch checked={contract.active} onValueChange={() => handleSwitchChange(contract)} />;
        },
      },
      {
        header: t('datasource'),
        accessorKey: 'datasource',
        cell: ({ row }) => {
          const contract = row.original;
          return <span>{contract.datasource.toLowerCase() === ContractDatasource.SAP ? 'SAP' : 'DATASUL'}</span>;
        },
      },
      {
        id: 'actions',
        enablePinning: true,
        accessorKey: 'action',
        header: t('actions'),
        enableSorting: false,
        cell: ({ row }) => {
          const record = row.original;
          return (
            <a
              href={`emission/${record.parent_id ?? record.id}${record.parent_id ? `?related=${record.id}` : ''}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconWithTooltip
                aria-label={t('pages.edocument.contractlist.table.details')}
                action="detail"
                title={t('pages.edocument.contractlist.table.details')}
              />
            </a>
          );
        },
      },
    ],
    [acessoryId, getContractsQuery.data],
  );

  if (getContractsQuery.data?.data?.length === 0 && getContractsQuery.isSuccess && filter?.search?.includes('-'))
    return (
      <Loading isLoading={getContractsQuery.isFetching} className="w-full">
        <div className="h-full w-full flex justify-center items-center">
          <EmptyTable hasSearched={filter.search} />
        </div>
      </Loading>
    );

  return (
    <DataTableAsync
      dispatchPagination={dispatchPagination}
      paginationConfig={paginationConfig}
      columns={columns}
      fixedHeader
      getRowCanExpand={row => row.original.dependencies?.length > 0 && !acessoryId}
      data={getContractsQuery?.data?.data ?? []}
      withPagination
      expandedRowContent={row => {
        if (!row.getCanExpand()) return null;
        return (
          <tr>
            <td colSpan={row.getVisibleCells().length} aria-label="col">
              <div className="p-4">
                <ContractTable acessoryId={row.original.id} />
              </div>
            </td>
          </tr>
        );
      }}
      onSortingChange={newSort => {
        if (typeof newSort === 'function') {
          const newSorting = newSort(
            paginationConfig.sort.map(sort => ({ id: sort.field, desc: sort.order === 'desc' })),
          );
          dispatchPagination({
            payload: newSorting.map(sort => ({
              field: sort.id,
              order: sort.desc ? 'desc' : 'asc',
            })),
            type: 'SET_SORT',
          });
        }
      }}
      isLoading={getContractsQuery?.isLoading || toggleContractActiveMutation?.isPending}
      isFetching={getContractsQuery?.isFetching || toggleContractActiveMutation?.isPending}
      pageCount={getContractsQuery?.data?.last_page ?? 0}
      defaultColumn={{
        enableSorting: true,
      }}
    />
  );
};
