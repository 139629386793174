import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { ModalStatus } from 'components';
import { Button } from 'components/ui/button';
import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input } from 'components/ui/input';
import Loading from 'components/ui/loading';
import { PageTitle } from 'components/ui/page-title';
import { divisions } from 'configs/divisions';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { usePage } from 'hooks/page';
import PermissionGroupFormData from 'types/PermissionGroup/PermissionGroupFormData';
import { Converter } from 'utils/Converter';

import { useGetPermissionGroupQuery } from './api/use-get-permission-group.query';
import { useStorePermissionGroupMutation } from './api/use-store-permission-group.mutation';
import { useUpdatePermissionGroupMutation } from './api/use-update-permission-group.mutation';

const initialValues: PermissionGroupFormData = {
  id: '',
  name: '',
  phases: [],
  status: [],
  operations: [],
  sub_divisions: [],
  teams: [],
  divisions: [],
};

export const PermissionGroupsForm = () => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: initialValues,
  });
  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();
  const { getCreditOptions, team_credit_status_phase, team_credit_status, division } = useCache();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const { id: idParam } = params;

  const selectedDivisions = form.watch('divisions');
  const selectedSubDivisions = form.watch('sub_divisions');

  const editing = idParam !== 'new';

  const storePermissionGroupMutation = useStorePermissionGroupMutation();
  const updatePermissionGroupMutation = useUpdatePermissionGroupMutation();
  const getPermissionGroupQuery = useGetPermissionGroupQuery(idParam !== 'new' ? idParam : '');

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = async (values: PermissionGroupFormData) => {
    await updatePermissionGroupMutation.mutateAsync(
      {
        ...values,
      },
      {
        onSuccess: () => {
          handleSuccess(t('permission-group-updated-with-success'));
          updateUserData();
        },
        onError: err => {
          alertStatus(err, 'error');
        },
      },
    );
  };

  const handleRegister = async (values: PermissionGroupFormData) => {
    await storePermissionGroupMutation.mutateAsync(
      {
        ...values,
      },
      {
        onSuccess: data => {
          handleSuccess(t('permission-group-created-with-success'));
          navigate(`/admin/permission-groups/${data.id}`);
        },
        onError: err => {
          alertStatus(err, 'error');
        },
      },
    );
  };

  const operation = getCreditOptions('operation');

  const subdivisionByDivision = useMemo(() => {
    const subDivisions = [...new Set(selectedDivisions?.map((division: string) => divisions[division]).flat())];
    return getCreditOptions('sub_division').filter(subdivision => subDivisions.includes(subdivision.value));
  }, [selectedDivisions, operation, getCreditOptions('sub_division')]);

  const handleChangeDivision = (value: string | string[]) => {
    if (!value || !Array.isArray(value)) return form.setValue('sub_divisions', []);
    if (!value.length) return form.setValue('sub_divisions', []);
    const subDivisions = [...new Set(value.map((division: string) => divisions[division]).flat())];
    const filteredSubdivisions = selectedSubDivisions.filter(subdivision => subDivisions.includes(subdivision));
    form.setValue('sub_divisions', filteredSubdivisions.length > 0 ? filteredSubdivisions : []);
  };

  useEffect(() => {
    form.setFocus('name');
  }, []);

  useEffect(() => {
    if (getPermissionGroupQuery.data) {
      form.reset({
        ...getPermissionGroupQuery.data,
        phases: getPermissionGroupQuery.data.phases.map(r => r.id),
        status: getPermissionGroupQuery.data.status.map(r => r.id),
        operations: getPermissionGroupQuery.data.operations.map(r => r.id),
        teams: getPermissionGroupQuery.data.teams.map(r => r.id),
        sub_divisions: getPermissionGroupQuery.data.sub_divisions.map(r => r.id),
        divisions: getPermissionGroupQuery.data.divisions.map(r => r.id),
      });
    }
  }, [getPermissionGroupQuery.data]);

  return (
    <div className="p-4">
      <PageTitle>{editing ? t('edit-permission-group') : t('register-permission-group')}</PageTitle>
      <Loading
        isLoading={
          updatePermissionGroupMutation.isPending ||
          storePermissionGroupMutation.isPending ||
          getPermissionGroupQuery.isFetching
        }
      >
        <Form form={form} onSubmit={form.handleSubmit(editing ? handleEdit : handleRegister)} className="mt-6">
          <FormGrid>
            <FormItem
              name="name"
              label={t('group-name')}
              rules={{
                required: true,
              }}
            >
              <Input maxLength={255} />
            </FormItem>
          </FormGrid>

          <FormGrid>
            <FormItem name="phases" label={t('phases')} rules={{ required: true }}>
              <ComboBox
                options={Converter.selectOptionArray(team_credit_status_phase, 'id', 'id', 'name')}
                mode="multi"
                selectAll
              />
            </FormItem>
            <FormItem name="teams" label={t('teams')} rules={{ required: true }}>
              <ComboBox options={getCreditOptions('team')} mode="multi" selectAll />
            </FormItem>
          </FormGrid>

          <FormGrid>
            <FormItem name="status" label={t('status')} rules={{ required: true }}>
              <ComboBox
                options={Converter.selectOptionArray(team_credit_status, 'id', 'id', 'name')}
                categoryKey="data.phase_id"
                categoryRender={phaseId => (
                  <span className="text-md p-2 font-bold text-primary">
                    {phaseId === 'other'
                      ? t('other')
                      : team_credit_status_phase.find(phase => phase.id === phaseId)?.name}
                  </span>
                )}
                mode="multi"
                selectAll
              />
            </FormItem>
            <FormItem name="operations" label={t('operations')} rules={{ required: true }}>
              <ComboBox options={getCreditOptions('operation')} mode="multi" selectAll />
            </FormItem>
          </FormGrid>

          <FormGrid>
            <FormItem name="divisions" label={t('divisions')} rules={{ required: true }}>
              <ComboBox
                options={Converter.selectOptionArray(division, 'id', 'id', 'name')}
                mode="multi"
                selectAll
                onChange={e => {
                  handleChangeDivision(e.target.value);
                }}
              />
            </FormItem>
            <FormItem name="sub_divisions" label={t('sub-divisions')} rules={{ required: true }}>
              <ComboBox options={subdivisionByDivision} mode="multi" selectAll disabled={!selectedDivisions?.length} />
            </FormItem>
          </FormGrid>

          <div className="mt-6 flex w-full justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate('/admin/permission-groups')}
              isLoading={updatePermissionGroupMutation.isPending || storePermissionGroupMutation.isPending}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              isLoading={updatePermissionGroupMutation.isPending || storePermissionGroupMutation.isPending}
            >
              {t('save')}
            </Button>
          </div>
        </Form>
      </Loading>
    </div>
  );
};
