import { create, StateCreator } from 'zustand';

import {
  createDocumentToCollectFormStateSlice,
  DocumentToCollectFormStateSlice,
} from './slices/package-form-state-slices/document-to-collect-form-state.slice';

export type Subform = 'checklist_document_reference';

export type PackageFormStateSlice = {
  resetFormState: () => void;
  documentBeingRemovedId: string | null;
  setDocumentBeingRemovedId: (documentBeingRemovedId: string | null) => void;
};

export const packgeFormStateSliceInitialState = {
  documentBeingRemovedId: null,
} as PackageFormStateSlice;

export const createPackageFormStateSlice: StateCreator<PackageFormStateSlice, [], [], PackageFormStateSlice> = set => ({
  ...packgeFormStateSliceInitialState,
  resetFormState: () => set(packgeFormStateSliceInitialState),
  setDocumentBeingRemovedId: documentBeingRemovedId => set({ documentBeingRemovedId }),
});

export const usePackageFormStateStore = create<PackageFormStateSlice & DocumentToCollectFormStateSlice>()((...a) => ({
  ...createPackageFormStateSlice(...a),
  ...createDocumentToCollectFormStateSlice(...a),
}));
