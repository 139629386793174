import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import { ModalStatus } from "components";
import type { ContractTemplateData } from "types/Contract/ContractTemplateData";
import { DTOErrorResponse } from "types/DTOReports";

import DTOErrorReponse from "../../../../@types/dtos/DTOErrorReponse";
import { useContract } from "../../../../hooks/fetch/useContract";
import { useContractType } from "../../../../hooks/fetch/useContractType";
import { useDraft } from "../../../../hooks/fetch/useDraft";
import { usePage } from "../../../../hooks/page";
import { formatValuesToFormData } from "../../../../services/form";
import { FormControllerProps } from "./types";

export const useFormController = ({
  closeForm,
  refresh,
  form,
  templateId,
}: FormControllerProps) => {
  const { t } = useTranslation();
  const { getFieldsFromDraftModel } = useDraft();
  const { updateTemplate, storeTemplate, showTemplate, isStoring } =
    useContract();
  const { getTypesOptions } = useContractType();
  const { alertStatus } = usePage();

  const [editedTemplate, setEditedTemplate] = useState<
    ContractTemplateData | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);
  const [hasTemplateFileChanged, setHasTemplateFileChanged] = useState(false);
  const [fileProcessedData, setFileProcessedData] = useState<any>();
  const [fields, setFields] = useState<any>({});
  const [uploadedFile, setUploadedFile] = useState<RcFile>();
  const [previousTemplateName, setPreviousTemplateName] = useState<string>("");

  const storeOrUpdate = async (values: any, action: string, id?: string) => {
    let formData = new FormData();

    if (!fileProcessedData) {
      ModalStatus({
        type: "error",
        title: t("modal.error"),
        subTitle: t("pages.edocuments.templates.form.attachmentRequired"),
      });
      return;
    }

    formData = formatValuesToFormData(
      { ...values, number: values.number.toString() },
      formData,
      [],
      ["template"],
    );
    fileProcessedData.fields
      .filter((field: any) => !field.isContractInput)
      .forEach((value: any, index: number) => {
        formData.append(`fields[${index}][key]`, value.key ?? "");
        formData.append(`fields[${index}][key_api]`, values[value.key] ?? "");
        if (id && !hasTemplateFileChanged)
          formData.append(`fields[${index}][id]`, value.id);
      });

    if (!id || hasTemplateFileChanged) {
      formData.append("template", uploadedFile);
    }

    // eslint-disable-next-line consistent-return
    return id ? updateTemplate(id, formData) : storeTemplate(formData);
  };

  const handleSend = async (values: any, action: string, id?: string) => {
    form.validateFields().then(async () => {
      const response = await storeOrUpdate(values, action, id);
      if (response) {
        refresh();
        ModalStatus({
          onOk: closeForm,
          type: "success",
          description: !id
            ? t("pages.edocuments.templates.form.store.success")
            : t("pages.edocuments.templates.form.edit.success"),
        });
        closeForm();
      }
    });
  };

  const handleEdit = (id: string, values: any) =>
    handleSend(values, "edit", id);

  const handleRegister = (values: any) => handleSend(values, "register");

  const processFile = async (file: RcFile) => {
    const hideProcessingMessage = message.loading(
      t("pages.edocuments.contracttypes.processingfile"),
      0,
    );
    const processedData = await getFieldsFromDraftModel(file);
    hideProcessingMessage();

    setFileProcessedData({
      ...processedData,
      fields: [
        ...(fileProcessedData?.fields || []),
        ...processedData.keys.map((key: string) => ({ key, display: "" })),
      ],
    });
  };

  const handleFileUpload = async (file: RcFile) => {
    const hasSameNameAsPrevious = file.name === previousTemplateName;
    if (!uploadedFile) {
      if (!file.name.includes(".docx")) {
        alertStatus(
          {
            message: `${file.name} ${t("pages.edocuments.contracttypes.notDocx")}`,
          } as DTOErrorReponse,
          "error",
        );
        return false;
      }
      setHasTemplateFileChanged(true);
      await processFile(file);
      if (editedTemplate) {
        setEditedTemplate({
          ...editedTemplate,
          url: "",
          fields: hasSameNameAsPrevious
            ? editedTemplate.fields.map((field: any) => ({
                ...field,
                value: "",
              }))
            : editedTemplate.fields,
          template: undefined,
        });
        if (!hasSameNameAsPrevious)
          editedTemplate.fields.map((field: any) =>
            form.setFieldsValue({ [field.key]: "" }),
          );
      }
      setUploadedFile(file);
    } else {
      alertStatus(
        {
          message: t("pages.edocuments.contracttypes.uploadOneFile"),
        } as DTOErrorResponse,
        "error",
      );
    }

    return false;
  };

  const handleFileRemove = () => {
    setUploadedFile(undefined);
    if (editedTemplate)
      setEditedTemplate({
        ...editedTemplate,
        url: "",
        template: undefined,
      });
    setFileProcessedData((oldFileProcessedData: any) => ({
      url: "",
      fields: oldFileProcessedData.fields.filter(
        (field: any) => field.isContractInput,
      ),
    }));
  };

  const handleCustomInputChangeValue = (key_api: string, key: string) => {
    const index = fileProcessedData.fields.findIndex(
      (field: { key: string; key_api: string }) => field.key === key,
    );

    const newFileProcessedData = { ...fileProcessedData };
    newFileProcessedData.fields[index] = {
      ...newFileProcessedData.fields[index],
      key_api,
    };

    setFileProcessedData({ ...newFileProcessedData });
  };

  const searchRequest = async (value: string, page?: number) => {
    const response = await getTypesOptions({ search: value, page }, "all");
    return response ?? { data: [] as any[], lastPage: 0 };
  };

  const getEditedTemplateData = async (id: string) => {
    setIsLoading(true);
    const templateData = await showTemplate(id);
    if (templateData) {
      setEditedTemplate(templateData);
      setUploadedFile(templateData.template);
      setFileProcessedData({
        url: templateData.url,
        fields: templateData.fields,
      });
      setPreviousTemplateName(templateData.template.name);
      form.setFieldsValue({ ...templateData });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (templateId) getEditedTemplateData(templateId);
  }, [templateId]);

  return {
    isLoading,
    setIsLoading,
    handleEdit,
    handleRegister,
    handleFileUpload,
    handleFileRemove,
    setUploadedFile,
    fileProcessedData,
    fields,
    setFields,
    handleCustomInputChangeValue,
    setFileProcessedData,
    searchRequest,
    editedTemplate,
    uploadedFile,
    isStoring,
  };
};
