import { List } from "antd";
import styled from "styled-components";

export const SListAttachmentsList = styled(List)`
  padding-left: 15px;
  padding-right: 15px;
`;

export const SListAttachmentsListItem = styled(List.Item)`
  padding-left: 15px;
  padding-right: 15px;

  .file-title {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 16px;
    display: flex;
  }

  .file-actions {
    display: flex;
  }
`;

export const CustomCol = styled.div`
  & h2:first-child {
    display: flex;
    flex-direction: column;
  }
`;
