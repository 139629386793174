import CreditRequestData from 'types/CreditRequest/CreditRequestData';
import { PageStatus } from 'types/CreditRequest/PageStatus';
import { create, StateCreator } from 'zustand';

import {
  AssigneesSlice,
  assigneesSliceInitialState,
  createAssigneesSlice,
} from './slices/credit-request-slices/assignees.slice';
import {
  createCreditRequestFormStateSlice,
  CreditRequestFormStateSlice,
  creditRequestFormStateSliceInitialState,
} from './slices/credit-request-slices/credit-request-form-state.slice';
import {
  createDivisionSlice,
  DivisionSlice,
  divisionSliceInitialState,
} from './slices/credit-request-slices/division.slice';
import {
  createHistoricSlice,
  HistoricSlice,
  historicSliceInitialState,
} from './slices/credit-request-slices/historic.slice';
import {
  createThirdProviderSlice,
  ThirdProviderSlice,
  thirdProviderSliceInitialState,
} from './slices/credit-request-slices/thirdProviders.slice';

export type CreditRequestSlice = {
  type: string;
  isPhysicalPerson: boolean;
  setIsPhysicalPerson: (isPhysicalPerson: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  pageStatus: PageStatus;
  setPageStatus: (pageStatus: PageStatus) => void;
  creditRequestId: string;
  setCreditRequestId: (creditRequestId: string) => void;
  creditRequestData?: CreditRequestData;
  setCreditRequestData: (creditRequestData?: CreditRequestData) => void;
  reset: () => void;
  manualProvider?: boolean;
  setManualProvider: (manualProvider: boolean) => void;
};

const initialValues = {
  isPhysicalPerson: false,
  isLoading: false,
  creditRequestId: '',
  creditRequestData: undefined,
  manualProvider: false,
} as CreditRequestSlice;

const createCreditRequestSlice: StateCreator<CreditRequestSlice, [], [], CreditRequestSlice> = _set => ({
  ...initialValues,
  type: 'creditRequest',
  pageStatus: {
    new: false,
    editing: false,
    viewing: false,
  },
  setIsPhysicalPerson: (isPhysicalPerson: boolean) => _set(state => ({ ...state, isPhysicalPerson })),
  setIsLoading: (isLoading: boolean) => _set(state => ({ ...state, isLoading })),
  setPageStatus: (pageStatus: PageStatus) => _set(state => ({ ...state, pageStatus })),
  setCreditRequestId: (creditRequestId: string) => _set(state => ({ ...state, creditRequestId })),
  setCreditRequestData: (creditRequestData?: CreditRequestData) => _set(state => ({ ...state, creditRequestData })),
  setManualProvider: (manualProvider: boolean) => _set(state => ({ ...state, manualProvider })),
  reset: () =>
    _set({
      ...initialValues,
      ...divisionSliceInitialState,
      ...creditRequestFormStateSliceInitialState,
      ...historicSliceInitialState,
      ...thirdProviderSliceInitialState,
      ...assigneesSliceInitialState,
    }),
});

export const useCreditRequestStore = create<
  AssigneesSlice & DivisionSlice & CreditRequestSlice & CreditRequestFormStateSlice & ThirdProviderSlice & HistoricSlice
>()((...a) => ({
  ...createDivisionSlice(...a),
  ...createCreditRequestSlice(...a),
  ...createCreditRequestFormStateSlice(...a),
  ...createThirdProviderSlice(...a),
  ...createHistoricSlice(...a),
  ...createAssigneesSlice(...a),
}));
