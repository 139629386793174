import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from '@radix-ui/react-dialog';
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from 'components/ui/dialog';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

import { AssigneesForm } from './assignees.form';

export type AssigneesModalProps = DialogProps & { children: React.ReactNode };

export const AssigneesModal = ({ children, ...props }: AssigneesModalProps) => {
  const { t } = useTranslation();
  const assigneeToEdit = useCreditRequestStore(state => state.assigneeToEdit);
  const isAssigneeModalOpen = useCreditRequestStore(state => state.isAssigneeModalOpen);
  const setIsAssigneeModalOpen = useCreditRequestStore(state => state.setAssigneeModalOpen);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <Dialog {...props} open={isAssigneeModalOpen} onOpenChange={setIsAssigneeModalOpen}>
      <DialogTrigger onClick={e => e.stopPropagation()} disabled={pageStatus.viewing}>
        {children}
      </DialogTrigger>
      <DialogContent bodyClassName="overflow-hidden flex flex-col p-0" onClick={e => e.stopPropagation()}>
        <DialogHeader>{assigneeToEdit ? t('edit-assignee') : t('new-assignee')}</DialogHeader>
        <AssigneesForm />
      </DialogContent>
    </Dialog>
  );
};
