import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';
import { CreditRequestValidator } from 'utils/CreditRequestValidator';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
}

export const QuantityReceivedInput = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { ADVANCE_PRODUCT_DELIVERED_RULES } = useCreditRequestRules();
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_quantity_received')}
      name="json_data.quantity_received"
      rules={[
        {
          validator: async (_, value) => {
            const formValues = creditForm.getFieldsValue();
            const quantityContract = Number(formValues['json_data.quantity_contract']);
            if (selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
              const validation = ADVANCE_PRODUCT_DELIVERED_RULES.receivedQuantityEqualsContractQuantityValidate(
                selectedOperationId,
                value,
                quantityContract,
              );
              if (validation) {
                return Promise.reject(new Error(validation));
              }
              return Promise.resolve();
            }

            const quantityKg = Number(formValues['json_data.quantity_kg']);
            const quantityAvailable = Number(formValues['json_data.quantity_delivery']);

            creditForm.setFields([
              {
                name: 'json_data.quantity_kg',
                errors: !CreditRequestValidator.volume(quantityKg, quantityContract, value, quantityAvailable)
                  ? [t('pages.credit-request.messages.contract_volume_exceeded')]
                  : [],
              },
            ]);
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.integer(value)}
        parser={(value: string) => Formatter.integerParser(value)}
        disabled={
          pageStatus.viewing ||
          (contractFieldsValue && contractFieldsValue['json_data.quantity_received'] !== undefined) ||
          disabled
        }
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};
