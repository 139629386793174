import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { useAbility } from 'hooks/ability';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import { UserFormData } from 'types/User/UserFormData';

export const useStoreUserMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  const ability = useAbility();

  return useMutation({
    mutationFn: async (data: UserFormData) => {
      if (!ability.can('admin.user.store', '')) {
        return alert({
          title: t('no-permission'),
          description: t('you-dont-have-permission-to-do-that'),
          type: 'error',
        });
      }
      const response = await api.post(`admin/user`, data);
      return response.data.data;
    },
    mutationKey: ['storeUser'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
