import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/use-credit-request-store';

export const NumberInput = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return !pageStatus.new ? (
    <FormItem label={t('pages.credit-request.form.number')} name="number">
      <Input disabled />
    </FormItem>
  ) : null;
};
