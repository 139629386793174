import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { DTOErrorResponse } from "types/DTOReports";
import { Formatter } from "utils/Formatter";

import DTOCprModel from "../../../../@types/dtos/monitoring/DTOCprModel";
import DTORegistrationModel from "../../../../@types/dtos/monitoring/DTORegistrationModel";
import DTOSurveyStage from "../../../../@types/dtos/monitoring/DTOSurveyStage";
import DTOOperator from "../../../../@types/dtos/monitoring/SurveyRequest/DTOOperator";
import { useAuth } from "../../../../hooks/auth";
import { useCpr } from "../../../../hooks/fetch/useCpr";
import { useOperator } from "../../../../hooks/fetch/useOperator";
import { useRegistration } from "../../../../hooks/fetch/useRegistration";
import { useSurvey } from "../../../../hooks/fetch/useSurvey";
import { useSurveyRequest } from "../../../../hooks/fetch/useSurveyRequest";
import { useModalMessage } from "../../../../hooks/helpers/useModalMessage";
import { ItemsType, usePage } from "../../../../hooks/page";
import { formatValuesToFormData } from "../../../../services/form";
import { SurveyRequestParams, SurveyRequestFormProps } from "./types";

export const useFormController = ({
  form,
  modal,
  setAttachments,
  cprId,
  registrationId,
}: SurveyRequestFormProps) => {
  const { alertStatus } = usePage();
  const { handleSuccess } = useModalMessage();
  const { surveyRequest: surveyRequestId } = useParams<SurveyRequestParams>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useAuth();

  const {
    getOptions: getCprs,
    isFetching: isFetchingCprs,
    getById: getCprById,
  } = useCpr();
  const { getOptions: getOperators, isFetching: isFetchingOperators } =
    useOperator();
  const { isFetching: isFetchingRegistrations, getOptions: getRegistrations } =
    useRegistration();
  const { getTypes: getSurveyTypes, getStageOptions } = useSurvey();
  const {
    store: storeSurveyRequest,
    update: updateSurveyRequest,
    getById: getSurvey,
  } = useSurveyRequest();

  const [registrations, setRegistrations] = useState<ItemsType[]>([]);
  const [surveyTypes, setSurveyTypes] = useState<ItemsType[]>();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [selectedCpr, setSelectedCpr] = useState<DTOCprModel>();
  const [selectedRegistration, setSelectedRegistration] =
    useState<DTORegistrationModel>();
  const [previousSurveyStageOption, setPreviousSurveyStageOption] =
    useState<ItemsType>();
  const [currentCpr, setCurrentCpr] = useState<DTOCprModel>();
  const [currentRegistration, setCurrentRegistration] =
    useState<DTORegistrationModel>();
  const [currentSurveyStage, setCurrentSurveyStage] =
    useState<DTOSurveyStage>();
  const [currentOperator, setCurrentOperator] = useState<DTOOperator>();
  const [kmls, setKmls] = useState<any[]>([]);

  const toggleLoading = (state: boolean) => {
    setIsLoading(state);
    setIsLoadingButton(state);
    setIsDisabledButton(state);
  };

  const canEdit = () => {
    return permissions?.includes("monitoring.surveyrequest.update");
  };

  const setPreviousSurveyStage = (surveyStage: DTOSurveyStage) =>
    setPreviousSurveyStageOption({
      key: surveyStage.id,
      value: surveyStage.id,
      label: surveyStage.name,
    });

  const handleChangeCpr = (cpr: DTOCprModel) => {
    form.setFieldsValue({ registration_id: null });
    setSelectedCpr(cpr);
    loadRegistrations(cpr.id);
  };

  const loadRegistrations = async (id: string) => {
    const response = await getRegistrations({
      params: {
        cpr_id: id,
      },
    });
    setRegistrations(response?.data || []);
  };

  const getCprsOptions = async (value: string, page: number) =>
    getCprs({ params: { search: value, page } });

  const getRegistrationsOptions = async (value: string, page: number) =>
    getRegistrations({
      params: { search: value, page, cpr_id: selectedCpr.id },
    });

  const handleCancel = () => {
    if (modal) modal.closeModal();
    else navigate(-1);
  };

  const loadSurveyRequestData = async () => {
    toggleLoading(true);
    const surveyData = await getSurvey(surveyRequestId);

    if (surveyData) {
      const { documents, ...surveyRequest } = surveyData;
      form.setFieldsValue({
        ...surveyRequest,
        cpr_id: surveyRequest.registration.cpr_id,
        deadline: surveyRequest.deadline
          ? Formatter.date(surveyRequest.deadline, true)
          : null,
      });

      setCurrentRegistration(surveyRequest.registration);
      setCurrentCpr(surveyRequest.registration.cpr);
      setCurrentSurveyStage(surveyRequest.survey_stage);
      setCurrentOperator(surveyRequest.operator);
      setKmls(surveyRequest.cars);

      setAttachments(documents);
      if (surveyRequest.registration)
        loadRegistrations(surveyRequest.registration.cpr_id);
      if (surveyRequest.survey_stage)
        setPreviousSurveyStage(surveyRequest.survey_stage);
      setIsEditing(true);
    }
    toggleLoading(false);
  };

  const loadSurveyTypes = async () => setSurveyTypes(await getSurveyTypes());

  const loadSurveyStages = (value: string, page: number) =>
    getStageOptions({ params: { search: value, page } });

  const getSendCallback = () =>
    modal ? modal.closeModal() : navigate(`/monitoring/surveyrequest`);

  const handleSend = (type: string) => {
    toggleLoading(true);
    form
      .validateFields()
      .then(async (values: any) => {
        let formData = new FormData();
        formData = formatValuesToFormData(values, formData, ["documents[]"]);
        const isEdit = type.toLowerCase() === "edit";
        const response = await (isEdit
          ? updateSurveyRequest(values.id, values)
          : storeSurveyRequest(formData));

        if (response)
          handleSuccess(
            isEdit
              ? t("pages.monitoring.surveyrequest.form.register.success")
              : t("pages.monitoring.surveyrequest.form.update.success"),
            () => getSendCallback(),
          );
      })
      .catch((err: DTOErrorResponse) => {
        alertStatus(err, "error");
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const handleRegister = () => handleSend("register");
  const handleEdit = () => handleSend("edit");

  const handleChangeRegistration = (registration: any) =>
    setSelectedRegistration(registration);

  useEffect(() => {
    if (surveyRequestId) loadSurveyRequestData();
    loadSurveyTypes();
  }, []);

  useEffect(() => {
    if (cprId) {
      (async () => {
        handleChangeCpr(await getCprById(cprId));
        form.setFieldsValue({ cpr_id: cprId });
      })();
    }
  }, [cprId]);

  useEffect(() => {
    if (registrationId) {
      form.setFieldsValue({ registration_id: registrationId });
    }
  }, [registrationId]);

  return {
    form,
    registrations,
    surveyTypes,
    loadSurveyTypes,
    loadSurveyStages,
    getCprs,
    getOperators,
    isFetchingCprs,
    isFetchingOperators,
    handleRegister,
    handleEdit,
    isFetchingRegistrations,
    isLoadingButton,
    isDisabledButton,
    isLoading,
    isEditing,
    previousSurveyStageOption,
    handleChangeCpr,
    handleCancel,
    getCprsOptions,
    getRegistrationsOptions,
    selectedCpr,
    setSelectedCpr,
    setCurrentCpr,
    currentCpr,
    setCurrentRegistration,
    currentRegistration,
    handleChangeRegistration,
    selectedRegistration,
    canEdit,
    currentSurveyStage,
    currentOperator,
    kmls,
  };
};
