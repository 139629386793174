import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ColumnType } from "antd/lib/table";
import { TableData } from "compositions/TableDataAsync";
import { useTable } from "compositions/TableDataAsync/hooks/useTable";
import { TableTranslation } from "compositions/TableDataAsync/types";

import { IconWithTooltip, Button } from "../../../../components";
import { Formatter } from "../../../../utils/Formatter";
import { Report } from "../../components";
import { TableSearch } from "../../components/Table/TableSearch";
import { Title } from "../../styles";
import { useManagement } from "../hooks/useManagement";
import { columns } from "./columns";
import { PropsResume } from "./types";

export const TableResume = ({
  getReport,
  generateExport,
  searchFilters,
  setSearchFilters,
  searchForm,
  generateReportPermissions = [],
  listReportPermissions = [],
}: PropsResume) => {
  const tableController = useTable();
  const [exportingGeneral, setExportingGeneral] = useState(false);
  const { handleChangeTableResume, isFetchingResume } = useManagement();
  const { t } = useTranslation();

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };
    const getValue = (record: any) => {
      let value = "";
      if (record) {
        value =
          record !== "0" ? Formatter.abbreviated(parseInt(record, 10)) : "";
      }
      return value;
    };
    switch (column.key) {
      case "requested_amount":
        column = {
          ...column,
          render: (record: any) => {
            return getValue(record);
          },
        };
        break;
      case "approved_value":
        column = {
          ...column,
          render: (record: any) => {
            return getValue(record);
          },
        };
        break;
      case "approval":
        column = {
          ...column,
          render: (record: any) => {
            return getValue(record);
          },
        };
        break;
      case "disapproved":
        column = {
          ...column,
          render: (record: any) => {
            return getValue(record);
          },
        };
        break;
      case "status":
        column = {
          ...column,
          render: (record: any, data: any) => {
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  background: data.color,
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <span>{record}</span>
              </div>
            );
          },
        };
        break;
      default:
        break;
    }
    return column;
  };

  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Link to={`/credit-request/view/${record.id}`} target="_blank">
          <IconWithTooltip
            action="view"
            title={t("pages.dashboard.resume.viewAction")}
          />
        </Link>
      </>
    ),
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>{t("pages.dashboard.resume.title")}</Title>
        <Button
          status="primary"
          onClick={() => {
            setExportingGeneral(true);
          }}
          permission={[...listReportPermissions, ...generateReportPermissions]}
        >
          {t("pages.sustainability.form.buttonExport")}
        </Button>
      </div>

      <TableSearch
        setSearchFilters={setSearchFilters}
        searchForm={searchForm}
        placeholder={t("pages.dashboard.search.placeholder")}
      />
      <TableData
        searchRequest={handleChangeTableResume}
        searchFilters={searchFilters}
        customColumns={customColumns}
        columns={columns}
        width="100%"
        pagination={{ position: ["bottomCenter"] }}
        controller={tableController}
        translation={
          {
            pagination: { totalOfRegisters: t("pagination.totalOfRegisters") },
          } as TableTranslation
        }
        rowKey="id"
        scroll={{ x: 1500 }}
        loading={isFetchingResume}
        tableConfig={{
          getActions,
        }}
      />
      <Report
        searchFilters={searchFilters}
        exportingGeneral={exportingGeneral}
        setExportingGeneral={setExportingGeneral}
        getReport={getReport}
        generateExport={generateExport}
      />
    </div>
  );
};
