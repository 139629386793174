import React, { useEffect, useMemo, useState } from 'react';

import { RadioGroupProps } from '@radix-ui/react-radio-group';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { useCreditStatus } from 'hooks/creditStatus';
import { useKanbanPhases } from 'hooks/useKanbanPhases';
import { kanbanConfigDefault, KanbanModuleConfig } from 'types/User/UserData';

import { TeamStatusOptionType } from '../../../../../@types/hooks/creditStatus';
import { KanbanRadioFilter } from './kanban-radio-filter';
import { teamsCredit } from './kanbanOptions';

type Props = RadioGroupProps;

export const PhaseFilter = ({ ...props }: Props) => {
  const { getTeam, getTeamBySlug, getListOfPhasesOrdered, getListOfTeamStatusOrderedTargetRules } = useCreditStatus();
  const { getLastSelectedPhase } = useKanbanPhases();
  const { team_credit_status_phase } = useCache();
  const {
    user: { config: userConfig },
    updateUserConfig,
  } = useAuth();

  const listPhases: TeamStatusOptionType[] | null = useMemo(() => {
    return getListOfPhasesOrdered(true) as TeamStatusOptionType[];
    // eslint-disable-next-line
  }, [getTeamBySlug, getTeam, team_credit_status_phase, getListOfTeamStatusOrderedTargetRules]);

  const [value, setValue] = useState(props.defaultValue || (listPhases.length ? listPhases[0].value.toString() : ''));
  const [userTeamsConfigs, setUserTeamsConfigs] = useState<KanbanModuleConfig | null>();

  const handleValueChange = (value: string) => {
    setValue(value);
    props.onValueChange?.(value);

    const updatedConfig = {
      ...userTeamsConfigs,
      lastPhase: value,
    } as any;

    setUserTeamsConfigs(updatedConfig);
    userConfig.modules.credit.kanban = updatedConfig;
    updateUserConfig(userConfig);
  };

  useEffect(() => {
    const configs = userConfig.modules.credit.kanban;
    if (!configs) {
      const creditKanbanDefault: KanbanModuleConfig = {} as KanbanModuleConfig;
      Object.keys(teamsCredit).forEach((value, index) => {
        creditKanbanDefault[teamsCredit[index]] = kanbanConfigDefault;
      });
      setUserTeamsConfigs(creditKanbanDefault);
    } else {
      setUserTeamsConfigs(configs);
      setValue(configs.lastPhase as any);
      if (configs.lastPhase) {
        props.onValueChange?.(configs.lastPhase as any);
      } else {
        props.onValueChange?.(team_credit_status_phase.length > 0 ? team_credit_status_phase[0].id.toString() : '');
      }
    }
  }, [userConfig, teamsCredit, userConfig.modules.credit.kanban]);

  useEffect(() => {
    if (!value) {
      const listPhases = getListOfPhasesOrdered(true) as TeamStatusOptionType[];

      const lastSelectedPhase = listPhases
        .filter(phase =>
          team_credit_status_phase.find(teamCreditStatusPhase => teamCreditStatusPhase.id === phase.value),
        )
        .find(phase => phase.key === getLastSelectedPhase())
        ?.value?.toString();

      if (lastSelectedPhase) {
        setValue(lastSelectedPhase);
      } else {
        let findFirst = listPhases.find(phase => phase.order === 1);
        if (findFirst === undefined) {
          findFirst = listPhases.shift() ?? null;
        }
        setValue(findFirst?.value?.toString());
        props.onValueChange?.(findFirst?.value?.toString());
      }
    }
    // eslint-disable-next-line
  }, [getListOfPhasesOrdered]);

  return (
    <KanbanRadioFilter
      {...props}
      options={listPhases.map(phase => ({ value: phase.value.toString(), label: phase.label }))}
      defaultValue={props.defaultValue || (listPhases.length ? listPhases[0].value.toString() : '')}
      value={value}
      onValueChange={handleValueChange}
    />
  );
};
